import { Input } from "@hiyllo/ux/input";
import React from "react";
import { ErrorText } from "../../../../ux/error";

export const HDLEditor = React.memo(function HDLEditor(props: {
  value: string;
  onChangeValue: (value: string) => void;
}): JSX.Element {
  const [error, setError] = React.useState<string | null>(null);

  const validateHDLBlock = React.useCallback((script: string) => {
    setError(null);
    console.log("Importing HDL...");
    // void import("@hiyllo/hdl").then((HDL) => {
    //   console.log("Creating Workflow...");
    //   new HDL.Workflow(script, {})
    //     .compile()
    //     .then(() => {
    //       console.log("Compiled");
    //     })
    //     .catch((err) => {
    //       setError((err as Error).message);
    //     });
    // });
  }, []);

  return (
    <div>
      <Input
        fullWidth
        multiline
        value={props.value}
        onChangeValue={(v) => {
          validateHDLBlock(v);
          props.onChangeValue(v);
        }}
      />
      <ErrorText message={error} />
    </div>
  );
});
