import { openWindow } from "../../../platform/open-window";
import { CircleButton } from "@hiyllo/ux/circle-button";
import { EnhancedFileAsset } from "../../../types/stuff/asset";
import { styled } from "@hiyllo/ux/styled";
import React from "react";
import { faArrowDownToLine, faArrowUpLeftFromCircle } from "@fortawesome/pro-light-svg-icons";
import { useNavigateTo } from "@hiyllo/omni-continuity/main";
import { Features } from "../../../types/navigation/features";
import { FileGallery } from "../../../platform/file-gallery";


const Container = styled("div", {
  display: "flex",
  flexDirection: "column",
  height: "100%",
  width: "100%",
});

const HeaderBar = styled<"div", { inModal?: boolean; }>("div", ({ $theme, inModal }) => ({
  background: $theme.background3,
  height: inModal ? 60 : 50,
  display: "flex",
  flexDirection: "row",
  // justifyContent: "space-between",
  alignItems: "center",
  flexShrink: 0,
  paddingLeft: inModal ? 130 : 10,
  paddingRight: 10,
}));

const ContentsContainer = styled("div", {
  padding: 20,
  height: 0,
  flexGrow: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
});

export const FileAssetView = React.memo(function FileAssetView(props: {
  asset: EnhancedFileAsset;
  inModal?: boolean;
}): JSX.Element {
  const download = React.useCallback(() => {
    openWindow(props.asset.src);
  }, [props.asset.src]);

  const goUp = useNavigateTo({
    feature: Features.stuff,
    params: {
      view: 'container',
      uuid: props.asset.parent,
    }
  });

  return (
    <Container>
      <HeaderBar inModal={props.inModal}>
        {props.inModal !== true ?
          <CircleButton icon={faArrowUpLeftFromCircle} onClick={goUp} size={25} />
          : null}
        <div style={{ width: 10 }} />
        <div style={{ fontFamily: "hiyllo", fontSize: 15 }}>
          {props.asset.name}
        </div>
        <div style={{ flexGrow: 1 }} />
        <CircleButton icon={faArrowDownToLine} onClick={download} size={30} />
      </HeaderBar>
      <ContentsContainer>
        <FileGallery files={[{ src: props.asset.src }]} />
      </ContentsContainer>
    </Container>
  );
});