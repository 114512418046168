/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { AlertType } from "../../types/alerts/alert";
import { DocumentContents } from "../../types/stuff/document";

export const TopicID = `docs-editing`;
export type TopicNameType = `[${string}]docs-editing/${string}`;
export type SubscriptionParamsType = null;
export type MessageType = {
  editorId: string;
  userId: string;
  delta: any | null; // QuillDelta
  range: any; // QuillRange
};
export const TopicNameRegExp = new RegExp("/docs-editing\\/[A-z]*/");
export const SubscriptionParamsSchema = { type: "null" };

export type Typings = {
  TopicNameType: TopicNameType;
  SubscriptionParamsType: SubscriptionParamsType;
  MessageType: MessageType;
};
