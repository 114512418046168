/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { DateYYYYMMDD } from "../../../types/calendar/base";
import { BookATimeRange, BookATimeSchedule, BookATimeSlot, BookATimeSlotDuration } from "../../../types/calendar/book-a-time";

export type ParamsType = {
  token: string
};
export type ResponseType = {
  schedule: {
    name: string,
    slotDuration: BookATimeSlotDuration,
    range: BookATimeRange[]
  },
  slots: BookATimeSlot[]
};
export const Endpoint = 'calendar/book-a-time/get-scheduling-details';
export const Method = 'GET';

export interface Plug {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
}

export const isPublic = true;export const paramsSchema = {"type":"object","properties":{"token":{"type":"string"}},"required":["token"],"$schema":"http://json-schema.org/draft-07/schema#"}