/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
/**
 * Stuff Access Model
 *
 * All containers and assets exist in a hierarchy. The implicit access model grants access to containers
 * and assets based on the root
 *
 *
 */

import { ContinuityAccessType } from "../continuity/access";

export enum StuffAccessEnum {
  write = "write",
  read = "read",
}

/**
 * If inherit is specified, the access level is inherited from the parent container.
 * Immediate access traverses inherit up the tree to the first parent whose access
 * type is not inherit.
 *
 * If root is specified, the access is derived from the context of the root container
 * 
 * Note: A user is assumed to have read-level access to an asset regardless of the access
 * set on the asset if the user has access to the container that contains the asset. This does
 * not transfer to containers.
 */
export type StuffAccessType =
  | "inherit"
  | "root"
  | {
    explicit: {
      [StuffAccessEnum.write]: ContinuityAccessType;
      [StuffAccessEnum.read]: ContinuityAccessType;
    };
  };
