import React from "react";
import { seamlessClient } from "../seamless-client";
import { useAlertsContext } from "@hiyllo/omni-alerts";
import { useNavigate } from "@hiyllo/omni-router";
import { type AlertType, type AlertLinkType } from "../types/alerts/alert";
import { idleStateListener } from "../platform/idle";
import * as MarkAlertsReadBP from "../blueprints/alerts/mark-alerts-read";
import { Electron } from "../platform/electron";
import { openWindow } from "../platform/open-window";

function navigateToAlertLink(
  eNav: ReturnType<typeof useNavigate>,
  link: AlertLinkType,
): void {
  if ("href" in link) {
    openWindow(link.href);
  } else {
    eNav(link.enav);
  }
}

export const AlertsWebPushProvider = React.memo(function AlertsWebPushProvider(
  props: React.PropsWithChildren,
): null {
  const { alertsCountQuery, useOnAlertReceived } = useAlertsContext();
  const markAlertsRead =
    seamlessClient.useMutation<MarkAlertsReadBP.Plug>(
      MarkAlertsReadBP,
      { querySideEffects: [alertsCountQuery] },
    );
  const eNav = useNavigate();

  const onNavigate = React.useCallback(
    (alert: AlertType): void => {
      void markAlertsRead.call({ alertUUIDs: [alert.uuid] });
      if (alert.link !== null) {
        navigateToAlertLink(eNav, alert.link);
      }
    },
    [eNav, markAlertsRead],
  );

  React.useEffect(() => {
    try {
      void window.Notification.requestPermission();
    } catch (e) {
      console.warn(e);
    }
  }, []);

  useOnAlertReceived(async (alert) => {
    if (!idleStateListener.isActive) {
      try {
        void window.Notification.requestPermission().then((status) => {
          if (status === "granted") {
            if (document.hasFocus() || alert == null || window.localStorage.muteAlerts === "1") return;
            const newAlert = alert;
            // eslint-disable-next-line no-new
            const notf = new window.Notification(newAlert.title, {
              body: newAlert.body ?? undefined,
              icon: "/hiyllo-work/gradient.png",
            });
            notf.onclick = () => {
              onNavigate(newAlert as AlertType);
              window.focus();
              if (Electron.isElectron) {
                Electron.callAPI("focus");
              }
              notf.close();
            };
          } else {
            console.log("Notifications disabled", status);
          }
        });
      } catch (e) {
        console.warn(e);
      }
    }
  });

  return null;
});
