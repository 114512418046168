import { useNymblTheme } from "@hiyllo/omni-dynamicui/main";
import React from "react";
import { useIsSolo } from "../../../../../platform/hooks/use-is-solo";

export const JoinHiylloMeetButton = React.memo(
  function JoinHiylloMeetButton(props: {
    onClick: () => void;
  }): JSX.Element | null {
    const theme = useNymblTheme();
    const isSolo = useIsSolo();

    if (isSolo || theme == null) {
      return null;
    }

    return (
      <div
        onClick={props.onClick}
        style={{
          fontSize: 16,
          cursor: "pointer",
          marginTop: 5,
          borderRadius: 5,
          paddingTop: 7.5,
          paddingBottom: 7.5,
          width: 200,
          textAlign: "center",
          textShadow: "none",
          backgroundColor: theme.colors.foreground,
          color: theme.colors.accent,
          opacity: 0.9,
        }}
      >
        Join Hiyllo Meet
      </div>
    );
  },
);
