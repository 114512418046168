import React, { type FormEvent, useContext } from "react";
import { type UXDashboardType } from "../../types/config/omni-config";
import { type PropertyOf } from "../../types/utils";
import { type Theme } from "../themings";

/**
 * Cascading contexts bring CSS-esque cascading to React
 */
export class CascadingContext<Type> {
  context: React.Context<Array<Partial<Type>>>;

  constructor() {
    this.context = React.createContext<Array<Partial<Type>>>([]);
  }

  Provider = (props: {
    value: Partial<Type>;
    children: React.ReactNode;
  }): JSX.Element => {
    const { Provider } = this.context;
    const currentValue = React.useContext(this.context);

    const computedValue = [...currentValue, props.value];

    return <Provider value={computedValue}>{props.children}</Provider>;
  };

  use = (): Type => {
    const ctxValue = React.useContext(this.context);
    const baseRecord = {};

    for (const value of ctxValue) {
      Object.assign(baseRecord, value);
    }

    return baseRecord as Type;
  };
}

export const FormIsLoadingContext = React.createContext(false);
export const FormSubmitContext = React.createContext<
  null | ((evt?: FormEvent) => void)
>(null);
export const DarkMode = React.createContext(false);
export const UXThemeOverride = new CascadingContext<Theme>();
export const useIsDark = (): boolean => useContext(DarkMode);
