import React from "react";
import { Stardate, StardateLogKind, StardateSourceEnum } from "@hiyllo/stardate";
import { useTenant } from "@hiyllo/omni-continuity";

export const HelloWorldProvider = React.memo(function HelloWorldProvider(props: React.PropsWithChildren): React.ReactNode {
  const tenant = useTenant();

  React.useEffect(() => {
    let lastHelloWorld: Date = new Date(0);
    function helloWorld(): void {
      // Max 1 hello world every 30 seconds
      if (new Date().getTime() - lastHelloWorld.getTime() < 30000) {
        return;
      }

      new Stardate(StardateSourceEnum.frontendWeb, "omni", "hello-world", tenant).log({
        kind: StardateLogKind.trace,
        message: "Hello World!"
      });
      lastHelloWorld = new Date();
    }

    window.addEventListener("focus", helloWorld);

    helloWorld();

    return () => {
      window.removeEventListener("focus", helloWorld);
    };
  }, [tenant]);

  return props.children;
});