/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
export type ParamsType = {
  taskUUID: string;
  isFollowing: boolean;
};
export type ResponseType = void;
export const Endpoint = "tasks/update-task-following";
export const Method = "POST";

export type Plug = {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
};

export const RateLimitingConfig = {
  calls: 4,
  per: 750,
};
export const paramsSchema = {"type":"object","properties":{"taskUUID":{"type":"string"},"isFollowing":{"type":"boolean"}},"required":["isFollowing","taskUUID"],"$schema":"http://json-schema.org/draft-07/schema#"}