import React from "react";
import { dayToRowPosition } from "../consts";
import moment from "moment";
import { type DayOfWeek } from "@hiyllo/omni-common/src/types/calendar/base";
import { type RepeatingConfig } from "@hiyllo/omni-common/src/types/calendar/repeating";
import { Select } from "@hiyllo/ux/select";
import { Modal as SmartModal } from "@hiyllo/ux/modal";
import { Card } from "@hiyllo/ux/surface";
import { CheckboxInput } from "@hiyllo/ux/checkbox-input";
import { Button } from "@hiyllo/ux/button";
import { Typography } from "@hiyllo/ux/typography";
import { Input } from "@hiyllo/ux/input";

const DAILY_DAYS_OPTIONS = [
  {
    value: 1,
    label: "Every Day",
  },
  {
    value: 2,
    label: "Every Other Day",
  },
  {
    value: 3,
    label: "Every Third Day",
  },
  {
    value: 4,
    label: "Every Fourth Day",
  },
  {
    value: 5,
    label: "Every Fifth Day",
  },
];

const WEEKLY_WEEKS_OPTIONS = [
  {
    value: 1,
    label: "Every Week",
  },
  {
    value: 2,
    label: "Every 2 Weeks",
  },
  {
    value: 3,
    label: "Every 3 Weeks",
  },
  {
    value: 4,
    label: "Every 4 Weeks",
  },
  {
    value: 5,
    label: "Every 4 Weeks",
  },
];

const AdvancedRepeatConfig = React.memo(function AdvancedRepeatConfig(props: {
  onSave: (config: RepeatingConfig) => void;
}): JSX.Element {
  const [type, setType] = React.useState<
    RepeatingConfig["schedule"]["type"] | null
  >(null);
  const [days, setDays] = React.useState<number>(1);
  const [weeks, setWeeks] = React.useState<number>(1);
  const [daysOfWeek, setDaysOfWeek] = React.useState<DayOfWeek[]>([]);
  const [until, setUntil] = React.useState<Date | null>(null);

  const onSubmit = React.useCallback(() => {
    if (type == null) return;
    props.onSave({
      endOn: { date: moment().add(100, "years").toDate() },
      startOn: { date: moment().toDate() },
      isRoot: true,
      schedule:
        type === "daily"
          ? {
            type: "daily",
            every: { days },
            until,
          }
          : type === "weekly"
            ? {
              type: "weekly",
              every: { weeks },
              on: {
                dow: daysOfWeek.length > 0 ? daysOfWeek : [0, 1, 2, 3, 4, 5, 6],
              },
            }
            : {
              // TODO
              type: "yearly",
              on: {
                dom: 1,
                month: 1,
              },
            },
    });
  }, [days, daysOfWeek, props, type, until, weeks]);
  const [repeatsForever, setRepeatsForever] = React.useState<boolean>(true);

  return (
    <Card color="background3">
      <div style={{ minWidth: 300 }}>
        <div>How should this event repeat?</div>
        <div style={{ height: 15 }} />
        <Select
          options={[
            {
              value: null,
              label: "Select...",
            },
            {
              value: "daily",
              label: "Daily",
            },
            {
              value: "weekly",
              label: "Weekly",
            },
            {
              value: "monthly",
              label: "Monthly",
            },
            {
              value: "yearly",
              label: "Yearly",
            },
          ]}
          value={type}
          onChangeValue={(v) =>
            setType(v as RepeatingConfig["schedule"]["type"] | null)
          }
          fullWidth
        />
        <div style={{ height: 15 }} />
        {type === "daily" ? (
          <>
            <Select
              options={DAILY_DAYS_OPTIONS}
              value={days}
              onChangeValue={(v) => setDays(v as number)}
              fullWidth
            />
            <div style={{ height: 15 }} />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-end",
                gap: 5,
              }}
            >
              <CheckboxInput
                label="Repeats Forever"
                value={repeatsForever}
                onChange={setRepeatsForever}
              />
              {repeatsForever ? null : (
                <div style={{ flexGrow: 1 }}>
                  <Typography.Label>Until</Typography.Label>
                  <Input
                    type="date"
                    fullWidth
                    value={moment(until).format("YYYY-MM-DD")}
                    onChange={(evt) =>
                      setUntil(moment(evt.target.value, "YYYY-MM-DD").toDate())
                    }
                  />
                </div>
              )}
            </div>
          </>
        ) : type === "weekly" ? (
          <>
            <Select
              options={WEEKLY_WEEKS_OPTIONS}
              value={weeks}
              onChangeValue={(v) => setWeeks(v as number)}
              fullWidth
            />
            <div style={{ height: 15 }} />
            <div>On:</div>
            <div style={{ height: 15 }} />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 10,
              }}
            >
              <CheckboxInput
                label="Monday"
                value={daysOfWeek.includes(0)}
                onChange={(v) =>
                  setDaysOfWeek(
                    v ? [...daysOfWeek, 0] : daysOfWeek.filter((d) => d !== 0),
                  )
                }
              />
              <CheckboxInput
                label="Tuesday"
                value={daysOfWeek.includes(1)}
                onChange={(v) =>
                  setDaysOfWeek(
                    v ? [...daysOfWeek, 1] : daysOfWeek.filter((d) => d !== 1),
                  )
                }
              />
              <CheckboxInput
                label="Wednesday"
                value={daysOfWeek.includes(2)}
                onChange={(v) =>
                  setDaysOfWeek(
                    v ? [...daysOfWeek, 2] : daysOfWeek.filter((d) => d !== 2),
                  )
                }
              />
              <CheckboxInput
                label="Thursday"
                value={daysOfWeek.includes(3)}
                onChange={(v) =>
                  setDaysOfWeek(
                    v ? [...daysOfWeek, 3] : daysOfWeek.filter((d) => d !== 3),
                  )
                }
              />
              <CheckboxInput
                label="Friday"
                value={daysOfWeek.includes(4)}
                onChange={(v) =>
                  setDaysOfWeek(
                    v ? [...daysOfWeek, 4] : daysOfWeek.filter((d) => d !== 4),
                  )
                }
              />
              <CheckboxInput
                label="Saturday"
                value={daysOfWeek.includes(5)}
                onChange={(v) =>
                  setDaysOfWeek(
                    v ? [...daysOfWeek, 5] : daysOfWeek.filter((d) => d !== 5),
                  )
                }
              />
              <CheckboxInput
                label="Sunday"
                value={daysOfWeek.includes(6)}
                onChange={(v) =>
                  setDaysOfWeek(
                    v ? [...daysOfWeek, 6] : daysOfWeek.filter((d) => d !== 6),
                  )
                }
              />
            </div>
          </>
        ) : null}
      </div>
      <div style={{ height: 15 }} />
      <div style={{ display: "flex" }}>
        <Button label="Save" onClick={onSubmit} />
      </div>
    </Card>
  );
});

export interface RepeatSettingInputRef {
  extract: (date: Date) => RepeatingConfig | null;
}

const generateLabel = function (config: RepeatingConfig): string {
  if (config.schedule.type === "daily") {
    return `Every ${config.schedule.every.days} Day${config.schedule.every.days === 1 ? "" : "s"
      }`;
  } else if (config.schedule.type === "weekly") {
    const daysOfWeek = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];

    const repeatDays = config.schedule.on.dow
      .map((day) => daysOfWeek[day])
      .join(", ");

    return `Every ${config.schedule.every.weeks} week(s) on ${repeatDays}`;
  } else {
    return "Custom";
  }
};

const RepeatSettingInput = React.forwardRef<RepeatSettingInputRef>(
  function RepeatSettingInput(props, ref): JSX.Element {
    const [repeatSetting, setRepeatSetting] = React.useState<
      | "none"
      | "weekly"
      | "daily"
      | "daily-on-weekdays"
      | "custom"
      | "custom-saved"
    >("none");
    const [customRepeatingConfig, setCustomRepeatingConfig] =
      React.useState<RepeatingConfig | null>(null);

    React.useImperativeHandle(
      ref,
      () => ({
        extract: (_date: Date): RepeatingConfig | null => {
          const date = new Date(_date.valueOf());

          return repeatSetting === "none" || repeatSetting === "custom"
            ? null
            : repeatSetting === "custom-saved" && customRepeatingConfig != null
              ? {
                ...customRepeatingConfig,
                startOn: { date },
              }
              : repeatSetting === "daily"
                ? {
                  endOn: { date: moment().add(100, "years").toDate() },
                  startOn: { date },
                  isRoot: true,
                  schedule: {
                    type: "daily",
                    every: { days: 1 },
                  },
                }
                : repeatSetting === "daily-on-weekdays"
                  ? {
                    endOn: { date: moment().add(100, "years").toDate() },
                    startOn: { date },
                    isRoot: true,
                    schedule: {
                      type: "weekly",
                      every: { weeks: 1 },
                      on: { dow: [0, 1, 2, 3, 4] as DayOfWeek[] },
                    },
                  }
                  : {
                    endOn: { date: moment().add(100, "years").toDate() },
                    startOn: { date },
                    isRoot: true,
                    schedule: {
                      type: "weekly",
                      every: { weeks: 1 },
                      on: {
                        dow: [
                          dayToRowPosition[
                          moment(date).days() as keyof typeof dayToRowPosition
                          ] as DayOfWeek,
                        ],
                      },
                    },
                  };
        },
      }),
      [customRepeatingConfig, repeatSetting],
    );

    const onSaveRepeatingConfig = React.useCallback(
      (config: RepeatingConfig) => {
        setCustomRepeatingConfig(config);
        setRepeatSetting("custom-saved");
      },
      [],
    );

    return (
      <>
        {repeatSetting === "custom" ? (
          <SmartModal onClose={() => setRepeatSetting("none")}>
            <AdvancedRepeatConfig onSave={onSaveRepeatingConfig} />
          </SmartModal>
        ) : null}
        <Select
          fullWidth
          options={[
            ...(customRepeatingConfig == null
              ? []
              : [
                {
                  value: "custom-saved",
                  label: generateLabel(customRepeatingConfig),
                },
              ]),
            {
              value: "none",
              label: "Doesn't Repeat",
            },
            {
              value: "weekly",
              label: "Repeat Weekly",
            },
            {
              value: "daily",
              label: "Every Day",
            },
            {
              value: "daily-on-weekdays",
              label: "Every Weekday",
            },
            {
              value: "custom",
              label: "Custom",
            },
          ]}
          onChangeValue={(v) => setRepeatSetting(v as "none" | "weekly")}
          value={repeatSetting}
        />
      </>
    );
  },
);

const RepeatSettingInputMemoized = React.memo(
  RepeatSettingInput,
) as typeof RepeatSettingInput;

export { RepeatSettingInputMemoized as RepeatSettingInput };
