/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { LocationHistoryPoint } from "../../../types/location-tracking/location-history-point";

export type ParamsType = { userId: string };
export type ResponseType = {
  lh: LocationHistoryPoint[];
};
export const Endpoint = "admin/get-gdv-data";
export const Method = "GET";

export interface Plug {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
}
export const paramsSchema = {"type":"object","properties":{"userId":{"type":"string"}},"required":["userId"],"$schema":"http://json-schema.org/draft-07/schema#"}