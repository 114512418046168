import React from "react";
import { useTasksInSprint } from "../sprints/hooks/use-tasks-in-sprint";
import { useSprintOrder } from "../hooks/use-sprint-order";
import { styled } from "@hiyllo/ux/styled";
import { useNavigate } from "@hiyllo/omni-router";
import { Features } from "../../../types/navigation/features";
import { useGetSprint } from "../hooks/use-get-sprint";
import { type UserProfileSlimType } from "../../../types/continuity/user-profile";
import { UserImage } from "@hiyllo/omni-images/main";
import { createTaskFilterFunction } from "../hql";
import { LoadingSpinnerFullView } from "../../../platform/loading/spinner-loading-full";
import { TasksView } from "./tasks-view";
import { Pill, PillContainer } from "../components/pill";

const Container = styled("div", {
  height: "100%",
  display: "flex",
  flexDirection: "column",
  gap: 10,
});

function arrayToggle(arr: any[], value: any): typeof arr {
  const wa = [...arr];
  const index = wa.indexOf(value);

  if (index !== -1) {
    wa.splice(index, 1);
  } else {
    wa.push(value);
  }

  return wa;
}

export const SprintKanbanView = React.memo(function SprintKanbanView(props: {
  sprintUUID: string;
}): JSX.Element {
  const tasks = useTasksInSprint(props.sprintUUID);
  const { order, onChangeOrder } = useSprintOrder({
    sprintUUID: props.sprintUUID,
  });
  const navigate = useNavigate();
  const sprintQuery = useGetSprint({ sprintUUID: props.sprintUUID });
  const openSprintPlanning = React.useCallback(() => {
    if (sprintQuery.isError || sprintQuery.isLoading) return;

    navigate({
      feature: Features.tasks,
      params: {
        view: "sprint-planning",
        sprintUUID: props.sprintUUID,
        teamUUID: sprintQuery.data.sprint.teamUUID,
      },
    });
  }, [navigate, props.sprintUUID, sprintQuery]);
  const [filters, setFilters] = React.useState<string[]>([]);

  const filteredTasks = React.useMemo(() => {
    const filter = filters.join(" AND ");
    return tasks.data.filter(createTaskFilterFunction(filter));
  }, [filters, tasks.data]);
  const assignees: UserProfileSlimType[] = React.useMemo(() => {
    const rv: UserProfileSlimType[] = [];
    filteredTasks
      .map((task) => task.assignee)
      .forEach((p) => {
        if (p != null && !rv.find((r) => r.userId === p.userId)) {
          rv.push(p);
        }
      });
    return rv;
  }, [filteredTasks]);

  if (order === null) {
    return <LoadingSpinnerFullView />;
  }

  return (
    <Container>
      <TasksView
        order={order}
        onChangeOrder={onChangeOrder}
        tasks={filteredTasks}
        hideTaskProjectLabels={false}
        extraHeaderOptions={
          <PillContainer>
            <Pill onClick={openSprintPlanning}>Sprint Planning</Pill>
            {assignees.map((assignee) => (
              <Pill
                key={assignee.userId}
                inactive={!filters.includes(`assignee IS ${assignee.userId}`)}
                onClick={() =>
                  setFilters((f) =>
                    arrayToggle(f, `assignee IS ${assignee.userId}`),
                  )
                }
              >
                <UserImage userId={assignee.userId} width={12.5} />
                {assignee.name}
              </Pill>
            ))}
          </PillContainer>
        }
      />
    </Container>
  );
});
