import React from "react";
import { SearchOverlay } from "@hiyllo/omni-search";
import {
  type SearchResultType,
  type TaskResultType,
} from "../../../types/search/search-results";
import { styled } from "@hiyllo/ux/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faSearch } from "@fortawesome/pro-light-svg-icons";
import { Modal } from "@hiyllo/ux/modal";
import { CreateNewTaskView } from "../views/create-new-task";

const InputPlaceholder = styled<"div">("div", ({ $theme }) => ({
  borderColor: $theme.background3,
  borderWidth: 2,
  borderStyle: "solid",
  cursor: "pointer",
  borderRadius: 8,
  padding: 15,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: 15,
  flexDirection: "row",
  flexBasis: "50%",
  flexGrow: 0
}));

export const AddExistingTaskView = React.memo(
  function AddExistingTaskView(props: {
    onTaskSelected: (taskUUID: string) => void;
  }): JSX.Element {
    const [searchForTask, setSearchForTask] = React.useState(false);
    const [createTask, setCreateTask] = React.useState(false);

    return (
      <>
        {searchForTask ? (
          <SearchOverlay
            onClose={() => setSearchForTask(false)}
            filterType={["tasks/task"]}
            onSelectResult={(r: SearchResultType) => {
              // Since we filterType to task we can assume it is a TaskResultType
              const result = r as TaskResultType;
              props.onTaskSelected(result.task.uuid);
              setSearchForTask(false);
            }}
          />
        ) : null}
        {createTask ? (
          <Modal onClose={() => setCreateTask(false)}>
            <CreateNewTaskView
              onTaskCreated={taskUUID => {
                props.onTaskSelected(taskUUID);
                setCreateTask(false);
              }}
              projectUUID={null}
              sprintUUID={null}
            />
          </Modal>
        ) : null}
        <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
          <InputPlaceholder onClick={() => setSearchForTask(true)}>
            <FontAwesomeIcon icon={faSearch} /> Add Existing Task
          </InputPlaceholder>
          <InputPlaceholder onClick={() => setCreateTask(true)}>
            <FontAwesomeIcon icon={faPlus} /> Create New Task
          </InputPlaceholder>
        </div>
      </>
    );
  },
);
