/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
export type ParamsType = { slug: string; fsId: string; };
export type ResponseType = void;
export const Endpoint = "chat/create-custom-emoji";
export const Method = "POST";

export interface Plug {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
}
export const paramsSchema = {"type":"object","properties":{"slug":{"type":"string"},"fsId":{"type":"string"}},"required":["fsId","slug"],"$schema":"http://json-schema.org/draft-07/schema#"}