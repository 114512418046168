/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
export type ParamsType = {
  taskUUID: string;
  newSprintUUID: string | null;
};
export type ResponseType = void;
export const Endpoint = "tasks/change-task-sprint";
export const Method = "POST";

export type Plug = {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
};

export const RateLimitingConfig = {
  calls: 1,
  per: 750,
};
export const paramsSchema = {"type":"object","properties":{"taskUUID":{"type":"string"},"newSprintUUID":{"type":["null","string"]}},"required":["newSprintUUID","taskUUID"],"$schema":"http://json-schema.org/draft-07/schema#"}