import React from "react";
import {
  TaskType,
  type ListTasksSlimTaskType,
} from "@hiyllo/omni-common/src/types/tasks/task-item";
import { useLookupByUserId } from "@hiyllo/omni-continuity";
import { styled } from "@hiyllo/ux/styled";
import { UserImage } from "@hiyllo/omni-images/main";
import { LoadingSpinner } from "@hiyllo/ux/loading-spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHammer } from "@fortawesome/pro-light-svg-icons";
import { IconsByTaskType } from "../../constants/icons-by-task-type";
import { getTotalSizingInDays } from "../../../../utils/tasks/sizing";
import { UnassignedIcon } from "@hiyllo/omni-tasks";

const Container = styled("div", ({ $theme }) => ({
  background: $theme.background3,
  padding: 10,
  borderRadius: 10,
  display: "flex",
  flexDirection: "column",
  gap: 10,
}));

const TopRow = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 10,
  justifyContent: "flex-start",
});

const UserName = styled("div", { fontSize: 20 });

const DetailRow = styled("div", ({ $theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 10,
  fontSize: 14,
  paddingLeft: 2,
}));

export const ICSummary = React.memo(function ICSummary(props: {
  userId: string;
  tasks: ListTasksSlimTaskType[];
}): JSX.Element {
  const userId = props.userId === "_unassigned" ? null : props.userId;
  const lookupData = useLookupByUserId(userId);
  const totalWork = getTotalSizingInDays(props.tasks);

  return (
    <Container>
      <TopRow>
        {userId === null ? (
          <UnassignedIcon width={20} />
        ) : (
          <UserImage userId={userId} width={20} />
        )}
        <UserName>
          {userId === null
            ? "Unassigned"
            : lookupData.data?.name ?? <LoadingSpinner />}
        </UserName>
      </TopRow>
      <DetailRow>
        <FontAwesomeIcon icon={faHammer} fixedWidth />
        <div>{Math.ceil(totalWork)} days of work</div>
      </DetailRow>
      <DetailRow>
        <FontAwesomeIcon icon={IconsByTaskType[TaskType.task]} fixedWidth />
        <div>
          {props.tasks.length} task{props.tasks.length === 1 ? "" : "s"}
        </div>
      </DetailRow>
    </Container>
  );
});
