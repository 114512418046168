import { faArrowRight } from "@fortawesome/pro-light-svg-icons";
import { CircleButton } from "@hiyllo/ux/circle-button";
import { styled } from "@hiyllo/ux/styled";
import React from "react";

const Centered = styled("div", ({ $theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  gap: 10,
  height: "100%",
  width: "100%",
  backgroundColor: $theme.background2,
  fontFamily: "hiyllo",
}));

const InputElement = styled("input", ({ $theme }) => ({
  outline: "none",
  border: "none",
  colorScheme: $theme.colorScheme,
  width: 0,
  flexGrow: 1,
  display: "inline-block",
  fontSize: "inherit",
  background: "transparent",
  color: $theme.foreground,
  resize: "none",
  padding: 0,
  fontFamily: "inherit",
  textAlign: "right",
}));

const ContainerElement = styled<
  "div",
  { fullWidth?: boolean; fontSize?: number }
>("div", ({ fullWidth, fontSize }) => ({
  width: fullWidth === true ? "100%" : "min(300px, calc(100% - 20px))",
  fontSize: fontSize ?? 14,
  fontFamily: '"Work Sans", sans-serif',
}));

const InputContainer = styled("div", ({ $theme }) => ({
  background: $theme.midground,
  borderRadius: 10,
  padding: "0.8em",
  display: "flex",
  flexDirection: "row",
  color: $theme.foreground,
}));

export const HiylloWorkPreLink = React.memo(
  function HiylloWorkPreLink(): React.ReactElement {
    const [host, setHost] = React.useState<string>("");

    const performLink = React.useCallback(() => {
      window.location.href = `https://${host.includes(".") ? host : `${host}.hiyllo.work`
        }/oauth/authorize/com.hiyllo.solo`;
    }, [host]);

    return (
      <Centered>
        <div style={{ fontSize: 24 }}>Link your Hiyllo Work account</div>
        <div>What is your organization&apos;s Hiyllo Work domain?</div>
        <div style={{ width: 300 }}>
          <ContainerElement>
            <label>
              <InputContainer>
                <InputElement
                  maxLength={20}
                  onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                    setHost(evt.target.value);
                  }}
                  value={host}
                  autoFocus
                />
                {host.includes(".") ? "" : ".hiyllo.work"}
              </InputContainer>
            </label>
          </ContainerElement>
        </div>
        <CircleButton icon={faArrowRight} onClick={performLink} size={50} />
      </Centered>
    );
  },
);
