import React from "react";
import { PillSelect } from "@hiyllo/ux/pill-select";
import { TasksView } from "./tasks-view";
import { useConfig } from "../../../platform/config/config-context";
import { LoadingSpinnerFullView } from "../../../platform/loading/spinner-loading-full";
import * as ListTasksForUserBP from "../../../blueprints/tasks/list-tasks-for-user";
import { seamlessClient } from "../../../seamless-client";

export const UserTasksView = React.memo(function TaskGroupView({
  userId,
}: {
  userId: string;
}): JSX.Element {
  const isSolo = useConfig().isSolo === true;

  const [filter, setFilter] = React.useState<"active-sprint" | null>(
    isSolo
      ? null
      : window.localStorage.icViewFilter === "none"
        ? null
        : window.localStorage.icViewFilter ?? "active-sprint",
  );

  React.useEffect(() => {
    window.localStorage.icViewFilter = filter ?? "none";
  }, [filter]);
  const listTasksForUserQuery =
    seamlessClient.useQuery<ListTasksForUserBP.Plug>(
      ListTasksForUserBP,
      { userId, filter },
    );
  const order = {};

  if (order === null) {
    return <LoadingSpinnerFullView />;
  }

  return (
    <TasksView
      order={order}
      onChangeOrder={() => {
        //
      }}
      tasks={listTasksForUserQuery.data?.tasks ?? []}
      isReady={!listTasksForUserQuery.isLoading}
      extraHeaderOptions={
        isSolo
          ? undefined
          : [
            <PillSelect
              key="filter"
              options={[
                {
                  value: "active-sprint",
                  label: "My Active Sprints",
                },
                {
                  value: null,
                  label: "All My Tasks",
                },
              ]}
              value={filter}
              onChangeValue={(v) => setFilter(v as "active-sprint" | null)}
              loading={listTasksForUserQuery.isLoading}
            />,
          ]
      }
    />
  );
});
