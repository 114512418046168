import React from "react";
import * as GetGDVDataBP from "../../blueprints/admin/user-management/get-gdv-data";
import { seamlessClient } from "../../seamless-client";
import { useParams } from "react-router-dom";
import { MapWithToken } from "../maps/map-with-token";
import moment from "moment";

export const UserGodView = React.memo(function UserGodView(): JSX.Element {
  const params = useParams<{ userId: string }>();
  const query = seamlessClient.useQuery<GetGDVDataBP.Plug>(
    GetGDVDataBP,
    {
      userId: params.userId ?? "",
    },
  );

  return (
    <div style={{ whiteSpace: "pre-wrap" }}>
      <div style={{ height: 750 }}></div>
      {JSON.stringify(query, null, 2)}
    </div>
  );
});
