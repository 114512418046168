import { type IconDefinition } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { styled } from "@hiyllo/ux/styled";
import { useTheme } from "@hiyllo/ux/theme";
import React from "react";

const SelectRow = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 10,
});

const SelectPill = styled<
  "div",
  { color: string; hasIcon: boolean; active: boolean }
>("div", ({ color, hasIcon, active }) => ({
  padding: 5,
  paddingLeft: !hasIcon ? 7.5 : 5,
  paddingRight: 7.5,
  color: active ? "white" : color,
  backgroundColor: active ? color : "transparent",
  borderColor: color,
  borderWidth: 1,
  borderStyle: "solid",
  borderRadius: 20,
  fontSize: 12,
  gap: 2.5,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  cursor: "pointer",
}));

function PillRowSelect<T extends string | null | number>(props: {
  value: T;
  onChange: (value: T) => void;
  options: Array<{
    value: T;
    label: string;
    color?: string;
    icon?: IconDefinition;
  }>;
}): JSX.Element {
  const theme = useTheme();

  return (
    <SelectRow>
      {props.options.map((option) => (
        <SelectPill
          key={option.value?.toString()}
          color={option.color ?? theme.midground ?? "white"}
          active={props.value === option.value}
          onClick={() => props.onChange(option.value)}
          hasIcon={option.icon != null}
        >
          {option.icon != null ? (
            <FontAwesomeIcon icon={option.icon} fixedWidth />
          ) : null}
          {option.label}
        </SelectPill>
      ))}
    </SelectRow>
  );
}

const PRS = React.memo(PillRowSelect) as typeof PillRowSelect;

export { PRS as PillRowSelect };
