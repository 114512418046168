import { useMultipleDocuments } from "@hiyllo/react-data";
import { seamlessClient } from "../../../../seamless-client";
import { type ListTasksSlimTaskType } from "@hiyllo/omni-common/src/types/tasks/task-item";

import * as ListTasksInSprintBP from "../../../../blueprints/tasks/sprints/list-tasks-in-sprint";
import * as TasksInSprintPSB from "../../../../blueprints/tasks/pubsub/tasks-in-sprint-spec";
import { Cache } from "../../../../platform/cache";
import { Tenant } from "../../../../platform/tenancy";

export function useTasksInSprint(sprintUUID: string): {
  data: ListTasksSlimTaskType[];
  queryLoading: boolean;
} {
  return useMultipleDocuments<
    ListTasksSlimTaskType,
    {
      query: ListTasksInSprintBP.Plug;
      subscription: TasksInSprintPSB.Typings;
    }
  >(seamlessClient, {
    cache: Cache,
    key: `tasks-in-sprint/${sprintUUID}`,
    subscription: {
      blueprint: TasksInSprintPSB,
      topic: `[${Tenant}]tasks-in-sprint/${sprintUUID}`,
    },
    query: {
      blueprint: ListTasksInSprintBP,
      params: { sprintUUID },
      mapperFn: (res) => res.tasks,
      deps: [sprintUUID],
      mode: "overwrite",
    },
    keyExtractorFn: (document) => document.uuid,
    resetWhenAggregatorChanges: true,
  });
}
