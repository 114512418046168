import React from "react";
import { useConfig } from "../../../platform/config/config-context";
import { BasicBooleanConfigPropertyButton } from "../components/basic-boolean-config-property-button";
import { Card } from "@hiyllo/ux/surface";
import { Typography } from "@hiyllo/ux/typography";
import { faUsers } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const InvitingPanel = React.memo(function InvitingPanel(): JSX.Element {
  const config = useConfig();

  return (
    <div>
      <Card color="background3">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 10,
            fontSize: 20,
            marginBottom: 10,
          }}
        >
          <FontAwesomeIcon icon={faUsers} />
          Inviting
        </div>
        <BasicBooleanConfigPropertyButton
          path="allowAllUsersToInvite"
          label="Allow any user to invite someone else"
          style="checkbox"
        />
        <BasicBooleanConfigPropertyButton
          path="displayUserInviter"
          label="Display who invited a user"
          style="checkbox"
        />
      </Card>
    </div>
  );
});
