/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { ListTasksSlimTaskType } from "../../types/tasks/task-item";

export type ParamsType = {
  projectUUID: string;
};
export type ResponseType = {
  tasks: ListTasksSlimTaskType[];
};
export const Endpoint = "tasks/list-task-items";
export const Method = "GET";

export type Plug = {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
};

export const RateLimitingConfig = {
  calls: 5,
  per: 1000,
};
export const paramsSchema = {"type":"object","properties":{"projectUUID":{"type":"string"}},"required":["projectUUID"],"$schema":"http://json-schema.org/draft-07/schema#"}