import React from "react";
import { LoadingSpinnerFullView } from "../../../../platform/loading/spinner-loading-full";
import { useLinkGoogle } from "../../hooks/use-link-google";
import { MoopsyError } from "@moopsyjs/core";

export const CompleteGoogleIntegration = React.memo(
  function CompleteGoogleIntegration() {
    const callingRef = React.useRef(false);
    const completeMicrosoftIntegration = useLinkGoogle();
    const [error, setError] = React.useState<string | null>(null);

    React.useEffect(() => {
      if (callingRef.current) {
        return;
      }
      callingRef.current = true;
      const code = new URLSearchParams(window.location.search).get("code");
      if (code) {
        void completeMicrosoftIntegration.call({ code }).then(() => {
          window.location.href =
            window.innerHeight > window.innerWidth
              ? "/settings/integrations"
              : `/v/profile?view="integrations"`;
        }).catch(err => {
          setError((err as MoopsyError).message);
        });
      }
    }, []);

    if (error != null) {
      return (
        <div style={{ height: "100%", width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
          {error}
        </div>
      );
    }

    return <LoadingSpinnerFullView />;
  },
);
