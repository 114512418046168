/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
export type ParamsType = {
  userAgent: string;
};
export type ResponseType = {
  url: string | null;
};
export const Endpoint = "misc/get-download-link";
export const Method = "GET";
export const isPublic = true;

export interface Plug {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
}
export const paramsSchema = {"type":"object","properties":{"userAgent":{"type":"string"}},"required":["userAgent"],"$schema":"http://json-schema.org/draft-07/schema#"}