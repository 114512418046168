import React from "react";
import { useConfig } from "../../../platform/config/config-context";
import * as RegisterWithEmailAndPasswordBlueprint from "../../../blueprints/accounts/registration/register-with-email-and-password";
import { hashString } from "../../../utils/browser-safe-sha256";
import {
  onLoginTokenAvailable,
  seamlessClient,
} from "../../../seamless-client";
import { type MoopsyError } from "@moopsyjs/core";
import { LoginUI } from "@hiyllo/ux/login-ui";
import { AccountAuthenticationMethod } from "../../../types/accounts/authentication";
import { getRootURL } from "../../../platform/environment/get-root-url";

/**
 * ?arc= Admin Registration Code (user to allow this account to instantly become admin)
 * ?evc= Email Verification Code (used to allow this email to be verified upon registration)
 * ?e=   Email (used to prefill the email field)
 */

export const RegisterPage = React.memo(
  function RegisterPage(): JSX.Element | null {
    const config = useConfig();
    const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
    const registerMutation =
      seamlessClient.useMutation<RegisterWithEmailAndPasswordBlueprint.Plug>(
        RegisterWithEmailAndPasswordBlueprint,
      );
    const isHiylloSSOAuth =
      config.accounts.authenticator === AccountAuthenticationMethod.hiylloAuth;

    const adminRegistrationCode = new window.URLSearchParams(
      window.location.search,
    ).get("arc");
    const registeringAsAdmin = adminRegistrationCode != null;
    const preFixedEmail = new window.URLSearchParams(
      window.location.search,
    ).get("e");

    const onRegisterFormSubmit = async ({
      email,
      authenticator,
    }: {
      email: string;
      authenticator: string;
    }): Promise<void> => {
      const plaintextPassword = isHiylloSSOAuth ? null : authenticator;
      const adminRegistrationCode = new window.URLSearchParams(
        window.location.search,
      ).get("arc");
      const emailVerificationCode = new window.URLSearchParams(
        window.location.search,
      ).get("evc");

      if (email == null || (!isHiylloSSOAuth && plaintextPassword == null)) {
        return;
      }

      if (plaintextPassword !== null && plaintextPassword.length < 8) {
        return setErrorMessage("Password must be at least 8 characters long");
      }

      try {
        const hashedPassword =
          plaintextPassword === null
            ? null
            : await hashString(plaintextPassword);

        const { tokenPackage } = await registerMutation.call({
          email,
          password: hashedPassword,
          adminRegistrationCode,
          emailVerificationCode,
        });

        await onLoginTokenAvailable(tokenPackage);
      } catch (e: unknown) {
        console.error(e);
        if (e != null && typeof e === "object" && "description" in e) {
          const MoopsyError = e as MoopsyError;
          setErrorMessage(MoopsyError.description);
        } else {
          setErrorMessage("Error");
        }
      }
    };

    return (
      <LoginUI
        authenticatorType="password"
        logoPath={
          config.branding?.logo?.fileId != null
            ? getRootURL() + "/ufplogo.png"
            : "hiyllo-work/gradient.png"
        }
        hasAccount={false}
        fixedIdentifier={preFixedEmail}
        disableIdentifier={preFixedEmail != null}
        overrideEmailLabel={
          preFixedEmail != null
            ? registeringAsAdmin
              ? "Your admin account's email address"
              : "We have your email as..."
            : null
        }
        showAuthenticator={!isHiylloSSOAuth}
        requirePasswordConfirmation={!isHiylloSSOAuth}
        overrideAuthenticatorLabel={
          registeringAsAdmin
            ? "Create a password to keep your account secure"
            : "Create a password"
        }
        errorMessage={errorMessage}
        onSubmit={onRegisterFormSubmit}
        isLoading={registerMutation.isLoading}
        overrideHeader={registeringAsAdmin ? "Welcome to your workspace" : null}
      />
    );
  },
);
