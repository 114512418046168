import React from "react";
import { LoadingSpinner } from "@hiyllo/ux/loading-spinner";
import { useProjectTasks } from "../hooks/use-project-tasks";
import { useProjectOrder } from "../hooks/use-project-order";
import {
  type ListTasksSlimTaskType,
  type TaskType,
} from "../../../types/tasks/task-item";
import { TasksView } from "./tasks-view";
import { HideTaskProjectLabelsCtx } from "../components/task-card";
import { ErrorSplash } from "../../../ux/error-splash";
import { TabDetails } from "../../tokyo/tabbing/tabs-provider";
import { FilterControl } from "../components/filter-control";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRunning, faUserCircle } from "@fortawesome/pro-light-svg-icons";
import { ViewCriteriaContext } from "../contexts/view-criteria-context";
import { LoadingSpinnerFullView } from "../../../platform/loading/spinner-loading-full";

type SprintFilterType = "with-sprint-only" | "without-sprint-only" | null;
type AssigneeFilterType = "has-assignee" | "unassigned" | null;

export const TaskProjectView = React.memo(function TaskGroupView(props: {
  projectUUID: string;
  columns?: string[] | null;
  hideHeader?: boolean;
  filterType?: TaskType | null;
  onClickTask?: (task: ListTasksSlimTaskType) => boolean;
}): JSX.Element {
  const [sprintFilter, setSprintFilter] = React.useState<SprintFilterType>(null);
  const [assigneeFilter, setAssigneeFilter] = React.useState<AssigneeFilterType>(null);
  const tasks = useProjectTasks({ projectUUID: props.projectUUID });
  const { order, project, onChangeOrder, error } = useProjectOrder({
    projectUUID: props.projectUUID,
    onTaskUpdated: (task) => {
      tasks.ingest([task], false);
    },
  });

  const preFilteredTasks = React.useMemo(() => {
    if (props.filterType) {
      return tasks.data.filter((t) => t.type === props.filterType);
    }

    return tasks.data;
  }, [props.filterType, tasks.data]);

  const filteredTasks = React.useMemo(() => {
    return preFilteredTasks.filter((t) => {
      if (sprintFilter === "with-sprint-only" && t.sprintUUID == null) {
        return false;
      }
      if (sprintFilter === "without-sprint-only" && t.sprintUUID != null) {
        return false;
      }
      if (assigneeFilter === "has-assignee" && t.assigneeUserId == null) {
        return false;
      }
      if (assigneeFilter === "unassigned" && t.assigneeUserId != null) {
        return false;
      }

      return true;
    });
  }, [assigneeFilter, preFilteredTasks, sprintFilter]);

  const viewCriteriaFn = React.useCallback((task: Omit<ListTasksSlimTaskType, "assignee"> | ListTasksSlimTaskType) => {
    return task.projectUUID === props.projectUUID;
  }, [props.projectUUID]);

  if (error !== null) {
    return (
      <ErrorSplash
        error={error}
        accessDeniedHint="You aren't a member of any of the teams with access to this project"
      />
    );
  }

  if (order === null) {
    return (
      <LoadingSpinnerFullView />
    );
  }

  return (
    <TabDetails label={project != null ? project.name : "Project"}>
      <ViewCriteriaContext.Provider value={viewCriteriaFn}>
        <HideTaskProjectLabelsCtx.Provider value={true}>
          <TasksView
            order={order}
            onChangeOrder={onChangeOrder}
            tasks={filteredTasks}
            columns={props.columns}
            hideHeader={props.hideHeader}
            onClickTask={props.onClickTask}
            isReady={!tasks.loading}
            extraHeaderOptions={
              [
                tasks.loading ? <LoadingSpinner /> : null,
                <div key="label">Filter:</div>,
                <FilterControl
                  key="sprint-filter"
                  value={sprintFilter}
                  onChangeValue={(v) => setSprintFilter(v as SprintFilterType | null)}
                  options={[
                    {
                      value: null,
                      label: "All",
                    },
                    {
                      value: "without-sprint-only",
                      label: "Without Sprint",
                    },
                    {
                      value: "with-sprint-only",
                      label: "With Sprint"
                    }
                  ]}
                >
                  <FontAwesomeIcon icon={faRunning} />
                  {sprintFilter === null ? "Sprint" : sprintFilter === "without-sprint-only" ? "Without Sprint" : "With Sprint"}
                </FilterControl>,
                <FilterControl
                  key="assignee-filter"
                  value={assigneeFilter}
                  onChangeValue={(v) => setAssigneeFilter(v as AssigneeFilterType | null)}
                  options={[
                    {
                      value: null,
                      label: "All",
                    },
                    {
                      value: "has-assignee",
                      label: "Has Assignee",
                    },
                    {
                      value: "unassigned",
                      label: "Unassigned"
                    }
                  ]}
                >
                  <FontAwesomeIcon icon={faUserCircle} />
                  {assigneeFilter === null ? "Assignee" : assigneeFilter === "has-assignee" ? "Has Assignee" : "Unassigned"}
                </FilterControl>
              ]
            }
          />
        </HideTaskProjectLabelsCtx.Provider>
      </ViewCriteriaContext.Provider>
    </TabDetails>
  );
});
