/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { TokenPackageType } from "../../../types/auth/token-package";
import { HashedString } from "../../../types/cryptography/hashed-string";

export type ParamsType = {
  email: string;
  password: HashedString | null;
  adminRegistrationCode: string | null;
  emailVerificationCode: string | null;
};
export type ResponseType = { tokenPackage: TokenPackageType };
export const Endpoint = "auth/register-with-email-and-password";
export const Method = "POST";
export const isPublic = true;

export interface Plug {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
}
export const paramsSchema = {"type":"object","properties":{"email":{"type":"string"},"password":{"anyOf":[{"type":"object","properties":{"digest":{"type":"string"},"algorithm":{"type":"string"}},"required":["algorithm","digest"]},{"type":"null"}]},"adminRegistrationCode":{"type":["null","string"]},"emailVerificationCode":{"type":["null","string"]}},"required":["adminRegistrationCode","email","emailVerificationCode","password"],"$schema":"http://json-schema.org/draft-07/schema#"}