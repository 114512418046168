import { type ListTasksSlimTaskType } from "../../../types/tasks/task-item";
import * as TasksInProjectSpec from "../../../blueprints/tasks/pubsub/tasks-in-project-spec";
import * as ListTaskItemsBP from "../../../blueprints/tasks/list-task-items";
import { Cache } from "../../../platform/cache";
import { seamlessClient } from "../../../seamless-client";
import { Tenant } from "../../../platform/tenancy";
import { useMultipleDocuments } from "@hiyllo/react-data";

export function useProjectTasks(opts: { projectUUID: string }): {
  data: ListTasksSlimTaskType[];
  loading: boolean;
  ingest: (
    data: ListTasksSlimTaskType[],
    dontCache: boolean,
    overwrite?: boolean | undefined,
  ) => void;
} {
  const tasks = useMultipleDocuments<
    ListTasksSlimTaskType,
    {
      query: ListTaskItemsBP.Plug;
      subscription: TasksInProjectSpec.Typings;
    }
  >(seamlessClient, {
    cache: Cache,
    key: `project-tasks/${opts.projectUUID}`,
    subscription: {
      blueprint: TasksInProjectSpec,
      topic: `[${Tenant}]tasks-in-project/${opts.projectUUID}`,
    },
    query: {
      blueprint: ListTaskItemsBP,
      params: {
        projectUUID: opts.projectUUID,
      },
      mapperFn: (res) => res.tasks,
      deps: [opts.projectUUID],
      mode: "overwrite",
    },
    keyExtractorFn: (document) => document.uuid,
    resetWhenAggregatorChanges: true,
  });

  return {
    data: tasks.data,
    loading: tasks.queryLoading,
    ingest: tasks._ingest,
  };
}
