import React from "react";
import { useUploadFile } from "@hiyllo/omni-user-files/main";
import * as CreateWardrobeEntryBP from "../../blueprints/wardrobe/create-wardrobe-entry";
import * as RetrieveWardrobeEntriesBP from "../../blueprints/wardrobe/retrieve-wardrobe-entries";
import { seamlessClient } from "../../seamless-client";
import { LoadingSpinner } from "@hiyllo/ux/loading-spinner";

export const WardrobeTest = React.memo(function WardrobeTest(): JSX.Element {
  const uploadFile = useUploadFile();
  const wardrobeEntriesQuery =
    seamlessClient.useQuery<RetrieveWardrobeEntriesBP.Plug>(
      RetrieveWardrobeEntriesBP,
      null,
    );
  const createWardrobeEntryMutation =
    seamlessClient.useMutation<CreateWardrobeEntryBP.Plug>(
      CreateWardrobeEntryBP,
      {
        querySideEffects: [wardrobeEntriesQuery],
      },
    );

  const onChange = React.useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      const file = evt.target.files?.[0];
      console.log(10, file);
      if (file != null) {
        console.log("Uploading...");
        void uploadFile(file)
          .then((res) => {
            void createWardrobeEntryMutation
              .call({
                label: null,
                imageFsId: res.fsId,
              })
              .then((res) => {
                console.log(res);
              })
              .catch(console.error);
          })
          .catch(console.error);
      }
    },
    [uploadFile],
  );

  return (
    <div>
      {createWardrobeEntryMutation.isLoading ? (
        <LoadingSpinner />
      ) : (
        <input type="file" onChange={onChange} />
      )}
      {wardrobeEntriesQuery.data?.entries.map((entry) => (
        <div key={entry.uuid}>
          <img
            src={entry.image.src}
            alt={entry.label}
            style={{
              width: 400,
              height: 400,
              background: "white",
              objectFit: "contain",
            }}
          />
          {entry.label}
        </div>
      ))}
    </div>
  );
});
