/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
export type ParamsType = { token: string };
export type ResponseType = void;
export const Endpoint = "users/logout";
export const Method = "POST";

export interface Plug {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
}
export const paramsSchema = {"type":"object","properties":{"token":{"type":"string"}},"required":["token"],"$schema":"http://json-schema.org/draft-07/schema#"}