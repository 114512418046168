import { styled } from "@hiyllo/ux/styled";
import React from "react";
import { useGetFormResponses } from "../hooks/use-get-form-responses";
import { LoadingSpinner } from "@hiyllo/ux/loading-spinner";
import { useGetFormResponseFile } from "../hooks/use-get-form-response-file";
import { Button } from "@hiyllo/ux/button";
import { type HiylloFormField } from "../../../types/forms/structure";
import { openWindow } from "../../../platform/open-window";

const Container = styled("div", {
  padding: 20,
  fontFamily: '"hiyllo", sans-serif',
  width: "calc(100% - 40px)",
  height: "100%",
});
const Header = styled("div", {
  fontSize: 32,
  marginBottom: 10,
  userSelect: "none",
});

const FileLink = React.memo(function FileLink(props: {
  fileId: string;
  formUUID: string;
}): JSX.Element {
  const getFormResponseFileMutation = useGetFormResponseFile();

  const onClick = React.useCallback(async () => {
    await getFormResponseFileMutation
      .call({ formUUID: props.formUUID, fileId: props.fileId })
      .then((res) => {
        openWindow(res.src);
      });
  }, [getFormResponseFileMutation, props.fileId, props.formUUID]);

  return (
    <Button
      label="Open File"
      isLoading={getFormResponseFileMutation.isLoading}
      onClick={onClick}
    />
  );
});

export const ViewResponses = React.memo(function ViewResponses(props: {
  formUUID: string;
}): JSX.Element {
  const formQuery = useGetFormResponses({ formUUID: props.formUUID });

  if (formQuery.data == null) {
    return (
      <Container>
        <LoadingSpinner />
      </Container>
    );
  }

  const form = formQuery.data.form;

  return (
    <Container>
      <Header>{formQuery.data.form.name}</Header>
      <table>
        <tr>
          {formQuery.data.form.structure.fields.map(
            (field: HiylloFormField) => (
              <th style={{ textAlign: "left" }} key={field.uuid}>
                {field.structure.label}
              </th>
            ),
          )}
        </tr>
        {formQuery.data.responses.map((response) => (
          <tr key={response.responseUUID}>
            {form.structure.fields.map((field) => {
              const thisResponse = response.fields[field.uuid];

              if (thisResponse == null) {
                return <td key={field.uuid} />;
              }

              return (
                <td key={field.uuid}>
                  {thisResponse.type === "text" ? (
                    thisResponse.value.toString()
                  ) : (
                    <FileLink
                      fileId={thisResponse.fileId}
                      formUUID={props.formUUID}
                    />
                  )}
                </td>
              );
            })}
          </tr>
        ))}
      </table>
    </Container>
  );
});
