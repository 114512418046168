import { type UseMoopsyQueryRetValAny } from "@moopsyjs/react";
import * as BP from "../../../blueprints/tasks/get-my-projects";
import { createQueryHook } from "../../../platform/factories/create-get-endpoint-hook";
import {
  type CombinedDataSource,
  useCombinedDataSource,
} from "../../../platform/ucds";
import { type TaskProject } from "../../../types/tasks/tasks-organization";
import { Cache } from "../../../platform/cache";

const useMyProjectsQueryHook = createQueryHook<BP.Plug>(BP);

export function useMyProjects(): {
  projectsQuery: UseMoopsyQueryRetValAny;
  projects: CombinedDataSource<TaskProject>;
} {
  const projectsQuery = useMyProjectsQueryHook(null);
  const projects = useCombinedDataSource<TaskProject>(projectsQuery, {
    cache: Cache,
    queryExtractKey: "projects",
    queryType: "hard",
    cacheKey: "tasks.my-projects",
  });

  return {
    projectsQuery,
    projects,
  };
}
