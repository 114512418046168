/* eslint-disable no-async-promise-executor */
import React from "react";
import { LoadingSpinner } from "@hiyllo/ux/loading-spinner";
import { seamlessClient } from "../seamless-client";
import {
  BlobCacheProviderContext,
  type BlobCacheType,
} from "@hiyllo/omni-images";
import SimpleIDB from "../platform/local-persistence/simple-idb";

const blobCacheDB = new SimpleIDB("omni.blobcachev3");
const memoryCache: Record<string, string> = {};
const fetchPromises: Record<
  string,
  { promise: Promise<string | null>; created: Date }
> = {};

export async function getBlobPath(blob: Blob): Promise<string> {
  if (blob.size <= 1000 * 500) {
    // < 500kb
    const fr = new FileReader();
    fr.readAsDataURL(blob);
    return await new Promise<string>((resolve) => {
      fr.onload = () => resolve(fr.result as string);
    });
  } else {
    return URL.createObjectURL(blob);
  }
}

export const BlobCacheProvider = React.memo(function BlobCacheProvider(
  props: React.PropsWithChildren,
): JSX.Element {
  const BlobCache: BlobCacheType = React.useMemo(() => {
    const get = async (userId: string, src: string): Promise<string | null> => {
      const res = await fetch(src);
      const blob = await res.blob();

      const blobPath = await getBlobPath(blob);

      if (res.status !== 200) {
        return null;
      }

      void blobCacheDB.write({
        id: userId,
        data: blob,
      });
      memoryCache[userId] = blobPath;

      return blobPath;
    };
    const retrieve = async (userId: string): Promise<string | null> => {
      if (userId in memoryCache) {
        return memoryCache[userId];
      }

      if (
        fetchPromises[userId] != null &&
        fetchPromises[userId].created.getTime() > Date.now() - 1000 * 5
      ) {
        return await fetchPromises[userId].promise;
      }

      const promise = new Promise<string | null>(async (resolve) => {
        try {
          await blobCacheDB.open();

          const existing = (await blobCacheDB.read(userId)) as {
            data: Blob;
          } | null;

          if (existing) {
            const fr = new FileReader();
            fr.readAsDataURL(existing.data);
            const blobPath = await new Promise<string>((resolve) => {
              fr.onload = () => resolve(fr.result as string);
            });

            // const blobPath = URL.createObjectURL(existing.data);
            memoryCache[userId] = blobPath;
            return resolve(blobPath);
          }
        } catch (e) {
          console.error("Error pulling from IDB blob cache", e);
        }

        resolve(null);
      });

      fetchPromises[userId] = {
        promise,
        created: new Date(),
      };

      return await promise;
    };

    return {
      get,
      retrieve,
      memoryCache,
      LoadingComponent: LoadingSpinner,
      client: seamlessClient,
    };
  }, []);

  return (
    <BlobCacheProviderContext.Provider value={BlobCache}>
      {props.children}
    </BlobCacheProviderContext.Provider>
  );
});
