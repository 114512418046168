/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { ListTasksSlimTaskType } from "../../types/tasks/task-item";

export type ParamsType = {
  userId: string;
  filter: null | "active-sprint";
  onlyTaskType?: boolean;
};
export type ResponseType = {
  tasks: ListTasksSlimTaskType[];
};
export const Endpoint = "tasks/list-tasks-for-user";
export const Method = "GET";

export type Plug = {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
};

export const RateLimitingConfig = {
  calls: 5,
  per: 1000,
};
export const paramsSchema = {"type":"object","properties":{"userId":{"type":"string"},"filter":{"anyOf":[{"enum":["active-sprint"],"type":"string"},{"type":"null"}]},"onlyTaskType":{"type":"boolean"}},"required":["filter","userId"],"$schema":"http://json-schema.org/draft-07/schema#"}