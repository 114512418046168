/**
 * Native Bridge is utilized when web app is running within a WebView
 */

interface NativeEventType {
  event: "logout" | "ready" | "setup-complete";
}

export function emitToNative(event: NativeEventType): void {
  // @ts-expect-error window.ReactNativeWebView
  if (window.ReactNativeWebView) {
    // @ts-expect-error window.ReactNativeWebView
    window.ReactNativeWebView.postMessage(JSON.stringify(event));
  }
}
