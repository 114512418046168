import React from "react";

export function useOnClickOut(callback: () => void): React.RefCallback<any> {
  const previousListenerRef = React.useRef<
    null | ((clickEvent: Event) => void)
  >(null);
  return React.useCallback(
    (node?: HTMLDivElement) => {
      if (previousListenerRef.current != null) {
        window.removeEventListener("click", previousListenerRef.current);
        previousListenerRef.current = null;
      }

      if (node != null) {
        const listener = (clickEvent: Event): void => {
          if ("path" in clickEvent) {
            // @ts-expect-error doesn't recognize path
            const targetWasClicked: boolean = clickEvent.path.includes(node);
            if (!targetWasClicked) {
              callback();
            }
          }
        };

        window.addEventListener("click", listener);
        previousListenerRef.current = listener;
      }
    },
    [callback],
  );
}

export function useOnClickOutV2(
  callback: () => void,
): React.MutableRefObject<any> {
  const ref = React.useRef<any>(null);

  React.useEffect(() => {
    const listener = (clickEvent: Event): void => {
      const path =
        clickEvent?.composedPath != null
          ? event?.composedPath()
          : // @ts-expect-error doesn't recognize path
            clickEvent.path;
      if (path != null && ref.current != null) {
        const targetWasClicked: boolean = path.includes(ref.current);
        if (!targetWasClicked) {
          callback();
        }
      }
    };

    window.setTimeout(() => {
      document.addEventListener("mousedown", listener);
    });

    return () => {
      document.removeEventListener("mousedown", listener);
    };
  }, [callback]);

  return ref;
}

export function useOnClickOutAny(callback: () => void): void {
  React.useEffect(() => {
    let mounted = true;
    const listener = (clickEvent: Event): void => {
      callback();
    };

    window.setTimeout(() => {
      if (mounted) {
        window.addEventListener("click", listener);
      }
    }, 50);

    return () => {
      mounted = false;
      window.removeEventListener("click", listener);
    };
  }, [callback]);
}
