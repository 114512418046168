/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
export type ParamsType = {
  scheduleUUID: string;
};
export type ResponseType = void;
export const Endpoint = 'calendar/book-a-time/delete-book-a-time-schedule';
export const Method = 'POST';

export interface Plug {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
}export const paramsSchema = {"type":"object","properties":{"scheduleUUID":{"type":"string"}},"required":["scheduleUUID"],"$schema":"http://json-schema.org/draft-07/schema#"}