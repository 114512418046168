/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { HiylloForm } from "../../types/forms/form";
import { HiylloFormResponse } from "../../types/forms/response";

export type ParamsType = {
  formUUID: string;
};
export type ResponseType = {
  form: HiylloForm;
  responses: HiylloFormResponse[];
};
export const Endpoint = "forms/get-form-responses";
export const Method = "GET";

export interface Plug {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
}
export const paramsSchema = {"type":"object","properties":{"formUUID":{"type":"string"}},"required":["formUUID"],"$schema":"http://json-schema.org/draft-07/schema#"}