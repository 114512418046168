import React from "react";
import {
  type TaskSizingType,
  type ListTasksSlimTaskType,
} from "@hiyllo/omni-common/src/types/tasks/task-item";
import { styled } from "@hiyllo/ux/styled";
import { SizingPill } from "../../components/sizing-pill";
import { useUpdateTaskSizing } from "../../hooks/use-update-task-sizing";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationTriangle,
  faTimes,
} from "@fortawesome/pro-light-svg-icons";
import { getTaskWarning } from "./helpers/get-task-warning";
import { CondensedDetailsPill } from "../../kanban-view/condensed-details-pill";
import { AssigneePill, StatusPill, DueDatePill, useUpdateTaskAssignee } from "@hiyllo/omni-tasks";

const Container = styled("div", ({ $theme }) => ({
  background: $theme.background3,
  padding: 10,
  borderRadius: 10,
  paddingBottom: 17.5,
}));

const PillsRow = styled("div", ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-start",
  gap: 10,
  marginBottom: 10,
  flexWrap: "wrap",
}));

export const WarningPill = styled("div", ({
  color: "red",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 7.5,
  background: "rgba(255, 0, 0, 0.20)",
  borderRadius: 20,
  height: 26,
  paddingRight: "0.6em",
  paddingLeft: "0.5em",
}));

const RemoveFromSprintButton = styled("div", ({ $theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 7.5,
  position: "absolute",
  top: 5,
  right: 10,
  background: $theme.midground,
  borderRadius: 20,
  height: 26,
  paddingRight: "1em",
  paddingLeft: "1em",
  fontSize: 12,
  cursor: "pointer",
  userSelect: "none",
}));

export const SprintPlanningTaskCard = React.memo(
  function SprintPlanningTaskCard(props: {
    task: ListTasksSlimTaskType;
    onRemoveFromSprint: () => void;
    onClick?: () => void;
  }): JSX.Element {
    const overdue =
      !["done", "wontdo"].includes(props.task.status.id) &&
      (props.task.dueDate?.date.valueOf() ?? Infinity) < Date.now();
    const updateTaskSizingMutation = useUpdateTaskSizing();
    const updateTaskAssignee = useUpdateTaskAssignee();

    const setSizing = React.useCallback(
      (sizing: TaskSizingType | null) => {
        void updateTaskSizingMutation.call({
          taskUUID: props.task.uuid,
          sizing,
        });
      },
      [props.task.uuid, updateTaskSizingMutation],
    );

    const setAssignee = React.useCallback(
      (assigneeUserId: string | null) => {
        void updateTaskAssignee.call({
          taskUUID: props.task.uuid,
          assigneeUserId,
        });
      },
      [props.task.uuid, updateTaskAssignee],
    );

    const warning = getTaskWarning(props.task);

    return (
      <Container onClick={props.onClick}>
        <PillsRow>
          {warning != null ? (
            <WarningPill>
              <FontAwesomeIcon icon={faExclamationTriangle} />
              {warning}
            </WarningPill>
          ) : null}
          <CondensedDetailsPill task={props.task} />
          <StatusPill status={props.task.status} onChangeStatus={null} />
          {props.task.dueDate !== null ? (
            <DueDatePill
              dueDate={props.task.dueDate}
              onChangeDueDate={null}
              overdue={overdue}
            />
          ) : null}
          <AssigneePill
            assignee={props.task.assigneeUserId}
            onChangeAssignee={setAssignee}
            hideLabel={true}
          />
          <SizingPill
            sizing={props.task.sizing}
            onChangeSizing={setSizing}
            collapsed={false}
          />
        </PillsRow>
        {props.task.title}
        <div style={{ position: "relative" }}>
          <RemoveFromSprintButton
            onClick={(evt: React.MouseEvent) => {
              evt.stopPropagation();
              props.onRemoveFromSprint();
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
            Remove From Sprint
          </RemoveFromSprintButton>
        </div>
      </Container>
    );
  },
);
