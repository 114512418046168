/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
export type HiylloWorkUserSafeIntegrationValue = {
  uuid: string;
  host: string;
  type: "hiyllo-work";
};

export type MicrosoftOutlookUserSafeIntegrationValue = {
  email: string | null;
  uuid: string;
  type: "microsoft/outlook";
};

export type GoogleUserSafeIntegrationValue = {
  uuid: string;
  email: string | null;
  type: "google";
};

export type UserSafeIntegrationValue =
  | HiylloWorkUserSafeIntegrationValue
  | MicrosoftOutlookUserSafeIntegrationValue
  | GoogleUserSafeIntegrationValue;

export type ParamsType = null;
export type ResponseType = {
  integrations: UserSafeIntegrationValue[];
};
export const Endpoint = "integrations/list";
export const Method = "POST";

export interface Plug {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
}

export const RateLimitingConfig = {
  calls: 5,
  per: 2000,
};
export const paramsSchema = {"type":"null","$schema":"http://json-schema.org/draft-07/schema#"}