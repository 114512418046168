import React from "react";

export const MainContent = ({
  noPadding,
  children,
  padding,
}: {
  noPadding?: boolean;
  padding?: number;
  children: React.ReactNode;
}): JSX.Element => {
  return (
    <div
      style={{
        width: 1,
        height: "100%",
        flexGrow: 1,
        color: "white",
        fontFamily: "hiyllo",
      }}
    >
      <div
        style={{
          padding: noPadding === true ? 0 : padding ?? 40,
          height:
            noPadding === true
              ? "100%"
              : `calc(100% - ${(padding ?? 40) * 2}px)`,
          overflowY: "auto",
        }}
      >
        {children}
      </div>
    </div>
  );
};
