import { styled } from "@hiyllo/ux/styled";
import React from "react";
import { useGetProject } from "../hooks/use-get-project";
import { LoadingSpinnerFullView } from "../../../platform/loading/spinner-loading-full";
import { type TaskProject } from "../../../types/tasks/tasks-organization";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faPlus,
  faSquareKanban,
  faTimeline,
  faTimelineArrow,
} from "@fortawesome/pro-light-svg-icons";
import { useMultipleDocuments } from "@hiyllo/react-data";
import { seamlessClient } from "../../../seamless-client";
import { Cache } from "../../../platform/cache";

import * as ListTasksBP from "../../../blueprints/tasks/list-task-items";
import * as TasksInProjectSpec from "../../../blueprints/tasks/pubsub/tasks-in-project-spec";
import { Tenant } from "../../../platform/tenancy";
import {
  TaskType,
  type ListTasksSlimTaskType,
  DEFAULT_STATUSES,
  type TaskSizingType,
} from "../../../types/tasks/task-item";
import { IconsByTaskType } from "../constants/icons-by-task-type";
import { Input } from "@hiyllo/ux/input";
import { CircleButton } from "@hiyllo/ux/circle-button";
import { useCreateTaskItem } from "../hooks/use-create-task-item";
import { SizingPill } from "../components/sizing-pill";
import { useProjectTasks } from "../hooks/use-project-tasks";
import {
  FixedSizingPillContext,
  FixedStatusPillContext,
  HighlightTaskContext,
  KanbanFramelessContext,
  TaskKanbanView,
} from "../kanban-view/task-kanban-view";
import moment from "moment";
import { useProjectOrder } from "../hooks/use-project-order";
import { useUpdateTaskSizing } from "../hooks/use-update-task-sizing";
import { useUpdateTaskParent } from "../hooks/use-update-task-parent";
import { useUpdateTaskStatus } from "../hooks/use-update-task-status";
import {
  formatEstimateFromWorkDays,
  getRemainingSizingInDays,
  getTotalSizingInDays,
} from "../../../utils/tasks/sizing";
import { ProjectArchivedBanner } from "../components/project-archived-banner";
import { useShowConfirmationDialog } from "@hiyllo/ux/dialogs";
import { TaskSearchOverlay } from "@hiyllo/omni-search";

const ProjectPlanningViewContainer = styled("div", ({ $theme }) => ({
  display: "flex",
  flexDirection: "row",
  overflowX: "auto",
  gap: 30,
  height: "100%",
}));

const ProjectName = styled("div", ({ $theme }) => ({
  fontSize: 60,
  fontWeight: 500,
  fontFamily: "hiyllo",
  wordBreak: "break-word",
}));

const ProjectColumn = styled("div", ({ $theme }) => ({
  maxWidth: 300,
  flexShrink: 0,
  // background: $theme.background2
}));

const EpicColumn = styled("div", ({ $theme }) => ({
  width: 300,
  display: "flex",
  flexDirection: "column",
  gap: 7.5,
  height: "100%",
  flexShrink: 0,
  // background: $theme.background2
}));

const Row = styled("div", ({ $theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 20,
  fontSize: 22,
  fontFamily: "hiyllo",
  userSelect: "none",
  // background: $theme.background2
}));

const AddCard = styled("div", ({ $theme }) => ({
  background: $theme.background2,
  padding: 15,
  borderRadius: 10,
  cursor: "pointer",
  display: "flex",
  flexDirection: "column",
  gap: 10,
}));

const PlaceholderCard = styled("div", ({ $theme }) => ({
  background: $theme.background2,
  borderRadius: 10,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  height: "100%",
}));

const BottomColumnCard = styled<"div", { isHighlighted?: boolean }>(
  "div",
  ({ $theme, isHighlighted }) => ({
    background: isHighlighted === true ? $theme.midground : $theme.background2,
    cursor: "pointer",
    borderRadius: 10,
    padding: 15,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: 15,
    flexDirection: "row",
  }),
);

const InputPlaceholder = styled<"div">("div", ({ $theme }) => ({
  borderColor: $theme.background3,
  borderWidth: 2,
  borderStyle: "solid",
  cursor: "pointer",
  borderRadius: 8,
  padding: 15,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: 15,
  flexDirection: "row",
}));

const AddCardInputRow = styled("div", ({}) => ({
  display: "flex",
  flexDirection: "row",
  gap: 15,
  alignItems: "center",
}));

const DetailRow = styled("div", ({ $theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 10,
  fontSize: 18,
}));

const ColumnIcon = styled("div", ({ $theme }) => ({ fontSize: 40 }));

const ProjectPlanningView = React.memo(function ProjectPlanningView(props: {
  project: TaskProject;
  tasksList: ListTasksSlimTaskType[];
}): JSX.Element {
  const tasks = useProjectTasks({ projectUUID: props.project.uuid });
  const updateTaskSizing = useUpdateTaskSizing();
  const createTaskMutation = useCreateTaskItem();
  const { order, onChangeOrder } = useProjectOrder({
    projectUUID: props.project.uuid,
    onTaskUpdated: (task) => {
      tasks.ingest([task], false);
    },
  });
  const updateTaskParent = useUpdateTaskParent();
  const [searchForTask, setSearchForTask] = React.useState(false);
  const [addExistingStory, setAddExistingStory] = React.useState(false);

  const epicInputRef = React.useRef<HTMLInputElement>(null);
  const [epicSizing, setEpicSizing] = React.useState<TaskSizingType | null>(
    null,
  );
  const [selectedEpic, setSelectedEpic] = React.useState<string | null>(null);
  const epics = React.useMemo(() => {
    return tasks.data.filter((t) => t.type === TaskType.epic);
  }, [tasks.data]);
  const showConfirmDialog = useShowConfirmationDialog();

  const onCreateEpic = React.useCallback(() => {
    const title = epicInputRef.current?.value;

    if (!title) {
      return;
    }

    const params = {
      title,
      description: "",
      status: DEFAULT_STATUSES[0],
      priority: 0,
      dueDate: null,
      sizing: epicSizing,
      assigneeUserId: null,
      projectUUID: props.project.uuid,
      type: TaskType.epic,
      parentUUID: null,
      ignoreSimilarTaskWarning: false,
    } as const;

    void createTaskMutation.call(params).then(async (res) => {
      if (res.status === "similar-task-warning") {
        if (
          await showConfirmDialog({
            title: "Similar Task",
            message: `A similar task (${res.task.title}) already exists. Do you want to create another task?`,
            confirmLabel: "Create Anyway",
          })
        ) {
          void createTaskMutation.call({
            ...params,
            ignoreSimilarTaskWarning: true,
          });
        }
      }
    });

    epicInputRef.current.value = "";
  }, [createTaskMutation, epicSizing, props.project.uuid, showConfirmDialog]);

  const storyInputRef = React.useRef<HTMLInputElement>(null);
  const [storySizing, setStorySizing] = React.useState<TaskSizingType | null>(
    null,
  );
  const [selectedStory, setSelectedStory] = React.useState<string | null>(null);
  const stories = React.useMemo(() => {
    return tasks.data.filter(
      (t) => t.type === TaskType.story && t.parentUUID === selectedEpic,
    );
  }, [selectedEpic, tasks.data]);

  const onCreateStory = React.useCallback(() => {
    const title = storyInputRef.current?.value;

    if (!title) {
      return;
    }

    const params = {
      title,
      description: "",
      status: DEFAULT_STATUSES[0],
      priority: 0,
      dueDate: null,
      sizing: storySizing,
      assigneeUserId: null,
      projectUUID: props.project.uuid,
      type: TaskType.story,
      parentUUID: selectedEpic,
      ignoreSimilarTaskWarning: false,
    } as const;

    void createTaskMutation.call(params).then(async (res) => {
      if (res.status === "similar-task-warning") {
        if (
          await showConfirmDialog({
            title: "Similar Task",
            message: `A similar task (${res.task.title}) already exists. Do you want to create another task?`,
            confirmLabel: "Create Anyway",
          })
        ) {
          void createTaskMutation.call({
            ...params,
            ignoreSimilarTaskWarning: true,
          });
        }
      }
    });

    storyInputRef.current.value = "";
  }, [
    createTaskMutation,
    props.project.uuid,
    selectedEpic,
    showConfirmDialog,
    storySizing,
  ]);

  const taskInputRef = React.useRef<HTMLInputElement>(null);
  const [taskSizing, setTaskSizing] = React.useState<TaskSizingType | null>(
    null,
  );
  const [selectedTask, setSelectedTask] = React.useState<string | null>(null);
  const actualTasks = React.useMemo(() => {
    return tasks.data.filter(
      (t) =>
        t.type === TaskType.task &&
        t.parentUUID === (selectedStory ?? selectedEpic),
    );
  }, [selectedEpic, selectedStory, tasks.data]);

  const onCreateTask = React.useCallback(() => {
    const title = taskInputRef.current?.value;

    if (!title) {
      return;
    }

    const params = {
      title,
      description: "",
      status: DEFAULT_STATUSES[0],
      priority: 0,
      dueDate: null,
      sizing: taskSizing,
      assigneeUserId: null,
      projectUUID: props.project.uuid,
      type: TaskType.task,
      parentUUID: selectedStory ?? selectedEpic,
      ignoreSimilarTaskWarning: true,
    } as const;

    void createTaskMutation.call(params).then(async (res) => {
      if (res.status === "similar-task-warning") {
        if (
          await showConfirmDialog({
            title: "Similar Task",
            message: `A similar task (${res.task.title}) already exists. Do you want to create another task?`,
            confirmLabel: "Create Anyway",
          })
        ) {
          void createTaskMutation.call({
            ...params,
            ignoreSimilarTaskWarning: true,
          });
        }
      }
    });

    taskInputRef.current.value = "";
  }, [
    createTaskMutation,
    props.project.uuid,
    selectedEpic,
    selectedStory,
    showConfirmDialog,
    taskSizing,
  ]);

  const correctStoryTiming = React.useCallback(() => {
    const days = getTotalSizingInDays(actualTasks);

    if (selectedStory == null) {
      return;
    }

    void updateTaskSizing.call({
      taskUUID: selectedStory,
      sizing:
        days >= 10
          ? {
              value: Math.ceil(days / 5),
              in: "work-weeks",
              type: "automatic",
            }
          : {
              value: days,
              in: "work-days",
              type: "automatic",
            },
    });
  }, [actualTasks, selectedStory, updateTaskSizing]);

  const correctEpicTiming = React.useCallback(() => {
    const days = getTotalSizingInDays(stories);

    if (selectedEpic == null) {
      return;
    }

    void updateTaskSizing.call({
      taskUUID: selectedEpic,
      sizing:
        days >= 10
          ? {
              value: Math.ceil(days / 5),
              in: "work-weeks",
              type: "automatic",
            }
          : {
              value: days,
              in: "work-days",
              type: "automatic",
            },
    });
  }, [selectedEpic, stories, updateTaskSizing]);
  const updateTaskStatus = useUpdateTaskStatus();

  const epicLevelEstimateDays = getTotalSizingInDays(epics);
  const epicLevelRemainingEstimateDays = getRemainingSizingInDays(epics);
  const epicLevelEstimateWorkWeeks = epicLevelEstimateDays / 5;

  const selectedEpicItem = epics.find((e) => e.uuid === selectedEpic);
  const selectedStoryItem = stories.find((s) => s.uuid === selectedStory);

  return (
    <FixedStatusPillContext.Provider value={true}>
      <KanbanFramelessContext.Provider value={true}>
        <FixedSizingPillContext.Provider value={true}>
          {searchForTask ? (
            <TaskSearchOverlay
              onClose={() => setSearchForTask(false)}
              onTaskSelected={(task) => {
                void updateTaskParent.call({
                  taskUUID: task.uuid,
                  parent: selectedStory ?? selectedEpic,
                });
                void updateTaskStatus.call({
                  taskUUID: task.uuid,
                  status: DEFAULT_STATUSES[0],
                  order: null,
                });
                setSearchForTask(false);
              }}
            />
          ) : null}
          {addExistingStory ? (
            <TaskSearchOverlay
              onClose={() => setAddExistingStory(false)}
              onTaskSelected={(task) => {
                void updateTaskParent.call({
                  taskUUID: task.uuid,
                  parent: selectedEpic,
                });
                void updateTaskStatus.call({
                  taskUUID: task.uuid,
                  status: DEFAULT_STATUSES[0],
                  order: null,
                });
                setAddExistingStory(false);
              }}
              typeFilter={TaskType.story}
            />
          ) : null}
          {props.project.archived === true ? (
            <div style={{ paddingBottom: 20 }}>
              <ProjectArchivedBanner projectUUID={props.project.uuid} />
            </div>
          ) : null}
          <ProjectPlanningViewContainer>
            <ProjectColumn>
              <ColumnIcon>
                <FontAwesomeIcon icon={faSquareKanban} />
              </ColumnIcon>
              <ProjectName>{props.project.name}</ProjectName>
              <div style={{ height: 15 }} />
              <DetailRow>
                <div style={{ flexShrink: 0 }}>
                  <FontAwesomeIcon icon={faTimeline} fixedWidth />
                </div>
                Total Project completion estimate is{" "}
                {formatEstimateFromWorkDays(epicLevelEstimateDays)}
              </DetailRow>
              <div style={{ height: 15 }} />
              <DetailRow>
                <div style={{ flexShrink: 0 }}>
                  <FontAwesomeIcon icon={faTimeline} fixedWidth />
                </div>
                Remaining work is estimated at{" "}
                {formatEstimateFromWorkDays(epicLevelRemainingEstimateDays)}
              </DetailRow>
              <div style={{ height: 15 }} />
              <DetailRow>
                <div style={{ flexShrink: 0 }}>
                  <FontAwesomeIcon icon={faCalendar} fixedWidth />
                </div>
                Project will be completed around{" "}
                {moment()
                  .add(epicLevelEstimateWorkWeeks, "weeks")
                  .format("MMMM Do, YYYY")}
              </DetailRow>
            </ProjectColumn>
            <EpicColumn>
              <Row>
                <ColumnIcon>
                  <FontAwesomeIcon icon={IconsByTaskType[TaskType.epic]} />
                </ColumnIcon>
                Epics
              </Row>
              <AddCard>
                <AddCardInputRow>
                  <SizingPill
                    sizing={epicSizing}
                    onChangeSizing={setEpicSizing}
                  />
                </AddCardInputRow>
                <AddCardInputRow>
                  <Input
                    multiline
                    placeholder="Add an epic..."
                    ref={epicInputRef}
                  />
                  <CircleButton
                    icon={faPlus}
                    onClick={onCreateEpic}
                    size={35}
                    isLoading={createTaskMutation.isLoading}
                  />
                </AddCardInputRow>
              </AddCard>
              <HighlightTaskContext.Provider value={selectedEpic}>
                {tasks.loading || (epics.length > 0 && order == null) ? (
                  <LoadingSpinnerFullView />
                ) : order == null || epics.length === 0 ? null : (
                  <TaskKanbanView
                    order={order}
                    onChangeOrder={onChangeOrder}
                    tasks={epics}
                    hideTaskProjectLabels={true}
                    columns={["*"]}
                    hideHeader={true}
                    onClickTask={(task) => {
                      setSelectedEpic(task.uuid);
                      setSelectedStory(null);
                      return selectedEpic !== task.uuid;
                    }}
                  />
                )}
              </HighlightTaskContext.Provider>
            </EpicColumn>
            <EpicColumn>
              {selectedEpic != null ? (
                <>
                  <Row>
                    <ColumnIcon>
                      <FontAwesomeIcon icon={IconsByTaskType[TaskType.story]} />
                    </ColumnIcon>
                    Stories
                  </Row>
                  <AddCard>
                    <AddCardInputRow>
                      <SizingPill
                        sizing={storySizing}
                        onChangeSizing={setStorySizing}
                      />
                    </AddCardInputRow>
                    <AddCardInputRow>
                      <Input
                        multiline
                        placeholder="Add a story..."
                        ref={storyInputRef}
                      />
                      <CircleButton
                        icon={faPlus}
                        onClick={onCreateStory}
                        size={35}
                        isLoading={createTaskMutation.isLoading}
                      />
                    </AddCardInputRow>
                  </AddCard>
                  <BottomColumnCard
                    isHighlighted={selectedStory === null}
                    onClick={() => setSelectedStory(null)}
                  >
                    <FontAwesomeIcon icon={IconsByTaskType[TaskType.task]} />
                    View Tasks without Stories
                  </BottomColumnCard>
                  <InputPlaceholder onClick={() => setAddExistingStory(true)}>
                    <FontAwesomeIcon icon={faPlus} /> Add Existing Story
                  </InputPlaceholder>

                  <HighlightTaskContext.Provider value={selectedStory}>
                    {order == null ? (
                      <LoadingSpinnerFullView />
                    ) : (
                      <TaskKanbanView
                        order={order}
                        onChangeOrder={onChangeOrder}
                        tasks={stories}
                        hideTaskProjectLabels={true}
                        columns={["*"]}
                        hideHeader={true}
                        onClickTask={(task) => {
                          setSelectedStory(task.uuid);
                          return selectedStory !== task.uuid;
                        }}
                      />
                    )}
                  </HighlightTaskContext.Provider>
                  <BottomColumnCard>
                    {formatEstimateFromWorkDays(getTotalSizingInDays(stories))}
                    {selectedEpicItem?.sizing?.type !== "automatic" ? (
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={correctEpicTiming}
                      >
                        <FontAwesomeIcon icon={faTimelineArrow} />
                      </div>
                    ) : null}
                  </BottomColumnCard>
                </>
              ) : (
                <PlaceholderCard>
                  <ColumnIcon>
                    <FontAwesomeIcon icon={IconsByTaskType[TaskType.story]} />
                  </ColumnIcon>
                  Stories
                </PlaceholderCard>
              )}
            </EpicColumn>
            <EpicColumn>
              {selectedEpic != null ? (
                <>
                  <Row>
                    <ColumnIcon>
                      <FontAwesomeIcon icon={IconsByTaskType[TaskType.task]} />
                    </ColumnIcon>
                    Tasks
                  </Row>
                  <AddCard>
                    <AddCardInputRow>
                      <SizingPill
                        sizing={taskSizing}
                        onChangeSizing={setTaskSizing}
                      />
                    </AddCardInputRow>
                    <AddCardInputRow>
                      <Input
                        multiline
                        placeholder="Add a task..."
                        ref={taskInputRef}
                      />
                      <CircleButton
                        icon={faPlus}
                        onClick={onCreateTask}
                        size={35}
                        isLoading={createTaskMutation.isLoading}
                      />
                    </AddCardInputRow>
                  </AddCard>
                  <InputPlaceholder onClick={() => setSearchForTask(true)}>
                    <FontAwesomeIcon icon={faPlus} /> Add Existing Task
                  </InputPlaceholder>
                  <HighlightTaskContext.Provider value={selectedTask}>
                    {order == null ? (
                      <LoadingSpinnerFullView />
                    ) : (
                      <TaskKanbanView
                        order={order}
                        onChangeOrder={onChangeOrder}
                        tasks={actualTasks}
                        hideTaskProjectLabels={true}
                        columns={["*"]}
                        hideHeader={true}
                        onClickTask={(task) => {
                          return false;
                        }}
                      />
                    )}
                  </HighlightTaskContext.Provider>
                  <BottomColumnCard>
                    {formatEstimateFromWorkDays(
                      getTotalSizingInDays(actualTasks),
                    )}
                    {(selectedStoryItem ?? selectedEpicItem)?.sizing?.type !==
                    "automatic" ? (
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={correctStoryTiming}
                      >
                        <FontAwesomeIcon icon={faTimelineArrow} />
                      </div>
                    ) : null}
                  </BottomColumnCard>
                </>
              ) : (
                <PlaceholderCard>
                  <ColumnIcon>
                    <FontAwesomeIcon icon={IconsByTaskType[TaskType.task]} />
                  </ColumnIcon>
                  Tasks
                </PlaceholderCard>
              )}
            </EpicColumn>
          </ProjectPlanningViewContainer>
        </FixedSizingPillContext.Provider>
      </KanbanFramelessContext.Provider>
    </FixedStatusPillContext.Provider>
  );
});

export const ProjectPlanningViewWrapper = React.memo(
  function ProjectPlanningViewWrapper(props: {
    projectUUID: string;
  }): JSX.Element {
    const projectQuery = useGetProject({ uuid: props.projectUUID });

    const tasksList = useMultipleDocuments<
      ListTasksSlimTaskType,
      {
        subscription: TasksInProjectSpec.Typings;
        query: ListTasksBP.Plug;
      }
    >(seamlessClient, {
      cache: Cache,
      key: "tasks.for-project/" + props.projectUUID,
      subscription: {
        blueprint: TasksInProjectSpec,
        topic: `[${Tenant}]tasks-in-project/${props.projectUUID}`,
      },
      query: {
        blueprint: ListTasksBP,
        params: { projectUUID: props.projectUUID },
        mapperFn: (res) => res.tasks,
        deps: [props.projectUUID],
      },
      keyExtractorFn: (d) => d.uuid,
    });

    if (projectQuery.isError) {
      return <div>Error Loading</div>;
    }

    if (projectQuery.isLoading) {
      return <LoadingSpinnerFullView />;
    }

    return (
      <ProjectPlanningView
        project={projectQuery.data.project}
        tasksList={tasksList.data}
      />
    );
  },
);
