import React from "react";
import { seamlessClient } from "../seamless-client";
import { UpdateBanner } from "../platform/components/update-banner";
import { Electron } from "../platform/electron";

// @ts-expect-error window._omniCommitHash
const embeddedCommitHash: string | null = window._omniCommitHash ?? null;

export const UpdateProvider = React.memo(
  function UpdateProvider(): null | JSX.Element {
    const [hasUpdate, setHasUpdate] = React.useState(false);

    React.useEffect(() => {
      if (!Electron.isElectron) {
        const cb = (): void => {
          void fetch("/_cmthsh").then(async (res) => {
            const newVersion = await res.text();
            if (newVersion !== embeddedCommitHash) {
              setHasUpdate(true);
            }
          });
        };

        seamlessClient.on.transportStatusChange(cb);

        return () => {
          seamlessClient.off.transportStatusChange(cb);
        };
      }
    }, []);

    if (hasUpdate) {
      return <UpdateBanner />;
    }

    return null;
  },
);
