import { type CalendarEvent } from "@hiyllo/omni-common/src/types/calendar/calendar-event";

export function useOverlapIndex(
  current: CalendarEvent,
  overlapsWith: CalendarEvent[],
): number {
  const all = [current, ...overlapsWith].sort(
    (a, b) => a.createdDate.valueOf() - b.createdDate.valueOf(),
  );
  return all.indexOf(current);
}
