/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { BookATimeSchedule } from "../../../types/calendar/book-a-time";

export type ParamsType = null;
export type ResponseType = {
  schedules: BookATimeSchedule[]
};
export const Endpoint = 'calendar/book-a-time/retrieve-book-a-time-schedules';
export const Method = 'GET';

export interface Plug {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
}export const paramsSchema = {"type":"null","$schema":"http://json-schema.org/draft-07/schema#"}