import React from "react";
import { Pressable, Text, View } from "react-native";
import { ContainerIcon, useFetchContainer } from "@hiyllo/omni-stuff";
import { styledRN } from "@hiyllo/ux/styled";
import { UserFacingStuffElement } from "../../../types/stuff/element";
import { Button } from "@hiyllo/ux/button";
import { EmptySplash } from "@hiyllo/ux/empty-splash";
import { faEmptySet } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Centered } from "../../../ux/alpha";

const PanelElement = styledRN<"View">(View, ({ $theme }) => ({
  backgroundColor: $theme.midground1,
  padding: 10,
  borderRadius: 10,
  width: 400,
  maxHeight: 500,
  minHeight: 200,
}));

const RowElement = styledRN<"Pressable">(Pressable, ({ $theme }) => ({
  backgroundColor: $theme.midground2,
  padding: 10,
  borderRadius: 10,
  marginBottom: 10,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 5
}));

const HeaderRow = styledRN<"View">(View, ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 5,
  marginBottom: 10
}));

export const ContainerSelectorPanel = React.memo(function ContainerSelectorPanel(props: {
  label?: string;
  isLoading?: boolean;
  onSelect: (containerUUID: string) => void;
}): JSX.Element {
  const [container, setContainer] = React.useState<string | null>(null);
  const { sortedElements, container: containerActual } = useFetchContainer({ uuid: container as string }, {
    onContainerChangeRequired: setContainer
  });

  const onSelect = React.useCallback(() => {
    props.onSelect(container ?? '');
  }, [container, props]);

  return (
    <PanelElement>
      <HeaderRow>
        {containerActual != null ?
          <>
            <ContainerIcon type={containerActual.type} size={20} color="white" />
            <Text style={{ color: "white", fontFamily: "hiyllo" }}>{containerActual.name}</Text>
          </>
          : null}
      </HeaderRow>
      <div style={{ overflowY: "auto" }}>
        {sortedElements.length === 0 ?
          <Centered style={{ height: '100%', flexGrow: 10, fontFamily: "hiyllo" }}>
            <FontAwesomeIcon icon={faEmptySet} /> &nbsp;Nothing in {containerActual?.name ?? "this folder"}
          </Centered>
          : sortedElements.map((element: UserFacingStuffElement) => (
            element.type === 'asset' ? null :
              <RowElement
                key={element.container.uuid}
                onPress={() => setContainer(element.container.uuid)}
              >
                <ContainerIcon type={element.container.type} size={20} color="white" />
                <Text style={{ color: "white", fontFamily: "hiyllo" }}>
                  {element.container.name}
                </Text>
              </RowElement>
          ))}
      </div>
      <div style={{ flexGrow: 1, height: 5, flexShrink: 0 }} />
      <div style={{ flexShrink: 0 }}>
        {containerActual != null ?
          <Button isLoading={props.isLoading} label={(props.label ?? "Select") + " " + containerActual.name} onClick={onSelect} />
          : null}
      </div>
    </PanelElement>
  );
});