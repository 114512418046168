/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
export type ParamsType = {
  formUUID: string;
  fileId: string;
};
export type ResponseType = {
  src: string;
};
export const Endpoint = "forms/get-form-response-file";
export const Method = "POST";

export interface Plug {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
}
export const paramsSchema = {"type":"object","properties":{"formUUID":{"type":"string"},"fileId":{"type":"string"}},"required":["fileId","formUUID"],"$schema":"http://json-schema.org/draft-07/schema#"}