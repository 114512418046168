/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { HashedString } from "@hiyllo/omni-common/src/types/cryptography/hashed-string";

export type ParamsType = {
  newPassword: HashedString;
  resetToken: string;
};
export type ResponseType = void;
export const Endpoint =
  "accounts/user/reset-password/change-password-with-reset-token";
export const Method = "POST";
export const isPublic = true;

export interface Plug {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
}
export const paramsSchema = {"type":"object","properties":{"newPassword":{"type":"object","properties":{"digest":{"type":"string"},"algorithm":{"type":"string"}},"required":["algorithm","digest"]},"resetToken":{"type":"string"}},"required":["newPassword","resetToken"],"$schema":"http://json-schema.org/draft-07/schema#"}