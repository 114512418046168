import _ from "lodash";
import {
  CalendarEvent,
  EventTypeEnum,
} from "@hiyllo/omni-common/src/types/calendar/calendar-event";
import moment from "moment";

export function doEventsOverlap(
  eventA: CalendarEvent,
  eventB: CalendarEvent,
): boolean {
  return (
    eventA.timing._computed.start.valueOf() <
      eventB.timing._computed.end.valueOf() - 1000 &&
    eventA.timing._computed.end.valueOf() - 1000 >
      eventB.timing._computed.start.valueOf()
  );
}

export function splitEvents(events: CalendarEvent[]): CalendarEvent[] {
  const splitEvents: CalendarEvent[] = [];

  for (const event of events) {
    const start = moment(event.timing._computed.start);
    const end = moment(event.timing._computed.end);

    if (start.day() !== end.day()) {
      const firstEvent = _.cloneDeep(event);
      firstEvent.timing._computed.end = start.endOf("day").toDate();
      splitEvents.push(firstEvent);

      const secondEvent = _.cloneDeep(event);
      secondEvent.timing._computed.start = end.startOf("day").toDate();
      splitEvents.push(secondEvent);
      console.log("###", firstEvent, secondEvent);
    } else {
      splitEvents.push(event);
    }
  }

  return splitEvents;
}

export function getPositionedEvents(
  events: CalendarEvent[],
  dayWidth: number | null = null,
  range?: { from: Date; to: Date },
): Array<{ event: CalendarEvent; width: number; left: number }> {
  // Clone and sort the events
  const clonedEvents = _.cloneDeep(events);

  const cloned = splitEvents(clonedEvents);

  const sortedEvents = cloned
    .filter((e) => e.type !== EventTypeEnum.busy && e.timing.allDay !== true)
    .sort(
      (a, b) =>
        a.timing._computed.start.valueOf() - b.timing._computed.start.valueOf(),
    );

  // if (sortedEvents.length === 0) {
  //   return [];
  // }

  const positionedEvents: Array<{
    event: CalendarEvent;
    width: number;
    left: number;
    lane: number;
  }> = [];

  // For each event
  for (const event of sortedEvents) {
    let placed = false;
    let lane = 0;

    while (!placed) {
      const overlappingEventsInLane = positionedEvents.filter(
        (e) => e.lane === lane && doEventsOverlap(e.event, event),
      );

      if (overlappingEventsInLane.length === 0) {
        positionedEvents.push({
          event,
          width: 0,
          left: 0,
          lane,
        }); // Width and left will be determined later
        placed = true;
      } else {
        lane++;
      }
    }
  }

  // Now, for each event, determine its width and left position based on its lane and the number of lanes it spans
  for (const positionedEvent of positionedEvents) {
    const overlappingLanes = positionedEvents
      .filter(
        (e) =>
          e !== positionedEvent &&
          doEventsOverlap(e.event, positionedEvent.event),
      )
      .map((e) => e.lane);
    const maxLane = Math.max(positionedEvent.lane, ...overlappingLanes);
    const minLane = Math.min(positionedEvent.lane, ...overlappingLanes);

    const lanesSpanned = maxLane - minLane + 1;

    positionedEvent.width = (1 / lanesSpanned) * (dayWidth ?? 0);
    positionedEvent.left = positionedEvent.lane * positionedEvent.width;
  }

  // Remove the 'lane' attribute before returning
  return positionedEvents
    .map(({ event, width, left }) => ({
      event,
      width,
      left,
    }))
    .concat(
      cloned
        .filter((e) => e.type === EventTypeEnum.busy)
        .map((event) => ({
          event,
          width: 0,
          left: 0,
        })),
    )
    .filter((e) => {
      if (range) {
        return (
          e.event.timing._computed.start >= range.from &&
          e.event.timing._computed.end <= range.to
        );
      } else {
        return true;
      }
    });
}
