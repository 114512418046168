/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { ListTasksSlimTaskType } from "../../../types/tasks/task-item";

export const TopicID = "tasks-in-project";
export type TopicNameType = `[${string}]tasks-in-project/${string}`;
export type SubscriptionParamsType = null;
export type MessageType = ListTasksSlimTaskType;
export const TopicNameRegExp = new RegExp('/tasks-in-project\\/[A-z]*/');
export const SubscriptionParamsSchema = { type: "null" };

export type Typings = {
  TopicNameType: TopicNameType,
  SubscriptionParamsType: SubscriptionParamsType,
  MessageType: MessageType;
};