/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { BookATimeMemberType, BookATimeRange, BookATimeSlotDuration } from "../../../types/calendar/book-a-time";

export type ParamsType = {
  schedule: {
    name: string,
    members: BookATimeMemberType[],
    slotDuration: BookATimeSlotDuration,
    range: BookATimeRange[]
  }
};
export type ResponseType = {
  uuid: string
};
export const Endpoint = 'calendar/book-a-time/create-book-a-time-schedule';
export const Method = 'POST';

export interface Plug {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
}export const paramsSchema = {"type":"object","properties":{"schedule":{"type":"object","properties":{"name":{"type":"string"},"members":{"type":"array","items":{"$ref":"#/definitions/BookATimeMemberType"}},"slotDuration":{"enum":[120,15,30,45,60,90],"type":"number"},"range":{"type":"array","items":{"$ref":"#/definitions/BookATimeRange"}}},"required":["members","name","range","slotDuration"]}},"required":["schedule"],"definitions":{"BookATimeMemberType":{"type":"object","properties":{"userId":{"type":"string"},"optional":{"type":"boolean","enum":[false]}},"required":["optional","userId"]},"BookATimeRange":{"type":"object","properties":{"day":{"$ref":"#/definitions/DayString"},"from":{"type":"object","properties":{"hours":{"type":"number"},"minutes":{"type":"number"},"timezone":{"type":"string"}},"required":["hours","minutes","timezone"]},"to":{"type":"object","properties":{"hours":{"type":"number"},"minutes":{"type":"number"},"timezone":{"type":"string"}},"required":["hours","minutes","timezone"]}},"required":["day","from","to"]},"DayString":{"enum":["fri","mon","sat","sun","thu","tue","wed"],"type":"string"}},"$schema":"http://json-schema.org/draft-07/schema#"}