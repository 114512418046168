import { faAdd } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useThemeStyling } from "../../../ux/themings";
import { useTheme } from "@hiyllo/ux/theme";

export const LSHeader = (props: {
  noTopPadding?: boolean;
  label: string;
  actionButton?: JSX.Element;
  actionButtonLabel?: string | null;
  actionButtonOnClick?: () => void;
}): JSX.Element => {
  const styling = useThemeStyling<"tokyo.sidebar">("tokyo.sidebar");
  const theme = useTheme();

  return (
    <div
      style={{
        padding: 20,
        paddingTop: props.noTopPadding ? 0 : 20,
        color: styling?.headerTextColor ?? "#656570",
        fontFamily: "hiyllo",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        height: 35,
      }}
      className="ls-header"
    >
      <div
        style={{
          paddingTop: 2,
          fontSize: 20,
          letterSpacing: 1,
        }}
      >
        {props.label}
      </div>
      {props.actionButton != null ? props.actionButton : null}
      {props.actionButtonLabel != null ? (
        <div
          style={{
            color: theme.foreground,
            background: theme.buttonBackground,
            boxShadow: "#00000011 0px 0px 15px 1px",
            fontFamily: "hiyllo",
            borderRadius: 5,
            padding: "0px 10px",
            fontSize: 15,
            height: 30,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            cursor: "pointer",
            userSelect: "none",
            whiteSpace: "nowrap",
          }}
          onClick={props.actionButtonOnClick}
        >
          <FontAwesomeIcon icon={faAdd} />
          &nbsp;{props.actionButtonLabel}
        </div>
      ) : null}
    </div>
  );
};
