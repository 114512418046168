import { styled } from "@hiyllo/ux/styled";
import React from "react";
import { ChatMessageEAD } from "../../../../types/alerts/alert";
import { UserImage } from "@hiyllo/omni-images";
import { useNavigateTo } from "@hiyllo/omni-continuity";

const Container = styled("div", ({ $theme }) => ({
  background: $theme.background3,
  width: 280,
  height: '100%',
  padding: 10,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  gap: 10,
  fontSize: 15,
  fontFamily: 'hiyllo',
  cursor: 'pointer',
  zIndex: 1002,
}));

const SenderName = styled("div", ({ $theme }) => ({
  color: $theme.foreground,
  fontSize: 15,
  fontWeight: 'bold',
  paddingTop: 0,
  zIndex: 1002,
}));

export const AlertMessageCard = React.memo(function AlertMessageCard(props: {
  ead: ChatMessageEAD;
  onClose: () => void;
}): JSX.Element {
  const navigate = useNavigateTo(props.ead.link);
  const onClick = () => {
    props.onClose();
    navigate();
  };

  return (
    <Container onClick={onClick}>
      <UserImage userId={props.ead.senderId} width={40} />
      <div>
        <SenderName>
          {props.ead.senderName}{props.ead.in != null ? ` in ${props.ead.in}` : ""}
        </SenderName>
        <div>
          {props.ead.message}
        </div>
      </div>
    </Container>
  );
});