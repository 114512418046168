import React from "react";

import * as SendRequestBP from "../../../../blueprints/executive-assistant/send-request";
import { seamlessClient } from "../../../../seamless-client";
import { useSelf } from "@hiyllo/omni-continuity/main";
import { LoadingSpinner } from "@hiyllo/ux/loading-spinner";
import { NymblIcon } from "@hiyllo/icons/main";

export const AssistantChatBlock = React.memo(
  function AssistantChatBlock(props: { color: string }): JSX.Element {
    const self = useSelf();
    const [response, setResponse] = React.useState<string | null>(null);
    const inputRef = React.useRef<HTMLInputElement>(null);
    const sendRequestMutation =
      seamlessClient.useMutation<SendRequestBP.Plug>(SendRequestBP);

    const onSubmit = React.useCallback(() => {
      void sendRequestMutation
        .call({
          message: inputRef.current?.value ?? "",
          sessionUUID: null,
        })
        .then((response) => {
          if (response) {
            setResponse(response.response);
          }
        });
    }, [sendRequestMutation]);

    if (!self.isAdmin) {
      return <div />;
    }

    if (response) {
      return (
        <div
          style={{
            marginTop: 15,
            width: "100%",
          }}
        >
          <NymblIcon width={16} color="white" />
          &nbsp;
          {response}
        </div>
      );
    }

    if (sendRequestMutation.isLoading) {
      return (
        <div
          style={{
            marginTop: 15,
            width: "calc(100% - 20px)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LoadingSpinner />
        </div>
      );
    }

    return (
      <input
        ref={inputRef}
        style={{
          marginTop: 15,
          width: "calc(100% - 20px)",
          padding: 10,
          border: "none",
          borderRadius: 20,
          background:
            props.color === "black"
              ? "rgba(0,0,0, 0.25)"
              : "rgba(255, 255, 255, 0.1)",
          backdropFilter: "blur(50px)",
        }}
        placeholder="Talk to Nymbl..."
        onKeyDown={(evt) => {
          if (evt.key === "Enter") {
            onSubmit();
          }
        }}
      />
    );
  },
);
