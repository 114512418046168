/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { OmniConfigType } from "../../types/config/omni-config";

export type ParamsType = { config: Partial<OmniConfigType>; };
export type ResponseType = void;
export const Endpoint = 'admin/set-config';
export const Method = 'POST';

export interface Plug {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
}

export const paramsSchema = {
  type:"object",
  required:[],
  properties:{},
  additionalProperties: true
}