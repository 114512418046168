/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
export type ParamsType = {
  code: string;
};
export type ResponseType = void;
export const Endpoint = "integrations/google/link-google";
export const Method = "POST";

export interface Plug {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
}

export const RateLimitingConfig = {
  calls: 2,
  per: 2500,
};
export const paramsSchema = {"type":"object","properties":{"code":{"type":"string"}},"required":["code"],"$schema":"http://json-schema.org/draft-07/schema#"}