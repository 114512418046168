import React from "react";
import * as RetrieveDocumentBP from "../../../blueprints/stuff/retrieve-document";
import { seamlessClient } from "../../../seamless-client";
import { usePath } from "@hiyllo/omni-router";
import { type StuffEDataParamsType } from "../../../types/navigation/edata";
import { LoadingSpinnerFullView } from "../../../platform/loading/spinner-loading-full";
import { type DocumentContents } from "../../../types/stuff/document";
import "@hiyllo/editor/src/editor.css";
import { faExclamationTriangle } from "@fortawesome/pro-light-svg-icons";
import { UseMoopsyQueryRetVal } from "@moopsyjs/react/main";
import { EmptySplash } from "@hiyllo/ux/empty-splash";
import { DocumentViewV1 } from "./document-view-v1";
import { DocumentViewV2 } from "./document-view-v2";

export const DocumentView = React.memo(function DocumentView(props: {
  retrieveDocumentQuery: UseMoopsyQueryRetVal<RetrieveDocumentBP.Plug>;
  contents: DocumentContents;
}): JSX.Element {
  const { uuid } = usePath().params as StuffEDataParamsType;

  if (uuid == null) {
    throw new Error("uuid is null");
  }

  const retrieveDocumentQuery =
    seamlessClient.useQuery<RetrieveDocumentBP.Plug>(
      RetrieveDocumentBP,
      {
        uuid,
      },
    );

  if (retrieveDocumentQuery.isLoading) {
    return <LoadingSpinnerFullView />;
  }

  if (retrieveDocumentQuery.isError) {
    return (
      <EmptySplash
        icon={faExclamationTriangle}
        label="Error"
        hint={retrieveDocumentQuery.error.message}
      />
    );
  }

  if (retrieveDocumentQuery.data.document.contents.v2 === true) {
    return <DocumentViewV2 retrieveDocumentQuery={retrieveDocumentQuery} contents={retrieveDocumentQuery.data.document.contents} />;
  }
  else {
    return <DocumentViewV1 retrieveDocumentQuery={retrieveDocumentQuery} contents={retrieveDocumentQuery.data.document.contents} />;
  }
});