import { LoadingSpinner } from "@hiyllo/ux/loading-spinner";
import React from "react";

export const LoadingSpinnerFullView = React.memo(
  function LoadingSpinnerFullView(): JSX.Element {
    return (
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LoadingSpinner />
      </div>
    );
  },
);
