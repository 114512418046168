
import React, { useContext } from "react";
import { type OmniConfigType } from "../../types/config/omni-config";
import { type AnyMoopsyQuery } from "@moopsyjs/react";

export const ConfigContext = React.createContext<{
  data: OmniConfigType;
  query: AnyMoopsyQuery;
} | null>(null);

export const InitConfigProvider = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  // @ts-expect-error window._omniConfigInit
  const data = window._omniConfigInit as OmniConfigType | void;

  if (data == null) {
    return <>{children}</>;
  }

  const customFonts = data.ux.customFonts ?? [];

  return (
    <ConfigContext.Provider
      value={{
        data,
        // @ts-expect-error query=null
        query: null,
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
};

export const ConfigOverride = ConfigContext.Provider;

export const useConfig = (): OmniConfigType =>
  useContext(ConfigContext)?.data as OmniConfigType;
export const useConfigQuery = (): AnyMoopsyQuery | null =>
  useContext(ConfigContext)?.query ?? null;
