/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { TeamType } from "../../../types/organization/teams";
import { StuffContainerType } from "../../../types/stuff/container";

export type ParamsType = {
  teamUUID: string;
};
export type ResponseType = {
  team: TeamType;
  container: StuffContainerType | null;
};
export const Endpoint = 'organization/users/get-team';
export const Method = 'GET';

export interface Plug {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
}
export const paramsSchema = {"type":"object","properties":{"teamUUID":{"type":"string"}},"required":["teamUUID"],"$schema":"http://json-schema.org/draft-07/schema#"}