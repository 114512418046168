/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
export type ParamsType = {
  fromSprintUUID: string;
  toSprintUUID: string;
};
export type ResponseType = void;
export const Endpoint = 'tasks/sprints/move-unfinished-tasks-to-different-sprint';
export const Method = 'POST';

export interface Plug {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
}

export const RateLimitingConfig = {
  calls: 2,
  per: 2000,
};export const paramsSchema = {"type":"object","properties":{"fromSprintUUID":{"type":"string"},"toSprintUUID":{"type":"string"}},"required":["fromSprintUUID","toSprintUUID"],"$schema":"http://json-schema.org/draft-07/schema#"}