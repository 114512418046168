import { styled } from "@hiyllo/ux/styled";

export const TokyoPageContainer = styled("div", ({ $theme }) => ({
  backgroundColor: $theme.background1,
  height: "100%",
  width: "100%",
  overflow: "hidden",
  display: "flex",
  flexDirection: "row",
  alignItems: "stretch",
  justifyContent: "flex-start",
}));
