import { useConfig } from "../../platform/config/config-context";
import React from "react";
import { DarkMode } from "../../ux/alpha/contexts";
import { useSelf } from "@hiyllo/omni-continuity";
import { SetupPage } from "../accounts/profile-setup";
import { DEFAULT_EDATA, usePath } from "@hiyllo/omni-router";
import { AlertsInAppProvider } from "../../main/alerts-inapp-provider";
import { AlertsWebPushProvider } from "../../main/alerts-webpush-provider";
import { MeetingProvider } from "../../main/meeting-provider";
import { DefaultEDataContext } from "./navigation/contexts/default-edata-context";
import { type EDataType } from "../../types/navigation/edata";
import { SearchProvider } from "../../main/search-provider";

import { Features } from "../../types/navigation/features";
import { useShowNymblHome } from "../../platform/xp";
import { ErrorBoundary } from "../../platform/error-boundary";
import { TokyoPageContainer } from "./components/base";
import { LeftDock } from "./components/dock";
import { TokyoENavRouter } from "./enav-router";
import { RouterProviderV1 } from "./navigation/routers/router-v1";
import { CentralTickerProvider } from "@hiyllo/omni-dynamicui/main";
import { idleStateListener } from "../../platform/idle";
import { StickiesProvider } from "../stickies/stickies-provider";
import { styled } from "@hiyllo/ux/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Electron } from "../../platform/electron";
import { TabArea, TabDetailsCtx, TabsContext, useOnNewTab } from "./tabbing/tabs-provider";
import { faBell, faEyeSlash, faPlus, faSparkles, faTimes, IconDefinition } from "@fortawesome/pro-light-svg-icons";
import { WebPortalsProvider } from "@hiyllo/ux/web-portals";
import { motion } from "framer-motion";
import { EmptySplash } from "@hiyllo/ux/empty-splash";
import { IdleStateProvider, useIsIdle } from "@hiyllo/idle/main";

const NativeTitleBarElem = styled("div", ({ $theme }) => ({
  background: "rgba(255, 255, 255, 0.05)",
  height: 40,
  flexShrink: 0,
  display: "flex",
  flexDirection: "row",
  // justifyContent: "space-between",
  alignItems: "center",
  gap: 10,
  paddingLeft: 0,
  paddingRight: 10,
  fontFamily: "hiyllo",
  fontSize: 15,
  "-webkit-app-region": "drag",
  userSelect: "none",
  cursor: "grab",
  overflow: "hidden",
  transition: "height 0.15s",
}));



const TabElement = styled<"div", { active: boolean }>("div", ({ $theme, active }) => ({
  paddingTop: 5,
  paddingBottom: 5,
  paddingLeft: 5,
  paddingRight: 5,
  background: active ? $theme.midground1 : $theme.background3,
  fontFamily: "hiyllo",
  color: active ? $theme.foreground : $theme.foregroundInactive,
  borderRadius: 5,
  fontSize: 15,
  cursor: active ? "default" : "pointer",
  gap: 5,
  display: "flex",
  flexDirection: "row",
  alignItems: "center"
}));

const NativeTitleBar = React.memo(function NativeTitleBar() {
  const tabbing = React.useContext(TabsContext);
  const tabDetails = React.useContext(TabDetailsCtx);
  const isFullscreen = Electron.useIsFullscreen();
  const newTab = useOnNewTab();

  return (
    <NativeTitleBarElem id="native-toolbar">
      <div style={{ width: isFullscreen ? 0 : 70, cursor: "default" }} />
      {tabbing.tabs.map(tab => {
        const details = tabDetails.find(t => t.uuid === tab.uuid);

        if (details == null) {
          throw new Error("Tab details not found");
        }

        return (
          <TabElement
            key={tab.uuid}
            active={tab.uuid === tabbing.activeTab}
            onClick={() => tabbing.setActiveTab(tab.uuid)}
          >
            {React.isValidElement(details.icon) ? details.icon :
              <FontAwesomeIcon icon={details.icon as IconDefinition} style={{ fontSize: 12.5 }} />
            }
            {details.label}
            <div
              onClick={(evt) => {
                evt.stopPropagation();
                tabbing.setTabs(t => {
                  return t.filter(t2 => t2.uuid !== tab.uuid);
                });
                const newActiveTab = tabbing.tabs.find(t2 => t2.uuid !== tab.uuid)?.uuid ?? '';
                tabbing.setActiveTab(newActiveTab);
              }}
              style={{ width: 15, height: 15, borderRadius: 5, background: "rgba(255, 255, 255, 0.1)", display: "flex", justifyContent: "center", alignItems: "center", fontSize: 10, cursor: "pointer" }}
            >
              <FontAwesomeIcon icon={faTimes} />
            </div>
          </TabElement>
        );
      })}
      <TabElement active={false} onClick={newTab}>
        <FontAwesomeIcon icon={faPlus} fixedWidth />
      </TabElement>
      <div style={{ flexGrow: 1, width: 0 }} />
      <img
        src="hiyllo-work/gradient-white.png"
        style={{ height: 20, width: 20 }}
      />
    </NativeTitleBarElem>
  );
});


function usePageHasActivity(): boolean {
  const [hasActivity, setHasActivity] = React.useState(true);
  const lastActivityRef = React.useRef<number>(Date.now());

  React.useEffect(() => {
    const active = (): void => {
      setHasActivity(true);
      lastActivityRef.current = Date.now();
    };

    window.addEventListener("mousemove", active);
    window.addEventListener("keydown", active);

    const interval = setInterval(() => {
      const now = Date.now();
      const diff = now - lastActivityRef.current;

      if (diff > 30000) {
        setHasActivity(false);
      }
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return hasActivity;
}

const ActivityPauseIndicator = React.memo(function ActivityPauseIndicator(props: {
  paused: boolean;
}) {
  const path = usePath();

  const display = path.feature === Features.chat && props.paused;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: display ? 1 : 0 }}
      style={{ fontFamily: "hiyllo", pointerEvents: "none", userSelect: "none", position: "fixed", bottom: 20, right: 20, zIndex: 1000000, padding: 10, width: 200, backdropFilter: "blur(25px)", gap: 10, color: "white", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", borderRadius: 10 }}
    >
      <FontAwesomeIcon icon={faBell} />
      <div style={{ fontSize: 12.5 }}>
        <b style={{ fontSize: 15 }}>AFK?</b><br />
        New messages won&apos;t be marked read till you&apos;re back
      </div>
    </motion.div>
  );
});

export const TokyoRoot = React.memo(function TokyoRoot(): JSX.Element {
  const config = useConfig();
  const userSelf = useSelf();
  const isIdle = useIsIdle();
  const showNymblHome = useShowNymblHome();
  const tabbing = React.useContext(TabsContext);
  const hasActivity = usePageHasActivity();

  if (
    userSelf.profile == null
  ) {
    return <SetupPage />;
  }

  const defaultRoute = showNymblHome
    ? ({
      feature: Features.home,
      params: null,
    } as EDataType)
    : config.rootFeature != null
      ? ({
        feature: config.rootFeature,
        params: null,
      } as EDataType)
      : DEFAULT_EDATA;

  return (
    <>
      <ErrorBoundary code="TokyoRoot">
        <CentralTickerProvider>
          <DefaultEDataContext.Provider value={defaultRoute}>
            <DarkMode.Provider value={true}>
              {tabbing.tabs.map(tab => (
                <TabArea tab={tab} key={tab.uuid} hasActivity={hasActivity}>
                  <RouterProviderV1>
                    <ActivityPauseIndicator paused={!hasActivity && !isIdle} />
                    <WebPortalsProvider>
                      <SearchProvider>
                        <MeetingProvider>
                          <AlertsWebPushProvider />
                          <AlertsInAppProvider />
                          <StickiesProvider>
                            <TokyoPageContainer
                              style={{
                                flexDirection: "column",
                                background: "transparent",
                              }}
                            >
                              {Electron.isElectron ? <NativeTitleBar /> : null}
                              <TokyoPageContainer>
                                <LeftDock />
                                <TokyoPageContainer>
                                  <ErrorBoundary code="TokyoRouter">
                                    <TokyoENavRouter />
                                  </ErrorBoundary>
                                </TokyoPageContainer>
                              </TokyoPageContainer>
                            </TokyoPageContainer>
                          </StickiesProvider>
                        </MeetingProvider>
                      </SearchProvider>
                    </WebPortalsProvider>
                  </RouterProviderV1>
                </TabArea>
              ))}
            </DarkMode.Provider>
          </DefaultEDataContext.Provider>
        </CentralTickerProvider>
      </ErrorBoundary>
    </>
  );
});
