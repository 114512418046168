import { styled } from "@hiyllo/ux/styled";
import { motion } from "framer-motion";

export const PillContainer = styled("div", ({ $theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 5,
  background: $theme.background3,
  borderRadius: 15,
  padding: 5
}));

export const Pill = styled<
  "div",
  React.ComponentProps<typeof motion.div> & {
    inactive?: boolean;
    highlight?: boolean;
  }
>("div", ({ $theme, inactive, highlight }) => ({
  background:
    inactive === true
      ? "transparent"
      : highlight === true
        ? $theme.foregroundInactive
        : $theme.midground,
  borderWidth: 2,
  borderColor: $theme.midground,
  borderStyle: "solid",
  padding: 3,
  paddingLeft: 6,
  paddingRight: 6,
  borderRadius: 10,
  cursor: "pointer",
  fontSize: 12,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 5,
  userSelect: "none",
}));

export const PillL = styled<
  "div",
  React.ComponentProps<typeof motion.div> & {
    inactive?: boolean;
    highlight?: boolean;
  }
>("div", ({ $theme, inactive, highlight }) => ({
  background:
    inactive === true
      ? "transparent"
      : highlight === true
        ? $theme.foregroundInactive
        : $theme.midground,
  borderWidth: 2,
  borderColor: $theme.midground,
  borderStyle: "solid",
  padding: 6,
  paddingLeft: 8,
  paddingRight: 8,
  borderRadius: 20,
  cursor: "pointer",
  fontSize: 14,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 5,
  userSelect: "none",
  whiteSpace: "nowrap",
}));

export const TagPill = styled<
  "div",
  React.ComponentProps<typeof motion.div> & {
    inactive?: boolean;
    highlight?: boolean;
  }
>("div", ({ $theme }) => ({
  background: $theme.midground1,
  padding: 5,
  paddingLeft: 7.5,
  paddingRight: 7.5,
  borderRadius: 5,
  cursor: "pointer",
  fontSize: 14,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 5,
  userSelect: "none",
  whiteSpace: "nowrap",
}));