/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { Sprint } from "../../../types/tasks/sprint";

export type ParamsType = {
  teamUUID: string;
};
export type ResponseType = {
  sprints: Sprint[];
};
export const Endpoint = 'tasks/sprints/get-unfinished-sprints-for-team';
export const Method = 'POST';

export interface Plug {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
}

export const RateLimitingConfig = {
  calls: 3,
  per: 1500,
};export const paramsSchema = {"type":"object","properties":{"teamUUID":{"type":"string"}},"required":["teamUUID"],"$schema":"http://json-schema.org/draft-07/schema#"}