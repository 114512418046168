import React from "react";
import { StickiesOverlay } from "./views/stickies-overlay";

const StickiesToggleContext = React.createContext<(v: boolean) => void>(() => {
  //
});

export function useStickiesToggle(): (v: boolean) => void {
  return React.useContext(StickiesToggleContext);
}

export const StickiesProvider = React.memo(function StickiesProvider(
  props: React.PropsWithChildren,
): JSX.Element {
  const [showStickies, setShowStickies] = React.useState(false);

  const toggleStickies = React.useCallback((v: boolean) => {
    setShowStickies(v);
  }, []);

  React.useEffect(() => {
    const handler = (evt: KeyboardEvent): void => {
      if (evt.key === "s" && (evt.ctrlKey || evt.metaKey)) {
        console.log("opening stickies...");
        evt.preventDefault();
        toggleStickies(true);
      }
    };

    window.addEventListener("keydown", handler);

    return () => {
      window.removeEventListener("keydown", handler);
    };
  });

  return (
    <>
      <StickiesToggleContext.Provider value={toggleStickies}>
        {showStickies ? <StickiesOverlay /> : null}
        {props.children}
      </StickiesToggleContext.Provider>
    </>
  );
});
