/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
export type ParamsType = { taskUUID: string; text: string; };
export type ResponseType = void;
export const Endpoint = 'tasks/add-comment-to-task';
export const Method = 'POST';

export interface Plug {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
}

export const RateLimitingConfig = {
  calls: 1,
  per: 2000,
};export const paramsSchema = {"type":"object","properties":{"taskUUID":{"type":"string"},"text":{"type":"string"}},"required":["taskUUID","text"],"$schema":"http://json-schema.org/draft-07/schema#"}