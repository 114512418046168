/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import {
  MeetingRecordingType,
  RecordingType,
} from "../../types/meet/recording";
export type ParamsType = {
  uuid: string;
};
export type ResponseType = {
  recording: Omit<MeetingRecordingType, "recordings"> & {
    recordings: Array<RecordingType & { src: string }>;
  };
};
export const Endpoint = "stuff/fetch-meeting-recording";
export const Method = "GET";

export interface Plug {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
}
export const paramsSchema = {"type":"object","properties":{"uuid":{"type":"string"}},"required":["uuid"],"$schema":"http://json-schema.org/draft-07/schema#"}