import React from "react";
import { PillSelect } from "@hiyllo/ux/pill-select";
import { TasksView } from "./tasks-view";
import { useConfig } from "../../../platform/config/config-context";
import { LoadingSpinnerFullView } from "../../../platform/loading/spinner-loading-full";
import { useOwnTasks } from "@hiyllo/omni-tasks";
import { useSelf } from "@hiyllo/omni-continuity";
import { ListTasksSlimTaskType } from "../../../types/tasks/task-item";
import { ViewCriteriaContext } from "../contexts/view-criteria-context";
import { TabDetails } from "../../tokyo/tabbing/tabs-provider";

export const TasksICView = React.memo(function TaskGroupView(): JSX.Element {
  const isSolo = useConfig().isSolo === true;
  const self = useSelf();

  const [filter, setFilter] = React.useState<"active-sprint" | null>(
    isSolo
      ? null
      : window.localStorage.icViewFilter === "none"
        ? null
        : window.localStorage.icViewFilter ?? null,
  );

  React.useEffect(() => {
    window.localStorage.icViewFilter = filter ?? "none";
  }, [filter]);

  const { tasks, order, onChangeOrder } = useOwnTasks({ filter }, self);

  const viewCriteriaFn = React.useCallback((task: Omit<ListTasksSlimTaskType, "assignee"> | ListTasksSlimTaskType) => {
    if (filter === "active-sprint") {
      if (task.sprintUUID == null) return false;
    }

    return task.assigneeUserId === self.userId;
  }, [filter, self.userId]);

  if (order === null) {
    return <LoadingSpinnerFullView />;
  }

  return (
    <TabDetails label="Your Tasks">
      <ViewCriteriaContext.Provider value={viewCriteriaFn}>
        <TasksView
          order={order}
          onChangeOrder={onChangeOrder}
          tasks={tasks.data}
          isReady={!tasks.queryLoading}
          extraHeaderOptions={
            isSolo
              ? undefined
              : [
                <PillSelect
                  key="filter"
                  options={[
                    {
                      value: null,
                      label: "All My Tasks",
                    },
                    {
                      value: "active-sprint",
                      label: "My Active Sprints",
                    },
                  ]}
                  value={filter}
                  onChangeValue={(v) => setFilter(v as "active-sprint" | null)}
                  loading={tasks.queryLoading}
                />,
              ]
          }
        />
      </ViewCriteriaContext.Provider>
    </TabDetails>
  );
});
