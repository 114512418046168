import { faCheck, faSearch } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { HorizontalLayout } from "../../../../ux/alpha";
import { useThemeStyling } from "../../../../ux/themings";
import { type UserProfileSlimType } from "@hiyllo/omni-common/src/types/continuity/user-profile";
import { styled } from "@hiyllo/ux/styled";
import { UserImage } from "@hiyllo/omni-images";
import { useLookupPersonByName } from "@hiyllo/omni-continuity/main";

const LookupResultContainer = styled("div", {
  backgroundColor: "rgba(255, 255, 255, 0.1)",
  padding: 10,
  display: "flex",
  justifyContent: "flex-start",
  flexDirection: "row",
  alignItems: "center",
  gap: 10,
  borderRadius: 5,
  width: "calc(100% - 20px)",
  marginTop: 10,
  cursor: "pointer",
  userSelect: "none",
});

const LookupResultNameContainer = styled("div", {
  fontSize: 20,
  userSelect: "none",
});

const AddedTextContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  fontSize: 10,
  marginTop: 2.5,
});

export const LookupView = React.memo(function LookupView(props: {
  members: string[];
  added?: UserProfileSlimType[];
  resetOnAdded?: boolean;
  onAddUser: (userId: string, profile: UserProfileSlimType) => Promise<void>;
}): JSX.Element {
  const lookupByName = useLookupPersonByName();
  const inputStyling = useThemeStyling<"input.standard">("input.standard");
  const nameInputRef = React.useRef<HTMLInputElement>(null);
  const [isLoading, setIsLoading] = React.useState<string | null>(null);

  const onType = (evt: React.ChangeEvent<HTMLInputElement>): void => {
    lookupByName.callLookup(evt.target.value);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 15,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          borderRadius: 10,
        }}
      >
        <HorizontalLayout
          style={{
            width: "100%",
            gap: 15,
          }}
        >
          <FontAwesomeIcon icon={faSearch} />
          <input
            style={{
              ...inputStyling,
              width: "calc(100% - 20px)",
              padding: 10,
              height: "1em",
              border: "none",
              fontSize: 14,
              borderRadius: 5,
            }}
            placeholder="Type a name..."
            onChange={onType}
            ref={nameInputRef}
          />
        </HorizontalLayout>
        {lookupByName.profiles != null
          ? lookupByName.profiles.map((r) => (
              <LookupResultContainer
                onClick={() => {
                  if (isLoading === r.id) return;

                  setIsLoading(r.id);
                  void props
                    .onAddUser(r.id, {
                      userId: r.id,
                      profilePictureSrc: r.profilePictureSrc ?? "",
                      name: r.name,
                    })
                    .then(() => {
                      setIsLoading(null);
                      if (props.resetOnAdded === true) {
                        lookupByName.callLookup("");
                      }
                      if (nameInputRef.current != null) {
                        nameInputRef.current.value = "";
                      }
                    });
                }}
                key={r.id}
              >
                <UserImage userId={r.id} width={20} />
                <div>
                  <LookupResultNameContainer>
                    {r.name}
                  </LookupResultNameContainer>
                  {props.members.find((c) => c === r.id) ? (
                    <AddedTextContainer>
                      <FontAwesomeIcon icon={faCheck} />
                      Added
                    </AddedTextContainer>
                  ) : null}
                </div>
              </LookupResultContainer>
            ))
          : null}
        {props.added != null
          ? props.added.map((r) => (
              <LookupResultContainer key={r.userId}>
                <UserImage userId={r.userId} width={20} />
                <div>
                  <LookupResultNameContainer>
                    {r.name}
                  </LookupResultNameContainer>
                  <AddedTextContainer>
                    <FontAwesomeIcon icon={faCheck} />
                    Added
                  </AddedTextContainer>
                </div>
              </LookupResultContainer>
            ))
          : null}
      </div>
    </div>
  );
});
