import { styled } from "@hiyllo/ux/styled";
import React from "react";

const LinkBox = styled("div", ({ $theme }) => ({
  background: $theme.midground,
  padding: 7.5,
  borderRadius: 5,
  fontSize: 12,
}));

export const CopyableLinkBox = React.memo(function CopyableLinkBox(props: {
  link: string;
  label: string;
}): JSX.Element {
  const [copied, setCopied] = React.useState(false);

  const copyMeetingLink = React.useCallback(() => {
    void window.navigator.clipboard.writeText(props.link);
    setCopied(true);
  }, [props.link]);

  return (
    <div>
      <div
        style={{
          fontSize: 12,
          userSelect: "none",
          marginBottom: 5,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 5,
        }}
      >
        <div>{props.label}</div>
        <div
          style={{
            textDecoration: copied ? "" : "underline",
            cursor: "pointer",
          }}
          onClick={copyMeetingLink}
        >
          {copied ? "(Copied!)" : "(Click here to copy)"}
        </div>
      </div>
      <LinkBox>{props.link}</LinkBox>
    </div>
  );
});
