/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { EventTimingWithComputed } from "../../types/calendar/calendar-event";
import { ActionRepeatContextEnum } from "../../types/calendar/repeating";

export type ParamsType = {
  eventUUID: string,
  timing: EventTimingWithComputed,
  repeatHandling: ActionRepeatContextEnum
};
export type ResponseType = void;
export const Endpoint = 'calendar/change-event-timing';
export const Method = 'POST';

export interface Plug {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
}export const paramsSchema = {"type":"object","properties":{"eventUUID":{"type":"string"},"timing":{"$ref":"#/definitions/EventTimingWithComputed"},"repeatHandling":{"$ref":"#/definitions/ActionRepeatContextEnum"}},"required":["eventUUID","repeatHandling","timing"],"definitions":{"EventTimingWithComputed":{"type":"object","properties":{"_computed":{"type":"object","properties":{"start":{"type":"object","format":"date-time"},"end":{"type":"object","format":"date-time"}},"required":["end","start"]},"date":{"type":"string"},"allDay":{"type":"boolean"},"start":{"type":"object","properties":{"hours":{"type":"number"},"minutes":{"type":"number"},"timezone":{"type":"string"}},"required":["hours","minutes","timezone"]},"durationInMinutes":{"type":"number"}},"required":["_computed","date","durationInMinutes","start"]},"ActionRepeatContextEnum":{"enum":["all","this","thisAndFuture"],"type":"string"}},"$schema":"http://json-schema.org/draft-07/schema#"}