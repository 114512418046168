/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { SprintTimingType } from "../../../types/tasks/sprint";

export type ParamsType = {
  sprintUUID: string;
  timing: null | SprintTimingType;
};
export type ResponseType = void;
export const Endpoint = 'tasks/sprints/start-sprint';
export const Method = 'POST';

export interface Plug {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
}

export const RateLimitingConfig = {
  calls: 2,
  per: 2000,
};export const paramsSchema = {"type":"object","properties":{"sprintUUID":{"type":"string"},"timing":{"anyOf":[{"$ref":"#/definitions/SprintTimingType"},{"type":"null"}]}},"required":["sprintUUID","timing"],"definitions":{"SprintTimingType":{"type":"object","properties":{"startDate":{"type":"object","format":"date-time"},"endDate":{"type":"object","format":"date-time"},"duration":{"type":"object","properties":{"workDays":{"type":"number"}},"required":["workDays"]}},"required":["duration","endDate","startDate"]}},"$schema":"http://json-schema.org/draft-07/schema#"}