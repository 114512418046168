/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { TokenPackageType } from "../../../types/auth/token-package";
import { HashedString, HashedStringSchema } from "../../../types/cryptography/hashed-string";

export type ParamsType = { identifier: string, password: HashedString }; 
export type ResponseType = { tokenPackage: TokenPackageType };
export const Endpoint = 'auth/login-with-identifier-and-password';
export const Method = 'POST';
export const isPublic = true;

export interface Plug {
  params: ParamsType
  response: ResponseType
  method: typeof Method
  endpoint: typeof Endpoint
}

export const paramsSchema = {
  type: "object",
  properties: {
    identifier: { type: "string" },
    password: HashedStringSchema
  },
  required: [ "identifier", "password" ],
  additionalProperties: false,
}