/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { SprintStatusEnum, SprintTimingType } from "../../../types/tasks/sprint";

export type ParamsType = {
  teamUUID: string;
  name: string;
  status: SprintStatusEnum;
  timing: null | SprintTimingType;
  rolloverFromSprintUUID: null | string;
};
export type ResponseType = {
  sprintUUID: string;
};
export const Endpoint = 'tasks/sprints/create-sprint';
export const Method = 'POST';

export interface Plug {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
}

export const RateLimitingConfig = {
  calls: 2,
  per: 2000,
};export const paramsSchema = {"type":"object","properties":{"teamUUID":{"type":"string"},"name":{"type":"string"},"status":{"$ref":"#/definitions/SprintStatusEnum"},"timing":{"anyOf":[{"$ref":"#/definitions/SprintTimingType"},{"type":"null"}]},"rolloverFromSprintUUID":{"type":["null","string"]}},"required":["name","rolloverFromSprintUUID","status","teamUUID","timing"],"definitions":{"SprintStatusEnum":{"enum":["active","completed","new"],"type":"string"},"SprintTimingType":{"type":"object","properties":{"startDate":{"type":"object","format":"date-time"},"endDate":{"type":"object","format":"date-time"},"duration":{"type":"object","properties":{"workDays":{"type":"number"}},"required":["workDays"]}},"required":["duration","endDate","startDate"]}},"$schema":"http://json-schema.org/draft-07/schema#"}