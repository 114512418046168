import { type IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { TaskType } from "../../../types/tasks/task-item";
import { faTableRows } from "@fortawesome/pro-regular-svg-icons";
import {
  faDiagramNext,
  faDiagramSubtask,
} from "@fortawesome/pro-light-svg-icons";

export const IconsByTaskType: { [k in TaskType]: IconDefinition } = {
  [TaskType.epic]: faTableRows,
  [TaskType.story]: faDiagramSubtask,
  [TaskType.task]: faDiagramNext,
};
