import React from "react";
import { type TaskSizingType } from "../../../types/tasks/task-item";
import { styled } from "@hiyllo/ux/styled";
import { Select } from "@hiyllo/ux/select";
import { Input } from "@hiyllo/ux/input";
import { useStatefulRef } from "@hiyllo/ux/use-stateful-ref";
import { CircleButton } from "@hiyllo/ux/circle-button";
import { faCheck } from "@fortawesome/pro-light-svg-icons";
import { TaskPill } from "./common";
import { Modal } from "@hiyllo/ux/modal";
import { PillL } from "./pill";

const PopoutContainer = styled("div", ({ $theme }) => ({
  background: $theme.background3,
  boxShadow: $theme.surfaceShadow,
  padding: 10,
  borderRadius: 10,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 10,
}));

interface PropsType {
  sizing: TaskSizingType | null;
  onChangeSizing: ((newValue: TaskSizingType | null) => void) | null;
  collapsed?: boolean;
}

function getSuffix(type: TaskSizingType["in"]): string {
  switch (type) {
    case "points":
      return "";
    case "work-hours":
      return "h";
    case "work-days":
      return "d";
    case "work-weeks":
      return "w";
    case "work-months":
      return "m";
  }
}

export function formatSizing(sizing: TaskSizingType): string {
  return `${sizing.value}${getSuffix(sizing.in)}`;
}

export const SizingPill = React.memo(function SizingPill(
  props: PropsType,
): JSX.Element {
  const [showSizingControl, setShowSizingControl] =
    React.useState<boolean>(false);
  const [value, setValue, valueRef] = useStatefulRef<string>(
    props.sizing?.value.toString() ?? "1",
  );
  const [inFormat, setInFormat, inFormatRef] = useStatefulRef<
    TaskSizingType["in"]
  >(props.sizing?.in ?? "work-hours");

  const onClose = React.useCallback(() => {
    const value = valueRef.current;
    const inFormat = inFormatRef.current;

    if (!isNaN(Number()) && value.length > 0) {
      props.onChangeSizing?.(
        value === "0"
          ? null
          : {
            value: Number(value),
            in: inFormat,
            type: "explicit",
          },
      );
    }

    setShowSizingControl(false);
  }, [inFormatRef, props, valueRef]);

  const onClick = React.useCallback(
    (evt: React.MouseEvent) => {
      evt.stopPropagation();
      if (props.onChangeSizing != null) {
        setShowSizingControl(true);
      }
    },
    [props.onChangeSizing],
  );

  return (
    <>
      {showSizingControl ? (
        <Modal onClose={() => setShowSizingControl(false)}>
          <PopoutContainer
            onMouseDown={(evt: React.MouseEvent) => evt.stopPropagation()}
            onClick={(evt: React.MouseEvent) => evt.stopPropagation()}
          >
            <div>
              <PillL
                onClick={() => {
                  setValue("0");
                  props.onChangeSizing?.(null);
                  setTimeout(() => {
                    setShowSizingControl(false);
                  }, 1);
                }}
              >
                None
              </PillL>
            </div>
            <div style={{ flexGrow: 0, width: 75 }}>
              <Input
                value={value}
                onChangeValue={setValue}
                type="number"
                fullWidth
                placeholder="123"
                onSubmit={onClose}
                autoFocus
              />
            </div>
            <div style={{ width: 140, whiteSpace: "nowrap" }}>
              <Select
                value={inFormat}
                onChangeValue={(v) => {
                  setInFormat(v as TaskSizingType["in"]);
                }}
                options={[
                  {
                    value: "work-hours",
                    label: "Work-Hour(s)",
                  },
                  {
                    value: "work-days",
                    label: "Work-Day(s)",
                  },
                ]}
                fullWidth
              />
            </div>
            <CircleButton size={35} onClick={onClose} icon={faCheck} />
          </PopoutContainer>
        </Modal>
      ) : null}
      <TaskPill
        label={props.collapsed !== true ? "Estimate" : null}
        onClick={onClick}
      >
        <div style={{ minWidth: 20, textAlign: "center" }}>
          {props.sizing?.value != null && props.sizing.in != null
            ? formatSizing(props.sizing)
            : null}
        </div>
      </TaskPill>
    </>
  );
});
