/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { UserStatusType } from "../../../types/accounts/user";

export type ParamsType = {
  status: UserStatusType;
};
export type ResponseType = void;
export const Endpoint = "users/set-status";
export const Method = "POST";

export interface Plug {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
}
export const paramsSchema = {"type":"object","properties":{"status":{"$ref":"#/definitions/UserStatusType"}},"required":["status"],"definitions":{"UserStatusType":{"type":"object","properties":{"derivedFrom":{"anyOf":[{"$ref":"#/definitions/StatusDerivedFromType"},{"type":"null"}]},"restore":{"anyOf":[{"$ref":"#/definitions/BaseStatusType"},{"type":"null"}]},"emoji":{"type":"string"},"message":{"type":"string"},"expires":{"anyOf":[{"type":"object","format":"date-time"},{"type":"null"}]}},"required":["emoji","expires","message"]},"StatusDerivedFromType":{"type":"object","properties":{"type":{"type":"string","enum":["calendar-event"]},"eventUUID":{"type":"string"}},"required":["eventUUID","type"]},"BaseStatusType":{"type":"object","properties":{"emoji":{"type":"string"},"message":{"type":"string"},"expires":{"anyOf":[{"type":"object","format":"date-time"},{"type":"null"}]}},"required":["emoji","expires","message"]}},"$schema":"http://json-schema.org/draft-07/schema#"}