/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { StickyPositionType } from "../../types/stickies/sticky";

export type ParamsType = {
  uuid: string;
  update: Partial<{
    content: string;
    position: StickyPositionType;
  }>;
};
export type ResponseType = void;
export const Endpoint = "stickies/update-sticky";
export const Method = "POST";

export interface Plug {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
}

export const RateLimitingConfig = {
  calls: 2,
  per: 750,
};
export const paramsSchema = {"type":"object","properties":{"uuid":{"type":"string"},"update":{"$ref":"#/definitions/Partial<{content:string;position:StickyPositionType;}>"}},"required":["update","uuid"],"definitions":{"Partial<{content:string;position:StickyPositionType;}>":{"type":"object","properties":{"content":{"type":"string"},"position":{"$ref":"#/definitions/StickyPositionType"}}},"StickyPositionType":{"type":"object","properties":{"x":{"type":"number"},"y":{"type":"number"},"width":{"type":"number"},"height":{"type":"number"}},"required":["height","width","x","y"]}},"$schema":"http://json-schema.org/draft-07/schema#"}