import React from "react";
import { useConfig } from "../../../platform/config/config-context";
import { hashString } from "../../../utils/browser-safe-sha256";
import * as LoginWithIdentifierAndPasswordBlueprint from "../../../blueprints/accounts/login/login-with-identifier-and-password";
import * as QueryLoginBP from "../../../blueprints/accounts/login/query-login";
import * as RequestPasswordResetEmailBP from "../../../blueprints/accounts/user/reset-password/request-password-reset-email";
import {
  onLoginTokenAvailable,
  seamlessClient,
} from "../../../seamless-client";
import { AccountAuthenticationMethod } from "../../../types/accounts/authentication";
import { LoginUI } from "@hiyllo/ux/login-ui";
import { type UseMoopsyMutationRetVal } from "@moopsyjs/react/main";
import { InitiateSSOLoginView } from "./sso";
import { type MoopsyError } from "@moopsyjs/core";
import { getRootURL } from "../../../platform/environment/get-root-url";
import { EmptySplash } from "@hiyllo/ux/empty-splash";
import { faDoorOpen } from "@fortawesome/pro-light-svg-icons";
import { Electron } from "../../../platform/electron";

const usePasswordLogin =
  (): UseMoopsyMutationRetVal<LoginWithIdentifierAndPasswordBlueprint.Plug> =>
    seamlessClient.useMutation<LoginWithIdentifierAndPasswordBlueprint.Plug>(
      LoginWithIdentifierAndPasswordBlueprint,
    );

const useResetPasswordEmail =
  (): UseMoopsyMutationRetVal<RequestPasswordResetEmailBP.Plug> =>
    seamlessClient.useMutation<RequestPasswordResetEmailBP.Plug>(
      RequestPasswordResetEmailBP,
    );

const useQueryLogin = (): UseMoopsyMutationRetVal<QueryLoginBP.Plug> =>
  seamlessClient.useMutation<QueryLoginBP.Plug>(QueryLoginBP);

export const LoginRouter = React.memo(function LoginRouter(): JSX.Element {
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const passwordLoginMutation = usePasswordLogin();
  const passwordResetEmailMutation = useResetPasswordEmail();
  const queryLoginMutation = useQueryLogin();
  const config = useConfig();
  const usp = new window.URLSearchParams(window.location.search);
  const [authenticator, setAuthenticator] =
    React.useState<AccountAuthenticationMethod | null>(
      (usp.get("aam") as AccountAuthenticationMethod | null) ?? null,
    );
  const prefixedEmail = usp.get("em");
  const emailRef = React.useRef<string | null>(prefixedEmail);

  const onSubmit = React.useCallback(
    async (value: { email: string; authenticator: string }) => {
      emailRef.current = value.email;
      if (authenticator == null) {
        try {
          const res = await queryLoginMutation.call({
            identifier: value.email,
          });

          setAuthenticator(res.status);
        } catch (e) {
          setErrorMessage((e as MoopsyError).description);
        }
      } else {
        try {
          if (authenticator === AccountAuthenticationMethod.password) {
            const hashedPassword = await hashString(value.authenticator);

            const { tokenPackage } = await passwordLoginMutation.call({
              identifier: value.email,
              password: hashedPassword,
            });

            console.log(">>> token received");

            await onLoginTokenAvailable(tokenPackage);
          }
        } catch (e) {
          setErrorMessage((e as MoopsyError).description);
        }
      }
    },
    [authenticator, passwordLoginMutation, queryLoginMutation],
  );

  const onForgotPassword = React.useCallback(
    async (email: string) => {
      setErrorMessage(null);

      if (email.length < 5) {
        setErrorMessage("Enter your email above, then click Forgot Password");
        throw new Error("");
      }

      try {
        await passwordResetEmailMutation.call({
          emailAddress: email,
        });
      } catch (e) {
        setErrorMessage((e as MoopsyError).description);
        throw e;
      }
    },
    [passwordResetEmailMutation],
  );

  React.useEffect(() => {
    if (Electron.isElectron) {
      const host = Electron.electronHost;

      if (host == null) {
        console.error("Error: Unable to determine host");
        return alert("Error: Unable to determine host");
      }

      Electron.callAPI("requestLogin", host);
    }
  }, []);

  if (Electron.isElectron) {
    return (
      <EmptySplash
        icon={faDoorOpen}
        label="Redirecting you to login..."
        hint="We send you to your browser to login so you can use autofill :)"
      />
    );
  }

  if (
    authenticator === AccountAuthenticationMethod.hiylloAuth ||
    config.isSolo === true
  ) {
    return <InitiateSSOLoginView email={emailRef.current ?? ""} />;
  }

  return (
    <LoginUI
      showAuthenticator={authenticator === AccountAuthenticationMethod.password}
      authenticatorType="password"
      logoPath={
        config.branding?.logo?.fileId != null
          ? getRootURL() + "/ufplogo.png"
          : "hiyllo-work/gradient.png"
      }
      onSubmit={onSubmit}
      fixedIdentifier={prefixedEmail ?? null}
      disableIdentifier={prefixedEmail != null && prefixedEmail.length >= 5}
      onForgotPassword={onForgotPassword}
      errorMessage={errorMessage}
      hasAccount={true}
      // requestBrowserCredentials="password"
      isLoading={
        passwordLoginMutation.isLoading ||
        passwordResetEmailMutation.isLoading ||
        queryLoginMutation.isLoading
      }
    />
  );
});
