/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { ContinuityAccessType } from "../../types/continuity/access";

export type ParamsType = {
  name: string;
  access: ContinuityAccessType;
};
export type ResponseType = {
  uuid: string;
};
export const Endpoint = "tasks/create-task-project";
export const Method = "POST";

export type Plug = {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
};

export const RateLimitingConfig = {
  calls: 1,
  per: 2000,
};
export const paramsSchema = {"type":"object","properties":{"name":{"type":"string"},"access":{"$ref":"#/definitions/ContinuityAccessType"}},"required":["access","name"],"definitions":{"ContinuityAccessType":{"type":"object","properties":{"teams":{"type":"array","items":{"type":"string"}},"users":{"type":"array","items":{"type":"string"}}},"required":["teams","users"]}},"$schema":"http://json-schema.org/draft-07/schema#"}