import React from "react";
import {
  type OrganizationTeamResultType,
  type PersonSearchResultType,
} from "@hiyllo/omni-common/src/types/search/search-results";
import { type ContinuityAccessType } from "@hiyllo/omni-common/src/types/continuity/access";
import { SearchOverlay } from "@hiyllo/omni-search";
import { Typography } from "@hiyllo/ux/typography";
import { CircleButton } from "@hiyllo/ux/circle-button";
import {
  faEmptySet,
  faPlus,
  faTimesCircle,
  faUserCircle,
  faUsers,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useGetTeam } from "../../../organization/hooks/use-get-team";
import { LoadingSpinner } from "@hiyllo/ux/loading-spinner";
import { styled } from "@hiyllo/ux/styled";
import { useLookupByUserId } from "@hiyllo/omni-continuity/main";
import { UserImage } from "@hiyllo/omni-images/main";

const EntryRow = styled("div", {
  display: "flex",
  flexDirection: "row",
  gap: 10,
  alignItems: "center",
  height: 30,
});

const EntryRowOuter = styled("div", {
  display: "flex",
  flexDirection: "row",
  gap: 10,
  justifyContent: "space-between",
  alignItems: "center",
  height: 30,
});

const TeamEntry = React.memo(function TeamEntry(props: { teamUUID: string }) {
  const teamQuery = useGetTeam({ teamUUID: props.teamUUID });

  if (teamQuery.data == null) {
    return (
      <EntryRow>
        <div
          style={{
            width: 25,
            height: 25,
            fontSize: 16,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FontAwesomeIcon icon={faUsers} />
        </div>
        <LoadingSpinner />
      </EntryRow>
    );
  }

  return (
    <EntryRow>
      <div
        style={{
          width: 25,
          height: 25,
          fontSize: 16,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FontAwesomeIcon icon={faUsers} />
      </div>
      <div>{teamQuery.data.team.name}</div>
    </EntryRow>
  );
});

const UserEntry = React.memo(function UserEntry(props: { userId: string }) {
  const userQuery = useLookupByUserId(props.userId);

  if (userQuery.data == null) {
    return (
      <EntryRow>
        <div
          style={{
            width: 25,
            height: 25,
            fontSize: 16,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FontAwesomeIcon icon={faUserCircle} />
        </div>
        <LoadingSpinner />
      </EntryRow>
    );
  }

  return (
    <EntryRow>
      <UserImage userId={props.userId} width={25} />
      <div>{userQuery.data.name}</div>
    </EntryRow>
  );
});

export const ContinuityAccessControl = React.memo(
  function ContinuityAccessControl(props: {
    value: ContinuityAccessType;
    onChange: (value: ContinuityAccessType) => void;
    filterTo?: Array<"person" | "organization/team">;
    header: string;
  }): JSX.Element {
    const [showSearchOverlay, setShowSearchOverlay] = React.useState(false);

    const onSelectResult = React.useCallback(
      (result: PersonSearchResultType | OrganizationTeamResultType) => {
        if (result.type === "person") {
          props.onChange({
            ...props.value,
            users: [...new Set([...props.value.users, result.user.id])],
          });
        } else {
          props.onChange({
            ...props.value,
            teams: [...new Set([...props.value.teams, result.team.uuid])],
          });
        }
        setShowSearchOverlay(false);
      },
      [props],
    );

    const onRemoveTeam = React.useCallback(
      (teamUUID: string) => {
        props.onChange({
          ...props.value,
          teams: props.value.teams.filter((t) => t !== teamUUID),
        });
      },
      [props],
    );

    const onRemoveUser = React.useCallback(
      (userId: string) => {
        props.onChange({
          ...props.value,
          users: props.value.users.filter((t) => t !== userId),
        });
      },
      [props],
    );

    return (
      <>
        {showSearchOverlay ? (
          <SearchOverlay
            onClose={() => setShowSearchOverlay(false)}
            filterType={props.filterTo ?? ["person", "organization/team"]}
            overridePlaceholderText="Type to start searching..."
            overrideFocusLabel={(props.filterTo != null && props.filterTo.length === 1) ? (props.filterTo.includes("person") ? "Add people" : "Add teams") : "Add people or teams"}
            onSelectResult={(r) =>
              onSelectResult(
                r as PersonSearchResultType | OrganizationTeamResultType,
              )
            }
          />
        ) : null}
        <div style={{ maxWidth: 300 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography.Label style={{ marginBottom: 0 }}>
              {props.header}
            </Typography.Label>
            <CircleButton
              onClick={() => setShowSearchOverlay(true)}
              size={30}
              icon={faPlus}
            />
          </div>
          <div style={{ height: 5 }} />
          {props.value.teams.length === 0 && props.value.users.length === 0 ? (
            <div style={{ textAlign: "center" }}>
              <FontAwesomeIcon icon={faEmptySet} />
            </div>
          ) : null}
          {props.value.teams.map((teamUUID) => (
            <EntryRowOuter key={teamUUID}>
              <TeamEntry teamUUID={teamUUID} />
              <div
                onClick={() => onRemoveTeam(teamUUID)}
                style={{ paddingRight: 7, paddingLeft: 7, cursor: "pointer" }}
              >
                <FontAwesomeIcon icon={faTimesCircle} />
              </div>
            </EntryRowOuter>
          ))}
          {props.value.users.map((userId) => (
            <EntryRowOuter key={userId}>
              <UserEntry userId={userId} />
              <div
                onClick={() => onRemoveUser(userId)}
                style={{ paddingRight: 7, paddingLeft: 7, cursor: "pointer" }}
              >
                <FontAwesomeIcon icon={faTimesCircle} />
              </div>
            </EntryRowOuter>
          ))}
        </div>
      </>
    );
  },
);
