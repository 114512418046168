/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { EnhancedTaskItem, type TaskItem } from "../../../types/tasks/task-item";

export const TopicID = "task";
export type TopicNameType = `[${string}]task/${string}`;
export type SubscriptionParamsType = null;
export type MessageType = EnhancedTaskItem;
export const TopicNameRegExp = new RegExp('/task\\/[A-z]*/');
export const SubscriptionParamsSchema = { type: "null" };

export type Typings = {
  TopicNameType: TopicNameType,
  SubscriptionParamsType: SubscriptionParamsType,
  MessageType: MessageType;
};