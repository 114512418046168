import { styled } from "@hiyllo/ux/styled";
import React from "react";
import { type ContinuityAccessType } from "../../../types/continuity/access";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimes, faUsers } from "@fortawesome/pro-light-svg-icons";
import { useGetTeam } from "../../organization/hooks/use-get-team";
import { useLookupByUserId } from "@hiyllo/omni-continuity/main";
import { UserImage } from "@hiyllo/omni-images/main";
import { CircleButton } from "@hiyllo/ux/circle-button";
import {
  type OrganizationTeamResultType,
  type PersonSearchResultType,
  type SearchResultType,
} from "../../../types/search/search-results";
import { UserAndTeamSearch } from "@hiyllo/omni-search/main";

const AccessControlViewContainer = styled("div", ({ $theme }) => ({
  backgroundColor: $theme.midground,
  padding: 10,
  borderRadius: 10,
  maxWidth: 280,
  boxShadow: $theme.surfaceShadow,
}));

const Row = styled("div", ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 10,
  height: 25,
}));

const TeamAccessRow = React.memo(function TeamAccessRow(props: {
  teamUUID: string;
  onRemove?: null | ((teamUUID: string) => void);
}): JSX.Element | null {
  const teamQuery = useGetTeam({ teamUUID: props.teamUUID });

  if (teamQuery.isLoading) {
    return null;
  }

  if (teamQuery.isError) {
    return <div>Error loading team</div>;
  }

  const { team } = teamQuery.data;

  return (
    <Row>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: 25,
          height: 25,
          fontSize: 12.5,
          borderRadius: 25 / 4,
          background: "rgba(0, 0, 0, 0.2)",
        }}
      >
        <FontAwesomeIcon icon={faUsers} fixedWidth />
      </div>
      <div>{team.name}</div>
      <div style={{ flexGrow: 1, minWidth: 5, flexShrink: 0 }} />
      <CircleButton
        icon={faTimes}
        onClick={() => {
          if (props.onRemove) {
            props.onRemove(props.teamUUID);
          }
        }}
        size={20}
      />
    </Row>
  );
});

const UserAccessRow = React.memo(function UserAccessRow(props: {
  userId: string;
  onRemove?: null | ((userId: string) => void);
}): JSX.Element | null {
  const userQuery = useLookupByUserId(props.userId);

  if (!userQuery.isReady) {
    return null;
  }

  const { data: user } = userQuery;

  return (
    <Row>
      <UserImage userId={props.userId} width={25} />
      <div>{user.name}</div>
      <div style={{ flexGrow: 1, minWidth: 5, flexShrink: 0 }} />
      <CircleButton
        icon={faTimes}
        onClick={() => {
          if (props.onRemove) {
            props.onRemove(props.userId);
          }
        }}
        size={20}
      />
    </Row>
  );
});

const AddMorePrompt = styled("div", ({
  background: "rgba(0, 0, 0, 0.2)",
  height: 25,
  // width: "100%",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  borderRadius: 25 / 4,
  fontSize: 12.5,
  fontFamily: "hiyllo",
  paddingLeft: 5,
  gap: 5,
  userSelect: "none",
  cursor: "pointer",
}));

export const AccessControlView = React.memo(
  function AccessControlView(props: {
    rwAccess: ContinuityAccessType | null;
    onChangeValue: (value: ContinuityAccessType) => void;
    label?: string;
  }): JSX.Element {
    const [showSearchOverlay, setShowSearchOverlay] = React.useState<
      false | true
    >(false);
    const access = React.useMemo(() => props.rwAccess ?? { users: [], teams: [] }, [props.rwAccess]);

    const onSelectResult = React.useCallback(
      (_r: SearchResultType) => {
        const result = _r as
          | PersonSearchResultType
          | OrganizationTeamResultType;
        if (result.type === "person") {
          props.onChangeValue({
            ...access,
            users: [...new Set([...access.users, result.user.id])],
          });
        } else {
          props.onChangeValue({
            ...access,
            teams: [...new Set([...access.teams, result.team.uuid])],
          });
        }
        setShowSearchOverlay(false);
      },
      [access, props],
    );

    const onRemoveUser = React.useCallback(
      (userId: string) => {
        props.onChangeValue({
          ...access,
          users: access.users.filter((id) => id !== userId),
        });
      },
      [access, props],
    );

    const onRemoveTeam = React.useCallback(
      (teamUUID: string) => {
        props.onChangeValue({
          ...access,
          teams: access.teams.filter((id) => id !== teamUUID),
        });
      },
      [access, props],
    );

    return (
      <>
        {showSearchOverlay ? (
          <UserAndTeamSearch
            onClose={() => setShowSearchOverlay(false)}
            onSelectResult={onSelectResult}
          />
        ) : null}
        <AccessControlViewContainer>
          {"rwAccess" in props ? (
            <div style={{ display: "flex", flexDirection: "column", gap: 7.5 }}>
              {access.users.map((userId) => (
                <UserAccessRow
                  key={userId}
                  userId={userId}
                  onRemove={onRemoveUser}
                />
              ))}
              {access.teams.map((teamUUID) => (
                <TeamAccessRow
                  key={teamUUID}
                  teamUUID={teamUUID}
                  onRemove={onRemoveTeam}
                />
              ))}
              <AddMorePrompt onClick={() => setShowSearchOverlay(true)}>
                <FontAwesomeIcon icon={faSearch} fixedWidth /> Add Users or
                Teams...
              </AddMorePrompt>
            </div>
          ) : null}
        </AccessControlViewContainer>
      </>
    );
  },
);

export { AccessControlView as RWAccessControlView };