import moment from "moment-timezone";
import { TimezoneContext } from "../contexts";
import React from "react";

export function useTimezone(): string {
  return (
    React.useContext(TimezoneContext) ??
    window.sessionStorage.calendar_timezoneoverride ??
    moment.tz.guess() ??
    "America/New_York"
  );
}
