/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { HiylloStudioWorkflowDBEntry } from "../../types/studio/workflow";

export type ParamsType = {
  uuid: string;
};
export type ResponseType = {
  workflow: Omit<HiylloStudioWorkflowDBEntry, "versions">;
};
export const Endpoint = "studio/get-workflow";
export const Method = "GET";

export interface Plug {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
}

export const RateLimitingConfig = {
  calls: 4,
  per: 1000,
};
export const paramsSchema = {"type":"object","properties":{"uuid":{"type":"string"}},"required":["uuid"],"$schema":"http://json-schema.org/draft-07/schema#"}