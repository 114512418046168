/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
export type ParamsType = {
  prompt: string;
  /**
   * 1-20
   */
  guidanceScale: number;
  /**
   * 1-50
   */
  steps: number;
};
export type ResponseType = {
  src: string;
};
export const Endpoint = 'playground/generate-image';
export const Method = 'POST';

export interface Plug {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
}
export const paramsSchema = {"type":"object","properties":{"prompt":{"type":"string"},"guidanceScale":{"description":"1-20","type":"number"},"steps":{"description":"1-50","type":"number"}},"required":["guidanceScale","prompt","steps"],"$schema":"http://json-schema.org/draft-07/schema#"}