import React from "react";
import { useThemeStyling } from "../themings";

export const CardA = (props: {
  children: React.ReactNode;
  style?: React.CSSProperties;
  disableTopRounding?: boolean;
}): JSX.Element => {
  const colorStyling = useThemeStyling<"card.standard">("card.standard");
  const shadowA = useThemeStyling<"shadow.a">("shadow.a");
  return (
    <div
      style={{
        ...colorStyling,
        padding: 20,
        ...shadowA,
        borderRadius: 10,
        ...props.style,
        ...(props.disableTopRounding === true
          ? {
              borderTopRightRadius: 0,
              borderTopLeftRadius: 0,
            }
          : {}),
      }}
    >
      {props.children}
    </div>
  );
};
