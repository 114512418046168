import React from "react";
import { useFetchMeetingRecording } from "../../hooks/use-fetch-meeting-recording";
import { LoadingSpinnerFullView } from "../../../../platform/loading/spinner-loading-full";
import { styled } from "@hiyllo/ux/styled";
import { type TranscriptItem } from "@hiyllo/omni-common/src/types/meet/recording";
import { UserImage } from "@hiyllo/omni-images/main";
import { useLookupByUserId } from "@hiyllo/omni-continuity";
import moment from "moment";

const Container = styled("div", ({ $theme }) => ({
  display: "flex",
  flexDirection: "row",
  color: $theme.foreground,
  fontFamily: "hiyllo",
  height: "100%",
}));

const RecordingsContainer = styled("div", ({ $theme }) => ({
  flexBasis: "50%",
  padding: 15,
  height: "calc(100% - 30px)",
  overflowY: "auto",
  // background: $theme.background3,
}));

const TranscriptContainer = styled("div", ({ $theme }) => ({
  flexBasis: "50%",
  padding: 15,
  background: $theme.background3,
  display: "flex",
  flexDirection: "column",
  gap: 12.5,
  height: "calc(100% - 30px)",
  overflowY: "auto",
}));

const TranscriptRowHeader = styled("div", ({ $theme }) => ({
  fontWeight: "bold",
  marginBottom: 2.5,
}));

const TranscriptPersonUser = React.memo(function TranscriptPersonUser(props: {
  userId: string;
}): JSX.Element {
  const lookup = useLookupByUserId(props.userId);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: 7.5,
      }}
    >
      <UserImage userId={props.userId} width={20} />
      {lookup.data?.name}
    </div>
  );
});

const TranscriptRow = React.memo(function TranscriptRow(props: {
  item: TranscriptItem;
}): JSX.Element {
  return (
    <div>
      <TranscriptRowHeader>
        {props.item.person.startsWith("user/") ? (
          <TranscriptPersonUser userId={props.item.person.split("/")[1]} />
        ) : (
          <div>{props.item.person.split("/")[1]}</div>
        )}
      </TranscriptRowHeader>
      <div>{props.item.text}</div>
    </div>
  );
});

export const MeetingRecordingView = React.memo(
  function MeetingRecordingView(props: { uuid: string }): JSX.Element {
    const recordingQuery = useFetchMeetingRecording({ uuid: props.uuid });

    if (recordingQuery.isLoading) {
      return <LoadingSpinnerFullView />;
    }

    if (recordingQuery.isError) {
      return <div>Error Loading Recording</div>;
    }

    const { transcript, recordings } = recordingQuery.data.recording;

    return (
      <Container>
        <RecordingsContainer>
          {recordings.map((recording, index) => (
            <div key={index}>
              {recording.person} -{" "}
              {moment(recording.startDate).format("h:mm:ss a")}
              <audio src={recording.src} controls />
            </div>
          ))}
        </RecordingsContainer>
        <TranscriptContainer>
          {transcript.map((item, index) => (
            <TranscriptRow item={item} key={index} />
          ))}
          <div>
            Action Items Raw:{" "}
            {JSON.stringify(
              recordingQuery.data.recording.actionItemsRaw ?? null,
            )}
          </div>
        </TranscriptContainer>
      </Container>
    );
  },
);
