import React from "react";
import axios from "axios";

export const MapTest = React.memo(function MapTest(): JSX.Element {
  const [token, setToken] = React.useState<string | null>(null);

  React.useEffect(() => {
    void axios
      .get(
        "https://service-gateway.hiyllo.cloud/mapkitjs/token?origin=" +
          window.location.origin,
      )
      .then((res) => {
        setToken(res.data);
      });
  }, []);

  if (token == null) {
    return <div>No Token</div>;
  }

  return <div />;
});
