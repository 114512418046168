/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { WorkingHoursType } from "../../types/accounts/user";
import { CalendarEvent } from "../../types/calendar/calendar-event";

export type ParamsType = {
  after: Date;
  before: Date;
  calendarForUsers: string[] | null;
  dev_includeDeleted?: boolean;
};
export type ResponseType = {
  events: CalendarEvent[];
  workingHours: Array<{
    userId: string;
    timezone: string;
    workingHours: WorkingHoursType | null;
  }>;
};
export const Endpoint = "calendar/retrieve-events";
export const Method = "GET";

export interface Plug {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
}

/**
 * dont use hashes, etc just create event in db when retrieved from schedule
 *
 * as soon as a non-all change is made FORK the events.
 */
export const paramsSchema = {"type":"object","properties":{"after":{"type":"object","format":"date-time"},"before":{"type":"object","format":"date-time"},"calendarForUsers":{"anyOf":[{"type":"array","items":{"type":"string"}},{"type":"null"}]},"dev_includeDeleted":{"type":"boolean"}},"required":["after","before","calendarForUsers"],"$schema":"http://json-schema.org/draft-07/schema#"}