/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { TaskOrderType } from "./tasks-organization";

export enum SprintStatusEnum {
    new = 'new',
    active = 'active',
    completed = 'completed'
}

export interface SprintTimingType {
    startDate: Date;
    endDate: Date;
    duration: { workDays: number; }
}

export interface Sprint {
    teamUUID: string;
    name: string;
    status: SprintStatusEnum;
    timing: null | SprintTimingType;
    order?: TaskOrderType | null;

    createdBy: string;
    uuid: string;
}