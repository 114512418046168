/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";

export function useDebounce(
  timeout = 300,
  behavior: "overwrite" | "ignore" = "ignore",
): (fn: () => void) => void {
  const timeoutRef = React.useRef<number | null>(null);
  const onTimeoutRef = React.useRef<() => void | null>();

  return React.useCallback(
    function (fn: any) {
      onTimeoutRef.current = fn;

      if (timeoutRef.current !== null) {
        if (behavior === "ignore") {
          return;
        } else {
          clearTimeout(timeoutRef.current);
        }
      }

      timeoutRef.current = setTimeout(() => {
        timeoutRef.current = null;
        if (onTimeoutRef.current != null) onTimeoutRef.current();
      }, timeout) as unknown as number;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [timeout],
  );
}
