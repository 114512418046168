import React from "react";

import * as ListCustomEmojiBP from '../../../blueprints/chat/list-custom-emoji';
import * as CreateCustomEmojiBP from '../../../blueprints/chat/create-custom-emoji';
import { FileInputV2 } from "../../../ux/alpha/input";
import { Input } from "@hiyllo/ux/input";
import { Button } from "@hiyllo/ux/button";
import { useAlertDialog } from "@hiyllo/ux/dialogs";
import { seamlessClient } from "../../../seamless-client";
import { styled } from "@hiyllo/ux/styled";

const slugRegexp = /^[a-z0-9-]{1,18}$/;

const EmojiContainer = styled("div", ({ $theme }) => ({
  background: $theme.midground1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: 10,
  borderRadius: 10,
  gap: 5
}));

const EmojiImage = styled("img", ({ $theme }) => ({
  height: 40,
  width: 40,
  objectFit: "contain",
}));

const EmojiSlug = styled("div", ({ $theme }) => ({
  fontSize: 12,
  minHeight: "2em"
}));

export const CustomEmojiPanel = React.memo(function CustomEmojiPanel(): JSX.Element {
  const listEmojisQuery = seamlessClient.useQuery<ListCustomEmojiBP.Plug>(ListCustomEmojiBP, null);
  const createEmojiMutation = seamlessClient.useMutation<CreateCustomEmojiBP.Plug>(CreateCustomEmojiBP, { querySideEffects: [listEmojisQuery] });
  const [createEmojiURL, setCreateEmojiURL] = React.useState<string | null>(null);
  const [createEmojiFsId, setCreateEmojiFsId] = React.useState<string | null>(null);
  const [createEmojiSuccess, setCreateEmojiSuccess] = React.useState<boolean>(false);
  const [slug, setSlug] = React.useState<string>("");
  const showAlert = useAlertDialog();

  React.useEffect(() => {
    setCreateEmojiSuccess(false);
  }, [createEmojiURL, createEmojiFsId, slug]);

  const create = React.useCallback(async () => {
    if (slug === "") {
      return showAlert({ title: "Error", message: "Slug is required" });
    }

    if (!createEmojiFsId) {
      return showAlert({ title: "Error", message: "Emoji is required" });
    }

    setCreateEmojiURL(null);
    setCreateEmojiFsId(null);
    setSlug("");

    void createEmojiMutation.call({ slug, fsId: createEmojiFsId }).then(() => {
      setCreateEmojiSuccess(true);
    });
  }, [createEmojiFsId, createEmojiMutation, showAlert, slug]);

  return (
    <div>
      <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
        {createEmojiURL ?
          <div style={{ height: 40, width: 40, display: "flex", justifyContent: "center", alignItems: "center" }}>
            <img src={createEmojiURL} style={{ height: 30, width: 30, objectFit: "contain" }} />
          </div>
          :
          <FileInputV2
            autoUpload={true}
            onFileId={(fsId) => {
              setCreateEmojiFsId(fsId);
            }}
            onImagePreviewUrlAvailable={(url) => {
              setCreateEmojiURL(url);
            }}
            square
          />
        }
        <Input
          placeholder="Slug"
          value={slug}
          onChangeValue={setSlug}
          regexp={slugRegexp}
        // startEnhancer=":"
        // endEnhancer=":"
        />
        <Button
          label="Create"
          onClick={create}
          isLoading={createEmojiMutation.isLoading}
          success={createEmojiSuccess}
        />
      </div>
      <div style={{ paddingTop: 10, display: "flex", flexDirection: "row", gap: 10, flexWrap: "wrap" }}>
        {(listEmojisQuery.data?.emoji ?? []).map((emoji) => (
          <EmojiContainer key={emoji.slug}>
            <EmojiImage src={emoji.src} />
            <EmojiSlug>{emoji.slug}</EmojiSlug>
          </EmojiContainer>
        ))}
      </div>
    </div>
  );
});