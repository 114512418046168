import React from "react";
import { Form, FormButton } from "../../../ux/alpha";
import { useConfigPropertyHandle } from "../hooks/config-property-handle";
import { styled } from "@hiyllo/ux/styled";
import { faSquare } from "@fortawesome/pro-light-svg-icons";
import { faSquareCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// eslint-disable-next-line @typescript-eslint/no-explicit-any

const CheckboxContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 5,
  cursor: "pointer",
  userSelect: "none",
  paddingTop: 5,
  paddingBottom: 5,
});

export const BasicBooleanConfigPropertyButton = (props: {
  path: string;
  label: string;
  style?: "checkbox" | "button";
}): JSX.Element => {
  const handle = useConfigPropertyHandle<boolean>(props.path);

  const onSubmit = (): void => {
    void handle.onChange(!handle.current);
  };

  return (
    <Form isLoading={handle.changeLoading} onSubmit={onSubmit}>
      {props.style === "checkbox" ? (
        <CheckboxContainer onClick={onSubmit}>
          <FontAwesomeIcon
            icon={handle.current ? faSquareCheck : faSquare}
            fixedWidth
          />
          {props.label}
        </CheckboxContainer>
      ) : (
        <FormButton
          label={(handle.current ? "Disable " : "Enable ") + props.label}
        />
      )}
    </Form>
  );
};
