/**
 * Working hours comprise of a start and end time. This function generates the exclusions for the working hours.
 */

import moment from "moment";
import {
  type WorkingHoursStartEnd,
  type WorkingHoursType,
} from "@hiyllo/omni-common/src/types/accounts/user";

function translateWorkingHoursValue(
  value: WorkingHoursStartEnd,
  timezone: string,
): WorkingHoursStartEnd {
  return {
    start: {
      hours: moment.tz(value.start.hours, timezone).hours(),
      minutes: moment.tz(value.start.minutes, timezone).minutes(),
    },
    end: {
      hours: moment.tz(value.end.hours, timezone).hours(),
      minutes: moment.tz(value.end.minutes, timezone).minutes(),
    },
  };
}

interface WorkingHourBlock {
  day: number;
  hours: number;
  minutes: number;
  duration: number;
  start: Date;
  end: Date;
}

interface WorkingHourFixedBlocks {
  start: Date;
  end: Date;
}

export function generateWorkingHourBlocks(
  wh: Partial<WorkingHoursType>,
  timezone: string,
  weekOf: Date,
): WorkingHourBlock[] {
  const rawBlocks: WorkingHourFixedBlocks[] = [];

  const start = moment.tz(weekOf, timezone).startOf("week").subtract(1, "days"); // Scroll 3 days back for safety

  const hasWorkingHours = Object.values(wh).filter((v) => v != null).length > 0;

  for (let i = 0; i < 8; i++) {
    const dayM = start.clone().add(i, "days");
    const day = dayM.format("ddd");
    const workingHours = wh[day.toLowerCase() as keyof WorkingHoursType];

    if (workingHours) {
      const start = dayM
        .clone()
        .hours(workingHours.start.hours)
        .minutes(workingHours.start.minutes);
      const end = dayM
        .clone()
        .hours(workingHours.end.hours)
        .minutes(workingHours.end.minutes);

      const dayWorkingHours = {
        start: start.toDate(),
        end: end.toDate(),
      };

      const preBlock = {
        start: dayM.startOf("day").toDate(),
        end: dayWorkingHours.start,
      };

      const postBlock = {
        start: dayWorkingHours.end,
        end: dayM.endOf("day").toDate(),
      };

      rawBlocks.push(preBlock, postBlock);
    } else if (hasWorkingHours) {
      const dayM = start.clone().add(i, "days");

      rawBlocks.push({
        start: dayM.startOf("day").toDate(),
        end: dayM.endOf("day").toDate(),
      });
    }
  }

  const localizedBlocks: WorkingHourBlock[] = [];
  for (const rawBlock of rawBlocks) {
    const start = moment(rawBlock.start);
    const end = moment(rawBlock.end);
    const duration = end.diff(start, "minutes");
    const startDay = start.day();
    const endDay = end.day();

    // If the block crosses between two days
    if (startDay !== endDay) {
      // First block from start to end of start day
      const firstBlock: WorkingHourBlock = {
        day: startDay,
        hours: start.hours(),
        minutes: start.minutes(),
        duration: moment(start).endOf("day").diff(start, "minutes"),
        start: start.toDate(),
        end: moment(start).endOf("day").toDate(),
      };
      localizedBlocks.push(firstBlock);

      // Second block from start of end day to end
      const secondBlock: WorkingHourBlock = {
        day: endDay,
        hours: 0,
        minutes: 0,
        duration: end.diff(moment(end).startOf("day"), "minutes"),
        start: moment(end).startOf("day").toDate(),
        end: end.toDate(),
      };
      localizedBlocks.push(secondBlock);
    } else {
      // Block within the same day
      const block: WorkingHourBlock = {
        day: startDay,
        hours: start.hours(),
        minutes: start.minutes(),
        duration,
        start: start.toDate(),
        end: end.toDate(),
      };
      localizedBlocks.push(block);
    }
  }

  localizedBlocks.forEach((b) => {
    if (b.day === 0) {
      b.start = moment(b.start).add(1, "week").toDate();
      b.end = moment(b.end).add(1, "week").toDate();
    }
  });

  const startOfWeek = moment(weekOf).isoWeekday(1).startOf("day");
  const endOfWeek = moment(weekOf).isoWeekday(0).endOf("day").add(1, "week");

  return localizedBlocks.filter((block) => {
    const blockStart = moment(block.start);
    const blockEnd = moment(block.end);
    return (
      blockStart.isSameOrAfter(startOfWeek) &&
      blockEnd.isSameOrBefore(endOfWeek)
    );
  });
}
