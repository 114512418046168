/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { BookATimeSchedule } from "../../../types/calendar/book-a-time";

export type ParamsType = {
  uuid: string
};
export type ResponseType = {
  schedule: BookATimeSchedule
};
export const Endpoint = 'calendar/book-a-time/get-book-a-time-schedule';
export const Method = 'GET';

export interface Plug {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
}export const paramsSchema = {"type":"object","properties":{"uuid":{"type":"string"}},"required":["uuid"],"$schema":"http://json-schema.org/draft-07/schema#"}