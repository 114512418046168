import EJSON from "ejson";
import { styled } from "@hiyllo/ux/styled";
import React from "react";
import { useGetWorkflow } from "../../hooks/use-get-workflow";
import { LoadingSpinnerFullView } from "../../../../platform/loading/spinner-loading-full";
import {
  type HiylloStudioWorkflowTriggered,
  type HiylloStudioWorkflowDBEntry,
} from "@hiyllo/omni-common/src/types/studio/workflow";
import { useStatefulRef } from "@hiyllo/ux/use-stateful-ref";
import { Typography } from "@hiyllo/ux/typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faHammer,
  faPlay,
  faPlus,
  faSave,
} from "@fortawesome/pro-light-svg-icons";
import { Select } from "@hiyllo/ux/select";
import { WorkflowTriggerEnum } from "@hiyllo/omni-common/src/types/studio/workflow-triggers";
import { Input } from "@hiyllo/ux/input";
import { HDLEditor } from "./hdl-editor";
import { useSaveWorkflowVersion } from "../../hooks/use-save-workflow-version";
import { type UseMoopsyQueryRetValAny } from "@moopsyjs/react";
import { Button } from "@hiyllo/ux/button";
import { useExecuteTestRun } from "../../hooks/use-execute-test-run";
import { CircleButton } from "@hiyllo/ux/circle-button";
import { PillL } from "../../../tasks/components/pill";

const Container = styled("div", { fontFamily: "hiyllo" });
const HeaderBar = styled("div", ({ $theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 10,
  paddingLeft: 20,
  paddingRight: 20,
  height: 60,
  background: $theme.background3,
}));
const Name = styled("div", { fontSize: 20, fontFamily: "hiyllo" });
const TriggerContainer = styled("div", ({ $theme }) => ({
  background: $theme.background1,
  padding: 20,
  display: "flex",
  flexDirection: "column",
  gap: 10,
}));
const InputsContainer = styled("div", ({ $theme }) => ({
  background: $theme.background3,
  padding: 20,
  display: "flex",
  flexDirection: "column",
  gap: 10,
}));
const ExecutionContainer = styled("div", ({ $theme }) => ({
  background: $theme.background1,
  padding: 20,
  display: "flex",
  flexDirection: "column",
  gap: 10,
}));

const WORKFLOW_TRIGGER_OPTIONS = [
  {
    value: "none",
    label: "None",
  },
  {
    value: WorkflowTriggerEnum.eventCreated,
    label: "Event Created",
  },
];

const WorkflowLoaded = React.memo(function WorkflowLoaded(props: {
  data: Omit<HiylloStudioWorkflowDBEntry, "versions">;
  workflowQuery: UseMoopsyQueryRetValAny;
}): JSX.Element {
  const saveWorkflowVersionMutation = useSaveWorkflowVersion({
    querySideEffects: [props.workflowQuery],
  });
  const executeTestRun = useExecuteTestRun();
  const [workflow, setWorkflow, workflowRef] =
    useStatefulRef<HiylloStudioWorkflowTriggered>(
      (window.sessionStorage["workflow." + props.data.uuid] != null
        ? EJSON.parse(window.sessionStorage["workflow." + props.data.uuid])
        : props.data.workflow) as HiylloStudioWorkflowTriggered,
    );

  React.useEffect(() => {
    window.sessionStorage["workflow." + props.data.uuid] =
      EJSON.stringify(workflow);
  }, [props.data.uuid, workflow]);

  const [testRunResults, setTestRunResults] = React.useState<{
    input: string;
    response: string;
  } | null>(null);

  const test = React.useCallback(() => {
    void executeTestRun
      .call({
        workflowUUID: props.data.uuid,
      })
      .then((res) => {
        setTestRunResults(res);
      });
  }, [executeTestRun, props.data.uuid]);

  const save = React.useCallback(() => {
    void saveWorkflowVersionMutation.call({
      workflowUUID: props.data.uuid,
      workflow: workflowRef.current,
    });
  }, [props.data.uuid, saveWorkflowVersionMutation, workflowRef]);

  const addHDLBlock = React.useCallback(() => {
    setWorkflow((w) => ({
      ...w,
      source: {
        ...w.source,
        execution: [
          ...w.source.execution,
          {
            uuid: crypto.randomUUID(),
            type: "hiylloscript",
            script: "",
          } as const,
        ],
      },
    }));
  }, [setWorkflow]);

  const addLLMBlock = React.useCallback(() => {
    setWorkflow((w) => ({
      ...w,
      source: {
        ...w.source,
        execution: [
          ...w.source.execution,
          {
            uuid: crypto.randomUUID(),
            type: "llm",
            prompt: "",
          } as const,
        ],
      },
    }));
  }, [setWorkflow]);

  const unsaved =
    JSON.stringify(props.data.workflow) !== JSON.stringify(workflow);

  return (
    <Container>
      <HeaderBar>
        <Name>{props.data.name}</Name>
        <div>
          <b>Triggered Workflow</b>
        </div>
        {unsaved ? <Button onClick={save} label="Save" /> : null}
      </HeaderBar>
      <TriggerContainer>
        <Typography.HeaderRow>
          <Typography.SubHeader>
            <FontAwesomeIcon icon={faPlay} />
          </Typography.SubHeader>
          <Typography.SubHeader>Trigger</Typography.SubHeader>
        </Typography.HeaderRow>
        <Select
          value={workflow.trigger}
          onChangeValue={(v) =>
            setWorkflow({
              ...workflowRef.current,
              trigger: v as WorkflowTriggerEnum,
            })
          }
          options={WORKFLOW_TRIGGER_OPTIONS}
        />
      </TriggerContainer>
      <InputsContainer>
        <Typography.HeaderRow>
          <Typography.SubHeader>
            <FontAwesomeIcon icon={faDownload} />
          </Typography.SubHeader>
          <Typography.SubHeader>Inputs & Context</Typography.SubHeader>
        </Typography.HeaderRow>
        {workflow.trigger === WorkflowTriggerEnum.none ? (
          <Typography.Paragraph>No Inputs</Typography.Paragraph>
        ) : (
          <div>
            <Typography.Paragraph>
              Execution Context: <b>@system</b>
            </Typography.Paragraph>
            <div style={{ height: 10 }} />
            <Typography.Paragraph>
              <b>$event</b>: The Calendar Event that was created
            </Typography.Paragraph>
            <Typography.Paragraph>
              <b>$internalGuests</b>: An array of internal guests (
              <code>{`{ userId: string; }`}</code>)
            </Typography.Paragraph>
          </div>
        )}
      </InputsContainer>
      <ExecutionContainer>
        <Typography.HeaderRow>
          <Typography.SubHeader>
            <FontAwesomeIcon icon={faHammer} />
          </Typography.SubHeader>
          <Typography.SubHeader>Execution</Typography.SubHeader>
        </Typography.HeaderRow>
        {workflow.source.execution.map((e, i) =>
          e.type === "llm" ? (
            <div key={e.uuid}>
              <Typography.Label>LLM Block #{i + 1}</Typography.Label>
              <Input
                value={e.prompt}
                onChangeValue={(v) => {
                  setWorkflow((w) => ({
                    ...w,
                    source: {
                      ...w.source,
                      execution: w.source.execution.map((e, j) =>
                        j === i
                          ? {
                            ...e,
                            prompt: v,
                          }
                          : e,
                      ),
                    },
                  }));
                }}
                fullWidth
                multiline
              />
            </div>
          ) : (
            <div key={e.uuid}>
              <Typography.Label>Block #{i + 1}</Typography.Label>
              <HDLEditor
                value={e.script}
                onChangeValue={(v) => {
                  setWorkflow((w) => ({
                    ...w,
                    source: {
                      ...w.source,
                      execution: w.source.execution.map((e, j) =>
                        j === i
                          ? {
                            ...e,
                            script: v,
                          }
                          : e,
                      ),
                    },
                  }));
                }}
              />
            </div>
          ),
        )}
        {workflow.source.execution.length === 0 ? (
          <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
            <PillL onClick={addLLMBlock}>
              <FontAwesomeIcon icon={faPlus} />
              New LLM Block
            </PillL>
          </div>
        ) : null}
      </ExecutionContainer>
      <InputsContainer>
        {unsaved ? (
          <CircleButton
            icon={faSave}
            size={30}
            onClick={save}
            isLoading={saveWorkflowVersionMutation.isLoading}
          />
        ) : (
          <CircleButton
            icon={faPlay}
            size={30}
            onClick={test}
            isLoading={executeTestRun.isLoading}
          />
        )}
        {testRunResults != null ? (
          <div>
            <Typography.SubHeader>Resolved Input</Typography.SubHeader>
            <Typography.Paragraph>
              <div style={{ whiteSpace: "pre-wrap" }}>
                {testRunResults.input}
              </div>
            </Typography.Paragraph>
            <div style={{ height: 10 }} />
            <Typography.SubHeader>AI Response</Typography.SubHeader>
            <Typography.Paragraph>
              <div style={{ whiteSpace: "pre-wrap" }}>
                {testRunResults.response}
              </div>
            </Typography.Paragraph>
          </div>
        ) : null}
      </InputsContainer>
    </Container>
  );
});

export const WorkflowView = React.memo(function WorkflowView(props: {
  uuid: string;
}): JSX.Element {
  const workflowQuery = useGetWorkflow({ uuid: props.uuid });

  if (workflowQuery.isError || workflowQuery.isLoading) {
    return <LoadingSpinnerFullView />;
  }

  return (
    <WorkflowLoaded
      data={workflowQuery.data.workflow}
      workflowQuery={workflowQuery}
    />
  );
});
