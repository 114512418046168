import React from "react";
import { Button, OutlineButton } from "@hiyllo/ux/button";
import { LoadingSpinner } from "@hiyllo/ux/loading-spinner";
import { motion } from 'framer-motion';
import { RSVP } from '@hiyllo/omni-calendar';

import * as RetrieveEventBP from "../../../../blueprints/calendar/retrieve-event";
import * as SetEventNotificationsEnabledBP from "../../../../blueprints/calendar/set-event-notifications-enabled";

import { seamlessClient } from "../../../../seamless-client";
import { useSelf } from "@hiyllo/omni-continuity";
import { ActionRepeatContextEnum } from "@hiyllo/omni-common/src/types/calendar/repeating";
import {
  type CalendarEvent,
  TranscriptionStatusEnum,
} from "@hiyllo/omni-common/src/types/calendar/calendar-event";


import { type UseMoopsyQueryRetValAny } from "@moopsyjs/react";
import { TimeInput } from "@hiyllo/ux/time-input";
import { Input } from "@hiyllo/ux/input";
import { EventTypeSelect } from "../components/event-type-select";
import { CheckboxInput } from "@hiyllo/ux/checkbox-input";
import { VisualPreviewWithGuests } from "../components/visual-preview-with-guests";
import {
  CenteredRow,
  EventDetailsViewContainer,
  FlexStartRow,
  GappedCol,
  Row,
} from "./components";
import { MeetingCard } from "./meeting";
import { RepeatModal } from "./repeat-modal";
import {
  GuestManagementView,
} from "@hiyllo/omni-calendar";
import { useNavigate } from "@hiyllo/omni-router";
import { Features } from "@hiyllo/omni-common/src/types/navigation/features";
import { useTimezone } from "../week-view/hooks/use-timezone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationPin } from "@fortawesome/pro-light-svg-icons";
import { AddressInput, PlacesProvider } from "@hiyllo/ux/address-input";
import { GOOGLE_MAPS_API_KEY } from "../../../google/maps/env";
import { useEditEventController } from "@hiyllo/omni-calendar";

import { EventTypeEnum } from "../../../../types/calendar/calendar-event";
import { TabDetails } from "../../../tokyo/tabbing/tabs-provider";
import { Modal } from "@hiyllo/ux/modal";
import { PillL } from "../../../tasks/components/pill";
import { AccessControlView } from "../../../continuity/access/access-control-view";
import { Typography } from "@hiyllo/ux/typography";
import { WarningCard } from "../../../../ux/warning-card";
import { useListMyTeams } from "../../../organization/hooks/use-list-my-teams";

export const EventDetailsViewWithEvent = React.memo(
  function EventDetailsViewWithEvent(props: {
    retrieveEventQuery: UseMoopsyQueryRetValAny;
    eventUUID: string;
    event: CalendarEvent;
    onClose: () => void;
  }): JSX.Element {
    const { retrieveEventQuery, event } = props;
    const setEventNotificationsEnabledMutation =
      seamlessClient.useMutation<SetEventNotificationsEnabledBP.Plug>(
        SetEventNotificationsEnabledBP,
        { querySideEffects: [retrieveEventQuery] },
      );
    const timezone = useTimezone();
    const self = useSelf();
    const [showSaveRepeatPopup, setShowSaveRepeatPopup] = React.useState<
      ((a: ActionRepeatContextEnum) => Promise<void>) | null
    >(null);
    const editEventController = useEditEventController({
      event,
      retrieveEventQuery,
      timezone,
      onRepeatSelectionRequired: fn => setShowSaveRepeatPopup(() => fn),
      onEventDeleted: props.onClose
    });

    const myTeamsQuery = useListMyTeams(null);
    const canEdit = event.editAccess == null || event.editAccess.users.includes(self.userId) || event.editAccess.teams.some(uuid => myTeamsQuery.data?.teams.some(t => t.uuid === uuid));
    const repeat = retrieveEventQuery.data.event.repeat;
    const preliminary = React.useCallback(
      (fn: (a: ActionRepeatContextEnum) => Promise<void>) => {
        if (repeat == null) {
          return fn(ActionRepeatContextEnum.this);
        } else {
          setShowSaveRepeatPopup(() => fn);
        }
      },
      [repeat],
    );

    const navigate = useNavigate();
    const [showEditAccessModal, setShowEditAccessModal] = React.useState(false);
    const defaultEditAccess = React.useRef({ teams: [], users: [self.userId] }).current;
    const noEditorsSpecified = editEventController.editAccess?.teams.length === 0 && editEventController.editAccess?.users.length === 0;

    const myGuest = event.guests.find(
      (g) => g.invitee.type === "internal" && g.invitee.userId === self.userId,
    );

    return (
      <TabDetails label={editEventController.title}>
        {showEditAccessModal ?
          <Modal onClose={() => {
            setShowEditAccessModal(false);
            if (editEventController.editAccess != null && noEditorsSpecified) {
              editEventController.setEditAccess(event.editAccess ?? null);
            }
          }}>
            <div>
              <Typography.SubHeader>Who Can Edit</Typography.SubHeader>
              <div style={{ height: 10 }} />
              <div style={{ width: 300, }}>
                {noEditorsSpecified ?
                  <>
                    <WarningCard>
                      No editors specified. Changes will be discarded.
                    </WarningCard>
                    <div style={{ height: 10 }} />
                  </>
                  : null}
                {editEventController.editAccess == null ?
                  <>
                    <Typography.Label>Anyone added to the event can edit/delete</Typography.Label>
                    <div style={{ height: 5 }} />
                    <Button label="Limit Access" onClick={() => editEventController.setEditAccess(defaultEditAccess)} />
                  </>
                  :
                  <AccessControlView
                    rwAccess={editEventController.editAccess}
                    onChangeValue={editEventController.setEditAccess}
                  />
                }
              </div>
            </div>
          </Modal>
          : null}
        {showSaveRepeatPopup !== null ? (
          <Modal onClose={() => setShowSaveRepeatPopup(null)}>
            <RepeatModal
              onClose={() => setShowSaveRepeatPopup(null)}
              onSubmit={async (repeatContext) => {
                if (showSaveRepeatPopup && repeatContext != null) {
                  await showSaveRepeatPopup(repeatContext);
                  setShowSaveRepeatPopup(null);
                }
              }}
            />
          </Modal>
        ) : null}
        <EventDetailsViewContainer>
          <FlexStartRow>
            <GappedCol style={{ position: "relative", overflowY: "auto" }}>
              <div>
                <EventTypeSelect
                  eventType={editEventController.type}
                  onEventTypeChanged={(type) => {
                    editEventController.onChangeType(type);
                  }}
                />
              </div>
              <Input
                value={editEventController.title}
                onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                  editEventController.setTitle(evt.currentTarget.value)
                }
                disabled={!canEdit}
                fullWidth
              />

              <CenteredRow>
                <PlacesProvider apiKey={GOOGLE_MAPS_API_KEY}>
                  <AddressInput
                    startEnhancer={
                      <div style={{ paddingRight: "0.5em" }}>
                        <FontAwesomeIcon icon={faLocationPin} fixedWidth />
                      </div>
                    }
                    value={editEventController.location}
                    onChangeValue={editEventController.setLocation}
                    fullWidth
                  />
                </PlacesProvider>
              </CenteredRow>

              <CenteredRow>
                <Input
                  type="date"
                  outerStyle={{ width: 140 }}
                  value={editEventController.date}
                  onChange={(evt) => editEventController.setDate(evt.target.value)}
                  disabled={!canEdit}
                />
                {canEdit ? (
                  <CheckboxInput
                    label="All Day"
                    value={editEventController.allDay}
                    onChange={editEventController.setAllDay}
                  />
                ) : null}
                {!editEventController.allDay ? (
                  <>
                    <TimeInput
                      value={editEventController.startTime ?? "00:00"}
                      onChangeValue={editEventController.onChangeStartTime}
                      minWidth
                      disabled={!canEdit}
                    />
                    to
                    <TimeInput
                      value={editEventController.endTime ?? "00:15"}
                      onChangeValue={editEventController.onChangeEndTime}
                      useEndingTimeOptions
                      disabled={!canEdit}
                      minWidth
                    />
                  </>
                ) : null}
              </CenteredRow>
              {event.limitedAccess !== true ? (
                <Input
                  value={editEventController.description}
                  onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) =>
                    editEventController.setDescription(evt.currentTarget.value)
                  }
                  disabled={!canEdit}
                  fullWidth
                  multiline
                  inputStyle={{ minHeight: 100 }}
                  placeholder="Add a description..."
                />
              ) : null}
              {myGuest != null ? (
                <>
                  <div>
                    <label
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 10,
                        cursor: "pointer",
                      }}
                    >
                      {setEventNotificationsEnabledMutation.isLoading ? (
                        <LoadingSpinner />
                      ) : (
                        <input
                          type="checkbox"
                          checked={!event.disableNotifications}
                          onChange={(evt) => {
                            const checked = evt.currentTarget.checked;
                            void preliminary(async (r: ActionRepeatContextEnum) => {
                              await setEventNotificationsEnabledMutation.call({
                                eventUUID: props.eventUUID,
                                repeatHandling: r,
                                notificationsEnabled: checked,
                              });
                              setShowSaveRepeatPopup(null);
                            });
                          }}
                        />
                      )}
                      Reminder me about this event
                    </label>
                  </div>
                  <RSVP
                    myGuest={myGuest}
                    preliminary={preliminary}
                    retrieveEventQuery={retrieveEventQuery}
                    event={event}
                  />
                </>
              ) : null}
              <MeetingCard
                event={event}
                retrieveEventQuery={retrieveEventQuery}
              />
              {event.transcriptionStatus ===
                TranscriptionStatusEnum.completed ? (
                event.transcriptUUID != null ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 5,
                    }}
                  >
                    <PillL
                      onClick={() => {
                        if (event.transcriptUUID != null) {
                          navigate({
                            feature: Features.stuff,
                            params: {
                              view: "meeting-recording",
                              uuid: event.transcriptUUID,
                            },
                          });
                        }
                      }}
                    >
                      View Transcript
                    </PillL>
                  </div>
                ) : (
                  <div>Transcript Available</div>
                )
              ) : event.transcriptionStatus ===
                TranscriptionStatusEnum.running ? (
                <div>Transcribing in Progress</div>
              ) : event.recordingStatus === "recording-available" ? (
                <div>Recording Completed, Transcribing will begin soon</div>
              ) : event.recordingStatus === "recording-in-progress" ? (
                <div>
                  Meeting is being recorded, transcript will be available after
                </div>
              ) : null}
              <div
                style={{
                  height: 0,
                  flexGrow: 1,
                }}
              />
              {editEventController.changesMade ? (
                <label>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 5,
                      cursor: "pointer",
                      userSelect: "none",
                    }}
                  >
                    <input
                      type="checkbox"
                      checked={editEventController.notifyGuests}
                      onChange={(evt) =>
                        editEventController.setNotifyGuests(evt.currentTarget.checked)
                      }
                    />
                    Notify Guests about my changes
                  </div>
                </label>
              ) : null}
              {canEdit ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  {editEventController.changesMade ? (
                    <Row>
                      <Button
                        label="Save Changes"
                        onClick={editEventController.saveChanges}
                        isLoading={editEventController.saveLoading}
                      />
                      <OutlineButton onClick={editEventController.discardChanges}>
                        Discard
                      </OutlineButton>
                    </Row>
                  ) : (
                    <div />
                  )}
                  <Row>
                    <Button
                      label="Manage Edit Access"
                      onClick={() => setShowEditAccessModal(true)}
                      isSecondary
                    />
                    <Button
                      label="Delete Event"
                      onClick={editEventController.deleteEvent}
                      isLoading={editEventController.deleteLoading}
                      isSecondary
                    />
                  </Row>
                </div>
              ) : null}
              <div style={{ height: 50 }} />
            </GappedCol>
            <div
              style={{
                minWidth: 250,
                overflowY: "auto",
                display: "flex",
                flexDirection: "column",
                flexGrow: 1,
              }}
            >
              {canEdit ? (
                <motion.div
                  style={{ overflow: "hidden" }}
                  animate={{ width: props.event.type === EventTypeEnum.event ? 300 : 0 }}
                  exit={{ width: 0 }}
                >
                  <GuestManagementView
                    ref={editEventController.guestPaneRef}
                    defaultGuests={props.event.guests}
                    onChanged={(guests) => {
                      editEventController.setGuests(guests);
                    }}
                  />
                </motion.div>
              ) : null}

              <div style={{ height: 50 }} />
            </div>
            <div>
              <VisualPreviewWithGuests
                startTime={editEventController.startTime}
                endTime={editEventController.endTime}
                date={editEventController.date}
                invitees={editEventController.guests.map((g) => g.invitee)}
                onChangeDate={
                  canEdit
                    ? editEventController.setDate
                    : () => {
                      //
                    }
                }
                ignoreEvents={props.eventUUID}
              />
            </div>
          </FlexStartRow>
        </EventDetailsViewContainer>
      </TabDetails>
    );
  },
);

export const EventDetailsView = React.memo(function EventDetailsView(props: {
  eventUUID: string;
  onClose: () => void;
}): JSX.Element {
  const retrieveEventQuery =
    seamlessClient.useQuery<RetrieveEventBP.Plug>(RetrieveEventBP, {
      eventUUID: props.eventUUID,
    });

  if (retrieveEventQuery.isError) {
    return (
      <EventDetailsViewContainer>Event Not Found</EventDetailsViewContainer>
    );
  }

  if (retrieveEventQuery.isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <EventDetailsViewWithEvent
      retrieveEventQuery={retrieveEventQuery}
      event={retrieveEventQuery.data.event}
      eventUUID={props.eventUUID}
      onClose={props.onClose}
    />
  );
});
