import { type AlertType } from "../../../types/alerts/alert";
import { type EDataType } from "../../../types/navigation/edata";
import { linkMatches } from "../../../features/tokyo/navigation/link-matches";

export function doesAlertLinkTo(alert: AlertType, link: EDataType): boolean {
  const isActionMatch = !!alert?.actions?.find(
    (a) => "params" in a.action && linkMatches(link, a.action.params.link),
  );

  if (isActionMatch) return true;

  const isLinkMatch = !!(
    alert?.link != null &&
    "enav" in alert.link &&
    linkMatches(link, alert.link.enav)
  );

  return isLinkMatch;
}
