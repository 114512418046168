import React from "react";
import { styled } from "@hiyllo/ux/styled";
import { LeftSidebar } from "../tokyo/components/left-sidebar";
import { LSHeader } from "../tokyo/components/ls-header";
import { MainContent } from "../tokyo/components/main-content";
import * as ListContactsBP from "../../blueprints/contacts/list-contacts";
import * as DeleteContactBP from "../../blueprints/contacts/delete-contact";
import { seamlessClient } from "../../seamless-client";
import { LoadingSpinnerFullView } from "../../platform/loading/spinner-loading-full";
import { EmptySplash } from "@hiyllo/ux/empty-splash";
import { faEmptySet, faExclamationTriangle, faTrash } from "@fortawesome/pro-light-svg-icons";
import { CircleButton } from "@hiyllo/ux/circle-button";

const TableBlock = styled("div", { padding: 0 });

const ListContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: 5,
});

const ContactRow = styled("div", ({ $theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  height: 40,
  paddingLeft: 10,
  paddingRight: 10,
  backgroundColor: $theme.background3,
  gap: 10
}));

export const ContactsFeature = React.memo(
  function ContactsFeature(): JSX.Element {
    const listContactsQuery = seamlessClient.useQuery<ListContactsBP.Plug>(ListContactsBP, null);
    const deleteContactMutation = seamlessClient.useMutation<DeleteContactBP.Plug>(DeleteContactBP, { querySideEffects: [listContactsQuery] });

    return (
      <>
        <LeftSidebar>
          <LSHeader label="Contacts" />
        </LeftSidebar>
        <MainContent noPadding>
          <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
            {/* <HeaderBlock>
              Contacts
            </HeaderBlock> */}
            <div style={{ height: 0, flexGrow: 1 }}>
              {listContactsQuery.isLoading ? <LoadingSpinnerFullView /> : listContactsQuery.isError ?
                <EmptySplash
                  icon={faExclamationTriangle}
                  label={listContactsQuery.error.message}
                />
                : listContactsQuery.data.contacts.length === 0 ?
                  <EmptySplash
                    icon={faEmptySet}
                    label="No Contacts Here"
                    hint="Contacts will automatically be created when you interact with external people (send emails, add external guests to events, etc)"
                  />
                  :
                  <TableBlock>
                    <ListContainer>
                      {listContactsQuery.data.contacts.map(contact => (
                        <ContactRow key={contact.uuid}>
                          <div>{contact.name ?? "<No Name>"}</div>
                          <div>{contact.email}</div>
                          <div>{contact.phoneNumberE164}</div>
                          <CircleButton secondary size={20} icon={faTrash} onClick={async () => {
                            await deleteContactMutation.call({ uuid: contact.uuid });
                          }} awaitOnClickForLoading />
                        </ContactRow>
                      ))}
                    </ListContainer>
                  </TableBlock>
              }
            </div>
          </div>
        </MainContent>
      </>
    );
  },
);
