import React from "react";
import { ListTasksSlimTaskType } from "../../../types/tasks/task-item";

export const ViewCriteriaContext = React.createContext<
  | ((
      task: Omit<ListTasksSlimTaskType, "assignee"> | ListTasksSlimTaskType,
    ) => boolean)
  | null
>(null);

export function useMatchesViewCriteria(
  task: Omit<ListTasksSlimTaskType, "assignee"> | ListTasksSlimTaskType,
): boolean {
  const ctx = React.useContext(ViewCriteriaContext);

  return React.useMemo(() => {
    if (ctx) {
      return ctx(task);
    }

    return true;
  }, [ctx, task]);
}
