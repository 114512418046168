import React from "react";
import { EnvironmentProvider as EnvironmentProviderOC } from "@hiyllo/omni-continuity";
import { useIsSolo } from "../platform/hooks/use-is-solo";

export const EnvironmentProvider = React.memo(function EnvironmentProvider(
  props: React.PropsWithChildren,
): JSX.Element {
  const isSolo = useIsSolo();

  return (
    <EnvironmentProviderOC environment={{ isSolo }}>
      {props.children}
    </EnvironmentProviderOC>
  );
});
