/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { TokenPackageType } from "../../../types/auth/token-package";

export type ParamsType = { SAMLResponse: string; RelayState: string };
export type ResponseType = { tokenPackage: TokenPackageType };
export const Endpoint = "auth/complete-hiyllo-auth-login";
export const Method = "POST";

export interface Plug {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
}
export const isPublic = true;

export const RateLimitingConfig = {
  calls: 2,
  per: 2500,
};
export const paramsSchema = {"type":"object","properties":{"SAMLResponse":{"type":"string"},"RelayState":{"type":"string"}},"required":["RelayState","SAMLResponse"],"$schema":"http://json-schema.org/draft-07/schema#"}