import { LoadingPage } from "@hiyllo/ux/standard-loader";
import React, { Suspense } from "react";

export const SuspenseProvider = React.memo(function SuspenseProvider({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  console.debug("Rendering <SuspenseProvider>");

  return (
    <Suspense fallback={<LoadingPage />}>{children}</Suspense>
  );
});
