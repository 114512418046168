/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { BetaFeaturesEnum } from "../../../types/accounts/user";

export type ParamsType = { userId: string; feature: BetaFeaturesEnum };
export type ResponseType = void;
export const Endpoint = "admin/enable-beta-feature";
export const Method = "POST";

export interface Plug {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
}
export const paramsSchema = {"type":"object","properties":{"userId":{"type":"string"},"feature":{"type":"string","enum":["wardrobe"]}},"required":["feature","userId"],"$schema":"http://json-schema.org/draft-07/schema#"}