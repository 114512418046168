import React from "react";
import { useDeterminePosition } from "../hooks/use-determine-position";
import { type PositioningDate } from "../consts";
import { useDetermineDateFromPosition } from "../hooks/use-determine-date-from-position";
import _ from "lodash";
import { type PanInfo, motion } from "framer-motion";
import { DayWidthContext } from "../contexts";
import { useHourHeight } from "../hooks/use-hour-height";

export const DraggedEventContext = React.createContext<PositioningDate | null>(
  null,
);

interface DragHandlers {
  onDragStart: (event: React.MouseEvent) => void;
  onDrag: (
    event: React.MouseEvent,
    cb?: (event: React.MouseEvent) => void,
  ) => void;
  onDragEnd: (event: React.MouseEvent) => void;
}

export const DatePositioned = React.memo(function DatePositioned(
  props: React.PropsWithChildren<{
    date: PositioningDate;
    innerRef?: React.RefObject<HTMLDivElement>;
    onClick?: () => void;
    duration?: number;
    draggable?: boolean;
    onNewDate?: (date: PositioningDate) => void;
  }>,
): JSX.Element {
  const incomingDate = React.useRef<PositioningDate>(props.date);
  const [date, setDate] = React.useState(props.date);
  const { x, y } = useDeterminePosition(date);
  const determineDateFromPosition = useDetermineDateFromPosition();
  const [draggedPosition, setDraggedPosition] =
    React.useState<PositioningDate | null>(null);
  const { x: newX, y: newY } = useDeterminePosition(draggedPosition ?? date);
  const isDragging = React.useRef(false);
  const dayWidth = React.useContext(DayWidthContext);
  const hourHeight = useHourHeight();

  React.useEffect(() => {
    if (!_.isEqual(props.date, incomingDate.current)) {
      setDate(props.date);
      incomingDate.current = props.date;
    }
  }, [props.date]);

  const onDrag = React.useCallback(
    (event: MouseEvent | TouchEvent | PointerEvent, info: PanInfo) => {
      event.preventDefault();
      event.stopPropagation();
      isDragging.current = true;

      // dragHandlers.onDrag(evt, (evt) => {
      const nd = determineDateFromPosition({
        x: Math.max(60, x + info.offset.x),
        y: y + info.offset.y,
      });

      setDraggedPosition((d) => (_.isEqual(d, nd) ? d : nd));
      // });
    },
    [determineDateFromPosition, x, y],
  );

  const onDragEnd = React.useCallback(
    (event: MouseEvent | TouchEvent | PointerEvent) => {
      isDragging.current = false;
      window.requestAnimationFrame(() => {
        setDraggedPosition((pos) => {
          props.onNewDate?.(pos ?? props.date);
          setDate(pos ?? props.date);
          window.requestAnimationFrame(() => {
            (event.target as HTMLDivElement).style.transform = "";
          });
          return null;
        });
      });
    },
    [props.date],
  );

  return (
    <DraggedEventContext.Provider value={draggedPosition}>
      <motion.div
        ref={props.innerRef}
        style={{
          position: "absolute",
          top: y,
          left: x,
        }}
        drag={props.draggable === true}
        onDrag={onDrag}
        onDragEnd={onDragEnd}
        onClick={isDragging.current ? undefined : props.onClick}
        key={y.toString() + x.toString()}
      >
        {props.children}
      </motion.div>
      {draggedPosition != null ? (
        <motion.div
          style={{
            position: "absolute",
            top: newY,
            left: newX,
            width: dayWidth,
            height: (props.duration ?? 60) * (hourHeight / 60),
            border: "2px red dashed",
          }}
        ></motion.div>
      ) : null}
    </DraggedEventContext.Provider>
  );
});
