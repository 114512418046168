import React from "react";
import { createRoot } from "react-dom/client";
import { setEnvironment } from "@hiyllo/stardate/main";
import { Main } from "./main";
import "./ux/index.css";

import packageConfig from '../package.json';
import { Electron } from "./platform/electron";

// @ts-expect-error ---
global.__DEV__ = false;
// @ts-expect-error ---
window.__DEV__ = false;
setEnvironment(
  // @ts-expect-error ---
  `${Electron.isElectron ? 'desktop' : window.location.host}@${(window._omniCommitHash ?? packageConfig.version ?? '').slice(0, 7)}`
);

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Main />
  </React.StrictMode>,
);
