import moment from "moment";
import React from "react";

import * as RetrieveEventsBP from "../../../../blueprints/calendar/retrieve-events";
import * as MyCalendarPSB from "../../../../blueprints/calendar/my-calendar.psb";

import { useNavigate, usePath } from "@hiyllo/omni-router";
import { Features } from "@hiyllo/omni-common/src/types/navigation/features";
import { seamlessClient } from "../../../../seamless-client";
import { EventDetailsView } from "../event-details/event-details-view";
import {
  DayWidthContext,
  TimezoneContext,
} from "./contexts";
import { TimeColumn } from "./components/time-column";
import { useDetermineDateFromPosition } from "./hooks/use-determine-date-from-position";
import { DayColumn } from "./components/day-column";
import { roundToIncrements } from "./utils";
import { CalendarHeader } from "./components/calendar-header";
import {
  type CreateEventSkeletonType,
  CreateSkeleton,
} from "./components/create-skeleton";
import { DayLine } from "./components/day-line";
import { styled } from "@hiyllo/ux/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/pro-regular-svg-icons";
import { OutlineButton } from "@hiyllo/ux/button";
import { LoadingSpinner } from "@hiyllo/ux/loading-spinner";
import { useSelf } from "@hiyllo/omni-continuity";
import { Tenant } from "../../../../platform/tenancy";
import {
  type CalendarEDataParamsType,
  type CalendarCalendarOfParamsType,
  type CalendarWeekAgendaParamsType,
} from "@hiyllo/omni-common/src/types/navigation/edata";
import {
  RSVPEnum,
  type EventGuestType,
} from "@hiyllo/omni-common/src/types/calendar/calendar-event";
import { Modal as SmartModal } from "@hiyllo/ux/modal";
import { PositionedEvents } from "./event-tile-renderer";
import { TimeZoneSelector } from "./components/time-zone-selector";
import { DatePositioned } from "./components/date-positioned";
import { type PositioningDate } from "./consts";
import { GUEST_COLOR_BY_INDEX } from "../consts/guest-color-by-index";
import { generateWorkingHourBlocks } from "./lib/generate-working-hours-blocks";
import { EventGuestInviteeType } from "../../../../types/calendar/calendar-event";

const EventTopBarContainer = styled("div", ({ $theme }) => ({
  height: 60,
  background: $theme.background3,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  width: "100%",
}));

const WeekButtonsContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  gap: 10,
  paddingLeft: 65,
  paddingRight: 20,
  alignItems: "center",
  userSelect: "none",
  fontSize: 16,
  width: "100%",
});

const WeekChangeButton = styled("div", ({ $theme }) => ({
  width: 30,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontSize: 20,
  color: $theme.foreground,
  flexShrink: 0,
  cursor: "pointer",
}));

const CurrentWeekContainer = styled("div", ({ $theme }) => ({
  paddingLeft: 5,
  color: $theme.foreground,
  textAlign: "center",
}));

export function determineWeekDateForCalendar(date: Date): Date {
  const m = moment(date);
  if (m.day() === 0) {
    m.subtract(1, "days");
  }
  return m.toDate();
}

export const WeekView = React.memo(function WeekView(props: {
  initialDate?: Date;
  calendarForUsers: string[];
}): JSX.Element {
  const self = useSelf();
  const params = usePath().params as CalendarEDataParamsType;
  const date = determineWeekDateForCalendar(
    params != null && "weekOf" in params && params.weekOf != null
      ? params.weekOf
      : props.initialDate ?? new Date(),
  );
  const _weekStart = moment(date).days(1).startOf("day");
  const weekStart = _weekStart.format("MMMM YYYY");
  const _weekEnd = moment(date).days(1).add(6, "days").endOf("day");
  const weekEnd = _weekEnd.format("MMMM YYYY");
  const navigate = useNavigate();

  const onPrevious = React.useCallback(() => {
    const nd = moment(date).subtract(1, "week").toDate();
    navigate({
      feature: Features.calendar,
      params: {
        view: "week-agenda",
        ...(params as
          | null
          | CalendarCalendarOfParamsType
          | CalendarWeekAgendaParamsType),
        weekOf: nd,
      },
    });
  }, [date, navigate, params]);

  const onNext = React.useCallback(() => {
    const nd = moment(date).add(1, "week").toDate();
    navigate({
      feature: Features.calendar,
      params: {
        view: "week-agenda",
        ...(params as
          | null
          | CalendarCalendarOfParamsType
          | CalendarWeekAgendaParamsType),
        weekOf: nd,
      },
    });
  }, [date, navigate, params]);
  const [selectedCalendarEventUUID, setSelectedCalendarEventUUID] =
    React.useState<string | null>(null);
  const containerRef = React.useRef<HTMLDivElement>(null);
  const scrollContainerRef = React.useRef<HTMLDivElement>(null);
  const dayLineRef = React.useRef<HTMLDivElement>(null);
  const [dayWidth, setDayWidth] = React.useState<number | null>(null);
  const [creatingEventSkeleton, setCreatingEventSkeleton] =
    React.useState<CreateEventSkeletonType | null>(null);
  const determineDateFromPosition = useDetermineDateFromPosition(dayWidth ?? 0);
  const startOfWeek = moment(
    moment(date).day() === 0 ? moment(date).subtract(1, "days") : date,
  )
    .days(1)
    .startOf("day")
    .toDate();
  const endOfWeek = moment(startOfWeek).add(6, "days").endOf("day").toDate();
  const calendarForUsers = React.useMemo(
    () =>
      props.calendarForUsers != null
        ? [...props.calendarForUsers, self.userId]
        : null,
    [props.calendarForUsers, self.userId],
  );
  const retrieveEventsParams = React.useMemo(() => ({
    after: startOfWeek,
    before: endOfWeek,
    calendarForUsers,
    dev_includeDeleted: window.localStorage.dev_includeDeleted === "true",
  }), [calendarForUsers, endOfWeek, startOfWeek]);
  const retrieveEventsQuery =
    seamlessClient.useQuery<RetrieveEventsBP.Plug>(RetrieveEventsBP, retrieveEventsParams);

  const workingHoursBlocks = (retrieveEventsQuery.data?.workingHours ?? [])
    .map((wh) =>
      generateWorkingHourBlocks(wh.workingHours ?? {}, wh.timezone, date).map(
        (b) => ({
          userId: wh.userId,
          block: b,
        }),
      ),
    )
    .flat();

  const today = React.useCallback(() => {
    navigate({
      feature: Features.calendar,
      params: {
        view: "week-agenda",
        ...(params as
          | null
          | CalendarCalendarOfParamsType
          | CalendarWeekAgendaParamsType),
        weekOf: determineWeekDateForCalendar(new Date()),
      },
    });
  }, [navigate, params]);

  React.useEffect(() => {
    const update = (): void => {
      if (containerRef.current == null) return;
      setDayWidth((containerRef.current.clientWidth - 60) / 7);
    };

    update();

    window.addEventListener("resize", update);

    return () => {
      window.removeEventListener("resize", update);
    };
  }, []);

  React.useEffect(() => {
    if (dayLineRef.current == null || scrollContainerRef.current == null)
      return;

    dayLineRef.current.scrollIntoView({
      behavior: "auto",
      block: "center",
      inline: "center",
    });
  }, []);

  const movementRef = React.useRef<number | null>(null);
  const initScrollTop = React.useRef<number | null>(null);
  const onMouseDown = React.useCallback(
    (evt: React.MouseEvent<HTMLDivElement>): void => {
      if (containerRef.current == null) return;

      movementRef.current = 0;
      initScrollTop.current = scrollContainerRef.current?.scrollTop ?? 0;
      const rect = evt.currentTarget.getBoundingClientRect();
      const x = evt.clientX - rect.left;
      const y = evt.clientY - rect.top;

      setCreatingEventSkeleton({
        x,
        y,
        height: 0,
        width: (dayWidth ?? 0) - 4,
      });
    },
    [dayWidth],
  );

  const onMouseMove = React.useCallback(
    (evt: React.MouseEvent<HTMLDivElement>): void => {
      const parent = evt.currentTarget.parentElement;
      (movementRef.current as number) += evt.movementY;
      setCreatingEventSkeleton((value) => {
        if (value === null) return null;
        return {
          ...value,
          height: Math.max(
            0,
            (movementRef.current as number) +
            (parent?.scrollTop ?? 0) -
            (initScrollTop.current ?? 0),
          ),
        };
      });
    },
    [],
  );

  const [timezone, setTimezone] = React.useState<string>(
    window.sessionStorage.calendar_timezoneoverride ??
    moment.tz.guess() ??
    "America/New_York",
  );

  const updateTimezone = React.useCallback((zone: string) => {
    window.sessionStorage.calendar_timezoneoverride = zone;
    setTimezone(zone);
  }, []);

  const onMouseUp = React.useCallback((): void => {
    if (creatingEventSkeleton !== null) {
      const startPosDate = determineDateFromPosition(creatingEventSkeleton);
      const startDate = moment(_weekStart.toDate())
        .tz(timezone)
        .hours(startPosDate.hours)
        .minutes(startPosDate.minutes)
        .seconds(0)
        .day(startPosDate.day)
        .add(startPosDate.day === 0 ? 1 : 0, "weeks");
      const durationHours = Math.floor(creatingEventSkeleton.height / 60);
      let durationMins = roundToIncrements(
        Math.floor(creatingEventSkeleton.height % 60),
        15,
      );

      if (durationHours === 0 && durationMins === 0) {
        durationMins = 30;
      }

      window.requestAnimationFrame(() => {
        navigate({
          feature: Features.calendar,
          params: {
            view: "create-event",
            guestUserIds:
              props.calendarForUsers != null ? props.calendarForUsers : null,
            timing: {
              start: startDate.toDate(),
              end: startDate
                .add(durationHours, "hours")
                .add(durationMins, "minutes")
                .tz(moment.tz.guess())
                .toDate(),
            },
          },
        });
        document.getSelection()?.empty();
      });
      setCreatingEventSkeleton(null);
    }
  }, [
    _weekStart,
    creatingEventSkeleton,
    determineDateFromPosition,
    navigate,
    props.calendarForUsers,
    timezone,
  ]);

  const events = React.useMemo(
    () =>
      (retrieveEventsQuery.data?.events ?? []).filter((event) => {
        const myRSVP =
          event.guests.find(
            (g: EventGuestType) =>
              g.invitee.type === "internal" && g.invitee.userId === self.userId,
          )?.rsvp ?? null;
        const matchingGuests = event.guests.filter(
          (g: EventGuestType<EventGuestInviteeType>) =>
            g.invitee.type === "internal" &&
            (calendarForUsers ?? [self.userId]).includes(g.invitee.userId),
        );

        return myRSVP !== RSVPEnum.no || matchingGuests.length > 1;
      }),
    [calendarForUsers, retrieveEventsQuery.data?.events, self.userId],
  );

  const showDayLine =
    new Date() < _weekEnd.toDate() && new Date() > _weekStart.toDate();

  seamlessClient.useSubscribeToTopic<MyCalendarPSB.Typings>(
    MyCalendarPSB,
    `[${Tenant}]calendar.my-calendar/for:${self.userId}`,
    () => {
      console.log('>>> calendar updated');
      void retrieveEventsQuery.refresh({ subtle: true });
    },
  );

  return (
    <TimezoneContext.Provider value={timezone}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          position: "relative",
        }}
      >
        {selectedCalendarEventUUID !== null ? (
          <SmartModal
            onClose={() => {
              setSelectedCalendarEventUUID(null);
              void retrieveEventsQuery.refresh({ subtle: true });
            }}
          >
            <EventDetailsView
              key={selectedCalendarEventUUID}
              eventUUID={selectedCalendarEventUUID}
              onClose={() => {
                setSelectedCalendarEventUUID(null);
                void retrieveEventsQuery.refresh({ subtle: true });
              }}
            />
          </SmartModal>
        ) : null}
        <EventTopBarContainer>
          <WeekButtonsContainer>
            <OutlineButton
              style={{
                fontSize: 12,
                borderColor: "white",
                color: "white",
                marginRight: 10,
                padding: 7.5,
                height: "",
              }}
              onClick={today}
            >
              Today
            </OutlineButton>
            <WeekChangeButton onClick={onPrevious}>
              <FontAwesomeIcon icon={faAngleLeft} />
            </WeekChangeButton>
            <WeekChangeButton onClick={onNext}>
              <FontAwesomeIcon icon={faAngleRight} />
            </WeekChangeButton>
            <CurrentWeekContainer>
              {weekStart === weekEnd
                ? weekStart
                : `${weekStart} - ${weekEnd}`}
            </CurrentWeekContainer>
            {retrieveEventsQuery.isLoading ? <LoadingSpinner /> : null}
            <div style={{ flexGrow: 1 }} />
            <TimeZoneSelector setTimezone={updateTimezone} />
          </WeekButtonsContainer>
        </EventTopBarContainer>
        <DayWidthContext.Provider value={dayWidth ?? 0}>
          <CalendarHeader
            startOfWeek={startOfWeek}
            events={events}
            setSelectedCalendarEventUUID={setSelectedCalendarEventUUID}
          />
          <div
            ref={scrollContainerRef}
            style={{
              flexGrow: 1,
              height: 0,
              overflowY: "auto",
            }}
          >
            <div
              ref={containerRef}
              style={{
                display: "flex",
                flexDirection: "row",
                position: "relative",
              }}
              onMouseDown={onMouseDown}
              onMouseMove={creatingEventSkeleton ? onMouseMove : undefined}
              onMouseUp={onMouseUp}
            >
              <TimeColumn />

              <DayColumn day={1} />
              <DayColumn day={2} />
              <DayColumn day={3} />
              <DayColumn day={4} />
              <DayColumn day={5} />
              <DayColumn day={6} />
              <DayColumn day={0} />

              {creatingEventSkeleton !== null ? (
                <CreateSkeleton
                  creatingEventSkeleton={creatingEventSkeleton}
                />
              ) : null}

              {workingHoursBlocks.map(({ block, userId }, i) => (
                <DatePositioned
                  date={{
                    day: block.day as PositioningDate["day"],
                    hours: block.hours,
                    minutes: block.minutes,
                  }}
                  key={i}
                >
                  <div
                    style={{
                      zIndex: 0,
                      height: block.duration,
                      background:
                        (userId === self.userId
                          ? "#9e9e9e"
                          : GUEST_COLOR_BY_INDEX[
                          (calendarForUsers?.indexOf(userId) ??
                            0) as keyof typeof GUEST_COLOR_BY_INDEX
                          ]) + "13",
                      width: dayWidth ?? 0,
                    }}
                  />
                </DatePositioned>
              ))}

              <PositionedEvents
                events={events}
                setSelectedCalendarEventUUID={setSelectedCalendarEventUUID}
                invitees={
                  props.calendarForUsers != null
                    ? props.calendarForUsers.map((userId) => ({
                      type: "internal",
                      userId,
                    }))
                    : []
                }
              />

              {showDayLine ? <DayLine dayLineRef={dayLineRef} /> : null}
            </div>
          </div>
        </DayWidthContext.Provider>
      </div>
    </TimezoneContext.Provider>
  );
});
