import { styled } from "@hiyllo/ux/styled";
import React from "react";
import moment from "moment";
import { DatePositioned } from "./date-positioned";
import { type PositioningDate } from "../consts";
import { DayWidthContext } from "../contexts";
import { useRealtimeMinuteSynced } from "../../../utils/use-realtime";

const DayLineComponent = styled<"div", { dayWidth: number }>(
  "div",
  ({ $theme, dayWidth }) => ({
    height: 1,
    width: dayWidth,
    background: $theme.buttonBackground,
    pointerEvents: "none",
  }),
);

export const DayLine = React.memo(function DayLine(props: {
  date?: Date;
  dayLineRef?: React.RefObject<HTMLDivElement>;
}): JSX.Element {
  const dayWidth = React.useContext(DayWidthContext);
  const liveDate = useRealtimeMinuteSynced();
  const date = props?.date ?? liveDate;

  return (
    <DatePositioned
      date={{
        day: moment(date).day() as PositioningDate["day"],
        hours: moment(date).hours(),
        minutes: moment(date).minutes(),
      }}
    >
      <DayLineComponent _ref={props.dayLineRef} dayWidth={dayWidth ?? 0} />
    </DatePositioned>
  );
});
