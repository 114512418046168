import React from "react";
import { EventTypeEnum } from "@hiyllo/omni-common/src/types/calendar/calendar-event";
import { PillRowSelect } from "../../../../ux/pill-select-row";
import { faBlockBrick, faCalendarAlt } from "@fortawesome/pro-light-svg-icons";

export const EventTypeSelect = React.memo(function EventTypeSelect(props: {
  eventType: EventTypeEnum;
  onEventTypeChanged: (eventType: EventTypeEnum) => void;
}): JSX.Element {
  return (
    <PillRowSelect<EventTypeEnum>
      value={props.eventType}
      onChange={props.onEventTypeChanged}
      options={[
        {
          value: EventTypeEnum.event,
          label: "Event",
          icon: faCalendarAlt,
        },
        {
          value: EventTypeEnum.busy,
          label: "Busy Block",
          color: "#c62828",
          icon: faBlockBrick,
        },
      ]}
    />
  );
});
