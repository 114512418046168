import React from "react";
import { useSelfMaybe } from "@hiyllo/omni-continuity";
import { seamlessClient } from "../seamless-client";
import { Tenant } from "../platform/tenancy";
import { AlertsProvider } from "@hiyllo/omni-alerts";

export const WrappedAlertsProvider = React.memo(function WrappedAlertsProvider(
  props: React.PropsWithChildren,
): JSX.Element {
  console.debug("<WrappedAlertsProvider>");
  const self = useSelfMaybe();

  if (self == null) {
    return <>{props.children}</>;
  }

  return (
    <AlertsProvider Tenant={Tenant} client={seamlessClient} self={self}>
      {props.children}
    </AlertsProvider>
  );
});
