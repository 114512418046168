/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { HashedString } from '../types/cryptography/hashed-string';

export async function hashString (plaintext: string): Promise<HashedString> {
  return {
    digest: Array.from(new Uint8Array(await crypto.subtle.digest('SHA-256', (new TextEncoder()).encode(plaintext)))).map(b => b.toString(16).padStart(2, '0')).join(''),
    algorithm: 'sha-256'
  };
}
