import {
  faAlarmClock,
  faCarSide,
  faChartGantt,
  faChartKanban,
  faLink,
  faLocationArrow,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CentralTickerProvider, useTime } from "@hiyllo/omni-dynamicui/main";
import { Button } from "@hiyllo/ux/button";
import { styled } from "@hiyllo/ux/styled";
import { useTheme } from "@hiyllo/ux/theme";
import { Typography } from "@hiyllo/ux/typography";
import moment from "moment";
import React from "react";
import { MeetingTimer } from "../home/views/components/meeting-timer";

const Jumbotron = styled("div", ({ $theme }) => ({
  height: "90vh",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
  background: "#090909",
}));

const GradientText = styled("span" as "div", ({ $theme }) => ({
  background: $theme.buttonBackground,
  backgroundClip: "text",
  color: "transparent",
  WebkitBackgroundClip: "text",
  fontWeight: "bold",
}));

const WhiteClipText = styled("span" as "div", ({ $theme }) => ({
  background: "transparent",
  backgroundClip: "text",
  color: "transparent",
  WebkitBackgroundClip: "text",
  fontWeight: "bold",
}));

const MockHome = React.memo(function MockHome(): JSX.Element {
  const theme = useTheme();
  const time = useTime();

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "row",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          width: 50,
          flexShrink: 0,
          background: theme.background3,
          height: "100%",
          overflow: "hidden",
        }}
      />
      <div style={{ overflow: "hidden", position: "relative" }}>
        <video
          src="https://cdn.hiyllo.net/dynamic-ui/cyberpunk/lostsoulsclub.mp4"
          style={{
            height: "100%",
            width: "100%",
            objectFit: "cover",
            overflow: "hidden",
            borderBottomRightRadius: "12%",
          }}
          autoPlay
          loop
          muted
        />
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div style={{ maxWidth: "80%" }}>
            <div
              style={{
                backgroundColor: "black",
                borderRadius: 5,
                background: "black",
                color: "white",
                fontSize: 10,
                padding: 4,
                display: "inline-block",
              }}
            >
              {moment().format("dddd, MMMM Do")}
            </div>
            <div style={{ fontSize: 70, color: "black", lineHeight: "0.9em" }}>
              {time.time}
              <span style={{ fontSize: 20 }}>{time.prefix}</span>
            </div>
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "stretch",
                }}
              >
                <div
                  style={{
                    width: 2,
                    backgroundColor: "black",
                    letterSpacing: 0.5,
                  }}
                />
                <div
                  style={{
                    width: 0,
                    flexGrow: 1,
                    padding: 5,
                    paddingTop: 2.5,
                    paddingBottom: 2.5,
                    backdropFilter: "blur(5px)",
                    color: "black",
                  }}
                >
                  <div style={{ fontSize: 15 }}>Your next event is:</div>
                  <div
                    style={{
                      fontSize: 20,
                      fontWeight: "bold",
                      lineHeight: "0.95em",
                    }}
                  >
                    Coffee with Taylor
                  </div>
                  <div style={{ fontSize: 15 }}>
                    in{" "}
                    <b>
                      <MeetingTimer
                        startDate={moment().add(1, "hour").toDate()}
                      />
                    </b>{" "}
                    at <b>{moment().add(1, "hour").format("h:mm a")}</b>
                  </div>
                </div>
              </div>
              <div
                style={{
                  backgroundColor: "black",
                  color: "white",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  padding: 5,
                  gap: 5,
                }}
              >
                <div style={{ fontSize: 15 }}>
                  <FontAwesomeIcon icon={faCarSide} />
                </div>
                <div style={{ fontSize: 11 }}>
                  It will take <b>15 minutes</b> to get there, leave in{" "}
                  <b>
                    <MeetingTimer
                      startDate={moment().add(45, "minutes").toDate()}
                    />
                  </b>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export const SoloLanding = React.memo(function SoloLanding(): JSX.Element {
  const theme = useTheme();
  const isMobile = window.innerWidth < window.innerHeight;

  return (
    <div style={{ fontFamily: "hiyllo" }}>
      <Jumbotron>
        <div style={{ position: "relative" }}>
          <img
            src="/iphone-frame.png"
            style={{
              objectFit: "contain",
              borderRadius: "13%",
              boxShadow: "rgba(0, 0, 0, 0.75) 0px 0px 20px 20px",
              zIndex: 2,
              position: "absolute",
              height: "calc(100% + 10px)",
              left: -10,
              top: -6,
            }}
          />
          <CentralTickerProvider>
            <div
              style={{
                width: 250,
                height: 540,
                zIndex: 0,
                borderRadius: "12%",
                overflow: "hidden",
              }}
            >
              <MockHome />
            </div>
          </CentralTickerProvider>
        </div>
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            height: "100%",
            width: "100%",
            zIndex: 1,
            display: "flex",
            flexDirection: "row",
          }}
        >
          {!isMobile ? (
            <>
              <div
                style={{
                  width: "30%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                  justifyContent: "center",
                  flexGrow: 1,
                }}
              >
                <Typography.Header>
                  Life is complicated enough.
                </Typography.Header>
                <Typography.SubHeader>
                  <GradientText>Solo</GradientText> makes things simple. Now in{" "}
                  <b>Public Beta</b>
                </Typography.SubHeader>
              </div>
              <div style={{ width: 400, flexShrink: 0, flexGrow: 0 }}></div>
              <div
                style={{
                  flexGrow: 1,
                  width: "30%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "center",
                  gap: 5,
                }}
              >
                <Typography.SubHeader>
                  Managing your schedule?
                </Typography.SubHeader>
                <Typography.SubHeader>
                  Keeping your reminders?
                </Typography.SubHeader>
                <Typography.SubHeader>
                  Tracking your tasks?
                </Typography.SubHeader>
                <Typography.SubHeader style={{ fontWeight: "bold" }}>
                  ✅ Sorted.
                </Typography.SubHeader>
              </div>
            </>
          ) : null}
        </div>
        <img
          src="https://cdn.hiyllo.net/logo/solo/gradient.png"
          style={{
            width: 100,
            position: "absolute",
            [isMobile ? "top" : "bottom"]: 40,
            left: 40,
          }}
        />
        <div
          style={{
            position: "absolute",
            bottom: 40,
            right: 40,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 10,
            zIndex: 2,
          }}
        >
          <Button
            onClick={() => {
              window.location.href =
                "https://apps.apple.com/us/app/hiyllo-solo/id6476942936";
            }}
            label="Download for iOS"
            isSecondary
          />
          {!isMobile ? (
            <Button
              onClick={() => {
                window.location.href = "/login";
              }}
              label="Login / Sign Up"
            />
          ) : null}
        </div>
      </Jumbotron>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          alignItems: "stretch",
        }}
      >
        <div
          style={{
            width: "50%",
            minWidth: Math.min(window.innerWidth, 500),
            background:
              "linear-gradient(to right, rgb(210 73 255), rgb(30 120 255))",
          }}
        >
          <div style={{ padding: 40 }}>
            <Typography.Header>Time Management</Typography.Header>
            <Typography.SubHeader>
              Solo is a calendar designed for people who don&apos;t want to
              stare at their calendar all day.
            </Typography.SubHeader>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 10,
                marginTop: 10,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 20,
                }}
              >
                <Typography.Header>
                  <FontAwesomeIcon icon={faLink} fixedWidth />
                </Typography.Header>
                <div style={{ fontSize: 20 }}>
                  Solo syncs to all your calendars (Google, Outlook/Teams, and
                  Hiyllo Work), so you can see all your events in one place.
                  When an event is added in one place, Solo creates a busy block
                  everywhere else.
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 20,
                }}
              >
                <Typography.Header>
                  <FontAwesomeIcon icon={faAlarmClock} fixedWidth />
                </Typography.Header>
                <div style={{ fontSize: 20 }}>
                  Solo keeps things simple. Open Solo to see a simple heads-up
                  view of your day.
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 20,
                }}
              >
                <Typography.Header>
                  <FontAwesomeIcon icon={faLocationArrow} fixedWidth />
                </Typography.Header>
                <div style={{ fontSize: 20 }}>
                  With <b>Solo+</b>, Solo will predict what time you need to
                  leave for events, display it in your heads up view, and
                  proactively alert you. Unexpected road trip? Solo can monitor
                  your location in real time, and alert you if you need to leave
                  earlier.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            width: "50%",
            minWidth: Math.min(window.innerWidth, 500),
            background: theme.background3,
          }}
        >
          <div style={{ padding: 40 }}>
            <Typography.Header>Task Management</Typography.Header>
            <Typography.SubHeader>
              Solo drops the gimmicks and focuses on what matters: your work.
            </Typography.SubHeader>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 10,
                marginTop: 10,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 20,
                }}
              >
                <Typography.Header>
                  <FontAwesomeIcon icon={faChartKanban} fixedWidth />
                </Typography.Header>
                <div style={{ fontSize: 20 }}>
                  Use a simple to-do list or a kanban board to manage your
                  tasks. Whichever works best for you.
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 20,
                }}
              >
                <Typography.Header>
                  <FontAwesomeIcon icon={faAlarmClock} fixedWidth />
                </Typography.Header>
                <div style={{ fontSize: 20 }}>
                  You can have Solo remind you about tasks at a specific time,
                  or schedule due dates which Solo will proactively remind you
                  about.
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 20,
                }}
              >
                <Typography.Header>
                  <FontAwesomeIcon icon={faLocationArrow} fixedWidth />
                </Typography.Header>
                <div style={{ fontSize: 20 }}>
                  With location tracking enabled, Solo can remind you about your
                  work tasks when you arrive at the office, and your personal
                  errands when you get home.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
