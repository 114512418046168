import React from "react";
import { Card } from "@hiyllo/ux/surface";
import { styled } from "@hiyllo/ux/styled";
import { ProjectSelect, SprintSelect, TaskPillsRow, useCreateTask, useGetMySprints } from "@hiyllo/omni-tasks";
import { RouterCtxProvider, useSelf } from "@hiyllo/omni-continuity";
import { RouterContext, useDeriveURL, useNavigate, usePath } from "@hiyllo/omni-router";
import { Input } from "@hiyllo/ux/input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { type MoopsyError } from "@moopsyjs/core";
import { CheckboxInput } from "@hiyllo/ux/checkbox-input";
import { useShowConfirmationDialog, useShowDialog } from "@hiyllo/ux/dialogs";
import { AnimateChangeInHeight } from "@hiyllo/ux/animation";
import { Button } from "@hiyllo/ux/button";

import { Textarea } from "../../../ux/alpha/input";
import { StatusPill, AssigneePill, DueDatePill } from "@hiyllo/omni-tasks";
import {
  type TaskStatusType,
  type PriorityType,
  DEFAULT_STATUSES,
  type TaskSizingType,
  type TaskDueDateType,
} from "../../../types/tasks/task-item";
import { SizingPill } from "../components/sizing-pill";
import { Features } from "../../../types/navigation/features";
import { isCommandKeyPressed } from "../../../platform/web/is-command-key-pressed";
import { PriorityColorMap } from "../maps/priority-colors-map";
import { PriorityIconsMap } from "../maps/priority-icons-map";
import { ErrorText } from "../../../ux/error";
import { useConfig } from "../../../platform/config/config-context";
import { useIsSolo } from "../../../platform/hooks/use-is-solo";
import { Priorites, usePriorityLabels } from "../constants/priorities";
import { TaskCardQueryWrapper } from "../components/task-card";

const Header = styled("div", {
  fontSize: 24,
  lineHeight: "1.25em",
});

const Spacer = styled("div", { height: 15 });

const StyledTextArea = styled(Textarea, ({ $theme }) => ({
  width: "calc(100% - 30px)",
  padding: 15,
  minHeight: "1.25em",
  border: "none",
  fontSize: 18,
  borderRadius: 10,
  background: $theme.midground1
}));

const Label = styled("div", {
  fontSize: 12,
  marginBottom: 5,
});

const PriorityRowSelectRow = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 10,
});

const PriorityRowPill = styled<"div", { color: string; active: boolean }>(
  "div",
  ({ color, active }) => ({
    padding: 5,
    paddingLeft: 5,
    paddingRight: 7.5,
    color: active ? "white" : color,
    backgroundColor: active ? color : "transparent",
    borderColor: color,
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: 20,
    fontSize: 12,
    gap: 2.5,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    cursor: "pointer",
  }),
);

const PriorityRowSelect = React.memo(function PriorityRowSelect(props: {
  priority: PriorityType;
  onChangePriority: (priority: PriorityType) => void;
}): JSX.Element {
  const pcm = PriorityColorMap();
  const labels = usePriorityLabels();

  return (
    <PriorityRowSelectRow>
      {Priorites.map((p) => (
        <PriorityRowPill
          key={p}
          color={pcm[p]}
          active={props.priority === p}
          onClick={() => props.onChangePriority(p)}
        >
          <FontAwesomeIcon icon={PriorityIconsMap[p]} fixedWidth />
          {labels[p]}
        </PriorityRowPill>
      ))}
    </PriorityRowSelectRow>
  );
});

interface PropsType {
  projectUUID: string | null;
  sprintUUID: string | null;
  title?: string | null;
  description?: string | null;
  noRedirect?: boolean;
  onTaskCreated?: ((taskUUID: string) => void) | null;
}

const CreateNewTaskViewInner = React.memo(function CreateNewTaskViewInner(
  props: PropsType,
): JSX.Element {
  const [projectUUID, setProjectUUID] = React.useState<string | null>(
    props.projectUUID,
  );
  const [sprintUUID, setSprintUUID] = React.useState<string | null>(
    props.sprintUUID,
  );
  const self = useSelf();
  const titleInputRef = React.useRef<HTMLInputElement>(null);
  const descriptionInputRef = React.useRef<HTMLInputElement>(null);
  const [priority, setPriority] = React.useState<PriorityType>(0);
  const [status, setStatus] = React.useState<TaskStatusType>(
    DEFAULT_STATUSES[0],
  );
  const [assigneeUserId, setAssignee] = React.useState<string | null>(
    self.userId,
  );
  const [dueDate, setDueDate] = React.useState<TaskDueDateType | null>(null);
  const [sizing, setSizing] = React.useState<TaskSizingType | null>(null);
  const [createAnotherTask, setCreateAnotherTask] =
    React.useState<boolean>(false);
  const navigate = useNavigate();
  const sprintsQuery = useGetMySprints(null);
  const [success, setSuccess] = React.useState<boolean>(false);
  const showConfirmDialog = useShowConfirmationDialog();

  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

  React.useEffect(() => {
    setSuccess(false);
  }, [priority, dueDate, sizing, projectUUID, sprintUUID, status, assigneeUserId]);

  React.useEffect(() => {
    setProjectUUID(props.projectUUID);
  }, [props.projectUUID]);

  const showDialog = useShowDialog();
  const path = usePath();
  const deriveURL = useDeriveURL();
  const { createTask: doCreateTask, isLoading } = useCreateTask({
    onSimilarTaskWarning: async (similarTask) => {
      const value = await new Promise<boolean>((resolve) => {
        showDialog({
          title: "Similar Task",
          message: `A similar task (${similarTask.title}) already exists. Do you want to create another task?`,
          onCancel: () => resolve(false),
          extras: (
            <RouterContext.Provider
              value={{
                path,
                navigate,
                deriveURL,
              }}
            >
              <div style={{ boxShadow: "rgba(0,0,0,0.25) 0px 0px 20px 20px", borderRadius: 15, marginTop: 10, marginBottom: 10 }}>
                <TaskCardQueryWrapper
                  taskUUID={similarTask.uuid}
                />
              </div>
            </RouterContext.Provider>
          ),
          buttons: [
            {
              type: 'primary',
              label: "Continue Anyway",
              onClick: () => {
                resolve(true);
              },
            },
            {
              type: 'secondary',
              label: "Cancel",
              onClick: () => {
                resolve(false);
              },
            },
          ]
        });
      });

      return value;
    },
    onErrorMessage: setErrorMessage,
  });

  const createTask = React.useCallback(
    (createAnother?: boolean) => {
      if (
        titleInputRef.current == null ||
        descriptionInputRef.current == null
      ) {
        return;
      }

      if (projectUUID == null) {
        setErrorMessage("Please select a project");
        return;
      }

      const title = titleInputRef.current.value;
      const description = descriptionInputRef.current.value;

      void doCreateTask({
        title,
        description,
        status,
        priority,
        dueDate,
        sizing,
        assigneeUserId,
        projectUUID,
        parentUUID: null,
        sprintUUID,
      })
        .then(async (res) => {
          if (titleInputRef.current) titleInputRef.current.value = "";
          if (descriptionInputRef.current)
            descriptionInputRef.current.value = "";

          if (props.onTaskCreated != null && res?.taskUUID != null) {
            return props.onTaskCreated(res.taskUUID);
          }

          if (res != null) {
            if (
              !createAnotherTask &&
              createAnother !== true &&
              props.noRedirect !== true
            ) {
              if (sprintUUID != null) {
                const sprint = sprintsQuery.data?.sprints.find(
                  (s) => s.uuid === sprintUUID,
                );
                const teamUUID = sprint?.teamUUID;

                if (teamUUID != null) {
                  navigate({
                    feature: Features.tasks,
                    params: {
                      view: "sprint-planning",
                      sprintUUID,
                      teamUUID,
                    },
                  });

                  return;
                }
              }

              navigate({
                feature: Features.tasks,
                params: {
                  view: "project",
                  projectUUID,
                },
              });
            } else {
              setSuccess(true);
            }
          }
        })
        .catch((err: MoopsyError) => {
          setErrorMessage(err.description);
        });
    },
    [assigneeUserId, createAnotherTask, doCreateTask, dueDate, navigate, priority, projectUUID, props, sizing, sprintUUID, sprintsQuery.data?.sprints, status],
  );

  const onKeyDown = React.useCallback(
    (evt: React.KeyboardEvent<HTMLInputElement | HTMLDivElement>) => {
      setSuccess(false);
      if (isCommandKeyPressed(evt) && evt.key === "Enter") {
        createTask(evt.shiftKey);
      }
    },
    [createTask],
  );

  const config = useConfig();
  const isSolo = useIsSolo();

  return (
    <>
      <Card color="background3">
        <Header>Create Task</Header>
        <Spacer />
        <TaskPillsRow>
          <PriorityRowSelect
            priority={priority}
            onChangePriority={setPriority}
          />
          <StatusPill status={status} onChangeStatus={setStatus} />
          <DueDatePill dueDate={dueDate} onChangeDueDate={setDueDate} />
          {!isSolo ? (
            <>
              <SizingPill sizing={sizing} onChangeSizing={setSizing} />
              <AssigneePill
                assignee={assigneeUserId}
                onChangeAssignee={setAssignee}
              />
            </>
          ) : null}
        </TaskPillsRow>
        <Spacer />
        <div>
          <Input
            onKeyDown={onKeyDown}
            type="text"
            placeholder="What needs to be done?"
            ref={titleInputRef}
            fullWidth
            multiline
            containerStyle={{
              padding: 5,
              fontSize: 18,
            }}
            defaultValue={props.title ?? ""}
          />
        </div>
        <Spacer />
        <div>
          <StyledTextArea
            onKeyDown={onKeyDown}
            type="text"
            placeholder="Optionally, add a description..."
            inputRef={descriptionInputRef}
            fullWidth
            style={{ minHeight: "5em" }}
            defaultValue={props.description ?? ""}
          />
        </div>
        <TaskPillsRow>
          <div
            style={{
              flexGrow: 1,
              flexShrink: 0,
              flexBasis: "calc(50% - 10px)",
            }}
          >
            <Label>Project</Label>
            <ProjectSelect
              projectUUID={projectUUID}
              setProjectUUID={setProjectUUID}
              disallowNullSelection={isSolo}
            />
          </div>
          {!isSolo ? (
            <>
              <div
                style={{
                  flexGrow: 1,
                  flexShrink: 0,
                  flexBasis: "calc(50% - 10px)",
                }}
              >
                <Label>Sprint</Label>
                <SprintSelect
                  value={sprintUUID ?? null}
                  onChangeValue={(option) => setSprintUUID(option as string)}
                />
              </div>
            </>
          ) : null}
        </TaskPillsRow>
        <Spacer />
        {config.restricted !== true ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 10,
            }}
          >
            <Button
              label="Create Task"
              isLoading={isLoading}
              onClick={() => createTask()}
              success={success}
              successMessage="Created!"
            />
            <CheckboxInput
              value={createAnotherTask}
              onChange={setCreateAnotherTask}
              label={"Create Another Task"}
            />
          </div>
        ) : (
          <div>Please fix your billing issues to create new tasks</div>
        )}
        <AnimateChangeInHeight>
          {errorMessage != null ? <div style={{ height: 15 }} /> : null}
          <ErrorText message={errorMessage} />
        </AnimateChangeInHeight>
      </Card>
    </>
  );
});

export const CreateNewTaskView = React.memo(function CreateNewTaskView(
  props: PropsType,
): JSX.Element {
  return <CreateNewTaskViewInner {...props} />;
});
