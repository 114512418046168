import { faBell, faBellSlash } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OptionType, Select } from "@hiyllo/ux/select";
import { styled } from "@hiyllo/ux/styled";
import { useTheme } from "@hiyllo/ux/theme";
import { motion } from "framer-motion";
import React from "react";
import { useMuted, useSetMuted } from "../../../../main/mute-provider";

const OuterContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const Container = styled<"div", React.ComponentProps<typeof motion.div>>(motion.div, ({ $theme }) => ({
  height: 40,
  width: "100%",
  background: $theme.midground1,
  borderRadius: 10,
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  userSelect: "none",
  flexShrink: 0
}));

const MuteControls = styled("div", ({ $theme }) => ({
  width: 0,
  flexGrow: 1,
  // background: $theme.midground1,
  height: 40,
  borderRadius: 10,
  overflow: "hidden",
}));

const MUTE_SELECT_OPTIONS: OptionType[] = [
  {
    value: "indefinite",
    label: "Muted until I unmute",
  }
];

export const MuteController = React.memo(function MuteController(): JSX.Element {
  const $theme = useTheme();
  const muted = useMuted();
  const setMuted = useSetMuted();

  const containerStyle = React.useMemo(() => (
    muted ? { width: 40, backgroundColor: "#d32f2f" } : { width: "100%", backgroundColor: $theme.midground1 }
  ), [muted]);

  return (
    <OuterContainer>
      <Container animate={containerStyle} onClick={() => setMuted(v => !v)}>
        <FontAwesomeIcon icon={muted ? faBellSlash : faBell} />
      </Container>
      <motion.div animate={{ width: muted ? 10 : 0, flexShrink: 0 }} />
      <MuteControls>
        <motion.div
          animate={{
            translateX: muted ? 0 : 190,
          }}
          transition={{
            ease: 'easeInOut'
          }}
          style={{
            width: 190,
            height: 40,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            fontFamily: "hiyllo",
          }}
        >
          <div style={{ paddingLeft: 5 }}>
            {/* <div><b>Quiet Time</b></div> */}
            <div style={{ fontSize: 15 }}>Push alerts paused on this device</div>
          </div>
          {/* <Select
            options={MUTE_SELECT_OPTIONS}
            value={muteSelection}
            onChangeValue={v => setMuteSelection(v as string)}
            fullWidth
          /> */}
        </motion.div>
      </MuteControls>
    </OuterContainer>
  );
});