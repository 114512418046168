export type FormatType = Record<string, unknown | string | number>;

export interface EditorFormatType {
  value: number;
  label: string;
}

export const Formats: EditorFormatType[] = [
  {
    value: 12.5,
    label: "12.5pt",
  },
  {
    value: 15,
    label: "15pt",
  },
  {
    value: 20,
    label: "20pt",
  },
  {
    value: 25,
    label: "25pt",
  }
];