/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { ContactType } from "../../types/contacts/contact";

export type ParamsType = null;
export type ResponseType = { contacts: ContactType[] };
export const Endpoint = "contacts/list-contacts";
export const Method = "GET";

export interface Plug {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
}

export const RateLimitingConfig = {
  calls: 2,
  per: 1000,
};
export const paramsSchema = {"type":"null","$schema":"http://json-schema.org/draft-07/schema#"}