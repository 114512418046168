/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
export type HashedString = {digest: string, algorithm: string};

export const HashedStringSchema = {
    type: "object",
    properties: {
        digest: { type: "string" },
        algorithm: { type: "string" },
    },
    required:[ "digest", "algorithm" ],
    additionalProperties: false,
}