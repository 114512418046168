import React from "react";
import { useIsSolo } from "../../platform/hooks/use-is-solo";

export const FeedbackFeature = React.memo(
  function FeedbackFeature(): JSX.Element {
    const isSolo = useIsSolo();

    return (
      <iframe
        style={{
          border: "none",
          outline: "none",
          height: "100%",
          width: "100%",
        }}
        src={
          isSolo
            ? "https://www.hiyllo.team/form/18c86aed-5b78-4d8a-af43-3fd86ff54cb3"
            : "https://www.hiyllo.team/form/c267f829-cb6c-4d25-86d6-a94908682b96"
        }
      />
    );
  },
);
