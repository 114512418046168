import React from "react";
import * as StartSamlSSOLoginBP from "../../../blueprints/accounts/login/start-hiyllo-auth-login";
import { seamlessClient } from "../../../seamless-client";
import { LoadingPage } from "@hiyllo/ux/standard-loader";

export const InitiateSSOLoginView = (props: { email: string }): JSX.Element => {
  const contextQuery =
    seamlessClient.useQuery<StartSamlSSOLoginBP.Plug>(
      StartSamlSSOLoginBP,
      null,
    );

  React.useEffect(() => {
    if (contextQuery.data?.context) {
      let path =
        contextQuery.data.context + "&returnRedirect=" + window.location.href;

      if (props.email != null && props.email !== "") {
        path += "&em=" + props.email;
      }

      window.location.href = path;
    }
  }, [contextQuery.data?.context, props.email]);

  return <LoadingPage />;
};
