import * as React from "react";
// import { useMaybeRoomContext } from '../../context';
// import { mergeProps } from '../../utils';
import type { LocalAudioTrack, LocalVideoTrack } from "livekit-client";
import {
  useMaybeRoomContext,
  useMediaDeviceSelect,
} from "@livekit/components-react";
import { Select } from "@hiyllo/ux/select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faExclamationTriangle,
  faMicrophone,
  faVideoCamera,
} from "@fortawesome/pro-light-svg-icons";
import { styled } from "@hiyllo/ux/styled";
import { CheckboxInput } from "@hiyllo/ux/checkbox-input";
import { WarningCard } from "../../../ux/warning-card";
// import { useMediaDeviceSelect } from '../../hooks';

const Container = styled("div", ({ $theme }) => ({
  background: $theme.background2,
  padding: 15,
  borderRadius: 20,
  display: "flex",
  flexDirection: "column",
  gap: 10,
}));

const Option = styled<"div", { active?: boolean }>(
  "div",
  ({ $theme, active }) => ({
    background: active ? $theme.background2 : $theme.background3,
    padding: 10,
    borderRadius: 10,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    cursor: "pointer",
    whiteSpace: "nowrap",
    gap: 10,
  }),
);

/** @public */
export interface MediaDeviceSelectProps
  extends React.HTMLAttributes<HTMLUListElement> {
  kind: MediaDeviceKind;
  onActiveDeviceChange?: (deviceId: string) => void;
  onDeviceListChange?: (devices: MediaDeviceInfo[]) => void;
  onDeviceSelectError?: (e: Error) => void;
  initialSelection?: string;
  /** will force the browser to only return the specified device
   * will call `onDeviceSelectError` with the error in case this fails
   */
  exactMatch?: boolean;
  track?: LocalAudioTrack | LocalVideoTrack;
  /**
   * this will call getUserMedia if the permissions are not yet given to enumerate the devices with device labels.
   * in some browsers multiple calls to getUserMedia result in multiple permission prompts.
   * It's generally advised only flip this to true, once a (preview) track has been acquired successfully with the
   * appropriate permissions.
   *
   * @see {@link MediaDeviceMenu}
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/enumerateDevices | MDN enumerateDevices}
   */
  requestPermissions?: boolean;
  onEnabledChange: (enabled: boolean) => void;
  enabled: boolean;
}

/**
 * The MediaDeviceSelect list all media devices of one kind.
 * Clicking on one of the listed devices make it the active media device.
 *
 * @example
 * ```tsx
 * <LiveKitRoom>
 *   <MediaDeviceSelect kind='audioinput' />
 * </LiveKitRoom>
 * ```
 * @public
 */
export function MediaDeviceSelect({
  kind,
  initialSelection,
  onActiveDeviceChange,
  onDeviceListChange,
  onDeviceSelectError,
  exactMatch,
  track,
  requestPermissions,
  ...props
}: MediaDeviceSelectProps): JSX.Element {
  const room = useMaybeRoomContext();
  const { devices, activeDeviceId, setActiveMediaDevice, className } =
    useMediaDeviceSelect({
      kind,
      room,
      track,
      requestPermissions,
    });
  React.useEffect(() => {
    if (initialSelection !== undefined) {
      void setActiveMediaDevice(initialSelection);
    }
  }, [setActiveMediaDevice]);

  React.useEffect(() => {
    if (typeof onDeviceListChange === "function") {
      onDeviceListChange(devices);
    }
  }, [onDeviceListChange, devices]);

  React.useEffect(() => {
    if (activeDeviceId && activeDeviceId !== "") {
      onActiveDeviceChange?.(activeDeviceId);
    }
  }, [activeDeviceId]);

  const handleActiveDeviceChange = async (deviceId: string): Promise<void> => {
    try {
      await setActiveMediaDevice(deviceId, { exact: exactMatch });
    } catch (e) {
      console.error("87", e);
      if (e instanceof Error) {
        onDeviceSelectError?.(e);
      } else {
        throw e;
      }
    }
  };

  return (
    <Container>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 10,
        }}
      >
        {kind === "videoinput" ? (
          <>
            <FontAwesomeIcon icon={faVideoCamera} />
            Camera
          </>
        ) : (
          <>
            <FontAwesomeIcon icon={faMicrophone} />
            Microphone
          </>
        )}
      </div>
      {devices.map((device) => (
        <Option
          onClick={() => handleActiveDeviceChange(device.deviceId)}
          key={device.deviceId}
          active={activeDeviceId === device.deviceId}
        >
          {activeDeviceId === device.deviceId ? (
            <FontAwesomeIcon icon={faCheck} />
          ) : null}
          {device.label}
        </Option>
      ))}
      {devices.length === 0 ? (
        <WarningCard>
          <FontAwesomeIcon icon={faExclamationTriangle} />
          <div>
            No {kind === "videoinput" ? "video" : "audio"} input devices found
            detected.
          </div>
        </WarningCard>
      ) : null}
      <CheckboxInput
        label={
          kind === "videoinput"
            ? "Join with Camera Off"
            : "Join with Microphone Muted"
        }
        value={!props.enabled}
        onChange={(v) => props.onEnabledChange(!v)}
      />
    </Container>
  );
}
