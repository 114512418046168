/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { TaskSizingType } from "../../types/tasks/task-item";

export type ParamsType = {
  sizing: TaskSizingType | null,
  taskUUID: string
};
export type ResponseType = void;
export const Endpoint = "tasks/update-task-sizing";
export const Method = "POST";

export type Plug = {
  params: ParamsType,
  response: ResponseType,
  method: typeof Method,
  endpoint: typeof Endpoint,
};

export const RateLimitingConfig = {
  calls: 3,
  per: 500,
};export const paramsSchema = {"type":"object","properties":{"sizing":{"anyOf":[{"$ref":"#/definitions/TaskSizingType"},{"type":"null"}]},"taskUUID":{"type":"string"}},"required":["sizing","taskUUID"],"definitions":{"TaskSizingType":{"type":"object","properties":{"value":{"type":"number"},"in":{"enum":["points","work-days","work-hours","work-months","work-weeks"],"type":"string"},"type":{"enum":["automatic","explicit"],"type":"string"}},"required":["in","type","value"]}},"$schema":"http://json-schema.org/draft-07/schema#"}