import React from "react";

import { LoadingSpinnerFullView } from "../../../platform/loading/spinner-loading-full";

import * as BP from "../../../blueprints/integrations/hiyllo-work/link-hiyllo-work";
import { seamlessClient } from "../../../seamless-client";
import { useParams } from "react-router-dom";

export const HiylloWorkOAuthConfirmation = React.memo(
  function HiylloWorkOAuthConfirmation(): JSX.Element {
    const token = useParams<{ token: string }>().token as string;
    const linkMutation = seamlessClient.useMutation<BP.Plug>(BP);

    React.useEffect(() => {
      if (!linkMutation.isLoading) {
        void linkMutation
          .call({
            token,
            host: new window.URLSearchParams(window.location.search).get(
              "host",
            )!,
          })
          .then(() => {
            window.location.href =
              window.innerHeight > window.innerWidth
                ? "/settings/integrations"
                : `/v/profile?view="integrations"`;
          });
      }
    }, []);

    return <LoadingSpinnerFullView />;
  },
);
