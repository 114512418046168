/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { TaskActivityType } from "../../types/tasks/task-item";

export type ParamsType = {
  taskUUID: string;
};
export type ResponseType = {
  activity: TaskActivityType[];
};
export const Endpoint = "tasks/get-task-activity";
export const Method = "GET";

export type Plug = {
  params: ParamsType,
  response: ResponseType,
  method: typeof Method,
  endpoint: typeof Endpoint,
};

export const RateLimitingConfig = {
  calls: 3,
  per: 1000,
};export const paramsSchema = {"type":"object","properties":{"taskUUID":{"type":"string"}},"required":["taskUUID"],"$schema":"http://json-schema.org/draft-07/schema#"}