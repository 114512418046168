import React from "react";

import { faCircleExclamation } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@hiyllo/ux/button";
import { styled } from "@hiyllo/ux/styled";
import { Card } from "@hiyllo/ux/surface";

import * as UnarchiveProjectBP from "../../../blueprints/tasks/unarchive-project";
import { seamlessClient } from "../../../seamless-client";

const Row = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
});

export const ProjectArchivedBanner = React.memo(
  function ProjectArchivedBanner(props: { projectUUID: string }): JSX.Element {
    const unarchiveMutation =
      seamlessClient.useMutation<UnarchiveProjectBP.Plug>(
        UnarchiveProjectBP,
      );

    const unarchive = React.useCallback(() => {
      void unarchiveMutation
        .call({
          projectUUID: props.projectUUID,
        })
        .then(() => {
          window.location.reload();
        });
    }, [props.projectUUID, unarchiveMutation]);

    return (
      <Card color="background3">
        <Row>
          <div>
            <FontAwesomeIcon icon={faCircleExclamation} />
            &nbsp;&nbsp;This project is archived
          </div>
          <Button
            label="Restore Project"
            onClick={unarchive}
            isLoading={unarchiveMutation.isLoading}
          />
        </Row>
      </Card>
    );
  },
);
