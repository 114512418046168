export const dayToRowPosition = {
  0: 6,
  1: 0,
  2: 1,
  3: 2,
  4: 3,
  5: 4,
  6: 5,
} as const;

export const HOURS = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
  22, 23,
] as const;

export interface PositioningDate {
  day: keyof typeof dayToRowPosition;
  hours: number;
  minutes: number;
}
