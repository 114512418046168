/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import {
  BetaFeaturesEnum,
  UserDeletedType,
} from "../../../types/accounts/user";

export interface AdminUserListItem {
  email: string;
  id: string;
  ips: string[];
  username: string;
  deleted: UserDeletedType | null;
  enabledBetaFeatures: BetaFeaturesEnum[];
  lastLogin: Date | null;
}

export type ParamsType = null;
export type ResponseType = {
  users: AdminUserListItem[];
};
export const Endpoint = "admin/user-management/list-users-admin";
export const Method = "GET";
export const isPublic = false;
export interface Plug {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
}
export const paramsSchema = {"type":"null","$schema":"http://json-schema.org/draft-07/schema#"}