import EJSON from "ejson";
import { useParams } from "react-router-dom";
import { useDefaultEData } from "../contexts/default-edata-context";
import { type EDataType } from "@hiyllo/omni-common/src/types/navigation/edata";
import { DEFAULT_EDATA } from "@hiyllo/omni-router";
import React from "react";
import { isDesktopApp } from "../../../../platform/environment/get-root-url";
import { RouterContext } from "@hiyllo/omni-router";
import { Electron } from "../../../../platform/electron";

/**
 * Tokyo V1 Router
 * Single Page.
 */

export const RouterProviderV1 = ({
  children,
}: React.PropsWithChildren): JSX.Element => {
  const raw = useParams<{ embpath: string } | { feature: string }>();
  const contextualDefault = useDefaultEData();
  const usp = new window.URLSearchParams(window.location.search);
  const eData: EDataType =
    "embpath" in raw
      ? JSON.parse(decodeURIComponent(raw.embpath as string))
      : "feature" in raw
        ? {
          feature: raw.feature,
          params:
            [...usp].length > 0
              ? Object.fromEntries(
                [...usp].map(([k, v]) => [k, EJSON.parse(v)]),
              )
              : null,
        }
        : contextualDefault ?? DEFAULT_EDATA;

  const [path, setPath] = React.useState<EDataType>(eData);

  const navigate = React.useCallback((data: EDataType) => {
    if (!isDesktopApp()) {
      window.history.pushState(
        null,
        "",
        "/v/" +
        (data.feature as string) +
        "?" +
        (data.params === null
          ? ""
          : new window.URLSearchParams(
            Object.entries(data.params).map(([k, v]) => [
              k,
              EJSON.stringify(v),
            ]),
          )
        ).toString(),
      );
    }
    setPath(data);
  }, []);

  const deriveURL = React.useCallback((data: EDataType): string => {
    return (
      (isDesktopApp()
        ? `https://${Electron.electronHost}`
        : window.location.origin) +
      ("/v/" +
        (data.feature as string) +
        "?" +
        (data.params === null
          ? ""
          : new window.URLSearchParams(
            Object.entries(data.params).map(([k, v]) => [
              k,
              EJSON.stringify(v),
            ]),
          )
        ).toString())
    );
  }, []);

  return (
    <RouterContext.Provider
      value={{
        path,
        navigate,
        deriveURL,
      }}
    >
      {children}
    </RouterContext.Provider>
  );
};
