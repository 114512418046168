import { faExclamationTriangle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Stardate,
  StardateLogKind,
  StardateSourceEnum,
} from "@hiyllo/stardate/main";
import { Typography } from "@hiyllo/ux/typography";
import React from "react";
import { Tenant } from "./tenancy";

interface StateType {
  hasError: boolean;
  errorMessage: null | string;
}

interface PropsType {
  children: React.ReactNode;
  code?: string;
  overrideFallback?: React.ReactNode;
}

export class ErrorBoundary extends React.Component<PropsType, StateType> {
  constructor(props: PropsType) {
    super(props);
    this.state = {
      hasError: false,
      errorMessage: null,
    };
  }

  static getDerivedStateFromError(er: Error): StateType {
    return {
      hasError: true,
      errorMessage: er.message,
    };
  }

  componentDidCatch(er: Error, errorInfo: React.ErrorInfo): void {
    console.error(er, errorInfo);
    const stardate = new Stardate(
      StardateSourceEnum.frontendWeb,
      "omni",
      "error-boundary",
      Tenant,
    );
    if (window.location.hostname !== "localhost") {
      stardate.log({
        kind: StardateLogKind.error,
        message: "React Error boundary caught",
        data: { errorInfo },
        error: er,
      });
    }
    this.setState({ errorMessage: er.message });
  }

  render(): React.ReactNode {
    if (this.state.hasError) {
      if (this.props.overrideFallback) return this.props.overrideFallback;

      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            background: "black",
            color: "white",
            height: "100%",
            width: "100%",
          }}
        >
          <div>
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              style={{ fontSize: 40 }}
            />
          </div>
          <Typography.Header>There was an error</Typography.Header>
          <Typography.SubHeader>
            This part of Hiyllo Work has crashed due to an error. Please reload
            the page, and contact support@hiyllo.io if this issue persists
          </Typography.SubHeader>
          <div>{this.state.errorMessage}</div>
        </div>
      );
    }
    return this.props.children;
  }
}
