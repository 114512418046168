import { styled } from "@hiyllo/ux/styled";
import React from "react";
import { useGetForm } from "../hooks/use-get-form";
import { LoadingSpinnerFullView } from "../../../platform/loading/spinner-loading-full";
import { Input } from "@hiyllo/ux/input";
import { useStatefulRef } from "@hiyllo/ux/use-stateful-ref";
import { type HiylloForm } from "../../../types/forms/form";
import { type UseMoopsyQueryRetValAny } from "@moopsyjs/react";
import { AnimatePresence, motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PillButton } from "@hiyllo/ux/pill-button";
import {
  faAngleRight,
  faBars,
  faCheckCircle,
  faGripDotsVertical,
  faPlusCircle,
  faTimesCircle,
} from "@fortawesome/pro-light-svg-icons";
import _ from "lodash";
import { useEditForm } from "../hooks/use-edit-form";
import { Button } from "@hiyllo/ux/button";
import {
  type HiylloFormSelectFieldStructure,
  type HiylloFormField,
  type HiylloFormFieldStructure,
  HiylloFormFormat,
} from "../../../types/forms/structure";
import { CheckboxInput } from "@hiyllo/ux/checkbox-input";
import { useConfig } from "../../../platform/config/config-context";
import { useMyProjects } from "../../tasks/hooks/use-my-projects";
import { type HiylloFormCreateTaskOutcome } from "../../../types/forms/outcome";
import { Modal as SmartModal } from "@hiyllo/ux/modal";
import { useSelf } from "@hiyllo/omni-continuity";
import { useNavigateTo } from "@hiyllo/omni-router";
import { Features } from "../../../types/navigation/features";
import { Select } from "@hiyllo/ux/select";
import { SimpleDND } from "../../../ux/simple-dnd";
import { type DraggableProvided } from "@hello-pangea/dnd";
import { ProjectSelect } from "@hiyllo/omni-tasks";

const Container = styled("div", {
  padding: 20,
  fontFamily: '"hiyllo", sans-serif',
  width: "calc(100% - 40px)",
  height: "calc(100% - 40px)",
  overflowY: "auto",
});

const ContainerAlt = styled(Container, ({ $theme }) => ({
  background: $theme.background3,
}));

const Row = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 20,
});

const NarrowRow = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 10,
});

const HeaderRow = styled("div", {
  marginBottom: 15,
  height: 40,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 15,
});

const FlexGrower = styled("div", { flexGrow: 1 });

const Header = styled("div", {
  fontSize: 32,
  marginBottom: 10,
  userSelect: "none",
});

const ShareLink = styled("div", ({ $theme }) => ({
  fontSize: 14,
  marginBottom: 10,
  background: $theme.midground,
  padding: 15,
  borderRadius: 10,
}));

const AddFieldButton = React.memo(function AddFieldButton(props: {
  index: number;
  setModifiedForm: (fn: (prev: HiylloForm) => HiylloForm) => void;
}): JSX.Element {
  const onClick = React.useCallback(() => {
    props.setModifiedForm((prev) => {
      const newFields = [...prev.structure.fields];
      newFields.splice(props.index, 0, {
        uuid: crypto.randomUUID(),
        structure: {
          label: "New Field",
          description: null,
          required: true,
          type: "text",
          multiline: false,
          minLength: null,
          maxLength: null,
        },
      });
      return { ...prev, structure: { ...prev.structure, fields: newFields } };
    });
  }, [props]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        gap: 10,
        cursor: "pointer",
        userSelect: "none",
      }}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={faPlusCircle} />
      New Field
    </div>
  );
});

const FieldCard = styled("div", ({ $theme }) => ({
  background: $theme.background3,
  padding: 15,
  borderRadius: 10,
}));

const FIELD_TYPE_OPTIONS = [
  {
    value: "text",
    label: "Text Field",
  },
  {
    value: "select",
    label: "Select",
  },
  {
    value: "file-upload",
    label: "File Upload",
  },
];

const SelectField = React.memo(function Field(props: {
  field: HiylloFormField;
  setModifiedForm: (fn: (prev: HiylloForm) => HiylloForm) => void;
}): JSX.Element {
  const field = props.field;
  if (field.structure.type !== "select") {
    throw new Error("SelectField only supports select fields");
  }

  const onChange = React.useCallback(
    <K extends keyof HiylloFormSelectFieldStructure>(
      key: K,
      value: HiylloFormSelectFieldStructure[K],
    ) => {
      props.setModifiedForm((prev) => {
        const index = prev.structure.fields.findIndex(
          (field) => field.uuid === props.field.uuid,
        );
        const newFields = [...prev.structure.fields];
        const newField = { ...newFields[index] };
        (newField.structure as HiylloFormSelectFieldStructure)[key] = value;
        newFields[index] = newField;
        return { ...prev, structure: { ...prev.structure, fields: newFields } };
      });
    },
    [props],
  );

  const onChangeOption = React.useCallback(
    (index: number, value: string) => {
      onChange("options", [
        ...(field.structure as HiylloFormSelectFieldStructure).options.slice(
          0,
          index,
        ),
        value,
        ...(field.structure as HiylloFormSelectFieldStructure).options.slice(
          index + 1,
        ),
      ]);
    },
    [field.structure, onChange],
  );

  const onAddOption = React.useCallback(() => {
    onChange("options", [
      ...(field.structure as HiylloFormSelectFieldStructure).options,
      "New Option",
    ]);
  }, [onChange, field.structure]);

  const { options } = field.structure;

  return (
    <div
      style={{
        paddingTop: 15,
        display: "flex",
        flexDirection: "column",
        gap: 10,
      }}
    >
      <div>Options</div>
      {options.map((option, index) => (
        <Input
          value={option}
          onChangeValue={(v) => onChangeOption(index, v)}
          key={index}
          fullWidth
        />
      ))}
      <div
        onClick={onAddOption}
        style={{ cursor: "pointer", userSelect: "none" }}
      >
        <FontAwesomeIcon icon={faPlusCircle} />
        &nbsp;Add Option
      </div>
    </div>
  );
});

const Field = React.memo(function Field(props: {
  field: HiylloFormField;
  setModifiedForm: (fn: (prev: HiylloForm) => HiylloForm) => void;
  dhp: DraggableProvided["dragHandleProps"];
}): JSX.Element {
  const onChange = React.useCallback(
    <K extends keyof HiylloFormFieldStructure>(
      key: K,
      value: HiylloFormFieldStructure[K],
    ) => {
      props.setModifiedForm((prev) => {
        const index = prev.structure.fields.findIndex(
          (field) => field.uuid === props.field.uuid,
        );
        const newFields = [...prev.structure.fields];
        const newField = _.cloneDeep(newFields[index]);
        newField.structure[key] = value;
        newFields[index] = newField;
        return { ...prev, structure: { ...prev.structure, fields: newFields } };
      });
    },
    [props],
  );

  const deleteField = React.useCallback(() => {
    props.setModifiedForm((prev) => {
      const newFields = prev.structure.fields.filter(
        (field) => field.uuid !== props.field.uuid,
      );
      return { ...prev, structure: { ...prev.structure, fields: newFields } };
    });
  }, [props]);

  const onChangeLabel = React.useCallback(
    (name: string) => onChange("label", name),
    [onChange],
  );

  const onChangeDescription = React.useCallback(
    (description: string) => onChange("description", description),
    [onChange],
  );

  const onChangeMultiline = React.useCallback(
    // @ts-expect-error zzz
    (v: boolean) => onChange("multiline", v),
    [onChange],
  );

  const onChangeMultiselect = React.useCallback(
    // @ts-expect-error zzz
    (v: boolean) => onChange("multiselect", v),
    [onChange],
  );

  const onChangeType = React.useCallback(
    (type: HiylloFormFieldStructure["type"]) => {
      if (type === "select" && props.field.structure.type !== "select") {
        // @ts-expect-error zzz
        onChange("options", ["New Option"]);
      }
      onChange("type", type);
    },
    [onChange, props.field.structure.type],
  );

  return (
    <FieldCard>
      <div {...props.dhp} style={{ marginBottom: 10, cursor: "pointer" }}>
        <FontAwesomeIcon icon={faGripDotsVertical} />
      </div>
      <Row>
        <NarrowRow style={{ whiteSpace: "nowrap", width: 200 }}>
          <Select
            value={props.field.structure.type}
            options={FIELD_TYPE_OPTIONS}
            onChangeValue={(v) =>
              onChangeType(v as HiylloFormFieldStructure["type"])
            }
            fullWidth
          />
        </NarrowRow>
        <FlexGrower>
          <Input
            value={props.field.structure.label}
            onChangeValue={onChangeLabel}
            fullWidth
          />
        </FlexGrower>
        {props.field.structure.type === "text" ? (
          <CheckboxInput
            label="Multiline"
            value={props.field.structure.multiline}
            onChange={onChangeMultiline}
          />
        ) : null}
        {props.field.structure.type === "select" ? (
          <CheckboxInput
            label="Allow Multiple Selections"
            value={props.field.structure.multiselect}
            onChange={onChangeMultiselect}
          />
        ) : null}
        <FontAwesomeIcon
          onClick={deleteField}
          icon={faTimesCircle}
          style={{ cursor: "pointer" }}
        />
      </Row>
      <div style={{ height: 10 }} />
      <Input
        value={props.field.structure.description ?? ""}
        onChangeValue={onChangeDescription}
        fullWidth
        inputStyle={{ minHeight: "3em" }}
        multiline
        placeholder="Add an optional description..."
      />
      {props.field.structure.type === "select" ? (
        <SelectField
          field={props.field}
          setModifiedForm={props.setModifiedForm}
        />
      ) : null}
    </FieldCard>
  );
});

const EditFormLoaded = React.memo(function EditFormLoaded(props: {
  form: HiylloForm;
  query: UseMoopsyQueryRetValAny;
}): JSX.Element {
  const [isShowingOptions, setIsShowingOptions] = React.useState(false);
  const editFormMutation = useEditForm({ querySideEffects: [props.query] });
  const [modifiedForm, setModifiedForm, modifiedFormRef] =
    useStatefulRef<HiylloForm>(props.form);
  const isModified = React.useMemo(
    () => !_.isEqual(props.form, modifiedForm),
    [props.form, modifiedForm],
  );

  const saveForm = React.useCallback(() => {
    void editFormMutation.call({
      uuid: props.form.uuid,
      changes: modifiedFormRef.current,
    });
  }, [editFormMutation, modifiedFormRef, props.form.uuid]);

  const onChangeName = React.useCallback(
    (name: string) => {
      setModifiedForm((prev) => ({ ...prev, name }));
    },
    [setModifiedForm],
  );

  const onChangeDescription = React.useCallback(
    (description: string) => {
      setModifiedForm((prev) => ({ ...prev, description }));
    },
    [setModifiedForm],
  );

  const onChangeExternalAccess = React.useCallback(
    (nv: boolean) => {
      setModifiedForm((prev) => ({
        ...prev,
        access: {
          ...prev.access,
          external: { enabled: nv },
        },
      }));
    },
    [setModifiedForm],
  );

  const onChangeInternalAccess = React.useCallback(
    (nv: boolean) => {
      setModifiedForm((prev) => ({
        ...prev,
        access: {
          ...prev.access,
          internal: nv
            ? { enabled: true, access: { users: [], teams: ["*"] } }
            : { enabled: false },
        },
      }));
    },
    [setModifiedForm],
  );

  const onChangeOneSubmissionPerUser = React.useCallback(
    (nv: boolean) => {
      setModifiedForm((prev) => ({
        ...prev,
        options: {
          ...prev.options,
          oneSubmissionPerUser: nv,
        },
      }));
    },
    [setModifiedForm],
  );

  const onChangeRecordOutcome = React.useCallback(
    (nv: boolean) => {
      setModifiedForm((prev) => ({
        ...prev,
        outcomes: nv
          ? [...prev.outcomes, { type: "record" }]
          : prev.outcomes.filter((outcome) => outcome.type !== "record"),
      }));
    },
    [setModifiedForm],
  );

  const onChangeNotifyOutcome = React.useCallback(
    (nv: boolean) => {
      setModifiedForm((prev) => ({
        ...prev,
        outcomes: nv
          ? [...prev.outcomes, { type: "notify", sendTo: "users-with-access" }]
          : prev.outcomes.filter((outcome) => outcome.type !== "notify"),
      }));
    },
    [setModifiedForm],
  );

  const onChangeDisplayUnison = React.useCallback(
    (nv: boolean) => {
      setModifiedForm((prev) => ({
        ...prev,
        structure: {
          ...prev.structure,
          format: nv ? HiylloFormFormat.unison : HiylloFormFormat.sequential,
        },
      }));
    },
    [setModifiedForm],
  );

  const myProjects = useMyProjects();
  const self = useSelf();

  const onChangeCreateTaskOutcome = React.useCallback(
    (nv: boolean) => {
      setModifiedForm((prev) => ({
        ...prev,
        outcomes: nv
          ? [
            ...prev.outcomes,
            {
              type: "create-task",
              projectUUID: myProjects.projects.data[0].uuid,
              attachToSprint: null,
              transformPrompt: null,
              assignedToUser: self.userId,
            },
          ]
          : prev.outcomes.filter((outcome) => outcome.type !== "create-task"),
      }));
    },
    [myProjects.projects.data, self.userId, setModifiedForm],
  );

  const onChangeProjectUUID = React.useCallback(
    (projectUUID: string) => {
      setModifiedForm((prev) => ({
        ...prev,
        outcomes: prev.outcomes.map((outcome) =>
          outcome.type === "create-task"
            ? { ...outcome, projectUUID }
            : outcome,
        ),
      }));
    },
    [setModifiedForm],
  );

  const viewResponses = useNavigateTo({
    feature: Features.forms,
    params: {
      view: "view-responses",
      formUUID: modifiedForm.uuid,
    },
  });

  const [showRespond, setShowRespond] = React.useState(false);

  const config = useConfig();

  const createTaskOutcome: HiylloFormCreateTaskOutcome | void =
    modifiedForm.outcomes.find(
      (o): o is HiylloFormCreateTaskOutcome => o.type === "create-task",
    );

  return (
    <>
      {showRespond ? (
        <SmartModal onClose={() => setShowRespond(false)}>
          <div>
            <Header style={{ textAlign: "center" }}>
              Share this link to respond
            </Header>
            <ShareLink>
              {window.location.origin}/form/{modifiedForm.uuid}
            </ShareLink>
          </div>
        </SmartModal>
      ) : null}
      <AnimatePresence>
        <div style={{ width: "100%", height: "100%", overflow: "hidden" }}>
          <motion.div
            animate={{
              transform: isShowingOptions
                ? "translateX(-50%)"
                : "translateX(0%)",
            }}
            style={{
              display: "flex",
              flexDirection: "row",
              width: "200%",
              height: "100%",
            }}
          >
            <Container>
              <HeaderRow>
                {isModified ? (
                  <Button
                    onClick={saveForm}
                    label="Save Changes"
                    isLoading={editFormMutation.isLoading}
                  />
                ) : (
                  <div>
                    <FontAwesomeIcon icon={faCheckCircle} /> All Changes Saved
                  </div>
                )}
                <FlexGrower />
                <PillButton label="View Responses" onClick={viewResponses} />
                <PillButton
                  label="Share Link to Respond"
                  onClick={() => {
                    setShowRespond(true);
                  }}
                />
                <motion.div
                  onClick={() => setIsShowingOptions((v) => !v)}
                  style={{ cursor: "pointer", paddingTop: 3 }}
                >
                  <FontAwesomeIcon icon={faBars} fixedWidth />
                </motion.div>
              </HeaderRow>
              <Input
                value={modifiedForm.name}
                onChangeValue={onChangeName}
                inputStyle={{
                  fontSize: 52,
                  fontFamily: '"hiyllo", sans-serif',
                }}
                fullWidth
              />
              <div style={{ height: 10 }} />
              <Input
                value={modifiedForm.description}
                onChangeValue={onChangeDescription}
                inputStyle={{
                  fontSize: 18,
                  minHeight: 100,
                  fontFamily: '"hiyllo", sans-serif',
                }}
                placeholder="Add a description..."
                multiline
                fullWidth
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 15,
                  paddingTop: 15,
                }}
              >
                <AddFieldButton index={0} setModifiedForm={setModifiedForm} />
                <SimpleDND<HiylloFormField>
                  renderItem={(field, index, dhp) => (
                    <>
                      <Field
                        field={field}
                        setModifiedForm={setModifiedForm}
                        key={field.uuid}
                        dhp={dhp}
                      />
                      <div style={{ padding: 15 }}>
                        <AddFieldButton
                          index={index + 1}
                          setModifiedForm={setModifiedForm}
                        />
                      </div>
                    </>
                  )}
                  items={modifiedForm.structure.fields}
                  keyExtractor={(field) => field.uuid}
                  onChangeOrder={({ items: fields }) => {
                    setModifiedForm((f) => ({
                      ...f,
                      structure: {
                        ...f.structure,
                        fields,
                      },
                    }));
                  }}
                />
              </div>
            </Container>
            <ContainerAlt>
              <HeaderRow>
                <motion.div
                  layoutId="bars"
                  onClick={() => setIsShowingOptions((v) => !v)}
                  style={{ cursor: "pointer" }}
                >
                  <FontAwesomeIcon icon={faAngleRight} fixedWidth />
                </motion.div>
                <FlexGrower />
                {isModified ? (
                  <Button
                    onClick={saveForm}
                    label="Save Changes"
                    isLoading={editFormMutation.isLoading}
                  />
                ) : (
                  <div>
                    <FontAwesomeIcon icon={faCheckCircle} />
                    &nbsp;All Changes Saved
                  </div>
                )}
              </HeaderRow>
              <Header>Who can respond?</Header>
              <NarrowRow>
                <CheckboxInput
                  label={"People outside of " + config.platformName}
                  value={modifiedForm.access.external.enabled}
                  onChange={onChangeExternalAccess}
                />
                <CheckboxInput
                  label={"Members of " + config.platformName}
                  value={modifiedForm.access.internal.enabled}
                  onChange={onChangeInternalAccess}
                />
              </NarrowRow>
              <div style={{ height: 20 }} />
              <Header>Submission Options</Header>
              <NarrowRow>
                <CheckboxInput
                  label="People can only submit once"
                  value={modifiedForm.options.oneSubmissionPerUser}
                  onChange={onChangeOneSubmissionPerUser}
                />
                <CheckboxInput
                  label="Display all questions simultaneously"
                  value={
                    modifiedForm.structure.format === HiylloFormFormat.unison
                  }
                  onChange={onChangeDisplayUnison}
                />
              </NarrowRow>
              <div style={{ height: 20 }} />
              <Header>Response Outcomes</Header>
              <div
                style={{ display: "flex", flexDirection: "column", gap: 10 }}
              >
                <NarrowRow>
                  <CheckboxInput
                    label="Record"
                    value={modifiedForm.outcomes.some(
                      (o) => o.type === "record",
                    )}
                    onChange={onChangeRecordOutcome}
                  />
                </NarrowRow>
                <NarrowRow>
                  <CheckboxInput
                    label="Notify Users with Access"
                    value={modifiedForm.outcomes.some(
                      (o) => o.type === "notify",
                    )}
                    onChange={onChangeNotifyOutcome}
                  />
                </NarrowRow>
                <NarrowRow>
                  <CheckboxInput
                    label="Create Task"
                    value={createTaskOutcome != null}
                    onChange={onChangeCreateTaskOutcome}
                  />
                  {createTaskOutcome != null ? (
                    <>
                      <ProjectSelect
                        projectUUID={createTaskOutcome.projectUUID}
                        setProjectUUID={onChangeProjectUUID}
                        disallowNullSelection
                      />
                    </>
                  ) : null}
                </NarrowRow>
              </div>
            </ContainerAlt>
          </motion.div>
        </div>
      </AnimatePresence>
    </>
  );
});

export const EditForm = React.memo(function EditForm(props: {
  formUUID: string;
}): JSX.Element {
  const formQuery = useGetForm({ uuid: props.formUUID });
  useStatefulRef(formQuery.data?.form.name);

  if (formQuery.data == null) {
    return <LoadingSpinnerFullView />;
  }

  return <EditFormLoaded form={formQuery.data.form} query={formQuery} />;
});
