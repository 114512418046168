import * as SetConfigBlueprint from "../../../blueprints/admin/set-config";
import {
  useConfig,
  useConfigQuery,
} from "../../../platform/config/config-context";
import React from "react";
import { seamlessClient } from "../../../seamless-client";

export function useConfigPropertyHandle<ValueType>(
  path: string,
  defaultValue?: ValueType,
): {
  isLoading: boolean;
  changeLoading: boolean;
  current: ValueType | null;
  onChange: (value: ValueType) => Promise<void>;
} {
  const config = useConfig();
  const configQuery = useConfigQuery();
  const setConfigMutation =
    seamlessClient.useMutation<SetConfigBlueprint.Plug>(
      SetConfigBlueprint,
      { querySideEffects: [configQuery] },
    );
  const [current, setCurrent] = React.useState<ValueType | null>(
    defaultValue ?? null,
  );
  const [initialLoaded, setInitialLoaded] = React.useState<boolean>(false);

  React.useEffect(() => {
    void import("lodash").then(({ default: _ }) => {
      setCurrent(_.get(config, path) ?? defaultValue);
      setInitialLoaded(true);
    });
  }, [defaultValue, config, path]);

  const onChange = async (newValue: ValueType): Promise<void> => {
    const { default: _ } = await import("lodash");
    _.set(config, path, newValue);
    console.log(40, config, path, newValue);
    void setConfigMutation.call({ config }).catch((err) => {
      console.error("Error saving config property", err);
    });
  };

  return {
    onChange,
    changeLoading: setConfigMutation.isLoading,
    current: defaultValue != null ? (current as ValueType) : current,
    isLoading: (configQuery?.isLoading ?? false) || !initialLoaded,
  };
}
