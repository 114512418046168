import moment from "moment";
import React from "react";

const makeTwo = (_n: number): string => {
  const n = _n.toString();
  return n.length === 1 ? `0${n}` : n;
};

/**
 *
 * @param bd Diff in milliseconds
 * @returns 00:00:00 formatted
 */
export function formatDiff(
  bd: number,
  omitTMinus = false,
  omitEmptyHour = false,
): string {
  const isNegative = bd < 0;
  const diff = Math.abs(bd) / 1000;

  const hours = Math.floor(diff / (60 * 60));
  const minutes = Math.floor((diff % (60 * 60)) / 60);
  const seconds = Math.floor(diff % 60);

  return `${isNegative && !omitTMinus ? "T-" : ""}${
    hours === 0 && omitEmptyHour ? "" : makeTwo(hours) + ":"
  }${makeTwo(minutes)}:${makeTwo(seconds)}`;
}

export function calculate(
  d: Date,
  omitTMinus: boolean,
  omitEmptyHour: boolean,
): string {
  const bd = moment().diff(d);
  return formatDiff(bd, omitTMinus, omitEmptyHour);
}

export function useDuration(
  d: Date,
  omitTMinus = false,
  omitEmptyHour = false,
): string {
  const [data, s] = React.useState(calculate(d, omitTMinus, omitEmptyHour));

  React.useEffect(() => {
    const interval = setInterval(() => {
      s(calculate(d, omitTMinus, omitEmptyHour));
    }, 1000);
    return () => clearInterval(interval);
  }, [d]);

  return data;
}
