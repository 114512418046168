import moment from "moment";
import React from "react";

export function useGreeting(name: string): string {
  return React.useMemo(() => {
    const hour = moment().hour();

    if (hour < 5) {
      return `Burning the midnight oil${name}?`;
    } else if (hour >= 5 && hour < 9) {
      return `Looks like you're an early bird${name}`;
    } else if (hour >= 9 && hour < 12) {
      return `Good morning${name}`;
    } else if (hour >= 12 && hour < 18) {
      return `Good afternoon${name}`;
    } else {
      return `Good evening${name}`;
    }
  }, [name]);
}
