/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { StuffAssetType } from "../../types/stuff/asset";
import { StuffContainerType, UserFacingStuffContainerType } from "../../types/stuff/container";

export type ParamsType = {
  elementUUID: string | null;
};
export type ResponseType = {
  /**
   * Containers are in reverse order, so the first container is the root container
   * and the last container is the immediate parent of the element
   */
  containers: UserFacingStuffContainerType[];
};
export const Endpoint = 'stuff/fetch-element-chain';
export const Method = 'GET';

export interface Plug {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
}export const paramsSchema = {"type":"object","properties":{"elementUUID":{"type":["null","string"]}},"required":["elementUUID"],"$schema":"http://json-schema.org/draft-07/schema#"}