import { faCaretDown } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Select } from "@hiyllo/ux/select";
import { styled } from "@hiyllo/ux/styled";
import React from "react";

const StaticElementContainer = styled<"div", { active: boolean; }>("div", ({ $theme, active }) => ({
  background: active ? $theme.buttonBackground : $theme.background3,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 5,
  fontFamily: 'hiyllo',
  width: "max-content",
  paddingLeft: 10,
  paddingRight: 10,
  paddingTop: 7.5,
  paddingBottom: 7.5,
  borderRadius: 5,
  cursor: "pointer",
}));

export const FilterControl = React.memo(function FilterControl<T extends string>(props: React.PropsWithChildren<{
  value: T | null;
  options: { value: T | null; label: string }[];
  onChangeValue: (value: T | null) => void;
}>): JSX.Element {
  return (
    <Select
      value={props.value}
      options={props.options}
      onChangeValue={v => props.onChangeValue(v as T | null)}
      fullWidth
      expandedContainerStyle={{
        width: "max-content",
        borderRadius: 5
      }}
      customStaticElement={
        <StaticElementContainer active={props.value !== null}>
          {props.children}
          <FontAwesomeIcon icon={faCaretDown} />
        </StaticElementContainer>
      }
    />
  );
});