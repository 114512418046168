/**
 * Continuity Meetings, allows a meeting to continue while navigating
 */

import { LiveKitRoom, RoomAudioRenderer } from "@livekit/components-react";
import React from "react";
import EventEmitter from "events";
import { type MeetingInitialPrefs } from "../features/meet/views/meeting-view";
import {
  Stardate,
  StardateLogKind,
  StardateSourceEnum,
} from "@hiyllo/stardate/main";
import { Tenant } from "../platform/tenancy";
import { type MediaDeviceFailure } from "livekit-client";

export interface MeetingStateTypeDef {
  room: string;
  token: string;
  serverUrl: string;
  label: string | null;
  started: Date;
  mip: MeetingInitialPrefs;
  videoMeeting:
  | { meetingUUID: string; meetingPassword?: string | null }
  | { eventUUID: string; meetingPassword: string };
  isRecording: boolean;
}

export type MeetingStateType = MeetingStateTypeDef | null;

export interface ContinuityMeetingContextType {
  connect: (params: MeetingStateType) => void;
  current: MeetingStateType;
  emitter: EventEmitter;
  leaveRequestedRef: React.RefObject<boolean>;
}

export const ContinuityMeetingContext =
  React.createContext<ContinuityMeetingContextType>({
    connect: () => {
      throw new Error("MeetingContext.connect called outside MeetingProvider");
    },
    current: null,
    emitter: new EventEmitter(),
    leaveRequestedRef: { current: false },
  });

export const useOnLeaveMeeting = (fn: () => void): void => {
  const ctx = React.useContext(ContinuityMeetingContext);

  React.useEffect(() => {
    ctx.emitter.on("disconnect", fn);
    return () => {
      ctx.emitter.off("disconnect", fn);
    };
  }, [ctx, fn]);
};

export const MeetingProvider = React.memo(function MeetingProvider(
  props: React.PropsWithChildren,
): JSX.Element {
  console.debug("Rendering <MeetingProvider>");

  const emitter = React.useRef(new EventEmitter()).current;
  const [current, _setCurrent] = React.useState<MeetingStateType>(null);
  const currentRef = React.useRef<MeetingStateType | null>(null);
  const leaveRequestedRef = React.useRef<boolean>(false);
  const stardate = React.useRef(
    new Stardate(
      StardateSourceEnum.frontendWeb,
      "omni",
      "meeting-provider",
      Tenant,
    ),
  ).current;

  const connect = React.useCallback((params: MeetingStateType) => {
    if (
      currentRef.current !== null &&
      currentRef.current?.room === params?.room
    ) {
      return;
    }

    leaveRequestedRef.current = false;

    if (currentRef.current !== null) {
      _setCurrent(null);
      setTimeout(() => {
        _setCurrent(params);
        currentRef.current = params;
      }, 100);
    } else {
      _setCurrent(params);
      currentRef.current = params;
    }
  }, []);

  const onDisconnected = React.useCallback(() => {
    _setCurrent(null);
    currentRef.current = null;
    emitter.emit("disconnect");
  }, []);

  const onError = React.useCallback(
    (err: Error) => {
      console.error(
        "Error from LiveKitRoom",
        { userAgent: window.navigator.userAgent },
        err,
      );

      stardate.log({
        kind: StardateLogKind.error,
        message: "Error in LiveKitRoom",
        data: {},
        error: err,
      });
    },
    [stardate],
  );

  const onMediaDeviceFailure = React.useCallback(
    (failure?: MediaDeviceFailure) => {
      console.error(
        "Error from LiveKitRoom",
        { userAgent: window.navigator.userAgent },
        failure,
      );

      stardate.log({
        kind: StardateLogKind.error,
        message: "Media Device Failure",
        data: { failure, userAgent: window.navigator.userAgent },
        error: new Error(failure),
      });
    },
    [stardate],
  );

  if (current !== null) {
    return (
      <ContinuityMeetingContext.Provider
        value={{
          connect,
          current,
          emitter,
          leaveRequestedRef,
        }}
      >
        <LiveKitRoom
          token={current.token}
          serverUrl={current.serverUrl}
          connect={true}
          onDisconnected={onDisconnected}
          onError={onError}
          onMediaDeviceFailure={onMediaDeviceFailure}
          options={{ adaptiveStream: false }}
          video={
            current.mip.videoEnabled
              ? { deviceId: current.mip.videoDeviceId }
              : false
          }
          audio={current.mip.audioEnabled}
        >
          <RoomAudioRenderer />
          {props.children}
        </LiveKitRoom>
      </ContinuityMeetingContext.Provider>
    );
  }

  return (
    <ContinuityMeetingContext.Provider
      value={{
        connect,
        current,
        emitter,
        leaveRequestedRef,
      }}
    >
      {props.children}
    </ContinuityMeetingContext.Provider>
  );
});
