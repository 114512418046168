/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { DocumentContents, DocumentContentsV2 } from "../../types/stuff/document";

export type ParamsType = {
  uuid: string;
  contents: DocumentContents | DocumentContentsV2;
  contentPreview: string;
};
export type ResponseType = void;
export const Endpoint = "stuff/save-document";
export const Method = "POST";

export interface Plug {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
}
export const paramsSchema = {"type":"object","properties":{"uuid":{"type":"string"},"contents":{"anyOf":[{"$ref":"#/definitions/DocumentContents"},{"$ref":"#/definitions/DocumentContentsV2"}]},"contentPreview":{"type":"string"}},"required":["contentPreview","contents","uuid"],"definitions":{"DocumentContents":{"type":"object","properties":{"v2":{"enum":[false],"type":"boolean"},"delta":{"$ref":"#/definitions/DeltaType"}},"required":["delta"]},"DeltaType":{"type":"object","properties":{"ops":{"type":"array","items":{}}},"required":["ops"]},"DocumentContentsV2":{"type":"object","properties":{"v2":{"type":"boolean","enum":[true]},"descendants":{"type":"array","items":{}}},"required":["descendants","v2"]}},"$schema":"http://json-schema.org/draft-07/schema#"}