import React from "react";
import { type MeetEDataParamsType } from "@hiyllo/omni-common/src/types/navigation/edata";
import { MainContent } from "../../components/main-content";
import { OngoingVideoMeeting } from "../../../meet/views/ongoing";
import {
  CallMeetingView,
  EventMeetingView,
} from "../../../meet/views/meeting-view";
import { EmptySplash } from "@hiyllo/ux/empty-splash";
import { faPhoneRotary } from "@fortawesome/pro-light-svg-icons";

export const MeetFeature = (props: {
  params: MeetEDataParamsType;
}): JSX.Element => {
  return (
    <>
      <MainContent noPadding>
        {props.params === null ? (
          <EmptySplash
            icon={faPhoneRotary}
            label="Meeting Ended"
          />
        ) : props.params.view === "ongoing" ? (
          <OngoingVideoMeeting />
        ) : props.params.view === "event" ? (
          <EventMeetingView
            eventUUID={props.params.eventUUID}
            password={props.params.password}
          />
        ) : (
          <CallMeetingView
            meetingUUID={props.params.meetingUUID}
            password={props.params.password ?? null}
          />
        )}
      </MainContent>
    </>
  );
};

export default MeetFeature;
