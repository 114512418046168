import {
  type MoopsyBlueprintConstsType,
  type MoopsyBlueprintPlugType,
} from "@moopsyjs/core";
import { seamlessClient } from "../../seamless-client";
import { type UseMoopsyQueryRetVal } from "@moopsyjs/react";

export function createQueryHook<Plug extends MoopsyBlueprintPlugType>(
  BP: MoopsyBlueprintConstsType,
) {
  return function useHook(
    params: Plug["params"],
  ): UseMoopsyQueryRetVal<Plug> {
    const query = seamlessClient.useQuery<Plug>(BP, params);
    return query;
  };
}
