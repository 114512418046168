import React from "react";
import { LoadingSpinner } from "@hiyllo/ux/loading-spinner";
import { type UserFacingStuffContainerType } from "@hiyllo/omni-common/src/types/stuff/container";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faUserShield } from "@fortawesome/pro-light-svg-icons";
import { useNavigateTo } from "@hiyllo/omni-router";
import { Features } from "@hiyllo/omni-common/src/types/navigation/features";
import * as GetContainerDetailsBP from "../../../../blueprints/stuff/get-container-details";
import { type UserFacingStuffAssetType } from "@hiyllo/omni-common/src/types/stuff/asset";
import {
  useExpandedControl,
  useIsExpanded,
} from "./contexts/expanded";
import { openWindow } from "../../../../platform/open-window";
import { ContainerIcon, useFetchContainer } from "@hiyllo/omni-stuff";
import { Chain } from "../chain";
import { LSHeader } from "../../../tokyo/components/ls-header";
import { Modal } from "@hiyllo/ux/modal";
import { AccessControlModal } from "../access-control-modal";
import { styled } from "@hiyllo/ux/styled";
import { LSButton } from "../../../tokyo/components/ls-button";
import { seamlessClient } from "../../../../seamless-client";

const ActiveUUIDCtx = React.createContext<string | null>(null);
const ContainerChildren = React.memo(function ContainerChildren(props: {
  containerUUID: string;
  i: number;
}): JSX.Element {
  const { containerQuery } = useFetchContainer({ uuid: props.containerUUID });
  const createFolderInput = React.useRef<HTMLInputElement>(null);
  const [showCreateFolder, setShowCreateFolder] = React.useState(false);

  React.useEffect(() => {
    createFolderInput.current?.focus();
  }, [showCreateFolder]);


  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "stretch",
          marginLeft: props.i === 0 ? 20 : 40,
        }}
      >
        <div
          style={{
            width: 0.25,
            backgroundColor: "white",
            marginBottom: 19,
          }}
        />
        <div>
          {containerQuery.isLoading || containerQuery.isError ? (
            <div
              style={{
                cursor: "pointer",
                padding: 10,
                marginLeft: (props.i + 1) * 20,
              }}
            >
              <LoadingSpinner />
            </div>
          ) : (
            containerQuery.data.elements
              .sort((a, b) => {
                return (
                  (b.type === "asset"
                    ? b.asset.lastEdited
                    : b.container.lastEdited
                  ).valueOf() -
                  (a.type === "asset"
                    ? a.asset.lastEdited
                    : a.container.lastEdited
                  ).valueOf()
                );
              })
              .map((element) =>
                element.type === "container" ? (
                  <Container
                    key={element.container.uuid}
                    i={props.i + 1}
                    container={element.container}
                  />
                ) : (
                  <Asset
                    key={element.asset.uuid}
                    asset={element.asset}
                    i={props.i + 1}
                  />
                ),
              )
          )}
        </div>
      </div>
    </>
  );
});

const Container = React.memo(function Container(props: {
  container: UserFacingStuffContainerType;
  i: number;
  expanded?: boolean;
}): JSX.Element {
  const expanded = useIsExpanded(props.container.uuid);
  const { expand, toggle } = useExpandedControl(props.container.uuid);
  const isActive = React.useContext(ActiveUUIDCtx) === props.container.uuid;

  const navigate = useNavigateTo({
    feature: Features.stuff,
    params: {
      view: "container",
      uuid: props.container.uuid,
    },
  });

  const onClick = React.useCallback(() => {
    expand();
    navigate();
  }, [expand, navigate]);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: props.i !== 0 ? 20 : 0,
            height: 0.25,
            background: "white",
          }}
        />
        <div
          style={{
            cursor: "pointer",
            padding: 0,
            marginLeft: 0,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 0,
            fontWeight: isActive ? "bold" : undefined,
          }}
        >
          <div
            style={{
              height: 40,
              width: 40,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={toggle}
          >
            <ContainerIcon type={props.container.type} color="white" />
          </div>
          <div onClick={onClick}>{props.container.name}</div>
        </div>
      </div>
      {expanded ? (
        <ContainerChildren containerUUID={props.container.uuid} i={props.i} />
      ) : null}
    </>
  );
});

const Asset = React.memo(function Container(props: {
  asset: UserFacingStuffAssetType;
  i: number;
  expanded?: boolean;
}): JSX.Element {
  // const [expanded, setExpanded] = React.useState(props.expanded ?? false);
  // const onClick = useNavigateTo({
  //   feature: Features.stuff,
  //   params: {
  //     view: 'container',
  //     uuid: props.container.uuid
  //   }
  // });

  const onClick = React.useCallback(() => {
    if (props.asset.type === "file") {
      openWindow(props.asset.src);
    }
  }, [props.asset]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <div
        style={{
          height: 0.25,
          width: 20,
          background: "white",
        }}
      />
      <div
        style={{
          cursor: "pointer",
          padding: 0,
          marginLeft: 0,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 0,
        }}
        onClick={onClick}
      >
        <div
          style={{
            height: 40,
            width: 40,
            flexShrink: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {props.asset.type === "file" &&
            (props.asset.src.includes(".png") ||
              props.asset.src.includes(".jpg") ||
              props.asset.src.includes(".jpeg")) ? (
            <img
              src={props.asset.src}
              style={{
                height: 30,
                width: 30,
                objectFit: "contain",
              }}
            />
          ) : (
            <FontAwesomeIcon icon={faFile} fixedWidth />
          )}
        </div>
        <div>{props.asset.name}</div>
      </div>
    </div>
  );
});


const ContainerSidebar = styled("div", ({ $theme }) => ({
  color: $theme.foreground,
  display: "flex",
  flexDirection: "column",
  height: "100%",
}));

export const StuffTreeView = React.memo(function StuffContainerFSView(props: {
  uuid: string | null;
}): JSX.Element {
  const [showSharing, setShowSharing] = React.useState(false);
  const containerQuery = seamlessClient.useQuery<GetContainerDetailsBP.Plug>(GetContainerDetailsBP, { uuid: props.uuid });

  return (
    <ActiveUUIDCtx.Provider value={props.uuid}>
      <ContainerSidebar>
        {showSharing ? (
          <Modal onClose={() => setShowSharing(false)}>
            {containerQuery.data?.container != null ?
              <AccessControlModal element={{ type: "container", container: containerQuery.data.container }} elementQuery={containerQuery} />
              : null}
          </Modal>
        ) : null}
        <LSHeader
          label="Stuff"
        />
        {props.uuid != null ? <Chain elementUUID={props.uuid} /> : null}
        <div style={{ height: 0, flexGrow: 1 }} />
        <LSButton
          label="Manage Access"
          onClick={() => setShowSharing(true)}
          isActive={showSharing}
          icon={{ fa: faUserShield }}
        />
      </ContainerSidebar>
    </ActiveUUIDCtx.Provider>
  );
});
