import { Descendant, Node, Text } from "slate";
import { DocumentContentsV2 } from "../../../../types/stuff/document";

// Function to convert Slate nodes to HTML
const serialize = (node: Node): string => {
  if (Text.isText(node)) {
    let string = node.text;
    // @ts-expect-error ---
    if (node.bold) {
      string = `<strong>${string}</strong>`;
    }
    // @ts-expect-error ---
    if (node.italic) {
      string = `<em>${string}</em>`;
    }
    // @ts-expect-error ---
    if (node.underline) {
      string = `<u>${string}</u>`;
    }
    return string;
  }

  // @ts-expect-error ---
  switch (node.type) {
    case 'paragraph':
      return `<p>${node.children.map(n => serialize(n)).join('')}</p>`;
    case 'heading-one':
      return `<h1>${node.children.map(n => serialize(n)).join('')}</h1>`;
    case 'heading-two':
      return `<h2>${node.children.map(n => serialize(n)).join('')}</h2>`;
    case 'bulleted-list':
      return `<ul>${node.children.map(n => serialize(n)).join('')}</ul>`;
    case 'list-item':
      return `<li>${node.children.map(n => serialize(n)).join('')}</li>`;
    default:
      return node.children.map(n => serialize(n)).join('');
  }
};

export const getHTMLPreview = (value: DocumentContentsV2) => {
  return value.descendants.map(node => serialize(node as Descendant)).join('');
};
