import React from "react";
import { useCompleteMicrosoftIntegration } from "../../hooks/use-complete-microsoft-integration";
import { LoadingSpinnerFullView } from "../../../../platform/loading/spinner-loading-full";

export const CompleteMicrosoftIntegration = React.memo(
  function CompleteMicrosoftIntegration() {
    const callingRef = React.useRef(false);
    const completeMicrosoftIntegration = useCompleteMicrosoftIntegration();

    React.useEffect(() => {
      if (callingRef.current) {
        return;
      }
      callingRef.current = true;
      const code = new URLSearchParams(window.location.search).get("code");
      if (code) {
        void completeMicrosoftIntegration.call({ code }).then(() => {
          window.location.href =
            window.innerHeight > window.innerWidth
              ? "/settings/integrations"
              : `/v/profile?view="integrations"`;
        });
      }
    }, []);

    return <LoadingSpinnerFullView />;
  },
);
