export async function cropImageToSquareAndDownsize(
  file: File,
  CROP_TO = 500,
): Promise<File> {
  return await new Promise((resolve, reject) => {
    const fr = new FileReader();
    fr.readAsDataURL(file);
    fr.onload = (event) => {
      const dataURL = event.target?.result as string;
      const image = new Image();
      image.src = dataURL;
      image.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = CROP_TO;
        canvas.height = CROP_TO;
        const ctx = canvas.getContext("2d");

        const cropTo = Math.min(image.height, image.width);

        ctx?.drawImage(
          image,
          (image.width - cropTo) / 2,
          (image.height - cropTo) / 2,
          cropTo,
          cropTo,
          0,
          0,
          CROP_TO,
          CROP_TO,
        );

        canvas.toBlob((b) => {
          if (b != null) {
            resolve(new File([b], "image.png", { type: "image/png" }));
          } else {
            reject(new Error("Failed to convert to Blob"));
          }
        }, "image/png");
      };
    };
  });
}

export async function downsizeImage(file: File, CROP_TO = 500): Promise<File> {
  return await new Promise((resolve, reject) => {
    const fr = new FileReader();
    fr.readAsDataURL(file);
    fr.onload = (event) => {
      const dataURL = event.target?.result as string;
      const image = new Image();
      image.src = dataURL;
      image.onload = () => {
        let width = CROP_TO;
        let height = CROP_TO;

        if (image.height > image.width) {
          width = Math.floor(height * (image.width / image.height));
        } else {
          height = Math.floor(width * (image.height / image.width));
        }

        const canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        const ctx = canvas.getContext("2d");

        ctx?.drawImage(image, 0, 0, canvas.width, canvas.height);

        const currDims = {
          width: Math.floor(image.width),
          height: Math.floor(image.height),
        };

        const halfDims = {
          width: 0,
          height: 0,
        };

        while (currDims.width * 0.5 > width) {
          halfDims.width = Math.floor(currDims.width * 0.5);
          halfDims.height = Math.floor(currDims.height * 0.5);
          ctx!.drawImage(
            canvas,
            0,
            0,
            currDims.width,
            currDims.height,
            0,
            0,
            halfDims.width,
            halfDims.height,
          );

          currDims.height = halfDims.height;
          currDims.width = halfDims.width;
        }

        const outCanvas = document.createElement("canvas");
        const outCtx = outCanvas.getContext("2d");
        outCanvas.width = width;
        outCanvas.height = height;
        outCtx?.drawImage(
          canvas,
          0,
          0,
          currDims.width,
          currDims.height,
          0,
          0,
          width,
          height,
        );

        outCanvas.toBlob((b) => {
          if (b != null) {
            resolve(new File([b], "image.png", { type: "image/png" }));
          } else {
            reject(new Error("Failed to convert to Blob"));
          }
        }, "image/png");
      };
    };
  });
}

export async function getImageDimensions(
  file: File,
): Promise<{ height: number; width: number }> {
  return await new Promise((resolve, reject) => {
    const fr = new FileReader();
    fr.readAsDataURL(file);
    fr.onload = (event) => {
      const dataURL = event.target?.result as string;
      const image = new Image();
      image.src = dataURL;
      image.onload = () => {
        resolve({
          height: image.height,
          width: image.width,
        });
      };
      image.onerror = reject;
    };
    fr.onerror = reject;
  });
}
