import React from "react";
import { SearchOverlay } from "@hiyllo/omni-search";

interface SearchOverlayContextType {
  show: () => void;
  hide: () => void;
  toggle: () => void;
}

const SearchOverlayContext =
  React.createContext<SearchOverlayContextType | null>(null);

export function useSearchOverlay(): SearchOverlayContextType {
  const value = React.useContext(
    SearchOverlayContext,
  ) as SearchOverlayContextType;

  if (!value) {
    throw new Error("useSearchOverlay must be used within a SearchProvider");
  }

  return value;
}

export const SearchProvider = React.memo(function SearchProvider(
  props: React.PropsWithChildren,
): JSX.Element {
  const [showSearchOverlay, setShowSearchOverlay] = React.useState(false);

  const show = React.useCallback(() => {
    setShowSearchOverlay(true);
  }, []);

  const hide = React.useCallback(() => {
    setShowSearchOverlay(false);
  }, []);

  const toggle = React.useCallback(() => {
    setShowSearchOverlay((v) => !v);
  }, []);

  React.useEffect(() => {
    const onKeyDown = (evt: KeyboardEvent): void => {
      if ((evt.metaKey || evt.ctrlKey) && evt.key === "p") {
        evt.preventDefault();
        evt.stopPropagation();
        setShowSearchOverlay((v) => !v);
      }
    };

    window.addEventListener("keydown", onKeyDown);

    return () => {
      window.removeEventListener("keydown", onKeyDown);
    };
  }, []);

  return (
    <SearchOverlayContext.Provider
      value={{
        show,
        hide,
        toggle,
      }}
    >
      {showSearchOverlay ? (
        <>
          <SearchOverlay onClose={() => setShowSearchOverlay(false)} />
        </>
      ) : null}
      {props.children}
    </SearchOverlayContext.Provider>
  );
});
