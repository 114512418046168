import { faArrowRight } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { LoadingSpinner } from "@hiyllo/ux/loading-spinner";
import { Electron } from "../platform/electron";

export const ElectronProvider = React.memo(function ElectronProvider(
  props: React.PropsWithChildren,
): JSX.Element {
  const [tenant, setTenant] = React.useState<string>("");
  const [showErrorState, setShowErrorState] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState(false);

  const submitLogin = React.useCallback(() => {
    setLoading(true);
    const tenantURL = tenant.includes(".")
      ? `${tenant.indexOf(".") === tenant.lastIndexOf(".") ? "www." : ""
      }${tenant}`
      : `${tenant}.hiyllo.work`;

    const onError = (): void => {
      setShowErrorState(true);
      setTimeout(() => setShowErrorState(false), 500);
    };

    const withStatus = (status: number): void => {
      console.log(status);
      setLoading(false);
      if (status === 200) {
        window.localStorage.electronTenant = JSON.stringify({
          tenantId: tenant,
          host: tenantURL,
        });
        window.location.reload();
      } else {
        onError();
      }
    };

    if (tenantURL.includes("hiyllo.work")) {
      void fetch("https://" + tenantURL + "/_omni/_mt/_vte?tenant=" + tenant)
        .then((res) => withStatus(res.status))
        .catch(onError);
    } else {
      void fetch("https://" + tenantURL + "/api/status" + tenant)
        .then((res) => withStatus(res.status))
        .catch(onError);
    }
  }, [tenant]);

  React.useEffect(() => {
    window.requestAnimationFrame(() => {
      if (
        window.localStorage.isSolo === "true" &&
        window.localStorage.electronTenant == null
      ) {
        window.localStorage.electronTenant = JSON.stringify({
          tenantId: "solo",
          host: "solo.hiyllo.io",
        });
        window.location.reload();
      }
    });
  }, []);

  if (window.location.origin.includes("localhost") && window.localStorage.electronTenant == null) {
    window.localStorage.electronTenant = JSON.stringify({
      tenantId: "localhost",
      host: "localhost:3001",
    });
  }


  if (
    Electron.isElectron &&
    Electron.electronHost == null &&
    !window.location.origin.includes("localhost")
  ) {
    return (
      <div
        style={{
          backgroundColor: "#000",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          color: "#fff",
        }}
      >
        <style>
          {`
            @keyframes shake {
              0% {
                transform: translateX(0px);
              }
              20% {
                transform: translateX(-20px);
              }
              40% {
                transform: translateX(20px);
              }
              60% {
                transform: translateX(-20px);
              }
              80% {
                transform: translateX(20px);
              }   
              0% {
                transform: translateX(0px);
              }                         
            }

            .shake {
              animation: shake 0.5s;
            }
          `}
        </style>
        <img src="hiyllo-work/gradient-text.png" style={{ height: 60 }} />
        <div style={{ height: 40 }} />
        <label>
          <div
            style={{
              backgroundColor: "rgba(255, 255, 255, 0.1)",
              width: 200,
              padding: 12.5,
              borderRadius: 10,
              lineHeight: "14px",
              fontSize: 14,
              display: "flex",
              flexDirection: "row",
              gap: 0,
              alignItems: "center",
            }}
            className={showErrorState ? "shake" : undefined}
          >
            <span
              style={{
                fontSize: "inherit",
                fontFamily: "inherit",
                backgroundColor: "transparent",
                border: "none",
                outline: "none",
                padding: 0,
                margin: 0,
                marginRight: 0,
                color: tenant.length > 0 ? "#fff" : "#aaa",
                minWidth: 1,
              }}
              // @ts-expect-error it works leave it
              placeholder="your-company"
              contentEditable
              onKeyUp={(evt) => {
                if (evt.key === "Enter") {
                  submitLogin();
                } else {
                  setTenant(evt.currentTarget.innerText);
                }
              }}
            />
            {!tenant.includes(".") ? (
              <span style={{ userSelect: "none" }}>.hiyllo.work</span>
            ) : null}
          </div>
        </label>
        <div style={{ height: 40 }} />
        <div
          style={{
            background:
              "linear-gradient(to right, rgb(254 2 254), rgb(254 143 1))",
            color: "white",
            height: 40,
            width: 40,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 20,
            cursor: "pointer",
          }}
          onClick={submitLogin}
        >
          {loading ? (
            <LoadingSpinner />
          ) : (
            <FontAwesomeIcon icon={faArrowRight} />
          )}
        </div>
      </div>
    );
  }

  return <>{props.children}</>;
});
