/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { StuffDocument } from "../../types/stuff/document";

export type ParamsType = null;
export type ResponseType = {
  documents: Array<Omit<StuffDocument, "contents">>;
};
export const Endpoint = "stuff/retrieve-stuff-home";
export const Method = "GET";

export interface Plug {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
}
export const paramsSchema = {"type":"null","$schema":"http://json-schema.org/draft-07/schema#"}