import React from "react";
import * as CreateNativeLoginTokenBP from "../../../blueprints/native/create-native-login-token";
import { seamlessClient } from "../../../seamless-client";
import { type UseMoopsyQueryRetVal } from "@moopsyjs/react";
import { LoadingSpinnerFullView } from "../../../platform/loading/spinner-loading-full";
import { useIsSolo } from "../../../platform/hooks/use-is-solo";
import { EmptySplash } from "@hiyllo/ux/empty-splash";
import { faWave } from "@fortawesome/pro-light-svg-icons";

const useCreateNativeLoginToken =
  (): UseMoopsyQueryRetVal<CreateNativeLoginTokenBP.Plug> =>
    seamlessClient.useQuery<CreateNativeLoginTokenBP.Plug>(
      CreateNativeLoginTokenBP,
      null,
    );

export const NativeLogin = React.memo(function NativeLogin(): JSX.Element {
  const createNativeLoginTokenQuery = useCreateNativeLoginToken();
  const isSolo = useIsSolo();
  const [sent, setSent] = React.useState(false);

  React.useEffect(() => {
    if (createNativeLoginTokenQuery.data != null) {
      const token = createNativeLoginTokenQuery.data.token;
      window.location.href = `${isSolo ? "com.hiyllo.solo" : (window.navigator.userAgent.toLowerCase().includes("android") ? "com.hiylloinc.work" : "com.hiyllo.work")
        }://auth/${token}`;
      setSent(true);
    }
  }, [createNativeLoginTokenQuery?.data?.token]);

  if (sent) {
    return (
      <EmptySplash
        icon={faWave}
        label="App Opened"
        hint="You can close this window and return to the app."
      />
    );
  }

  return <LoadingSpinnerFullView />;
});
