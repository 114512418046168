/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { HiylloForm, HiylloFormOptionsType } from "../../types/forms/form";
import { HiylloFormStructure } from "../../types/forms/structure";

export type ParamsType = {
  uuid: string;
};
export type ResponseType = {
  name: string;
  description: string;
  structure: HiylloFormStructure;
  options: HiylloFormOptionsType;
};
export const Endpoint = "forms/retrieve-form-for-submission";
export const Method = "GET";

export interface Plug {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
}

export const isPublic = true;
export const paramsSchema = {"type":"object","properties":{"uuid":{"type":"string"}},"required":["uuid"],"$schema":"http://json-schema.org/draft-07/schema#"}