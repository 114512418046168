import type React from "react";
import { useConfig } from "../platform/config/config-context";
import { UXThemeOverride } from "./alpha/contexts";

export interface Theme {
  isDarkByDefualt?: boolean;
  "action.primary": {
    background: string;
    color: string;
  };
  "action.secondary": {
    background: string;
    color: string;
  };
  "action.outline": {
    color: string;
    border: string;
    background: string;
  };
  "header_button.standard": {
    background: string;
    color: string;
  };
  "card.standard": {
    background: string;
    color: string;
  };
  "page.standard": {
    background: string;
    color: string;
  };
  "post.typography.poster"?: React.CSSProperties;
  "post.typography.poster.by"?: React.CSSProperties;
  "post.typography.date"?: React.CSSProperties;
  "post.frameless"?: boolean;
  "input.standard"?: {
    background: string;
    color: string;
  };
  "shadow.a": React.CSSProperties;
  "shadow.b": React.CSSProperties;
  "shadow.c": React.CSSProperties;
  "page.background.alt"?: string;
  "post.inline.input"?: React.CSSProperties;
  "post.inline.button"?: React.CSSProperties;
  "post.inline.justify-content"?: "flex-start" | "center" | "flex-end";
  "post.new-post"?: React.CSSProperties;
  "sheet-editor"?: {
    cellBG: string;
    cellFG: string;
    headerCellBG: string;
    headerCellFG: string;
  };
  "post.card.content"?: React.CSSProperties;
  "dock.button"?: {
    active: {
      bg: string;
      fg: string;
    };
    inactive: {
      bg: string;
      fg: string;
    };
  };
  "tokyo.sidebar"?: {
    bg?: string;
    headerTextColor?: string;
    activeButtonFG?: string;
    inactiveButtonFG?: string;
  };
  tab?: {
    inactive: {
      fg: string;
      bg: string;
    };
    active: {
      fg: string;
      bg: string;
    };
  };
}

const defaultLightTheme: Theme = {
  "shadow.a": { boxShadow: "0px 0px 20px -4px rgb(0 0 0 / 12.5%)" },
  "shadow.b": { boxShadow: "0px 0px 10px 1px rgb(0 0 0 / 25%)" },
  "shadow.c": { boxShadow: "0px 0px 15px 1px rgb(0 0 0 / 12.5%)" },
  "action.primary": {
    background: "linear-gradient(45deg, #2196f3, #03a9f4)",
    color: "white",
  },
  "action.secondary": {
    background: "white",
    color: "#121212",
  },
  "action.outline": {
    color: "#5E9BAE",
    border: "#5E9BAE",
    background: "transparent",
  },
  "header_button.standard": {
    background: "white",
    color: "#121212",
  },
  "card.standard": {
    background: "white",
    color: "#121212",
  },
  "page.standard": {
    background: "#eee",
    color: "#121212",
  },
  "page.background.alt": "#eee",
  "dock.button": {
    active: {
      bg: "linear-gradient(45deg, rgb(33, 150, 243), rgb(3, 169, 244))",
      fg: "#ffffff",
    },
    inactive: {
      bg: "#fff",
      fg: "#121212",
    },
  },
  "tokyo.sidebar": {
    bg: "#ffffff",
    headerTextColor: "#121212",
    activeButtonFG: "#121212",
    inactiveButtonFG: "#888888",
  },
};
const defaultDarkTheme: Theme = {
  isDarkByDefualt: true,
  "shadow.a": { boxShadow: "0px 0px 20px -4px rgb(255 255 255 / 12.5%)" },
  "shadow.b": { boxShadow: "0px 0px 10px 1px rgb(255 255 255 / 25%)" },
  "shadow.c": { boxShadow: "0px 0px 15px 1px rgb(255 255 255 / 12.5%)" },
  "action.primary": {
    background: "linear-gradient(45deg, #3c1a0f, #161e47)",
    color: "white",
  },
  "action.secondary": {
    background: "#212025",
    color: "#fff",
  },
  "action.outline": {
    color: "#5E9BAE",
    border: "#5E9BAE",
    background: "transparent",
  },
  "header_button.standard": {
    background: "#212025",
    color: "#fff",
  },
  "card.standard": {
    background: "#212025",
    color: "#fff",
  },
  "page.standard": {
    background: "rgb(16, 16, 20)",
    color: "#fff",
  },
  "page.background.alt": "rgb(16, 16, 20)",
  "input.standard": {
    background: "rgba(255, 255, 255, 0.1)",
    color: "#fff",
  },
  "sheet-editor": {
    cellBG: "#000000",
    cellFG: "#ffffff",
    headerCellBG: "#111111",
    headerCellFG: "#eeeeee",
  },
  "dock.button": {
    active: {
      bg: "rgb(32, 31, 36)",
      fg: "#ffffff",
    },
    inactive: {
      bg: "rgb(23, 22, 27)",
      fg: "#9e9e9e",
    },
  },
  "tokyo.sidebar": {},
  tab: {
    inactive: {
      fg: "#fff",
      bg: "#222",
    },
    active: {
      fg: "#fff",
      bg: "#555",
    },
  },
};
const fiTheme: Theme = Object.assign({}, defaultLightTheme, {
  "shadow.a": { boxShadow: "0px 0px 20px -4px rgb(0 0 0 / 12.5%)" },
  "shadow.b": { boxShadow: "0px 0px 10px 1px rgb(0 0 0 / 25%)" },
  "shadow.c": { boxShadow: "0px 0px 15px 1px rgb(0 0 0 / 12.5%)" },
  "action.primary": {
    background: "#5E9BAE",
    color: "white",
  },
  "action.secondary": {
    background: "white",
    color: "#121212",
  },
  "action.outline": {
    color: "#5E9BAE",
    border: "1px #5E9BAE solid",
    background: "transparent",
  },
  "header_button.standard": {
    background: "#F4ECE1",
    color: "#121212",
  },
  "page.background.alt": "#FBF1E3",
  "card.standard": {
    background: "rgba(244, 236, 225, 50%)",
    color: "#666666",
  },
  "page.standard": {
    background: "#fff",
    color: "#121212",
  },
  "post.typography.poster": {
    color: "rgb(33,141,166)",
    fontWeight: 500,
    fontFamily: "Oswald",
  },
  "post.typography.poster.by": {
    fontFamily: "Open Sans",
    fontWeight: 500,
  },
  "post.typography.date": { color: "#751515" },
  "input.standard": {
    background: "#fff",
    color: "#000",
  },
  "post.inline.input": {
    background: "transparent",
    paddingBottom: 0,
  },
  "post.inline.button": {
    color: "rgb(112, 111, 109)",
    border: "0.5px rgb(143, 140, 136) solid",
    background: "transparent",
    paddingBottom: 5,
    paddingTop: 5,
    lineHeight: "1em",
    borderRadius: 5,
    fontSize: 16,
  },
  "post.inline.justify-content": "flex-end",
  "post.new-post": { backgroundColor: "#F4ECE17F" },
  "post.card.content": {
    fontFamily: "Inconsolata",
    fontWeight: 400,
    whiteSpace: "pre-wrap",
    color: "rgb(85,85,85)",
  },
});

export const themeMap = {
  defaultDarkTheme,
  defaultLightTheme,
  fiTheme,
};

export const themeKeys = Object.keys(themeMap);

export function useThemeStyling<K extends keyof Theme>(
  property: keyof Theme,
): Theme[K] {
  const config = useConfig();
  const overrides = UXThemeOverride.use();
  const themeKeyFromConfig: string = config?.theme ?? "";

  const currentThemeKey: keyof typeof themeMap =
    themeKeyFromConfig in themeMap
      ? (themeKeyFromConfig as keyof typeof themeMap)
      : "defaultLightTheme";
  const currentTheme = {
    ...themeMap[currentThemeKey],
    ...overrides,
    ...(config.themeJSON ?? {}),
  };
  return (currentTheme[property] ??
    defaultLightTheme[property] ??
    null) as Theme[K];
}

export function useTheme(): Theme {
  const config = useConfig();
  const overrides = UXThemeOverride.use();
  const themeKeyFromConfig: string = config?.theme ?? "";

  const currentThemeKey: keyof typeof themeMap =
    themeKeyFromConfig in themeMap
      ? (themeKeyFromConfig as keyof typeof themeMap)
      : "defaultLightTheme";
  const currentTheme = {
    ...themeMap[currentThemeKey],
    ...overrides,
    ...(config.themeJSON ?? {}),
  };
  return (currentTheme ?? defaultLightTheme) as Theme;
}
