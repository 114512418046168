import { useLookupByUserId } from "@hiyllo/omni-continuity/main";
import { UserImage } from "@hiyllo/omni-images/main";
import { styledRN } from "@hiyllo/ux/styled";
import React from "react";
import { Text, View } from "react-native";

const ChipContainer = styledRN<"View">(View, ({ $theme }) => ({
  backgroundColor: $theme.midground1,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  padding: 5,
  gap: 5,
  borderRadius: 5,
}));

const ChipText = styledRN<"Text">(Text, ({ $theme }) => ({
  color: $theme.foreground,
  fontSize: 15,
  fontFamily: "hiyllo"
}));

export const UserChip = React.memo(function UserChip(props: {
  userId: string;
}): JSX.Element {
  const { data } = useLookupByUserId(props.userId);

  return (
    <ChipContainer>
      <UserImage
        width={20}
        userId={props.userId}
      />
      <ChipText>
        {data == null ? "Loading..." : data.name}
      </ChipText>
    </ChipContainer>
  );
});