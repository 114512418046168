/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { DateYYYYMMDD } from "../../../types/calendar/base";
import { BookATimeRange, BookATimeSchedule, BookATimeSlot, BookATimeSlotDuration } from "../../../types/calendar/book-a-time";
import { EventTimingWithComputed } from "../../../types/calendar/calendar-event";

export type ParamsType = {
  token: string,
  slot: EventTimingWithComputed,
  guest: {
    name: string,
    emailAddress: string
  }
};
export type ResponseType = void;
export const Endpoint = 'calendar/book-a-time/book-slot';
export const Method = 'POST';

export interface Plug {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
}

export const isPublic = true;export const paramsSchema = {"type":"object","properties":{"token":{"type":"string"},"slot":{"$ref":"#/definitions/EventTimingWithComputed"},"guest":{"type":"object","properties":{"name":{"type":"string"},"emailAddress":{"type":"string"}},"required":["emailAddress","name"]}},"required":["guest","slot","token"],"definitions":{"EventTimingWithComputed":{"type":"object","properties":{"_computed":{"type":"object","properties":{"start":{"type":"object","format":"date-time"},"end":{"type":"object","format":"date-time"}},"required":["end","start"]},"date":{"type":"string"},"allDay":{"type":"boolean"},"start":{"type":"object","properties":{"hours":{"type":"number"},"minutes":{"type":"number"},"timezone":{"type":"string"}},"required":["hours","minutes","timezone"]},"durationInMinutes":{"type":"number"}},"required":["_computed","date","durationInMinutes","start"]}},"$schema":"http://json-schema.org/draft-07/schema#"}