/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { UserFacingStuffAssetType } from "../../types/stuff/asset";

export type ParamsType = {
  uuid: string;
};
export type ResponseType = {
  asset: UserFacingStuffAssetType;
};
export const Endpoint = "stuff/get-asset";
export const Method = "GET";

export interface Plug {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
}
export const paramsSchema = {"type":"object","properties":{"uuid":{"type":"string"}},"required":["uuid"],"$schema":"http://json-schema.org/draft-07/schema#"}