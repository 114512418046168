import { ThemeProvider } from "@hiyllo/ux/theme";
import { HiylloTokyoThemeDark } from "@hiyllo/ux/tokyo-theme";
import React from "react";

export const UXThemeProvider = React.memo(function UXThemeProvider(
  props: React.PropsWithChildren,
): JSX.Element {
  return (
    <ThemeProvider theme={HiylloTokyoThemeDark}>{props.children}</ThemeProvider>
  );
});
