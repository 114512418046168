import { styled } from "@hiyllo/ux/styled";
import React from "react";
import { Typography } from "@hiyllo/ux/typography";
import { useGetProject } from "../hooks/use-get-project";
import { LoadingSpinnerFullView } from "../../../platform/loading/spinner-loading-full";
import { Input } from "@hiyllo/ux/input";
import { CircleButton } from "@hiyllo/ux/circle-button";
import { type TaskProject } from "../../../types/tasks/tasks-organization";
import { faCheck, faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import { type UseMoopsyQueryRetValAny } from "@moopsyjs/react";
import { useUpdateProjectName } from "../hooks/use-update-project-name";
import { useArchiveProject } from "../hooks/use-archive-project";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useShowConfirmationDialog, useShowDialog } from "@hiyllo/ux/dialogs";
import { RWAccessControlView } from "../../continuity/access/access-control-view";
import * as UpdateProjectAccessBP from "../../../blueprints/tasks/update-project-access";
import { seamlessClient } from "../../../seamless-client";
import { type MoopsyError } from "@moopsyjs/core";
import { useNavigate } from "@hiyllo/omni-router";
import { Features } from "../../../types/navigation/features";
import { PillL } from "../components/pill";

const Container = styled("div", ({ $theme }) => ({
  fontFamily: "hiyllo",
  padding: 10,
}));

const Row = styled("div", ({ $theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 10,
}));

const EditProjectName = React.memo(function EditProjectName(props: {
  project: TaskProject;
  projectQuery: UseMoopsyQueryRetValAny;
  projectsQuery: UseMoopsyQueryRetValAny;
}): JSX.Element {
  const updateProjectNameMutation = useUpdateProjectName({
    querySideEffects: [props.projectQuery, props.projectsQuery],
  });
  const [editedName, setEditedName] = React.useState<string>(
    props.project.name,
  );

  const saveProjectName = React.useCallback(() => {
    void updateProjectNameMutation.call({
      uuid: props.project.uuid,
      name: editedName,
    });
  }, [editedName, props.project.uuid, updateProjectNameMutation]);

  const changed = editedName !== props.project.name;

  return (
    <>
      <Typography.Label>Project Name</Typography.Label>
      <Row>
        <Input value={editedName} onChangeValue={setEditedName} />
        {changed ? (
          <CircleButton
            size={35}
            onClick={saveProjectName}
            icon={faCheck}
            isLoading={updateProjectNameMutation.isLoading}
          />
        ) : null}
      </Row>
    </>
  );
});

export const ProjectSettingsView = React.memo(
  function ProjectSettingsView(props: {
    projectUUID: string;
    projectsQuery: UseMoopsyQueryRetValAny;
  }): JSX.Element {
    const projectQuery = useGetProject({ uuid: props.projectUUID });
    const archiveProjectMutation = useArchiveProject({
      querySideEffects: [props.projectsQuery],
    });
    const updateProjectAccessMutation =
      seamlessClient.useMutation<UpdateProjectAccessBP.Plug>(
        UpdateProjectAccessBP,
        { querySideEffects: [projectQuery] },
      );
    const showConfirmDialog = useShowConfirmationDialog();
    const navigate = useNavigate();

    const archiveProject = React.useCallback(() => {
      void showConfirmDialog({
        title: "Archive Project",
        message:
          "Are you sure you want to archive this project? You will not be able to change any tasks in this project and it will only be accessible from search.",
      }).then((res) => {
        if (res) {
          void archiveProjectMutation.call({ projectUUID: props.projectUUID });
          navigate({
            feature: Features.tasks,
            params: null,
          });
        }
      });
    }, [
      archiveProjectMutation,
      navigate,
      props.projectUUID,
      showConfirmDialog,
    ]);

    const dialog = useShowDialog();

    if (projectQuery.isLoading || projectQuery.isError) {
      return <LoadingSpinnerFullView />;
    }

    return (
      <Container>
        <Typography.Header>{projectQuery.data.project.name}</Typography.Header>
        <div style={{ height: 10 }} />
        <EditProjectName
          project={projectQuery.data.project}
          projectQuery={projectQuery}
          projectsQuery={props.projectsQuery}
        />
        <div style={{ height: 10 }} />
        <Typography.Label>Project Access</Typography.Label>
        <RWAccessControlView
          rwAccess={projectQuery.data.project.access}
          onChangeValue={(access) => {
            console.log(117, access);
            void updateProjectAccessMutation
              .call({
                uuid: props.projectUUID,
                access,
              })
              .catch((err) => {
                dialog({
                  title: "Error updating project access",
                  message: (err as MoopsyError).description,
                  confirmLabel: "OK",
                  onConfirm: () => {
                    //
                  },
                });
              });
          }}
        />
        {projectQuery.data.project.archived !== true ? (
          <>
            <div style={{ height: 20 }} />
            <Row>
              <PillL onClick={archiveProject}>
                <FontAwesomeIcon icon={faTrashAlt} />
                Archive Project
              </PillL>
            </Row>
          </>
        ) : null}
      </Container>
    );
  },
);
