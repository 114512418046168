/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { Sprint } from "../../../types/tasks/sprint";

export const TopicID = "sprint";
export type TopicNameType = `[${string}]sprint/${string}`;
export type SubscriptionParamsType = null;
export type MessageType = Sprint;
export const TopicNameRegExp = new RegExp('/sprint\\/[A-z]*/');
export const SubscriptionParamsSchema = { type: "null" };

export type Typings = {
  TopicNameType: TopicNameType,
  SubscriptionParamsType: SubscriptionParamsType,
  MessageType: MessageType;
};