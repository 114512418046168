/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
export type ParamsType = {
  sprintUUID: string;
  taskUUID: string;
};
export type ResponseType = void;
export const Endpoint = 'tasks/sprints/remove-task-from-sprint';
export const Method = 'POST';

export interface Plug {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
}

export const RateLimitingConfig = {
  calls: 3,
  per: 1000,
};export const paramsSchema = {"type":"object","properties":{"sprintUUID":{"type":"string"},"taskUUID":{"type":"string"}},"required":["sprintUUID","taskUUID"],"$schema":"http://json-schema.org/draft-07/schema#"}