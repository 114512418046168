/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { ActionRepeatContextEnum } from "../../types/calendar/repeating";

export type ParamsType = {
  eventUUID: string,
  notificationsEnabled: boolean,
  repeatHandling: ActionRepeatContextEnum
};
export type ResponseType = void;
export const Endpoint = 'calendar/set-event-notifications-enabled';
export const Method = 'POST';

export interface Plug {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
}export const paramsSchema = {"type":"object","properties":{"eventUUID":{"type":"string"},"notificationsEnabled":{"type":"boolean"},"repeatHandling":{"$ref":"#/definitions/ActionRepeatContextEnum"}},"required":["eventUUID","notificationsEnabled","repeatHandling"],"definitions":{"ActionRepeatContextEnum":{"enum":["all","this","thisAndFuture"],"type":"string"}},"$schema":"http://json-schema.org/draft-07/schema#"}