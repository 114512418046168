import { styled } from "@hiyllo/ux/styled";
import React from "react";
import { TimeColumnHour } from "./time-column-hour";
import { HOURS } from "../consts";

export const TimeColumnContainer = styled("div", {
  width: 60,
  flexShrink: 0,
  fontSize: 12,
});

export const TimeColumn = React.memo(function TimeColumn(): JSX.Element {
  return (
    <TimeColumnContainer>
      {HOURS.map((hour) => (
        <TimeColumnHour key={hour} hour={hour} />
      ))}
    </TimeColumnContainer>
  );
});
