import React from "react";
import { useRequestMicrosoftIntegration } from "../hooks/use-request-microsoft-integration";
import { LoadingSpinnerFullView } from "../../../platform/loading/spinner-loading-full";
import { useListMyIntegrations } from "../hooks/use-list-my-integrations";
import { Typography } from "@hiyllo/ux/typography";
import { Button } from "@hiyllo/ux/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEmptySet } from "@fortawesome/pro-light-svg-icons";
import { Card } from "@hiyllo/ux/surface";
import { useRequestGoogleLinkUrl } from "../hooks/use-request-google-link-url";
import { useDeleteIntegration } from "../hooks/use-delete-integration";

export const IntegrationsView = React.memo(function IntegrationsView() {
  const myIntegrationsQuery = useListMyIntegrations(null);

  const requestMicrosoftIntegration = useRequestMicrosoftIntegration();

  const onStartMicrosoftIntegration = React.useCallback(() => {
    void requestMicrosoftIntegration.call(null).then((res) => {
      window.location.href = res.url;
    });
  }, [requestMicrosoftIntegration]);

  const requestGoogleIntegration = useRequestGoogleLinkUrl();

  const onStartGoogleIntegration = React.useCallback(() => {
    void requestGoogleIntegration.call(null).then((res) => {
      window.location.href = res.url;
    });
  }, [requestGoogleIntegration]);

  const onStartHiylloWorkIntegration = React.useCallback(() => {
    window.location.href = "/integrations/hiyllo-work";
  }, []);

  const deleteIntegrartionMutation = useDeleteIntegration({
    querySideEffects: [myIntegrationsQuery],
  });

  const onDeleteIntegration = React.useCallback(
    (integrationUUID: string) => {
      void deleteIntegrartionMutation.call({ integrationUUID });
    },
    [deleteIntegrartionMutation],
  );

  if (myIntegrationsQuery.isLoading || myIntegrationsQuery.isError) {
    return <LoadingSpinnerFullView />;
  }

  return (
    <div>
      <Typography.Header>Integrations</Typography.Header>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 10,
          marginTop: 10,
        }}
      >
        {myIntegrationsQuery.data.integrations.length < 1 ? (
          <div>
            <FontAwesomeIcon icon={faEmptySet} />
            &nbsp;No Integrations
          </div>
        ) : (
          myIntegrationsQuery.data.integrations.map((integration) =>
            integration.type === "hiyllo-work" ? (
              <Card color="background3" key={integration.uuid}>
                <img
                  src="https://www.hiyllo.work/work-logo.png"
                  style={{ height: 20, width: "auto" }}
                />
                <div>{integration.host}</div>
                <button onClick={() => onDeleteIntegration(integration.uuid)}>
                  Delete
                </button>
              </Card>
            ) : integration.type === "google" ? (
              <Card color="background3" key={integration.uuid}>
                <Typography.SubHeader>Google</Typography.SubHeader>
                <div>{integration.email ?? "Unknown Email"}</div>
                <button onClick={() => onDeleteIntegration(integration.uuid)}>
                  Delete
                </button>
              </Card>
            ) : (
              <Card color="background3" key={integration.uuid}>
                <Typography.SubHeader>Outlook</Typography.SubHeader>
                <div>{integration.email ?? "Unknown Email"}</div>
                <button onClick={() => onDeleteIntegration(integration.uuid)}>
                  Delete
                </button>
              </Card>
            ),
          )
        )}

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 10,
            whiteSpace: "nowrap",
            flexWrap: "wrap",
            alignItems: "center",
          }}
        >
          <div
            style={
              window.innerHeight > window.innerWidth ? { width: "100%" } : {}
            }
          >
            <Button
              onClick={onStartMicrosoftIntegration}
              label="Link your Microsoft Teams/Outlook Calendar"
              isLoading={requestMicrosoftIntegration.isLoading}
              style={{ width: "calc(100% - 30px)" }}
            />
          </div>
          <div
            style={
              window.innerHeight > window.innerWidth ? { width: "100%" } : {}
            }
          >
            <Button
              onClick={onStartGoogleIntegration}
              label="Link your Google Calendar"
              isLoading={requestGoogleIntegration.isLoading}
              style={{ width: "calc(100% - 30px)" }}
            />
          </div>
          <div
            style={
              window.innerHeight > window.innerWidth ? { width: "100%" } : {}
            }
          >
            <Button
              onClick={onStartHiylloWorkIntegration}
              label="Link your Hiyllo Work Calendar"
              isLoading={requestMicrosoftIntegration.isLoading}
              style={{ width: "calc(100% - 30px)" }}
            />
          </div>
        </div>
      </div>
      {/* <div>
        {myPreferencesQuery.data.preferences.linkedMicrosoftAccount != null ? (
          <div>
            Linked to Microsoft account:{" "}
            {myPreferencesQuery.data.preferences.linkedMicrosoftAccount}
          </div>
        ) : (
          <Button
            onClick={onStartMicrosoftIntegration}
            label="Link your Microsoft Teams/Outlook Calendar"
            isLoading={requestMicrosoftIntegration.isLoading}
          />
        )}
        </div> */}
      {/* myPreferencesQuery.data.preferences.linkedHiylloWorkAccount != null ? (
        <div>
          Linked to Hiyllo Work:{" "}
          {myPreferencesQuery.data.preferences.linkedHiylloWorkAccount}
        </div>
      ) : (
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Button
            onClick={() => {
              window.location.href = "/integrations/hiyllo-work";
            }}
            label="Link your Hiyllo Work Calendar"
            isLoading={requestMicrosoftIntegration.isLoading}
          />
        </div>
          ) */}
    </div>
  );
});
