import React from "react";
import { Chart, type AxisOptions } from "react-charts";
import * as ListUsersAdminBP from "../../blueprints/admin/user-management/list-users-admin";
import * as RestoreUserBP from "../../blueprints/admin/user-management/restore-user";
import * as EnableBetaFeatureBP from "../../blueprints/admin/user-management/enable-beta-feature";
import * as DisableMailBP from "../../blueprints/admin/mail/disable-mail";
import { useConfig } from "../../platform/config/config-context";
import { styled } from "@hiyllo/ux/styled";
import * as GetTotalStorageUsedWithBreakdownBP from "../../blueprints/admin/storage/get-total-storage-used-with-breakdown";
import { seamlessClient } from "../../seamless-client";
import { Card } from "@hiyllo/ux/surface";
import { LoadingSpinner } from "@hiyllo/ux/loading-spinner";
import { BasicSelectConfigPropertyEditor } from "./components/basic-select-config-property-editor";
import { AccountAuthenticationMethod } from "../../types/accounts/authentication";
import { IS_BETA_ENV } from "../../platform/xp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHammer,
  faPaintbrush,
  faPencil,
  faPieChart,
  faSmileWink,
  faUsers,
} from "@fortawesome/pro-light-svg-icons";
import { BasicTextConfigPropertyEditor } from "./components/basic-text-config-property-editor";
import { FileInputV2 } from "../../ux/alpha/input";
import { useConfigPropertyHandle } from "./hooks/config-property-handle";
import { CircleButton } from "@hiyllo/ux/circle-button";
import { useGetDailyLogs } from "./hooks/use-get-daily-logs";
import moment from "moment";
import { BetaFeaturesEnum } from "@hiyllo/omni-common/src/types/accounts/user";
import { getRootURL } from "../../platform/environment/get-root-url";
import { useIsSolo } from "../../platform/hooks/use-is-solo";
import { Button } from "@hiyllo/ux/button";
import { useShowDialog } from "@hiyllo/ux/dialogs";
import { useTheme } from "@hiyllo/ux/theme";
import { InvitingPanel } from "./view/inviting-panel";
import { PublicAPIAdmin } from "./view/public-api";
import { CustomEmojiPanel } from "./view/custom-emoji";

interface DailyStorageUsage {
  date: Date;
  totalStorageUsed: number;
}

interface Series {
  label: string;
  data: DailyStorageUsage[];
}

const WorkspaceName = styled("div", ({ $theme }) => ({
  fontSize: 35,
  fontFamily: "hiyllo",
  fontWeight: "bold",
  paddingLeft: 5,
  paddingRight: 5,
}));

const BarPiece = React.memo(function BarPiece(props: {
  percent: number;
  color: string;
}): JSX.Element {
  console.log(19, props.percent);
  return (
    <div
      style={{
        width: props.percent.toString() + "%",
        background: props.color,
        height: 10,
      }}
    />
  );
});

const Circle = styled<"div", { color: string }>("div", ({ $theme, color }) => ({
  width: 20,
  height: 20,
  borderRadius: 10,
  background: color,
}));

const Bar = styled("div", ({ $theme }) => ({
  display: "flex",
  flexDirection: "row",
  borderRadius: 20,
  height: 10,
  alignItems: "flex-end",
  gap: 0,
  overflow: "hidden",
  background: $theme.midground,
}));

const LegendRow = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 10,
});

const SpacedList = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: 10,
});

function formatBytes(bytes: number): string {
  const mb = bytes / 1000 / 1000;
  const gb = mb / 1000;

  return gb > 0.1 ? `${gb.toFixed(2)}GB` : `${Math.ceil(mb)}MB`;
}

const StoragePanel = React.memo(function StoragePanel(): JSX.Element {
  const query =
    seamlessClient.useQuery<GetTotalStorageUsedWithBreakdownBP.Plug>(
      GetTotalStorageUsedWithBreakdownBP,
      null,
    );

  if (query.isError || query.isLoading) {
    return (
      <Card color="background1">
        <LoadingSpinner />
      </Card>
    );
  }

  const total = Object.values<number>(query.data.breakdown).reduce(
    (a: number, b: number) => a + b,
    0,
  );

  return (
    <Card color="background3">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 10,
          fontSize: 20,
          marginBottom: 10,
        }}
      >
        <FontAwesomeIcon icon={faPieChart} />
        Storage
      </div>
      <SpacedList>
        <div>
          <b>{formatBytes(total)}</b> Storage Used
        </div>
        <Bar>
          <BarPiece
            percent={(query.data.breakdown.chat / total) * 100}
            color="#fd872e"
          />
          <BarPiece
            percent={(query.data.breakdown.stuffAssets / total) * 100}
            color="#ff6b73"
          />
          <BarPiece
            percent={(query.data.breakdown.formUploads / total) * 100}
            color="#fe0bf8"
          />
          <BarPiece
            percent={(query.data.breakdown.userProfiles / total) * 100}
            color="#9c27b0"
          />
          <BarPiece
            percent={(query.data.breakdown.admin / total) * 100}
            color="#e91e63"
          />
          <BarPiece
            percent={(query.data.breakdown.misc / total) * 100}
            color="#555555"
          />
        </Bar>
        <LegendRow>
          <Circle color="#fd872e" /> Chat Messages -{" "}
          {formatBytes(query.data.breakdown.chat)}
        </LegendRow>
        <LegendRow>
          <Circle color="#ff6b73" /> File Storage -{" "}
          {formatBytes(query.data.breakdown.stuffAssets)}
        </LegendRow>
        <LegendRow>
          <Circle color="#fe0bf8" /> Form Uploads -{" "}
          {formatBytes(query.data.breakdown.formUploads)}
        </LegendRow>
        <LegendRow>
          <Circle color="#9c27b0" /> User Profiles -{" "}
          {formatBytes(query.data.breakdown.userProfiles)}
        </LegendRow>
        <LegendRow>
          <Circle color="#e91e63" /> Admin -{" "}
          {formatBytes(query.data.breakdown.admin)}
        </LegendRow>
        <LegendRow>
          <Circle color="#555555" /> Miscellaneous / Queued for Deletion -{" "}
          {formatBytes(query.data.breakdown.misc)}
        </LegendRow>
      </SpacedList>
      <div style={{ height: 20 }} />
      <div style={{ height: 380 }}>
        <StorageChart />
      </div>
    </Card>
  );
});

const AccountsPanel = React.memo(function AccountsPanel(): JSX.Element {
  return (
    <Card color="background3">
      <BasicSelectConfigPropertyEditor
        path="accounts.authenticator"
        label="Authenticator"
        options={[
          {
            value: AccountAuthenticationMethod.password,
            label: "Password",
          },
          {
            value: AccountAuthenticationMethod.hiylloAuth,
            label: "Hiyllo Account",
          },
        ]}
      />
    </Card>
  );
});

const BrandingPanel = React.memo(function BrandingPanel(): JSX.Element {
  const config = useConfig();
  const handle = useConfigPropertyHandle<string | null>("branding.logo.fileId");
  const faviconHandle = useConfigPropertyHandle<string | null>(
    "favicon.fileId",
  );
  const [editingLogo, setEditingLogo] = React.useState(false);
  const [editingFavicon, setEditingFavicon] = React.useState(false);

  return (
    <Card color="background3">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 10,
          fontSize: 20,
          marginBottom: 10,
        }}
      >
        <FontAwesomeIcon icon={faPaintbrush} />
        Appearance
      </div>
      <BasicTextConfigPropertyEditor
        label="Workspace Name"
        path="platformName"
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 10,
          marginBottom: 10,
        }}
      >
        <div style={{ fontSize: 16 }}>Logo</div>
        {!editingLogo ? (
          <CircleButton
            icon={faPencil}
            onClick={() => {
              setEditingLogo(true);
            }}
            size={25}
          />
        ) : null}
      </div>
      {editingLogo ? (
        <div style={{ whiteSpace: "nowrap" }}>
          <FileInputV2
            autoUpload
            onFileId={(fileId) => {
              console.log(165, fileId);
              void handle.onChange(fileId).then(() => {
                setTimeout(() => {
                  setEditingLogo(false);
                }, 500);
              });
            }}
            skipCropping
            fullWidth
          />
        </div>
      ) : config.branding?.logo?.fileId == null ? (
        <div>No Logo Set</div>
      ) : (
        <img
          src={
            getRootURL() +
            "/ufplogo.png?fr=1&key=" +
            config.branding?.logo?.fileId
          }
          style={{
            height: 50,
            padding: 10,
            borderRadius: 10,
            width: "auto",
            objectFit: "contain",
            background: "rgba(255, 255, 255, 0.1)",
          }}
        />
      )}

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 10,
          marginBottom: 10,
          marginTop: 10,
        }}
      >
        <div style={{ fontSize: 16 }}>Favicon</div>
        {!editingFavicon ? (
          <CircleButton
            icon={faPencil}
            onClick={() => {
              setEditingFavicon(true);
            }}
            size={25}
          />
        ) : null}
      </div>
      {editingFavicon ? (
        <div style={{ whiteSpace: "nowrap" }}>
          <FileInputV2
            autoUpload
            onFileId={(fileId) => {
              console.log(165, fileId);
              void faviconHandle.onChange(fileId).then(() => {
                setTimeout(() => {
                  setEditingFavicon(false);
                }, 1000);
              });
            }}
            fullWidth
          />
        </div>
      ) : config.favicon?.fileId == null ? (
        <div>No Favicon Set</div>
      ) : (
        <img
          src={"/favicon.png"}
          style={{
            height: 50,
            width: "auto",
            objectFit: "contain",
            background: "rgba(255, 255, 255, 0.1)",
            padding: 10,
            borderRadius: 10,
          }}
        />
      )}
      <div style={{ height: 20 }} />
      <BasicTextConfigPropertyEditor
        label="App Domain (Use with caution, contact Hiyllo before setting)"
        path="appDomain"
      />
    </Card>
  );
});

const StorageChart = React.memo(function StorageChart(): JSX.Element {
  const logsQuery = useGetDailyLogs(null);

  const primaryAxis = React.useMemo(
    (): AxisOptions<DailyStorageUsage> => ({
      getValue: (datum) => datum.date,
      formatters: {
        scale: (datum: Date) => {
          return moment(datum).format("MMM D, YYYY");
        },
      },
    }),
    [],
  );

  const secondaryAxes = React.useMemo(
    (): Array<AxisOptions<DailyStorageUsage>> => [
      {
        getValue: (datum) => datum.totalStorageUsed,
        formatters: {
          scale: (datum: number) => {
            return formatBytes(datum);
          },
        },
      },
    ],
    [],
  );

  const data = React.useMemo<Series[]>(() => {
    const rawData = logsQuery.data == null ? [] : logsQuery.data.logs;

    const data: Series[] = [
      {
        label: "Total Storage Usage",
        data: rawData.map((log) => ({
          date: log.date,
          totalStorageUsed: log.storageUsed,
        })),
      },
    ];

    return data;
  }, [logsQuery.data]);

  console.log(378, data, logsQuery.data);

  if (data[0].data.length === 0) {
    return <div />;
  }

  return (
    <Chart
      options={{
        data,
        primaryAxis,
        secondaryAxes,
        dark: true,
      }}
    />
  );
});

enum ViewEnum {
  storage = "storage",
  appearance = "appearance",
  inviting = "inviting",
  api = "api",
  customEmoji = "customEmoji",
}

const SidebarButton = styled<"div", { active: boolean }>(
  "div",
  ({ $theme, active }) => ({
    background: active ? $theme.midground : $theme.background3,
    color: $theme.foreground,
    padding: 10,
    borderRadius: 10,
    border: "none",
    cursor: "pointer",
    fontSize: 16,
    // fontWeight: active ? "bold" : "normal",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    gap: 5,
  }),
);

const AdminDashboard = (): JSX.Element => {
  const [view, setView] = React.useState<ViewEnum>(ViewEnum.storage);
  const config = useConfig();
  const showAdvanced = IS_BETA_ENV;
  const listUsersQuery = seamlessClient.useQuery<ListUsersAdminBP.Plug>(
    ListUsersAdminBP,
    null,
  );
  const restoreUserMutation =
    seamlessClient.useMutation<RestoreUserBP.Plug>(RestoreUserBP, {
      querySideEffects: [listUsersQuery],
    });
  const enableBetaFeatureMutation =
    seamlessClient.useMutation<EnableBetaFeatureBP.Plug>(
      EnableBetaFeatureBP,
      {
        querySideEffects: [listUsersQuery],
      },
    );
  const disableMailMutation =
    seamlessClient.useMutation<DisableMailBP.Plug>(DisableMailBP);
  const isSolo = useIsSolo();
  const showDialog = useShowDialog();

  const disableMail = React.useCallback(() => {
    showDialog({
      title: "Disable Mail",
      message: "Are you sure you want to disable mail? This cannot be undone",
      onConfirm: () => {
        void disableMailMutation.call(null);
      },
    });
  }, [disableMailMutation, showDialog]);
  const $theme = useTheme();

  return (
    <div
      style={{
        paddingTop: 20,
        paddingBottom: 20,
        fontFamily: "hiyllo",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        height: "calc(100% - 40px)",
      }}
    >
      <WorkspaceName>{config.platformName}</WorkspaceName>
      <div
        style={{
          height: 0,
          flexGrow: 1,
          paddingTop: 10,
          display: "flex",
          flexDirection: "row",
          gap: 20,
          paddingRight: 20,
        }}
      >
        <div
          style={{
            background: $theme.background2,
            padding: 10,
            borderRadius: 10,
            width: 300,
            display: "flex",
            flexDirection: "column",
            gap: 10,
            height: "calc(100% - 20px)",
          }}
        >
          <SidebarButton
            active={view === ViewEnum.storage}
            onClick={() => setView(ViewEnum.storage)}
          >
            <FontAwesomeIcon icon={faPieChart} fixedWidth />
            Storage
          </SidebarButton>
          <SidebarButton
            active={view === ViewEnum.appearance}
            onClick={() => setView(ViewEnum.appearance)}
          >
            <FontAwesomeIcon icon={faPaintbrush} fixedWidth />
            Appearance
          </SidebarButton>
          <SidebarButton
            active={view === ViewEnum.inviting}
            onClick={() => setView(ViewEnum.inviting)}
          >
            <FontAwesomeIcon icon={faUsers} fixedWidth />
            Inviting
          </SidebarButton>
          <SidebarButton
            active={view === ViewEnum.customEmoji}
            onClick={() => setView(ViewEnum.customEmoji)}
          >
            <FontAwesomeIcon icon={faSmileWink} fixedWidth />
            Custom Emoji
          </SidebarButton>



          <SidebarButton
            active={view === ViewEnum.api}
            onClick={() => setView(ViewEnum.api)}
          >
            <FontAwesomeIcon icon={faHammer} fixedWidth />
            Admin API (Advanced)
          </SidebarButton>
        </div>
        <div style={{ flexGrow: 1, overflowY: "auto" }}>
          {view === ViewEnum.storage ? (
            <>
              <StoragePanel />
            </>
          ) : null}
          {view === ViewEnum.appearance ? (
            <>
              <BrandingPanel />
            </>
          ) : null}
          {view === ViewEnum.inviting ? (
            <>
              <InvitingPanel />
            </>
          ) : null}
          {view === ViewEnum.api ?
            <PublicAPIAdmin />
            : null}
          {view === ViewEnum.customEmoji ?
            <CustomEmojiPanel />
            : null}
        </div>
      </div>
      {isSolo ? (
        <Card color="background3">
          {listUsersQuery.isLoading ? (
            <div>Loading..</div>
          ) : listUsersQuery.isError ? (
            <div>Error</div>
          ) : (
            <table>
              <tr>
                <th>Email</th>
                <th>ID</th>
                <th>IPs</th>
                <th>Last Login</th>
                <th>Username</th>
                <th>Status</th>
              </tr>
              {listUsersQuery.data.users.map((user) => (
                <tr key={user.id}>
                  <td
                    onClick={() => {
                      window.location.href = `/admn/gfv-u/${user.id}`;
                    }}
                  >
                    {user.email}
                  </td>
                  <td>{user.id}</td>
                  <td>{user.ips.at(-1) ?? "???"}</td>
                  <td>
                    {user.lastLogin == null
                      ? "Never"
                      : moment(user.lastLogin).format("h:mm a, ddd MMM Do")}
                  </td>
                  <td>{user.username}</td>
                  <td>
                    {user.deleted?.value ? (
                      <button
                        onClick={() => {
                          void restoreUserMutation.call({
                            userId: user.id,
                          });
                        }}
                      >
                        Restore
                      </button>
                    ) : (
                      "Active"
                    )}
                    {user.enabledBetaFeatures.includes(
                      BetaFeaturesEnum.wardrobe,
                    ) ? null : (
                      <button
                        onClick={() => {
                          void enableBetaFeatureMutation.call({
                            userId: user.id,
                            feature: BetaFeaturesEnum.wardrobe,
                          });
                        }}
                      >
                        Enable Wardrobe
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </table>
          )}
        </Card>
      ) : null}
    </div>
  );
};

export default AdminDashboard;
