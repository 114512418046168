/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
/**
 * Identifer used when authenticating
 */
export enum AccountAuthIdentificationMethod {
  email = "EMAIL",
  phoneNumber = "PHONE_NUMBER",
  username = "USERNAME",
}

export enum AccountAuthenticationMethod {
  /**
   * User will enter a password directly into Omni
   */
  password = "PASSWORD",
  /**
   * User will utilize a U2F security key paired with Omni
   */
  u2f = "U2F",
  /**
   * User will utilze a OTP or link sent to their email address
   */
  otpEmail = "PASSWORDLESS_EMAIL",
  /**
   * User will utilize a OTP or link sent to their phone number
   */
  otpPhoneNumber = "PASSWORDLESS_PHONE_NUMBER",
  /**
   * User will utilize app's configured SAML SSO
   */
  samlSSO = "SAML_SSO",
  /**
   * User will utilize SSO with Hiyllo Cloud
   */
  hiylloAuth = "HIYLLO_CLOUD_AUTH",
}
