import { type EDataType } from "../../../types/navigation/edata";

export function linkMatches(base: EDataType, compare: EDataType): boolean {
  if (base.feature !== compare.feature) {
    return false;
  }

  if (base.params == null) {
    return compare.params == null;
  }

  if (compare.params == null) {
    return false;
  }

  for (const [key, value] of Object.entries(base.params)) {
    if (compare.params[key as keyof typeof compare.params] !== value) {
      return false;
    }
  }

  return true;
}
