import { styled } from "@hiyllo/ux/styled";
import React from "react";
import { useHourHeight } from "../hooks/use-hour-height";
import moment from "moment";

const TimeColumnHourContainer = styled<"div", { height: number }>(
  "div",
  ({ $theme, height }) => ({
    height,
    width: "100%",
    // position: 'relative'
  }),
);

const TimeColumnHourContainerText = styled("div", {
  top: -6,
  // position: 'absolute',
  paddingLeft: 10,
});

export const TimeColumnHour = React.memo(function TimeColumnHour(props: {
  hour: number;
}): JSX.Element {
  const height = useHourHeight();

  return (
    <TimeColumnHourContainer height={height}>
      {props.hour !== 0 ? (
        <TimeColumnHourContainerText>
          {moment().minutes(0).hours(props.hour).format("h A")}
        </TimeColumnHourContainerText>
      ) : null}
    </TimeColumnHourContainer>
  );
});
