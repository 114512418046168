export const GUEST_COLOR_BY_INDEX = {
  0: "#fd872e",
  1: "#ff6b73",
  2: "#fe0bf8",
  3: "#9c27b0",
  4: "#e91e63",
  5: "#2196f3",
  6: "#4caf50",
  7: "#009688",
  8: "#ffeb3b",
  9: "#673ab7",
  10: "#8bc34a",
  11: "#ff9800",
  12: "#ff5722",
  13: "#795548",
  14: "#607d8b",
  15: "#3f51b5",
  16: "#00bcd4",
  17: "#cddc39",
  18: "#ffc107",
  19: "#ffeb3b",
  20: "#ff9800",
  21: "#ff5722",
} as const;

export const SELF_COLOR = "#2196f3" as const;
