import React from "react";
import * as RetrieveDocumentBP from "../../../blueprints/stuff/retrieve-document";
import * as SaveDocumentBP from "../../../blueprints/stuff/save-document";
import * as DocsEditingSpec from "../../../blueprints/docs/docs-editing-pubsub-spec";
import { seamlessClient } from "../../../seamless-client";
import { usePath } from "@hiyllo/omni-router";
import { type StuffEDataParamsType } from "../../../types/navigation/edata";
import { Editor, type EditorRef } from "@hiyllo/editor";
import { LoadingSpinnerFullView } from "../../../platform/loading/spinner-loading-full";
import { useDebounce } from "@hiyllo/ux/use-debounce";
import { type DocumentContents } from "../../../types/stuff/document";
import { Tenant } from "../../../platform/tenancy";
import { useSelf } from "@hiyllo/omni-continuity/main";
import "@hiyllo/editor/src/editor.css";
import { type Delta } from "quill/core";
import { TabDetails } from "../../tokyo/tabbing/tabs-provider";
import { faFile } from "@fortawesome/pro-light-svg-icons";
import { useUploadFile } from "@hiyllo/omni-user-files/main";
import { UseMoopsyQueryRetVal } from "@moopsyjs/react/main";

export const DocumentViewV1 = React.memo(function DocumentViewV1(props: {
  retrieveDocumentQuery: UseMoopsyQueryRetVal<RetrieveDocumentBP.Plug>;
  contents: DocumentContents;
}): JSX.Element {
  const { uuid } = usePath().params as StuffEDataParamsType;

  if (uuid == null) {
    throw new Error("uuid is null");
  }

  const retrieveDocumentQuery =
    seamlessClient.useQuery<RetrieveDocumentBP.Plug>(
      RetrieveDocumentBP,
      {
        uuid,
      },
    );
  const saveDocumentMutation =
    seamlessClient.useMutation<SaveDocumentBP.Plug>(SaveDocumentBP);

  const saveDebounce = useDebounce(1250, "ignore");
  const contentsRef = React.useRef<DocumentContents | null>(null);
  const [unsavedChanges, setUnsavedChanges] = React.useState<boolean>(false);
  const self = useSelf();
  const editorId = React.useRef<string>(Math.random().toString());
  const editorRef = React.useRef<EditorRef>(null);

  const onDocsEditMessage = React.useCallback(
    (data: DocsEditingSpec.Typings["MessageType"]) => {
      if (data.editorId !== editorId.current) {
        if (data.delta != null) {
          editorRef.current?.quill?.updateContents(data.delta);
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const cursors = editorRef.current?.quill?.getModule("cursors") as any;
        cursors?.createCursor(
          data.editorId,
          data.userId,
          "#1976d2",
          data.range,
        );
        cursors?.toggleFlag(data.editorId, true);
        cursors?.moveCursor(data.editorId, data.range);
      }
    },
    [],
  );

  const psTopic = React.useRef(`[${Tenant}]docs-editing/${uuid}`).current;
  const DocsEditingPubsub = seamlessClient.usePublishToTopic(DocsEditingSpec);
  seamlessClient.useSubscribeToTopic(
    DocsEditingSpec,
    psTopic,
    onDocsEditMessage,
  );

  React.useEffect(() => {
    setTimeout(() => {
      editorRef.current?.quill?.on(
        "selection-change",
        (r: unknown, or: unknown, src: string) => {
          if (src === "api") {
            DocsEditingPubsub.publish(psTopic, {
              editorId: editorId.current,
              userId: self.profile?.name ?? self.userId,
              delta: null,
              range: editorRef.current?.quill?.getSelection(),
            });
          }
        },
        [],
      );
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onContentsChanged = React.useCallback(
    (contents: DocumentContents, delta: Delta) => {
      setUnsavedChanges(true);
      contentsRef.current = contents;

      DocsEditingPubsub.publish(psTopic, {
        editorId: editorId.current,
        userId: self.profile?.name ?? self.userId,
        delta,
        range: editorRef.current?.quill?.getSelection(),
      });

      saveDebounce.debounce(() => {
        if (contentsRef.current != null) {
          const contents = contentsRef.current;

          void saveDocumentMutation
            .call({
              uuid,
              contents,
              contentPreview:
                editorRef.current?.quill?.getSemanticHTML(0, 1000) ??
                "<div></div>",
            })
            .then(() => {
              setUnsavedChanges(contents !== contentsRef.current);
            });
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [saveDebounce, saveDocumentMutation, uuid],
  );
  const uploadFile = useUploadFile();

  if (retrieveDocumentQuery.data == null) {
    return <LoadingSpinnerFullView />;
  }

  return (
    <TabDetails label={retrieveDocumentQuery.data.document.name} icon={faFile}>
      <Editor
        ref={editorRef}
        initialContents={props.contents.delta.ops}
        onContentsChanged={onContentsChanged}
        isSaving={saveDocumentMutation.isLoading || unsavedChanges}
        name={null}
        readOnly={!retrieveDocumentQuery.data.hasWriteAccess}
        onImageUploaded={async (blob) => {
          return await uploadFile(new File([blob], "signature-image.png"));
        }}
        onExportBlob={(blob) => {
          const file = new File([blob], "document.pdf");
          const downloadUrl = URL.createObjectURL(file);
          const link = document.createElement("a");
          link.href = downloadUrl;
          link.download = file.name;
          link.click();
          URL.revokeObjectURL(downloadUrl);
        }}
      />
    </TabDetails>
  );
});