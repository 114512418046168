import React from "react";
import {
  type CalendarEvent,
  EventTypeEnum,
  type EventGuestInviteeType,
  RSVPEnum,
} from "@hiyllo/omni-common/src/types/calendar/calendar-event";
import { EventTile } from "./components/event-tile";
import { DayWidthContext } from "./contexts";
import {
  doEventsOverlap,
  getPositionedEvents,
} from "./utils/get-positioned-events";
import { useSelf } from "@hiyllo/omni-continuity";
import { GUEST_COLOR_BY_INDEX } from "../consts/guest-color-by-index";
import { type dayToRowPosition } from "./consts";

export const PositionedEvents = React.memo(function PositionedEvents(props: {
  events: CalendarEvent[];
  invitees: EventGuestInviteeType[];

  setSelectedCalendarEventUUID?: (i: string) => void;
  overrideDay?: keyof typeof dayToRowPosition;
  range?: { from: Date; to: Date };
}): JSX.Element {
  const self = useSelf();
  const dayWidth = React.useContext(DayWidthContext);
  const positionedEvents = React.useMemo(
    () => getPositionedEvents(props.events, dayWidth, props.range),
    [dayWidth, props.events, props.range],
  );

  return (
    <>
      {positionedEvents.map(({ event, width, left }) => {
        const overlappingGuest =
          props.invitees.find(
            (i): i is { type: "internal"; userId: string } =>
              i.type === "internal" &&
              event.guests.find(
                (g) =>
                  g.invitee.type === "internal" &&
                  g.invitee.userId === i.userId,
              ) != null,
          )?.userId ?? null;
        const myEvent =
          event.guests.find(
            (g) =>
              g.invitee.type === "internal" &&
              g.invitee.userId === self.userId &&
              g.rsvp !== RSVPEnum.no,
          ) != null;

        return (
          <EventTile
            event={event}
            key={event.uuid}
            width={width}
            left={left}
            onClick={() => {
              props.setSelectedCalendarEventUUID?.(
                event.attachedToEvent != null
                  ? event.attachedToEvent
                  : event.uuid,
              );
            }}
            overrideDay={props.overrideDay}
            color={
              myEvent
                ? null
                : GUEST_COLOR_BY_INDEX[
                    props.invitees.findIndex(
                      (i) =>
                        i.type === "internal" && i.userId === overlappingGuest,
                    ) as keyof typeof GUEST_COLOR_BY_INDEX
                  ] ?? null
            }
            overlapsWith={props.events.filter(
              (e) =>
                e.uuid !== event.uuid &&
                e.type === EventTypeEnum.busy &&
                doEventsOverlap(e, event),
            )}
          />
        );
      })}
    </>
  );
});
