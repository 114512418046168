import React from "react";
import { type MeetingUserType } from "./track-tile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/pro-light-svg-icons";
import { UserImage } from "@hiyllo/omni-images/main";

export const NoVideoPlaceholder = React.memo(
  function NoVideoPlaceholder(props: { user: MeetingUserType }): JSX.Element {
    return (
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 100,
          background: "#121212",
        }}
      >
        {props.user.type === "guest" ? (
          <FontAwesomeIcon icon={faUserCircle} />
        ) : (
          <UserImage userId={props.user.userId} width={100} />
        )}
      </div>
    );
  },
);
