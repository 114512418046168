import { faExclamationTriangle } from "@fortawesome/pro-light-svg-icons";
import { EmptySplash } from "@hiyllo/ux/empty-splash";
import { type MoopsyError } from "@moopsyjs/core";
import React from "react";

export const ErrorSplash = React.memo(function ErrorSplash({
  error,
  accessDeniedHint = "You don't have access to this",
}: {
  error: MoopsyError;
  accessDeniedHint?: string;
}): JSX.Element {
  if (error.code === 403) {
    return (
      <EmptySplash
        icon={faExclamationTriangle}
        label="Access Denied"
        hint={accessDeniedHint}
      />
    );
  }
  return <EmptySplash icon={faExclamationTriangle} label={error.description} />;
});
