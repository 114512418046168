import React from "react";
import { Card } from "@hiyllo/ux/surface";
import { Radio, RadiosContainer } from "../../../../ux/alpha/radio";
import { ActionRepeatContextEnum } from "@hiyllo/omni-common/src/types/calendar/repeating";
import { Row } from "./components";
import { Button, OutlineButton } from "@hiyllo/ux/button";

export const RepeatModal = React.memo(function RepeatModal(props: {
  onClose: () => void;
  onSubmit: (ac: ActionRepeatContextEnum) => Promise<void>;
}): JSX.Element {
  const [repeatContext, setRepeatContext] =
    React.useState<ActionRepeatContextEnum | null>(null);
  const [isLoading, setIsLoading] = React.useState(false);

  const onSave = React.useCallback(() => {
    if (repeatContext != null) {
      setIsLoading(true);
      void props.onSubmit(repeatContext).finally(() => {
        setIsLoading(false);
      });
    }
  }, [props, repeatContext]);

  return (
    <Card color="background3">
      <div>Which events do you want to change?</div>
      <RadiosContainer>
        <Radio
          selected={repeatContext === ActionRepeatContextEnum.this}
          onSelect={() => setRepeatContext(ActionRepeatContextEnum.this)}
          label="Just This Event"
        />
        <Radio
          selected={repeatContext === ActionRepeatContextEnum.thisAndFuture}
          onSelect={() =>
            setRepeatContext(ActionRepeatContextEnum.thisAndFuture)
          }
          label="This and Future Events"
        />
        <Radio
          selected={repeatContext === ActionRepeatContextEnum.all}
          onSelect={() => setRepeatContext(ActionRepeatContextEnum.all)}
          label="All Events"
        />
      </RadiosContainer>
      <Row>
        {repeatContext == null ? (
          <OutlineButton>Save Changes</OutlineButton>
        ) : (
          <Button isLoading={isLoading} label="Save Changes" onClick={onSave} />
        )}
      </Row>
    </Card>
  );
});
