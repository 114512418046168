/**
 * HQL - Hiyllo Query Language
 *
 * (C) Hiyllo Inc, 2023
 *
 * Primitives:
 *  - String:
 *    somestring
 *  - Number
 *    12.34
 *  - Boolean
 *    true
 * Arrays:
 *  - [primitive, primitive, primitive]
 * Base operators:
 *  - <key => primitive> IS <primitive>
 *  - <key => primitive> ISNOT <primitive>
 *  - <key => primitive> IS ONEOF <array of primitive>
 *  - <key => primitive> ISNOT ONEOF <array of primitive>
 *  - <key => array> CONTAINS <primitive>
 *  - <key => array> EXCLUDES <primitive>
 * Operator joiners:
 *  - <operator> AND ... AND <operator>
 *  - <operator> OR ... OR <operator>
 * Order of operations (parenthesis):
 *  - (<operator> AND <operator>) OR (<operator> AND <operator>)
 * Example HQL Statements:
 *  - assignee IS johndoe
 *  - assignee NOT IS ONEOF [alexdoe, taylordoe]
 *  - assignee IS johndoe OR status IS done
 *  - (assignee IS johndoe OR assignee IS janedoe) AND status IS ONEOF [todo, inprogress]
 * Notes
 *  - => represents "points to", so <key => primitive> means a key that points to a primitive value
 */

import _ from "lodash";
import { type ListTasksSlimTaskType } from "../../types/tasks/task-item";

export function resolveFilterSegment(
  item: Record<string, any>,
  filterSegment: `${string}=${string}`,
  pathResolution: Record<string, string> = {},
): boolean {
  const [k, v] = filterSegment.split(" IS ");
  let key = k;
  let value: string | null = v;

  if (value === "_none") {
    value = null;
  }

  if (key in pathResolution) {
    key = pathResolution[key];
  }

  return _.get(item, key) === value;
}

export function createTaskFilterFunction(
  filter: string,
): (task: ListTasksSlimTaskType) => boolean {
  const segments = filter
    .split(/ AND /)
    .filter((f) => f !== "") as Array<`${string}=${string}`>;

  return (task: ListTasksSlimTaskType) => {
    return segments.every((segment) =>
      resolveFilterSegment(task, segment, { assignee: "assignee.userId" }),
    );
  };
}
