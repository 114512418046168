import {
  faChevronDown,
  faChevronsDown,
  faChevronsUp,
  faChevronUp,
  faEquals,
} from "@fortawesome/pro-light-svg-icons";

export const PriorityIconsMap = {
  0: faChevronsUp,
  1: faChevronUp,
  2: faEquals,
  3: faChevronDown,
  4: faChevronsDown,
};
