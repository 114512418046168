import { faBrowser } from "@fortawesome/pro-light-svg-icons";
import { EmptySplash } from "@hiyllo/ux/empty-splash";
import React from "react";
import { useIsSolo } from "../platform/hooks/use-is-solo";
import { Button } from "@hiyllo/ux/button";

export const BrowserCheckProvider = React.memo(function BrowserCheckProvider(props: React.PropsWithChildren) {
  const isSolo = useIsSolo();

  if ('brave' in window.navigator && window.localStorage.ignoreBraveBlock !== "1") {
    return (
      <EmptySplash
        icon={faBrowser}
        label="Brave Browser is Unsupported"
        hint={
          `Hiyllo ${isSolo ? "Solo" : "Work"} is not supported on Brave due to a technical bug in the Brave browser. By continuing you acknowledge things may not function normally.`
        }
        afterElement={isSolo ? null :
          <Button
            label="Ignore & Continue"
            style={{ marginTop: 10 }}
            onClick={() => {
              window.localStorage.ignoreBraveBlock = "1";
              window.location.reload();
            }}
          />
        }

      />
    );
  }

  return props.children;
});