/**
 * Deprecated. Use @hiyllo/react-data
 */

import { type EJSONCache } from "@hiyllo/ejson-cache";
import { type UseMoopsyQueryRetValAny } from "@moopsyjs/react";
import React from "react";

export interface CDSConfigType {
  cache: EJSONCache;
  /**
   * if "hard", mutation result will overwrite existing data
   * if "soft", mutation result will be merged with existing data
   */
  queryExtractKey: string;
  queryType: "hard" | "soft";
  cacheKey: string;
}

export interface CombinedDataSource<RecordType> {
  data: RecordType[];
  ingest: (newData: RecordType[]) => void;
  isReady: boolean;
}

export function useCombinedDataSource<RecordType extends { uuid: string }>(
  query: UseMoopsyQueryRetValAny,
  options: CDSConfigType,
  isNewer?: (incoming: RecordType, previous: RecordType) => boolean,
): CombinedDataSource<RecordType> {
  const queryData = query.data;
  const { cache } = options;
  const ListKey = `${options.cacheKey}._list`;
  const [data, setData] = React.useState<RecordType[]>([]);

  const ingest = React.useCallback(
    async (dataIn: RecordType[], dontStore?: boolean) => {
      const start = Date.now();

      setData((current) => {
        const data = dataIn.filter(
          isNewer != null
            ? (doc) => {
              const existing = current.find((i) => i.uuid === doc.uuid);
              if (existing) {
                return isNewer(doc, existing);
              }
              return true;
            }
            : () => true,
        );

        if (dontStore !== true) {
          for (const item of data) {
            void options.cache.set(`${options.cacheKey}.${item.uuid}`, item);
          }

          void options.cache.mutate<string[]>(
            ListKey,
            (l) => {
              return [...new Set([...l, ...data.map((m) => m.uuid)])];
            },
            [],
          );
        }

        const dataSet = [
          ...current,
          ...data.filter((m) => !current.find((a) => m.uuid === a.uuid)),
        ];

        for (const item of data.filter((m) =>
          current.find((a) => m.uuid === a.uuid),
        )) {
          dataSet[dataSet.findIndex((i) => i.uuid === item.uuid)] = item;
        }

        const end = Date.now();
        return dataSet;
      });
    },
    [ListKey, options.cache, options.cacheKey],
  );

  React.useEffect(() => {
    if (queryData != null) {
      const data = queryData[options.queryExtractKey] as RecordType[];

      if (options.queryType === "soft") {
        void ingest(data);
      } else {
        setData(data);
        void options.cache.set(
          ListKey,
          data.map((d) => d.uuid),
        );
        for (const item of data) {
          void options.cache.set(`${options.cacheKey}.${item.uuid}`, item);
        }
      }
    }
  }, [
    ListKey,
    ingest,
    options.cache,
    options.cacheKey,
    options.queryExtractKey,
    options.queryType,
    queryData,
  ]);

  React.useEffect(() => {
    void cache.get<string[]>(`${options.cacheKey}._list`).then(async (list) => {
      if (list === null) {
        return;
      }

      const cachedEntries = (
        await Promise.all(
          list.map(async (uuid) => {
            return await cache.get<RecordType>(`${options.cacheKey}.${uuid}`);
          }),
        )
      ).filter((r) => r !== null) as RecordType[];

      await ingest(cachedEntries, true);
    });
  }, [ListKey, cache, ingest, options.cacheKey]);

  return {
    data,
    ingest,
    isReady: !query.isLoading,
  };
}
