/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import {
  ListTasksSlimTaskType,
  PriorityType,
  TaskDueDateType,
  TaskSizingType,
  TaskStatusType,
  TaskType,
} from "../../types/tasks/task-item";

export type ParamsType = {
  title: string;
  description: string;
  status: TaskStatusType;
  priority: PriorityType;
  dueDate: TaskDueDateType | null;
  sizing: TaskSizingType | null;
  assigneeUserId: string | null;
  projectUUID: string;
  type: TaskType;
  parentUUID: string | null;
  sprintUUID?: string | null;
  ignoreSimilarTaskWarning?: boolean;
  reminderCriteria?: {
    location: string | null;
  };
};
export type ResponseType =
  | {
    status: "success";
    taskUUID: string;
  }
  | {
    status: "similar-task-warning";
    task: ListTasksSlimTaskType;
  };
export const Endpoint = "tasks/create-task";
export const Method = "POST";

export type Plug = {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
};

export const RateLimitingConfig = {
  calls: 2,
  per: 1500,
};
export const paramsSchema = {"type":"object","properties":{"title":{"type":"string"},"description":{"type":"string"},"status":{"$ref":"#/definitions/TaskStatusType"},"priority":{"description":"Underlying system uses P0-P4 but we can map these to other values (e.g. \"high\" | \"medium\" | \"low\")\non the frontend","enum":[0,1,2,3,4],"type":"number"},"dueDate":{"anyOf":[{"$ref":"#/definitions/TaskDueDateType"},{"type":"null"}]},"sizing":{"anyOf":[{"$ref":"#/definitions/TaskSizingType"},{"type":"null"}]},"assigneeUserId":{"type":["null","string"]},"projectUUID":{"type":"string"},"type":{"$ref":"#/definitions/TaskType"},"parentUUID":{"type":["null","string"]},"sprintUUID":{"type":["null","string"]},"ignoreSimilarTaskWarning":{"type":"boolean"},"reminderCriteria":{"type":"object","properties":{"location":{"type":["null","string"]}},"required":["location"]}},"required":["assigneeUserId","description","dueDate","parentUUID","priority","projectUUID","sizing","status","title","type"],"definitions":{"TaskStatusType":{"type":"object","properties":{"id":{"type":"string"},"label":{"type":"string"},"color":{"$ref":"#/definitions/TaskStatusColorEnum"}},"required":["color","id","label"]},"TaskStatusColorEnum":{"enum":["amber","blue","gray","green","red"],"type":"string"},"TaskDueDateType":{"type":"object","properties":{"date":{"type":"object","format":"date-time"},"as":{"enum":["day","day-and-time"],"type":"string"}},"required":["as","date"]},"TaskSizingType":{"type":"object","properties":{"value":{"type":"number"},"in":{"enum":["points","work-days","work-hours","work-months","work-weeks"],"type":"string"},"type":{"enum":["automatic","explicit"],"type":"string"}},"required":["in","type","value"]},"TaskType":{"enum":["epic","story","task"],"type":"string"}},"$schema":"http://json-schema.org/draft-07/schema#"}