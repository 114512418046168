/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { ContinuityAccessType } from "../../types/continuity/access";

export type ParamsType = {
  uuid: string;
  access: ContinuityAccessType;
};
export type ResponseType = void;
export const Endpoint = "tasks/update-project-access";
export const Method = "POST";

export type Plug = {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
};

export const RateLimitingConfig = {
  calls: 2,
  per: 750,
};
export const paramsSchema = {"type":"object","properties":{"uuid":{"type":"string"},"access":{"$ref":"#/definitions/ContinuityAccessType"}},"required":["access","uuid"],"definitions":{"ContinuityAccessType":{"type":"object","properties":{"teams":{"type":"array","items":{"type":"string"}},"users":{"type":"array","items":{"type":"string"}}},"required":["teams","users"]}},"$schema":"http://json-schema.org/draft-07/schema#"}