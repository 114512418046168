import { styled } from "@hiyllo/ux/styled";
import React from "react";
import { InfoCard } from "../../../ux/info-card";
import { Button } from "@hiyllo/ux/button";
import { useNavigate } from "@hiyllo/omni-router";
import { Features } from "../../../types/navigation/features";
import { useCreatePersonalProject } from "@hiyllo/omni-tasks";

const Row = styled("div", {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: 20,
  width: "100%",
  height: "100%",
});

export const PersonalProjectSplash = React.memo(
  function PersonalProjectSplash(): JSX.Element {
    const navigate = useNavigate();
    const createPersonalProjectMutation = useCreatePersonalProject();

    const onClick = React.useCallback(() => {
      void createPersonalProjectMutation.call(null).then(({ projectUUID }) => {
        navigate({
          feature: Features.tasks,
          params: {
            view: "create-task",
            projectUUID,
            sprintUUID: null,
          },
        });
      });
    }, [createPersonalProjectMutation, navigate]);

    return (
      <Row>
        <div>
          <InfoCard
            label="What is Your Personal Board?"
            title="Personal Board"
            description={[
              "Your Personal Board is a place to keep track of personal tasks, like a running to-do list.",
              "Anything you have to do that doesn't fit in an existing project can go here!",
              "Ready to get started? Click the button below to create your first task.",
            ]}
          />
        </div>
        <Button label="Create Personal Task" onClick={onClick} />
      </Row>
    );
  },
);
