import React from "react";
import * as MarkAlertsReadBP from "../../../../blueprints/alerts/mark-alerts-read";
import {
  type AlertType,
  type JoinMeetingActionType,
} from "@hiyllo/omni-common/src/types/alerts/alert";
import { seamlessClient } from "../../../../seamless-client";
import { useNavigate } from "@hiyllo/omni-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneAlt } from "@fortawesome/pro-light-svg-icons";

export const CallBanner = React.memo(function CallBanner({
  alert,
  onClose,
}: {
  alert: AlertType;
  onClose: () => void;
}): JSX.Element {
  const action = alert.actions?.[0] as JoinMeetingActionType;
  const markAlertsRead =
    seamlessClient.useMutation<MarkAlertsReadBP.Plug>(
      MarkAlertsReadBP,
      { querySideEffects: [] },
    );
  const navigate = useNavigate();

  const onClick = React.useCallback(() => {
    void markAlertsRead.call({ alertUUIDs: [alert.uuid] });
    navigate(action.action.params.link);
    onClose();
  }, [
    action.action.params.link,
    alert.uuid,
    markAlertsRead,
    navigate,
    onClose,
  ]);

  return (
    <div
      onClick={onClick}
      style={{
        paddingLeft: 10,
        paddingRight: 10,
        height: 33.5,
        backgroundColor: "#4caf50",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        flexDirection: "row",
      }}
    >
      {action.label ?? alert.title}
      <div>
        <FontAwesomeIcon icon={faPhoneAlt} />
        &nbsp;
        {action.as === "call" ? "Answer Call" : "Join Meeting"}
      </div>
    </div>
  );
});
