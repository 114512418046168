/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
export type ParamsType = {
  workflowUUID: string;
};
export type ResponseType = {
  input: string;
  response: string;
};
export const Endpoint = "studio/execute-test-run";
export const Method = "POST";

export interface Plug {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
}

export const RateLimitingConfig = {
  calls: 1,
  per: 2000,
};
export const paramsSchema = {"type":"object","properties":{"workflowUUID":{"type":"string"}},"required":["workflowUUID"],"$schema":"http://json-schema.org/draft-07/schema#"}