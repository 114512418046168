/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { LoadingSpinnerFullView } from "../platform/loading/spinner-loading-full";

function usePromiseValue<T>(promise: Promise<T>): T | null {
  const [value, setValue] = React.useState<T | null>(null);

  React.useEffect(() => {
    void promise.then(setValue);
  }, [promise]);

  return value;
}

export function createLazyComponent(
  promiseFn: () => Promise<{ default: React.ComponentType<any> | React.FC }>,
): ReturnType<typeof React.lazy> & { preload: () => void } {
  const lazyComponent = React.memo(function LazyComponent(
    props: any,
  ): JSX.Element {
    const Component = usePromiseValue(promiseFn());

    if (Component != null) {
      return <Component.default {...props} />;
    }

    return <LoadingSpinnerFullView />;
  }) as ReturnType<typeof React.lazy> & { preload?: () => void };

  lazyComponent.preload = () => {
    window.requestAnimationFrame(() => {
      void promiseFn();
    });
  };

  return lazyComponent as ReturnType<typeof React.lazy> & {
    preload: () => void;
  };
}
