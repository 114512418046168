import React from "react";
import { faTimes, faUserShield } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@hiyllo/ux/button";
import { Input } from "@hiyllo/ux/input";
import { styled } from "@hiyllo/ux/styled";
import { Typography } from "@hiyllo/ux/typography";

import * as SendFeedbackBP from "../../blueprints/feedback/send-feedback";
import { seamlessClient } from "../../seamless-client";

const Container = styled("div", ({ $theme }) => ({
  width: 500,
  height: 300,
  padding: 20,
  background: $theme.background3,
  borderRadius: 10,
}));

export const MiniFeedbackForm = React.memo(function MiniFeedbackForm(props: {
  onClose: () => void;
}): JSX.Element {
  const sendFeedbackMutation =
    seamlessClient.useMutation<SendFeedbackBP.Plug>(SendFeedbackBP);
  const ref = React.useRef<HTMLInputElement>(null);
  const [showSuccess, setShowSuccess] = React.useState(false);

  const onSubmit = React.useCallback(async () => {
    const text = ref.current?.value;

    if (text == null || text.trim().length < 1) {
      return;
    }

    await sendFeedbackMutation.call({ text });

    setShowSuccess(true);
  }, [sendFeedbackMutation]);

  if (showSuccess) {
    return (
      <Container>
        <Typography.SubHeader>
          Thank you for your feedback!
        </Typography.SubHeader>
        <Typography.Label>
          We will review your feedback and follow up if necessary
        </Typography.Label>
        <div style={{ height: 5 }} />
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Button label="Close" onClick={props.onClose} />
        </div>
      </Container>
    );
  }

  return (
    <Container onClick={(evt: React.MouseEvent) => evt.stopPropagation()}>
      <div style={{ position: "absolute", top: 10, right: 10 }}>
        <FontAwesomeIcon
          icon={faTimes}
          onClick={props.onClose}
          style={{ cursor: "pointer" }}
          fixedWidth
        />
      </div>
      <Typography.SubHeader>Send Feedback</Typography.SubHeader>
      <Typography.Label>
        Your feedback will be sent directly to Hiyllo engineers
      </Typography.Label>
      <div style={{ height: 5 }} />
      <Input
        placeholder="Enter your feedback here..."
        multiline
        fullWidth
        inputStyle={{ minHeight: 145, maxHeight: 145, overflow: "auto" }}
        ref={ref}
        onSubmit={onSubmit}
        autoFocus
      />
      <div style={{ height: 15 }} />
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Button
          label="Send"
          onClick={onSubmit}
          isLoading={sendFeedbackMutation.isLoading}
        />
      </div>
      <div
        style={{
          marginTop: 10,
          fontSize: 14,
          opacity: 0.8,
          fontFamily: "hiyllo",
        }}
      >
        <FontAwesomeIcon icon={faUserShield} /> Your email will be shared with
        Hiyllo in case we need to follow up
      </div>
    </Container>
  );
});
