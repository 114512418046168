/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { StuffDocument } from "../../types/stuff/document";

export type ParamsType = {
  uuid: string;
};
export type ResponseType = {
  document: StuffDocument;
  hasWriteAccess: boolean;
};
export const Endpoint = "stuff/retrieve-document";
export const Method = "POST";

export interface Plug {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
}
export const paramsSchema = {"type":"object","properties":{"uuid":{"type":"string"}},"required":["uuid"],"$schema":"http://json-schema.org/draft-07/schema#"}