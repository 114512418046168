/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
export type ParamsType = {
  projectUUID: string;
  order: {
    [k: string]: string[]
  }
};
export type ResponseType = void;
export const Endpoint = 'tasks/set-project-tasks-order';
export const Method = 'POST';

export interface Plug {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
}

export const RateLimitingConfig = {
  calls: 1,
  per: 1500,
};export const paramsSchema = {"type":"object","properties":{"projectUUID":{"type":"string"},"order":{"type":"object","additionalProperties":{"type":"array","items":{"type":"string"}}}},"required":["order","projectUUID"],"$schema":"http://json-schema.org/draft-07/schema#"}