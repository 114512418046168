import React from "react";

import { PriorityIconsMap } from "../maps/priority-icons-map";
import { PriorityColorMap } from "../maps/priority-colors-map";
import { type PriorityType } from "../../../types/tasks/task-item";
import { Select } from "@hiyllo/ux/select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons";

interface PropsType {
  priority: PriorityType;
  onChangePriority: null | ((newValue: PriorityType) => void);
}

const PRIORITY_OPTIONS = [
  {
    icon: PriorityIconsMap[0],
    label: "P0",
    value: 0,
  },
  {
    icon: PriorityIconsMap[1],
    label: "P1",
    value: 1,
  },
  {
    icon: PriorityIconsMap[2],
    label: "P2",
    value: 2,
  },
  {
    icon: PriorityIconsMap[3],
    label: "P3",
    value: 3,
  },
  {
    icon: PriorityIconsMap[4],
    label: "P4",
    value: 4,
  },
];

export const PriorityPill = React.memo(function PriorityPill({
  priority,
  onChangePriority,
}: PropsType): JSX.Element {
  const selectedOption = PRIORITY_OPTIONS.find((o) => o.value === priority);

  return (
    <Select
      options={PRIORITY_OPTIONS}
      value={priority}
      disabled={onChangePriority === null}
      onChangeValue={
        onChangePriority != null
          ? (v) => onChangePriority(v as PriorityType)
          : undefined
      }
      containerStyle={{ width: "min-content" }}
      expandedContainerStyle={{ borderRadius: 5 }}
      customStaticElement={
        <div
          style={{
            background: PriorityColorMap()[priority],
            fontSize: 12,
            paddingLeft: 5,
            paddingRight: 5,
            height: 25,
            borderRadius: 5,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 5,
            cursor: "pointer",
            whiteSpace: "nowrap",
          }}
        >
          {selectedOption != null ? (
            <>
              <FontAwesomeIcon icon={selectedOption.icon} fixedWidth />
              {onChangePriority != null ? (
                <FontAwesomeIcon icon={faCaretDown} />
              ) : null}
            </>
          ) : null}
        </div>
      }
    />
  );
});
