/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
export enum AIScheduleUrgencyEnum {
  asap = "asap",
  thisWeek = "thisWeek",
  nextWeek = "nextWeek",
}
import { ContinuityAccessType } from "../../types/continuity/access";

export type ParamsType = {
  title: string;
  description: string;
  duration: number;
  access: ContinuityAccessType;
  urgency: AIScheduleUrgencyEnum;
};
export type ResponseType = {
  eventUUID: string;
};
export const Endpoint = "calendar/request-ai-schedule-event";
export const Method = "POST";

export interface Plug {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
}
export const paramsSchema = {"type":"object","properties":{"title":{"type":"string"},"description":{"type":"string"},"duration":{"type":"number"},"access":{"$ref":"#/definitions/ContinuityAccessType"},"urgency":{"$ref":"#/definitions/AIScheduleUrgencyEnum"}},"required":["access","description","duration","title","urgency"],"definitions":{"ContinuityAccessType":{"type":"object","properties":{"teams":{"type":"array","items":{"type":"string"}},"users":{"type":"array","items":{"type":"string"}}},"required":["teams","users"]},"AIScheduleUrgencyEnum":{"enum":["asap","nextWeek","thisWeek"],"type":"string"}},"$schema":"http://json-schema.org/draft-07/schema#"}