/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { TokenPackageType } from "../../../types/auth/token-package";

export type ParamsType = { token: string };
export type ResponseType = { tokenPackage: TokenPackageType };
export const Endpoint = "auth/login-with-native-token";
export const Method = "POST";

export interface Plug {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
}
export const isPublic = true;

export const RateLimitingConfig = {
  calls: 2,
  per: 2500,
};
export const paramsSchema = {"type":"object","properties":{"token":{"type":"string"}},"required":["token"],"$schema":"http://json-schema.org/draft-07/schema#"}