import React from "react";
import { type EDataType } from "../../../types/navigation/edata";
import { usePath } from "@hiyllo/omni-router";

export type FeatureRoutesType<P extends { view: string }> = Record<
  P["view"],
  React.ComponentType<any>
>;

export const FeatureRouter = React.memo(function FeatureRouter<
  P extends EDataType["params"],
>(props: { routes: FeatureRoutesType<any>; default: P }): JSX.Element {
  const current = (usePath().params ?? props.default) as {
    view: string;
  } | null;

  if (current == null) {
    throw new Error(
      "E-FR13: FeatureRouter requires a default property in the params object",
    );
  }

  if (!("view" in current)) {
    throw new Error(
      "E-FR17: FeatureRouter requires a view property in the params object",
    );
  }

  const Component = props.routes[current.view];

  return <Component {...current} />;
});
