/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
export enum EventTypeEnum {
  /**
   * Standard Event
   */
  event = "event",
  /**
   * Busy/OOO Block
   */
  busy = "busy",
  /**
   * Location Selection, sets where the user will be for the purpose of travel time calculation
   */
  location = "location",
  /**
   * (Typically) auto generated event to represent travel time
   */
  travel = "travel",
}
