import React from "react";
import { ContinuityMeetingContext } from "../../../main/meeting-provider";
import { useNavigateTo } from "@hiyllo/omni-router";
import { Features } from "../../../types/navigation/features";
import { VideoMeetingUI } from "./video-meeting-ui";

export const OngoingVideoMeeting = React.memo(
  function OngoingVideoMeeting(): JSX.Element {
    const { current } = React.useContext(ContinuityMeetingContext);
    const navigateHome = useNavigateTo({
      feature: Features.meet,
      params: null,
    });

    React.useEffect(() => {
      if (current == null) {
        navigateHome();
      }
    }, [current, navigateHome]);

    if (current == null) {
      return <div />;
    }

    return <VideoMeetingUI />;
  },
);
