/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
export type ParamsType = {
  eventUUID: string;
  password?: string | null;
  name?: string | null;
};
export type ResponseType = {
  jwt: string;
  meetingServerURL: string;
  room: string;
  label: string | null;
  started: Date;
  videoMeeting: {
    meetingUUID: string;
    meetingPassword: string | null;
  };
  isRecording: boolean;
};
export const Endpoint = "meet/join-event-meeting";
export const Method = "POST";

export interface Plug {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
}

export const isPublic = true;
export const paramsSchema = {"type":"object","properties":{"eventUUID":{"type":"string"},"password":{"type":["null","string"]},"name":{"type":["null","string"]}},"required":["eventUUID"],"$schema":"http://json-schema.org/draft-07/schema#"}