import React from "react";

// @ts-expect-error window.isElectron
const isElectron: boolean | null = window.isElectron ?? null;

function validatePushSupport(): boolean {
  if (isElectron === true) {
    return false;
  }
  if (!("serviceWorker" in window.navigator)) {
    return false;
  }
  if (!("PushManager" in window)) {
    return false;
  }
  return true;
}

export const ServiceWorkerProvider = React.memo(
  function ServiceWorkerProvider(): null {
    React.useEffect(() => {
      if (validatePushSupport()) {
        void window.navigator.serviceWorker.register("/sw.js");
      }
    }, []);

    return null;
  },
);
