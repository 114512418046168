import React from "react";
import { Router } from "./root-router";
import { OfflineConfigProvider, OnlineConfigProvider } from "./config-provider";
import { ConnectionProvider } from "./connection-provider";
import { AuthenticationProvider } from "./authentication-provider";
import { SelfProvider } from "./self-provider";
import { UXThemeProvider } from "./theme-provider";
import { ErrorBoundary } from "../platform/error-boundary";
import { SuspenseProvider } from "./suspense-provider";
import { ServiceWorkerProvider } from "./service-worker-provider";
import { BlobCacheProvider } from "./blob-cache-provider";
import { WrappedAlertsProvider } from "./alerts-provider";
import { ElectronProvider } from "./electron-provider";
import { OmniChatUniversalEmitterProvider } from "@hiyllo/omni-chat";
import { UpdateProvider } from "./update-provider";
import { seamlessClient } from "../seamless-client";
import { OmniUserFilesProvider } from "@hiyllo/omni-user-files";
import { FontAwesomeIconCtx } from "@hiyllo/ux/platform";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { HiylloBGProvider } from "@hiyllo/ux/hiyllo-bg-provider";
import { styled } from "@hiyllo/ux/styled";
import { DialogProvider } from "@hiyllo/ux/dialogs";
import { WebPortalsProvider } from "@hiyllo/ux/web-portals";
import { IsElectronContext } from "@hiyllo/ux/contexts";
import { FiberProvider } from 'its-fine';
import {
  CacheProvider,
  ClientProvider,
  RouterCtxProvider,
  TenantProvider,
  UndoableProvider,
} from "@hiyllo/omni-continuity";
import { Cache } from "../platform/cache";
import { Tenant } from "../platform/tenancy";
import { EnvironmentProvider } from "./environment-provider";
import {
  useNavigate,
  useNavigateTo,
} from "@hiyllo/omni-router";
import { BrowserCheckProvider } from "./browser-check-provider";
import { TabsProvider } from "../features/tokyo/tabbing/tabs-provider";
import { Electron } from "../platform/electron";
import { IdleStateProvider } from "@hiyllo/idle/main";
import { HelloWorldProvider } from "./hello-world-provider";
import { MuteProvider } from "./mute-provider";

const HiylloBGNative = styled("div", ({ $theme }) => ({
  background: $theme.buttonBackground,
}));

const RootContainer = styled("div", ({ $theme }) => ({
  background: $theme.background1,
  height: "100%",
  width: "100%"
}));

export const Main = React.memo(function Main(): JSX.Element {
  console.log("<Main>");


  return (
    <ErrorBoundary>
      <FiberProvider>
        <WebPortalsProvider>
          <FontAwesomeIconCtx.Provider value={FontAwesomeIcon as any}>
            <CacheProvider cache={Cache}>
              <ClientProvider client={seamlessClient}>
                <TenantProvider tenant={Tenant}>
                  <HelloWorldProvider>
                    <ElectronProvider>
                      <OfflineConfigProvider>
                        <ConnectionProvider>
                          <OnlineConfigProvider>
                            <EnvironmentProvider>
                              <SuspenseProvider>
                                <AuthenticationProvider>
                                  <SelfProvider>
                                    <WrappedAlertsProvider>
                                      <OmniUserFilesProvider>
                                        <BlobCacheProvider>
                                          <UXThemeProvider>
                                            <HiylloBGProvider
                                              component={HiylloBGNative}
                                            >
                                              <RootContainer>
                                                <IsElectronContext.Provider value={Electron.isElectron}>
                                                  <DialogProvider>
                                                    <BrowserCheckProvider>
                                                      <UndoableProvider>
                                                        <OmniChatUniversalEmitterProvider>
                                                          <IdleStateProvider>
                                                            <MuteProvider>
                                                              <TabsProvider>
                                                                <RouterCtxProvider
                                                                  useNavigateTo={useNavigateTo}
                                                                  useNavigate={useNavigate}
                                                                >
                                                                  <Router />
                                                                </RouterCtxProvider>
                                                              </TabsProvider>
                                                            </MuteProvider>
                                                          </IdleStateProvider>
                                                        </OmniChatUniversalEmitterProvider>
                                                        <UpdateProvider />
                                                        <ServiceWorkerProvider />
                                                      </UndoableProvider>
                                                    </BrowserCheckProvider>
                                                  </DialogProvider>
                                                </IsElectronContext.Provider>
                                              </RootContainer>
                                            </HiylloBGProvider>
                                          </UXThemeProvider>
                                        </BlobCacheProvider>
                                      </OmniUserFilesProvider>
                                    </WrappedAlertsProvider>
                                  </SelfProvider>
                                </AuthenticationProvider>
                              </SuspenseProvider>
                            </EnvironmentProvider>
                          </OnlineConfigProvider>
                        </ConnectionProvider>
                      </OfflineConfigProvider>
                    </ElectronProvider>
                  </HelloWorldProvider>
                </TenantProvider>
              </ClientProvider>
            </CacheProvider>
          </FontAwesomeIconCtx.Provider>
        </WebPortalsProvider>
      </FiberProvider>
    </ErrorBoundary>
  );
});
