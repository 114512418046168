/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
export type ParamsType = {
  label: string | null;
  imageFsId: string;
};
export type ResponseType = {
  uuid: string;
};
export const Endpoint = "wardrobe/create-wardrobe-entry";
export const Method = "POST";

export interface Plug {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
}

export const RateLimitingConfig = {
  calls: 1,
  per: 2000,
};
export const paramsSchema = {"type":"object","properties":{"label":{"type":["null","string"]},"imageFsId":{"type":"string"}},"required":["imageFsId","label"],"$schema":"http://json-schema.org/draft-07/schema#"}