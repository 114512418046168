import moment from "moment";

export function formatEventTime(time: Date, timezone: string): string {
  const m = moment(time);

  if (m.valueOf() === m.clone().endOf("day").valueOf()) {
    return "12:00 am";
  }

  return m.format("h:mm a");
}

export function formatEventTime24H(time: Date, timezone: string): string {
  const m = moment(time);

  if (m.valueOf() === m.clone().endOf("day").valueOf()) {
    return "00:00";
  }

  return m.format("HH:mm");
}
