import React from "react";
import {
  isTrackReference,
  type TrackReferenceOrPlaceholder,
} from "@livekit/components-core";
import { styled } from "@hiyllo/ux/styled";
import {
  ConnectionQualityIndicator,
  TrackMutedIndicator,
  VideoTrack,
  useIsMuted,
} from "@livekit/components-react";
import { Track } from "livekit-client";
import { NoVideoPlaceholder } from "./no-video-placeholder";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDisplay } from "@fortawesome/pro-light-svg-icons";

export const TrackCountContext = React.createContext(1);

const TrackTileElement = styled<"div", { totalTracks: number }>(
  "div",
  ({ $theme, totalTracks }) => ({
    background: $theme.midground,
    position: "relative",
    overflow: "hidden",
    height: "100%",
    width: "100%",
    // flexBasis: totalTracks === 1 ? "100%" : "50%",
    // flexShrink: 1,
    // flexGrow: 1,
  }),
);

const CornerInfo = styled("div", {
  position: "absolute",
  bottom: 10,
  right: 10,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 7.5,
});

export type MeetingUserType =
  | { type: "user"; name: string; userId: string }
  | { type: "guest"; name: string };

export const TrackTile = React.memo(function TrackTile(props: {
  track: TrackReferenceOrPlaceholder;
  isScreenShare?: boolean;
  screenShareActive?: boolean;
}): JSX.Element {
  const user: MeetingUserType = React.useMemo(() => {
    try {
      return JSON.parse(decodeURIComponent(props.track.participant.identity));
    } catch (e) {
      return { type: "guest", name: "Guest" };
    }
  }, [props.track.participant.identity]);
  const cameraMuted = useIsMuted(Track.Source.Camera);

  const showVideoTrack = React.useMemo(() => {
    return (
      isTrackReference(props.track) &&
      (props.isScreenShare === true || !cameraMuted)
    );
  }, [cameraMuted, props.isScreenShare, props.track]);

  const totalTracks = React.useContext(TrackCountContext);

  return (
    <div
      style={{
        height: props.screenShareActive === true ? undefined : "100%",
        width: "100%",
      }}
    >
      <TrackTileElement totalTracks={totalTracks}>
        {showVideoTrack ? (
          <VideoTrack {...props.track} />
        ) : (
          <NoVideoPlaceholder user={user} />
        )}
        <CornerInfo>
          {user.name}
          {props.isScreenShare === true ? "'s Screen" : ""}
          <TrackMutedIndicator
            source={Track.Source.Microphone}
            style={{ marginRight: 0 }}
          />
          {props.isScreenShare === true ? (
            <FontAwesomeIcon icon={faDisplay} fixedWidth />
          ) : (
            <TrackMutedIndicator source={props.track.source} />
          )}
          <ConnectionQualityIndicator style={{ height: 16 }} />
        </CornerInfo>
      </TrackTileElement>
    </div>
  );
});
