import { useIsSolo } from "../../../platform/hooks/use-is-solo";

export const Priorites = [0, 1, 2, 3, 4] as const;
export const usePriorityLabels = (): string[] => {
  const isSolo = useIsSolo();
  return isSolo
    ? [
        "Crazy Important",
        "Super Important",
        "Important",
        "Less Important",
        "Not So Important",
      ]
    : ["P0", "P1", "P2", "P3", "P4"];
};
