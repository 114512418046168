import React from "react";
import { styled } from "@hiyllo/ux/styled";
import { useParams } from "react-router-dom";

import { LoadingSpinnerFullView } from "../../platform/loading/spinner-loading-full";

import * as AuthorizeBP from "../../blueprints/oauth/authorize";
import { seamlessClient } from "../../seamless-client";
import { OAuthScopeEnum } from "../../types/oauth/scopes";

const Centered = styled("div", ({ $theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  width: "100%",
  backgroundColor: $theme.background2,
  fontFamily: "hiyllo",
}));

export const OAuthAuthorize = React.memo(
  function OAuthAuthorize(): React.ReactElement {
    const params = useParams<{ appId: string }>();
    const authorizeMutation =
      seamlessClient.useMutation<AuthorizeBP.Plug>(AuthorizeBP);

    React.useEffect(() => {
      if (params.appId != null && !authorizeMutation.isLoading) {
        void authorizeMutation
          .call({
            appId: params.appId,
            scopes: [OAuthScopeEnum.readCalendar, OAuthScopeEnum.writeCalendar],
          })
          .then((res) => {
            window.location.href = res.returnUrl;
          });
      }
    }, [authorizeMutation.call, params.appId]);

    if (params.appId == null) {
      return <Centered>OAuth Error - Invalid Request</Centered>;
    }

    return (
      <Centered>
        <LoadingSpinnerFullView />
      </Centered>
    );
  },
);
