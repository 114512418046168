import React from "react";
import { TaskProject, type TaskOrderType } from "../../../types/tasks/tasks-organization";
import { useGetProject } from "./use-get-project";
import { Tenant } from "../../../platform/tenancy";
import { Cache } from "../../../platform/cache";
import { seamlessClient } from "../../../seamless-client";
import * as ProjectSpec from "../../../blueprints/tasks/pubsub/project-spec";
import { useSetProjectTasksOrder } from "./use-set-project-tasks-order";
import { type ListTasksSlimTaskType } from "../../../types/tasks/task-item";
import { type MoopsyError } from "@moopsyjs/core";

export function useProjectOrder(opts: {
  projectUUID: string;
  onTaskUpdated: (task: ListTasksSlimTaskType) => void;
}): {
  order: TaskOrderType | null;
  project: TaskProject | null;
  onChangeOrder: (
    newOrder: TaskOrderType,
    updatedTask: ListTasksSlimTaskType | null,
  ) => void;
  error: MoopsyError | null;
} {
  const liveProject = useGetProject({ uuid: opts.projectUUID });
  const cacheKey = React.useMemo(() => (`tasks.project.${opts.projectUUID}`), [opts.projectUUID]);
  Cache.useCacheLive(cacheKey, liveProject.data);
  const setProjectTasksOrderMutation = useSetProjectTasksOrder();

  const [order, setOrder] = React.useState<TaskOrderType | null>(null);

  seamlessClient.useSubscribeToTopic<ProjectSpec.Typings>(
    ProjectSpec,
    `[${Tenant}]project/${opts.projectUUID}`,
    (project) => {
      setOrder(project.order);
    },
  );

  React.useEffect(() => {
    if (liveProject.data != null) {
      setOrder(liveProject.data.project.order);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [liveProject.data != null]);

  React.useEffect(() => {
    void Cache.get<TaskProject>(cacheKey).then(response => {
      setOrder(o => o == null ? response?.order ?? null : o);
    });
  }, [cacheKey]);

  const onChangeOrder = React.useCallback(
    (
      newOrder: TaskOrderType,
      updatedTask: ListTasksSlimTaskType | null,
    ): void => {
      setOrder(newOrder);
      void setProjectTasksOrderMutation.call({
        projectUUID: opts.projectUUID,
        order: newOrder,
      });
      if (updatedTask !== null) {
        opts.onTaskUpdated(updatedTask);
        // tasks.ingest([updatedTask]);
      }
    },
    [setProjectTasksOrderMutation, opts],
  );

  return {
    order,
    onChangeOrder,
    project: liveProject.data != null ? liveProject.data.project : null,
    error: liveProject.error,
  };
}
