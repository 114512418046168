/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { CalendarEvent } from "../../types/calendar/calendar-event";

export type ParamsType = {
  eventUUID: string;
};
export type ResponseType = {
  event: CalendarEvent;
};
export const Endpoint = 'calendar/retrieve-event';
export const Method = 'GET';

export interface Plug {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
}export const paramsSchema = {"type":"object","properties":{"eventUUID":{"type":"string"}},"required":["eventUUID"],"$schema":"http://json-schema.org/draft-07/schema#"}