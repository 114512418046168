/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
export type ParamsType = null;
export type ResponseType = {
  url: string;
};
export const Endpoint =
  "accounts/user/integrations/request-microsoft-integration";
export const Method = "POST";

export type Plug = {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
};

export const RateLimitingConfig = {
  calls: 2,
  per: 1500,
};
export const paramsSchema = {"type":"null","$schema":"http://json-schema.org/draft-07/schema#"}