import React from "react";
import fuzzysort from "fuzzysort";
import { Modal } from "@hiyllo/ux/modal";
import { FindView } from "@hiyllo/ux/find";
import { Button } from "@hiyllo/ux/button";
import { TaskView } from "../views/task-view";
import { usePath } from "@hiyllo/omni-router";
import { DragDropContext } from "@hello-pangea/dnd";
import { EmptySplash } from "@hiyllo/ux/empty-splash";
import { useNavigateTo } from "@hiyllo/omni-continuity";
import { faEmptySet } from "@fortawesome/pro-light-svg-icons";
import { type OnChangeOrderFnType, useDNDOnDragEnd, useOrder } from "@hiyllo/omni-tasks";

import { KanbanColumn } from "./kanban-column";
import { Features } from "../../../types/navigation/features";
import { HideTaskProjectLabelsCtx } from "../components/task-card";
import { type ListTasksSlimTaskType } from "../../../types/tasks/task-item";
import { type TasksEDataParamsType } from "../../../types/navigation/edata";
import { type TaskOrderType } from "../../../types/tasks/tasks-organization";
import { BoardColumn, BoardColumnContainer, BoardColumnsContainer } from "./components";
import { LoadingSpinnerFullView } from "../../../platform/loading/spinner-loading-full";

export const HighlightTaskContext = React.createContext<string | null>(null);
export const FixedSizingPillContext = React.createContext<boolean>(false);
export const FixedStatusPillContext = React.createContext<boolean>(false);
export const KanbanFramelessContext = React.createContext<boolean>(false);

const KanbanSkeleton = React.memo(function KanbanSkeleton(): JSX.Element {
  return (
    <BoardColumnsContainer>
      <BoardColumnContainer>
        <BoardColumn>
          <LoadingSpinnerFullView />
        </BoardColumn>
      </BoardColumnContainer>
      <BoardColumnContainer>
        <BoardColumn>
          <LoadingSpinnerFullView />
        </BoardColumn>
      </BoardColumnContainer>
      <BoardColumnContainer>
        <BoardColumn>
          <LoadingSpinnerFullView />
        </BoardColumn>
      </BoardColumnContainer>
      <BoardColumnContainer>
        <BoardColumn>
          <LoadingSpinnerFullView />
        </BoardColumn>
      </BoardColumnContainer>
    </BoardColumnsContainer>
  );
});

export const TaskKanbanView = React.memo(function TaskKanbanView(props: {
  hideTaskProjectLabels?: boolean;
  order: TaskOrderType;
  onChangeOrder: OnChangeOrderFnType;
  tasks: ListTasksSlimTaskType[];
  columns?: string[] | null;
  hideHeader?: boolean;
  onClickTask?: (task: ListTasksSlimTaskType) => boolean;
  isReady?: boolean;
}): JSX.Element {
  const [search, setSearch] = React.useState("");
  const params = usePath().params as TasksEDataParamsType;

  const [selectedTaskUUID, setSelectedTaskUUID] = React.useState<string | null>(
    params != null && "taskUUID" in params ? params.taskUUID ?? null : null,
  );
  const order = useOrder(props.order, props.tasks);

  const onSelectTask = React.useCallback(
    (task: ListTasksSlimTaskType) => {
      if (props.onClickTask != null) {
        const result = props.onClickTask(task);
        if (result) {
          return;
        }
      }

      setSelectedTaskUUID(task.uuid);
    },
    [props],
  );

  const onDragEnd = useDNDOnDragEnd({
    order,
    onChangeOrder: props.onChangeOrder,
    tasks: props.tasks,
  });

  const createTask = useNavigateTo({
    feature: Features.tasks,
    params: {
      view: 'create-task'
    }
  });

  const tasks = React.useMemo(() => {
    if (search === "") {
      return props.tasks;
    }

    return fuzzysort.go(search, props.tasks, {
      keys: ["title", "shortId"],
    }).map((result) => result.obj);
  }, [props.tasks, search]);

  if (props.tasks.length === 0) {
    if (props.isReady === true) {
      return (
        <EmptySplash
          icon={faEmptySet}
          label="No Tasks Here"
          hint="Click the + in the sidebar or use the button below to create a task"
          afterElement={
            <Button
              label="Create Task"
              onClick={createTask}
              style={{ marginTop: 5 }}
            />
          }
        />
      );
    } else {
      return <KanbanSkeleton />;
    }
  }

  return (
    <>
      <HideTaskProjectLabelsCtx.Provider
        value={props.hideTaskProjectLabels === true}
      >
        {selectedTaskUUID !== null ? (
          <Modal onClose={() => setSelectedTaskUUID(null)}>
            <TaskView uuid={selectedTaskUUID} />
          </Modal>
        ) : null}

        <DragDropContext onDragEnd={onDragEnd}>
          <BoardColumnsContainer>
            <FindView
              placeholder="Search tasks..."
              onChangeValue={setSearch}
              value={search}
            />
            {props.columns?.includes("*") ? (
              <KanbanColumn
                tasks={tasks}
                order={[
                  ...(order.todo ?? []),
                  ...(order.inprogress ?? []),
                  ...(order.inreview ?? []),
                  ...(order.done ?? []),
                  ...(order.wontdo ?? []),
                ]}
                onSelectTask={onSelectTask}
                id="all"
                label="All"
                onChangeOrder={props.onChangeOrder}
                currentOrder={order}
                hideHeader={props.hideHeader}
              />
            ) : null}
            {props.columns != null && props.columns.length === 0 ? (
              <KanbanColumn
                tasks={tasks}
                order={order.todo ?? []}
                onSelectTask={onSelectTask}
                id={null}
                label="Tasks"
                onChangeOrder={props.onChangeOrder}
                currentOrder={order}
                hideHeader={props.hideHeader}
              />
            ) : null}
            {props.columns == null || props.columns.includes("todo") ? (
              <KanbanColumn
                tasks={tasks}
                order={order.todo ?? []}
                onSelectTask={onSelectTask}
                id="todo"
                label="To-Do"
                onChangeOrder={props.onChangeOrder}
                currentOrder={order}
                hideHeader={props.hideHeader}
              />
            ) : null}
            {props.columns == null || props.columns.includes("inprogress") ? (
              <KanbanColumn
                tasks={tasks}
                order={order.inprogress ?? []}
                onSelectTask={onSelectTask}
                id="inprogress"
                label="In Progress"
                onChangeOrder={props.onChangeOrder}
                currentOrder={order}
                hideHeader={props.hideHeader}
              />
            ) : null}
            {props.columns == null || props.columns.includes("inreview") ? (
              <KanbanColumn
                tasks={tasks}
                order={order.inreview ?? []}
                onSelectTask={onSelectTask}
                id="inreview"
                label="In Review"
                onChangeOrder={props.onChangeOrder}
                currentOrder={order}
                hideHeader={props.hideHeader}
              />
            ) : null}
            {props.columns == null || props.columns.includes("done") ? (
              <KanbanColumn
                tasks={tasks}
                order={order.done ?? []}
                onSelectTask={onSelectTask}
                id="done"
                label="Done"
                onChangeOrder={props.onChangeOrder}
                currentOrder={order}
                hideHeader={props.hideHeader}
              />
            ) : null}
            {props.columns != null && props.columns.includes("wontdo") ? (
              <KanbanColumn
                tasks={tasks}
                order={order.wontdo ?? []}
                onSelectTask={onSelectTask}
                id="wontdo"
                label="Wont Do"
                onChangeOrder={props.onChangeOrder}
                currentOrder={order}
                hideHeader={props.hideHeader}
              />
            ) : null}
          </BoardColumnsContainer>
        </DragDropContext>
      </HideTaskProjectLabelsCtx.Provider>
    </>
  );
});
