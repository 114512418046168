import React from "react";
import { JoinHiylloMeetButton, Row } from "./components";
import {
  EventTypeEnum,
  type CalendarEvent,
} from "@hiyllo/omni-common/src/types/calendar/calendar-event";
import { CopyableLinkBox } from "../../../../ux/copyable-link-box";
import { Features } from "@hiyllo/omni-common/src/types/navigation/features";
import { type UseMoopsyQueryRetValAny } from "@moopsyjs/react";
import { useNavigate } from "@hiyllo/omni-router";
import { useIsSolo } from "../../../../platform/hooks/use-is-solo";
import { getRootURL } from "../../../../platform/environment/get-root-url";
import { VideoMeetingIcon } from "@hiyllo/icons/main";

export const MeetingCard = React.memo(function MeetingCard({
  event,
  retrieveEventQuery,
}: {
  event: CalendarEvent;
  retrieveEventQuery: UseMoopsyQueryRetValAny;
}): JSX.Element {
  const navigate = useNavigate();

  const onClickJoinMeeting = React.useCallback(() => {
    if (retrieveEventQuery.data?.event?.videoMeeting != null) {
      navigate({
        feature: Features.meet,
        params: {
          view: "event",
          eventUUID: event.uuid,
          password: retrieveEventQuery.data.event.videoMeeting.meetingPassword,
        },
      });
    }
  }, [event.uuid, navigate, retrieveEventQuery.data.event.videoMeeting]);
  const isSolo = useIsSolo();

  return (
    <Row style={{ alignItems: "stretch" }}>
      {event.videoMeeting != null &&
        !isSolo &&
        event.type !== EventTypeEnum.busy ? (
        <>
          <JoinHiylloMeetButton onClick={onClickJoinMeeting}>
            <VideoMeetingIcon width={20} color="white" />
            Join Hiyllo Meet
          </JoinHiylloMeetButton>
          <CopyableLinkBox
            label="Public Meeting Join Link"
            link={`${getRootURL()}/meet/${event.uuid}/${event.videoMeeting.meetingPassword
              }`}
          />
        </>
      ) : null}
    </Row>
  );
});
