import React from "react";
import { type AlertType } from "@hiyllo/omni-common/src/types/alerts/alert";
import { styled } from "@hiyllo/ux/styled";
import { UserImage } from "@hiyllo/omni-images";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneAlt, faTimes } from "@fortawesome/pro-light-svg-icons";

import * as MarkAlertsReadBP from "../../../../blueprints/alerts/mark-alerts-read";
import { useNavigate } from "@hiyllo/omni-router";
import { seamlessClient } from "../../../../seamless-client";
import { useLookupByUserId } from "@hiyllo/omni-continuity/main";

const AlertCallCardContainer = styled("div", ({ $theme }) => ({
  background: $theme.background3,
  padding: 20,
  borderRadius: 20,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: 15,
  borderColor: "#4caf50",
  borderWidth: 2.5,
  borderStyle: "solid",
}));

const CallerName = styled("div", ({ $theme }) => ({
  fontFamily: "hiyllo",
  fontSize: 24,
}));

const ButtonsRow = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  gap: 20,
});

const Button = styled("div", ({ $theme }) => ({
  background: $theme.buttonBackground,
  color: "white",
  padding: 10,
  paddingLeft: 15,
  paddingRight: 15,
  borderRadius: 30,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 5,
  cursor: "pointer",
  userSelect: "none",
}));

export const AlertCallCard = React.memo(function AlertCallCard(props: {
  alert: AlertType;
  onClose: () => void;
}): JSX.Element {
  const userId = props.alert.from ?? "";
  const lookupUser = useLookupByUserId(userId);
  const nav = useNavigate();
  const markAlertAsReadMutation =
    seamlessClient.useMutation<MarkAlertsReadBP.Plug>(MarkAlertsReadBP);

  const onAccept = React.useCallback(() => {
    // @ts-expect-error zzz
    const link = props.alert.actions?.[0].action.params.link;
    nav(link);
    props.onClose();
  }, [nav, props]);

  const onDecline = React.useCallback(() => {
    void markAlertAsReadMutation.call({ alertUUIDs: [props.alert.uuid] });
    props.onClose();
  }, [markAlertAsReadMutation, props]);

  return (
    <AlertCallCardContainer>
      <UserImage userId={userId} width={100} />
      <CallerName>{lookupUser.data?.name}</CallerName>
      <ButtonsRow>
        <Button onClick={onAccept}>
          <FontAwesomeIcon icon={faPhoneAlt} fixedWidth />
          Answer
        </Button>
        <Button style={{ background: "#444" }} onClick={onDecline}>
          <FontAwesomeIcon icon={faTimes} fixedWidth />
          Decline
        </Button>
      </ButtonsRow>
    </AlertCallCardContainer>
  );
});
