/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { HiylloForm, HiylloFormOptionsType } from "../../types/forms/form";
import { HiylloFormResponseFields } from "../../types/forms/response";
import { HiylloFormStructure } from "../../types/forms/structure";

export type ParamsType = {
  uuid: string;
  fields: HiylloFormResponseFields;
  respondentEmail: string | null;
};
export type ResponseType = void;
export const Endpoint = "forms/submit-form";
export const Method = "POST";

export interface Plug {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
}

export const isPublic = true;
export const paramsSchema = {"type":"object","properties":{"uuid":{"type":"string"},"fields":{"type":"object","additionalProperties":{"anyOf":[{"type":"object","properties":{"type":{"type":"string","enum":["text"]},"value":{"type":"string"}},"required":["type","value"]},{"type":"object","properties":{"type":{"type":"string","enum":["file"]},"fileId":{"type":"string"}},"required":["fileId","type"]}]}},"respondentEmail":{"type":["null","string"]}},"required":["fields","respondentEmail","uuid"],"$schema":"http://json-schema.org/draft-07/schema#"}