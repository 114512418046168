import React from "react";
import { faBell, faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { type AlertLinkType } from "../../../types/alerts/alert";
import { AnimatePresence } from "framer-motion";
import { useOnClickOutV2 } from "../../../ux/alpha/utils";
import { useAlertsContext } from "@hiyllo/omni-alerts";
import { AlertsPillContainer } from "./alerts/pill-components";
import { AlertsWindow } from "./alerts/alerts-window";
import { type useNavigate } from "@hiyllo/omni-router";
import { openWindow } from "../../../platform/open-window";

export function navigateToAlertLink(
  eNav: ReturnType<typeof useNavigate>,
  link: AlertLinkType,
): void {
  if ("href" in link) {
    openWindow(link.href);
  } else {
    eNav(link.enav);
  }
}

export const AlertsPill = React.memo(function AlertsPill(): JSX.Element {
  const [windowIsOpen, setWindowIsOpen] = React.useState(false);
  const clickOutSafeAreaRef = useOnClickOutV2(() => setWindowIsOpen(false));
  const { count, alertsCountQuery } = useAlertsContext();

  return (
    <div style={{ position: "relative" }}>
      <AlertsPillContainer
        _ref={clickOutSafeAreaRef}
        isActive={count !== 0}
        onClick={() => {
          setWindowIsOpen((v) => !v);
        }}
        animate={
          windowIsOpen
            ? {
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              paddingRight: 15,
              marginLeft: 15,
              transition: { duration: 0.01 },
            }
            : {
              borderTopRightRadius: 15,
              borderBottomRightRadius: 15,
              transition: { delay: 0.1 },
            }
        }
      >
        {windowIsOpen ? (
          <FontAwesomeIcon icon={faTimes} fixedWidth />
        ) : (
          <>
            <FontAwesomeIcon icon={faBell} style={{ fontSize: "0.9em" }} />
            <div>&nbsp;{count}</div>
          </>
        )}
      </AlertsPillContainer>
      <AnimatePresence>
        {windowIsOpen ? (
          <AlertsWindow
            alertsCountQuery={alertsCountQuery}
            onClose={() => {
              setWindowIsOpen(false);
            }}
          />
        ) : null}
      </AnimatePresence>
    </div>
  );
});
