import React from "react";
import { Node, Transforms } from "slate";
import { ReactEditor } from "slate-react";
import { useUploadFile } from "@hiyllo/omni-user-files";

/**
 * Handles uploading of pasted images
 */
export function useOnPaste(editor: ReactEditor) {
  const uploadFile = useUploadFile();

  return React.useCallback((evt: React.ClipboardEvent) => {
    const items = evt.clipboardData?.items ?? [];

    const kinds = [...items].map(i => i.kind);

    if (items.length === 0 || !kinds.some(k => k === "file")) return;

    evt.preventDefault();
    evt.stopPropagation();

    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (item.kind === "file") {
        const file = item.getAsFile();
        if (file == null) continue;

        void uploadFile(file).then((result) => {
          const imageElement = {
            type: "hiylloImage",
            src: result.src,
            fsId: result.fsId,
            children: [{ text: " " }]
          } as unknown as Node;
          Transforms.insertNodes(editor, imageElement);
        });
      }
    }
  }, [editor, uploadFile]);
}