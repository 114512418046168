export const SHAKE_KEYFRAME_TIME = 3;
export const SHAKE_KEYFRAME = [
  SHAKE_KEYFRAME_TIME,
  0,
  -SHAKE_KEYFRAME_TIME,
  0,
  SHAKE_KEYFRAME_TIME,
  0,
  -SHAKE_KEYFRAME_TIME,
  0,
  SHAKE_KEYFRAME_TIME,
  0,
  -SHAKE_KEYFRAME_TIME,
  0,
  SHAKE_KEYFRAME_TIME,
  0,
  -SHAKE_KEYFRAME_TIME,
  0,
  SHAKE_KEYFRAME_TIME,
  0,
  -SHAKE_KEYFRAME_TIME,
  0,
  SHAKE_KEYFRAME_TIME,
  0,
  -SHAKE_KEYFRAME_TIME,
  0,
  SHAKE_KEYFRAME_TIME,
  0,
  -SHAKE_KEYFRAME_TIME,
  0,
  SHAKE_KEYFRAME_TIME,
  0,
  -SHAKE_KEYFRAME_TIME,
  0,
  SHAKE_KEYFRAME_TIME,
  0,
  -SHAKE_KEYFRAME_TIME,
  0,
  SHAKE_KEYFRAME_TIME,
  0,
  -SHAKE_KEYFRAME_TIME,
  0,
  SHAKE_KEYFRAME_TIME,
  0,
  -SHAKE_KEYFRAME_TIME,
  0,
  SHAKE_KEYFRAME_TIME,
  0,
  -SHAKE_KEYFRAME_TIME,
  0,
  SHAKE_KEYFRAME_TIME,
  0,
  -SHAKE_KEYFRAME_TIME,
  0,
  SHAKE_KEYFRAME_TIME,
  0,
  -SHAKE_KEYFRAME_TIME,
  0,
  SHAKE_KEYFRAME_TIME,
  0,
  -SHAKE_KEYFRAME_TIME,
  0,
];
