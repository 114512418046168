import React from "react";
import { PillSelect } from "@hiyllo/ux/pill-select";
import { TaskKanbanView } from "../kanban-view/task-kanban-view";
import { type TaskOrderType } from "../../../types/tasks/tasks-organization";
import { DEFAULT_STATUSES, type ListTasksSlimTaskType } from "../../../types/tasks/task-item";
import { styled } from "@hiyllo/ux/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBarsSort, faEllipsisV, faListCheck, faSquare, faSquareCheck, faSquareKanban } from "@fortawesome/pro-light-svg-icons";
import { ListView } from "../list-view/list-view";
import { ContextMenuContainer, ContextMenuItem, useContextMenu } from "@hiyllo/ux/context-menu";
import { CircleButton } from "@hiyllo/ux/circle-button";

const Container = styled("div", {
  height: "100%",
  padding: 0,
  display: "flex",
  flexDirection: "column",
  gap: 10,
});

const ViewContainer = styled('div', {
  height: 0,
  flexGrow: 1,
});

const SpaceBetweenRow = styled("div", ({ $theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
}));

const viewTypeOptions = [
  {
    value: "kanban",
    label: <FontAwesomeIcon icon={faSquareKanban} />,
  },
  {
    value: "list",
    label: <FontAwesomeIcon icon={faListCheck} />,
  },
];

export interface TasksViewPropsType {
  extraHeaderOptions?: (JSX.Element | null)[] | JSX.Element;
  order: TaskOrderType;
  onChangeOrder: (
    order: TaskOrderType,
    updatedTask: ListTasksSlimTaskType | null,
  ) => void;
  tasks: ListTasksSlimTaskType[];
  isReady?: boolean;
  columns?: string[] | null;
  hideHeader?: boolean;
  onClickTask?: (task: ListTasksSlimTaskType) => boolean;
  hideTaskProjectLabels?: boolean;
}

export const TasksView = React.memo(function TasksView(
  props: TasksViewPropsType,
): JSX.Element {
  const [viewType, setViewType] = React.useState<"kanban" | "list">(
    window.localStorage.preferredTaskView ?? "kanban",
  );
  const [showWontDo, setShowWontDo] = React.useState<boolean>(false);
  const cxMenu = useContextMenu();

  const ViewComponent = viewType === "kanban" ? TaskKanbanView : ListView;

  return (
    <Container>
      <cxMenu.CXMenuContainer>
        <ContextMenuContainer>
          <ContextMenuItem
            icon={showWontDo ? faSquareCheck : faSquare}
            label="Show Wont Do Column"
            onClick={() => {
              setShowWontDo(!showWontDo);
            }}
          />
        </ContextMenuContainer>
      </cxMenu.CXMenuContainer>
      <SpaceBetweenRow>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 10 }}>
          {props.extraHeaderOptions ?? <div />}
        </div>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 10 }}>
          <PillSelect
            options={viewTypeOptions}
            value={viewType}
            onChangeValue={(value) => {
              window.localStorage.preferredTaskView = value;
              setViewType(value as "kanban" | "list");
            }}
            loading={false}
          />
          <CircleButton
            icon={faEllipsisV}
            onClick={(evt) => {
              if (evt != null) cxMenu.open(evt);
            }}
            size={27.5}
            secondary
          />
        </div>
      </SpaceBetweenRow>
      <ViewContainer>
        <ViewComponent
          order={props.order}
          onChangeOrder={props.onChangeOrder}
          tasks={props.tasks}
          isReady={props.isReady}
          columns={showWontDo ? ["todo", "inprogress", "inreview", "done", "wontdo"] : ["todo", "inprogress", "inreview", "done"]}
          hideTaskProjectLabels={props.hideTaskProjectLabels}
        />
      </ViewContainer>
    </Container>
  );
});
