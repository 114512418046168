import React from "react";

const MutedCtx = React.createContext<boolean>(false);
const SetMutedCtx = React.createContext<React.Dispatch<React.SetStateAction<boolean>>>(() => { });

export function useMuted(): boolean {
  return React.useContext(MutedCtx);
}

export function useSetMuted(): React.Dispatch<React.SetStateAction<boolean>> {
  return React.useContext(SetMutedCtx);
}

export const MuteProvider = React.memo(function MuteProvider(props: React.PropsWithChildren): JSX.Element {
  const [muted, setMuted] = React.useState(window.localStorage.muteAlerts === "1");

  React.useEffect(() => {
    window.localStorage.muteAlerts = muted ? "1" : "0";
  }, [muted]);

  return (
    <SetMutedCtx.Provider value={setMuted}>
      <MutedCtx.Provider value={muted}>
        {props.children}
      </MutedCtx.Provider>
    </SetMutedCtx.Provider>
  );
});