import React from "react";
import { motion } from "framer-motion";
import { useSelf } from "@hiyllo/omni-continuity";
import { UserImage } from "@hiyllo/omni-images/main";
import { styled } from "@hiyllo/ux/styled";
import { useCurrentTab } from "../../tabbing/tabs-provider";
import { Emoji } from "@hiyllo/omni-emoji";

const EmojiView = styled("div", ({ $theme }) => ({
  position: "absolute",
  bottom: -4,
  right: -4,
  height: 18,
  width: 18,
  borderRadius: 20 / 4,
  background: $theme.midground,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  fontSize: 12,
  userSelect: "none",
}));

export const SelfImage = React.memo(function SelfImage(props: {
  onClick?: () => void;
}): JSX.Element {
  const self = useSelf();
  const currentTab = useCurrentTab();

  return (
    <div style={{ height: 40, width: 40 }} className="SelfUserImage">
      <motion.div
        onClick={props.onClick}
        layoutId={"myprofileicon" + currentTab}
        style={{
          height: 40,
          width: 40,
          position: "absolute",
        }}
      >
        <UserImage userId={self.userId} width={40} />
        {self.status?.emoji != null ? (
          <EmojiView>
            <Emoji emoji={self.status.emoji} size={12.5} />
          </EmojiView>
        ) : null}
      </motion.div>
    </div>
  );
});
