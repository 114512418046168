/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
export type ParamsType = {
  taskUUID: string;
  fsId: string;
};
export type ResponseType = void;
export const Endpoint = "tasks/add-task-attachment";
export const Method = "POST";

export type Plug = {
  params: ParamsType,
  response: ResponseType,
  method: typeof Method,
  endpoint: typeof Endpoint,
};

export const RateLimitingConfig = {
  calls: 3,
  per: 500,
};export const paramsSchema = {"type":"object","properties":{"taskUUID":{"type":"string"},"fsId":{"type":"string"}},"required":["fsId","taskUUID"],"$schema":"http://json-schema.org/draft-07/schema#"}