import React from "react";
import { type ListTasksSlimTaskType } from "../../../types/tasks/task-item";
import { TinyPillContainer } from "../components/tiny-pill";
import { PriorityColorMap } from "../maps/priority-colors-map";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PriorityIconsMap } from "../maps/priority-icons-map";

export const CondensedDetailsPill = React.memo(
  function CondensedDetailsPill(props: {
    task: Omit<ListTasksSlimTaskType, "assignee">;
  }): JSX.Element {
    return (
      <TinyPillContainer style={{ paddingLeft: 0, borderRadius: 5 }}>
        <div
          style={{
            height: 25,
            backgroundColor: PriorityColorMap()[props.task.priority],
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            paddingRight: 5,
            paddingLeft: 5,
            fontSize: 12.5,
          }}
        >
          <FontAwesomeIcon
            icon={PriorityIconsMap[props.task.priority]}
            fixedWidth
          />
        </div>
        <div style={{ paddingRight: 5 }}>{props.task.shortId}</div>
      </TinyPillContainer>
    );
  },
);
