import { ReactEditor } from "slate-react";
import { Element, Editor, Transforms } from "slate";

/**
 * Makes it so that hitting delete when on the empty first line of the document will remove the line.
 */
export const withDeleteFirstLineBehavior = (editor: ReactEditor) => {
  const { deleteBackward } = editor;

  editor.deleteBackward = (...args) => {
    const { selection } = editor;

    if (selection) {
      const [firstNode] = Editor.node(editor, [0]);

      // Check if the selection is at the very start of the document
      if (Editor.isStart(editor, selection.anchor, [0]) && Element.isElement(firstNode)) {
        const isFirstLineEmpty = Editor.string(editor, [0]) === '';

        if (isFirstLineEmpty) {
          // Remove the first node (line) if it is empty
          Transforms.removeNodes(editor, { at: [0] });
          return;
        }
      }
    }

    // Default behavior
    deleteBackward(...args);
  };

  return editor;
};