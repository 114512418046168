import { styled } from "@hiyllo/ux/styled";
import React from "react";
import { useListWorkflows } from "../hooks/use-list-workflows";
import { LoadingSpinnerFullView } from "../../../platform/loading/spinner-loading-full";
import { Typography } from "@hiyllo/ux/typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEmptySet, faPlus } from "@fortawesome/pro-light-svg-icons";

const CenteredContainer = styled("div", {
  height: "100%",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
});

export const WorkflowsList = React.memo(function WorkflowsList(): JSX.Element {
  const listWorkflowsQuery = useListWorkflows(null);

  if (listWorkflowsQuery.isLoading || listWorkflowsQuery.isError) {
    return <LoadingSpinnerFullView />;
  }

  if (listWorkflowsQuery.data.workflows.length === 0) {
    return (
      <CenteredContainer>
        <Typography.HeaderIcon>
          <FontAwesomeIcon icon={faEmptySet} />
        </Typography.HeaderIcon>
        <Typography.Header>No Workflows Yet</Typography.Header>
        <Typography.SubHeader>Time to make your first one</Typography.SubHeader>
        <Typography.Paragraph>
          Click the <FontAwesomeIcon icon={faPlus} /> icon to create a workflow
        </Typography.Paragraph>
      </CenteredContainer>
    );
  }

  return <div>workflows list</div>;
});
