/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { DateYYYYMMDD, TimeWithTimezone } from "./base";
import { EventTimingWithComputed } from './calendar-event';

export enum DayString {
    mon = 'mon',
    tue = 'tue',
    wed = 'wed',
    thu = 'thu',
    fri = 'fri',
    sat = 'sat',
    sun = 'sun'
}

export interface BookATimeMemberType {
    userId: string;
    optional: false;
}

export type BookATimeSlotDuration = 15 | 30 | 45 | 60 | 90 | 120;

export interface BookATimeRange {
    day: DayString;
    from: TimeWithTimezone,
    to: TimeWithTimezone
}

export interface BookATimeSchedule {
    /**
     * The owner is the person whose calendar events will be added to,
     * the other members will be added as guests along with the person
     * booking the time.
     */
    owner: string
    /**
     * Other users who get added, to events automatically. All members
     * who are not marked optional must be avaialble for a slot to be
     * open
     */
    members: BookATimeMemberType[]
    /**
     * How long slots should be, in minutes
     */
    slotDuration: BookATimeSlotDuration
    /**
     * The range of times can be booked.
     */
    range: BookATimeRange[]
    /**
     * Name of "Book a Time"
     */
    name: string

    uuid: string
    /**
     * Short token used in link
     */
    token: string
}

export interface BookATimeSlot {
    timing: EventTimingWithComputed
}