/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
export type ParamsType = { taskUUID: string; dependencyUUID: string };
export type ResponseType = void;
export const Endpoint = "tasks/remove-task-dependency";
export const Method = "POST";

export interface Plug {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
}

export const RateLimitingConfig = {
  calls: 2,
  per: 750,
};
export const paramsSchema = {"type":"object","properties":{"taskUUID":{"type":"string"},"dependencyUUID":{"type":"string"}},"required":["dependencyUUID","taskUUID"],"$schema":"http://json-schema.org/draft-07/schema#"}