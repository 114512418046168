import React from "react";
import { LeftSidebar } from "../../components/left-sidebar";
import { MainContent } from "../../components/main-content";
import { type StuffEDataParamsType } from "@hiyllo/omni-common/src/types/navigation/edata";
import { StuffTreeView } from "../../../stuff/components/tree-view/main-beta";
import { StuffContainerView } from "../../../stuff/components/container-view";
import {
  FeatureRouter,
  type FeatureRoutesType,
} from "../../navigation/feature-router";
import { MeetingRecordingView } from "../../../stuff/views/meeting-recording";
import { DocumentView } from "../../../stuff/documents/document-view";
import { AssetDetailsSidebar } from "../../../stuff/components/asset-details-sidebar";
import { StuffAssetView } from "../../../stuff/views/stuff-asset-view";
import { DocumentHistoryView } from "../../../stuff/documents/document-history";
import { TabDetails } from "../../tabbing/tabs-provider";
import { faFolder } from "@fortawesome/pro-light-svg-icons";

const Routes: FeatureRoutesType<StuffEDataParamsType> = {
  document: DocumentView,
  container: StuffContainerView,
  "meeting-recording": MeetingRecordingView,
  "document-history": DocumentHistoryView,
  asset: StuffAssetView,
};

export const StuffFeature = React.memo(function StuffFeature(props: {
  params: StuffEDataParamsType;
}): JSX.Element {
  if (props.params.view === 'document-history') {
    return <DocumentHistoryView />;
  }

  return (
    <TabDetails icon={faFolder} label="Stuff">
      <LeftSidebar>
        {props.params.view === "container" ? (
          <StuffTreeView uuid={props.params.uuid} />
        ) : props.params.view === "document" ? (
          <AssetDetailsSidebar />
        ) : (
          <StuffTreeView uuid={null} />
        )}
      </LeftSidebar>
      <MainContent noPadding>
        <FeatureRouter
          routes={Routes}
          default={{ view: "container", uuid: null }}
        />
      </MainContent>
    </TabDetails>
  );
});
