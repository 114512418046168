import React, { useContext, type FormEvent } from "react";
import { FormIsLoadingContext, FormSubmitContext } from "./contexts";
import { LoadingSpinner } from "@hiyllo/ux/loading-spinner";
import { useTheme } from "@hiyllo/ux/theme";

interface FormButtonPropsType {
  onClick?: (event: React.MouseEvent) => void;
  label: string | JSX.Element;
  id?: string;
  style?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
  noMargin?: boolean;
  isLoading?: boolean;
}

export const FormButton = (props: FormButtonPropsType): JSX.Element => {
  const isDisabled =
    useContext(FormIsLoadingContext) || props.isLoading === true;
  const theme = useTheme();

  return (
    <div
      style={{
        paddingBottom: props.noMargin === true ? 0 : 12.5,
        ...(props.containerStyle ?? {}),
      }}
    >
      {isDisabled ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            color: "#2196f3",
          }}
        >
          <LoadingSpinner />
        </div>
      ) : (
        <button
          id={props.id}
          type={props.onClick != null ? undefined : "submit"}
          onClick={
            props.onClick != null
              ? (evt) => {
                evt.preventDefault();
                if (props.onClick != null) props.onClick(evt);
              }
              : undefined
          }
          disabled={isDisabled}
          style={{
            background: theme.buttonBackground,
            color: theme.foreground,
            border: "none",
            padding: "0.9em",
            paddingLeft: "1.1em",
            paddingRight: "1.1em",
            borderRadius: "0.8em",
            cursor: isDisabled ? "" : "pointer",
            ...props.style,
          }}
        >
          {props.label}
        </button>
      )}
    </div>
  );
};

type ChildType = JSX.Element | null;

interface PropsType {
  onSubmit?: (evt?: FormEvent) => void;
  isLoading?: boolean;
  children: ChildType | ChildType[];
  style?: React.CSSProperties;
}

export const Form = (props: PropsType): JSX.Element => {
  return (
    <FormIsLoadingContext.Provider value={props.isLoading ?? false}>
      <FormSubmitContext.Provider value={props.onSubmit ?? null}>
        <form
          onSubmit={(evt) => {
            evt.preventDefault();
            evt.stopPropagation();

            if (props.onSubmit != null) {
              props.onSubmit(evt);
            }
          }}
          style={props.style}
        >
          {props.children}
        </form>
      </FormSubmitContext.Provider>
    </FormIsLoadingContext.Provider>
  );
};

export { FormIsLoadingContext };
