import { styled } from "@hiyllo/ux/styled";

export const AccountsPageWrapper = styled("div", ({ $theme }) => ({
  height: "100%",
  width: "100%",
  backgroundColor: $theme.background1,
  color: $theme.foreground,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  fontFamily: "Inter, sans-serif",
}));

export const CardHeader = styled("div", {
  fontSize: 30,
  paddingBottom: 10,
});

export const CardLinksContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  gap: 20,
  paddingTop: 10,
});

export const CardLink = styled("div", {
  textDecoration: "underline",
  cursor: "pointer",
});
