import React from "react";
import { DayWidthContext } from "../contexts";
import { useDetermineDateFromPosition } from "../hooks/use-determine-date-from-position";
import { useHourHeight } from "../hooks/use-hour-height";
import { roundToIncrements } from "../utils";
import { DatePositioned } from "./date-positioned";
import moment from "moment";

export interface CreateEventSkeletonType {
  x: number;
  y: number;
  height: number;
  width: number;
}

export const CreateSkeleton = React.memo(function CreateSkeleton(props: {
  creatingEventSkeleton: CreateEventSkeletonType;
}): JSX.Element {
  const dayWidth = React.useContext(DayWidthContext);
  const determineDateFromPosition = useDetermineDateFromPosition(dayWidth ?? 0);
  const hourHeight = useHourHeight();
  const minuteHeight = hourHeight / 60;
  const quarterHourHeight = minuteHeight * 15;
  const date = determineDateFromPosition(props.creatingEventSkeleton);
  const durationHours = Math.floor(props.creatingEventSkeleton.height / 60);
  const durationMins = roundToIncrements(
    Math.floor(props.creatingEventSkeleton.height % 60),
    15,
  );

  return (
    <DatePositioned date={date}>
      <div
        style={{
          height:
            roundToIncrements(
              props.creatingEventSkeleton.height,
              quarterHourHeight,
            ) -
            4 -
            5,
          width: props.creatingEventSkeleton.width - 5,
          border: "2.5px red dashed",
          userSelect: "none",
        }}
      >
        <div style={{ padding: 10 }}>
          {moment().hours(date.hours).minutes(date.minutes).format("h:mma")}-
          {moment()
            .hours(date.hours + durationHours)
            .minutes(date.minutes + durationMins)
            .format("h:mma")}
        </div>
      </div>
    </DatePositioned>
  );
});
