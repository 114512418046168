import React from "react";
import { useConfig } from "../../platform/config/config-context";

export const SupportFeature = React.memo(
  function SupportFeature(): JSX.Element {
    const config = useConfig();

    return (
      <iframe
        style={{
          border: "none",
          outline: "none",
          height: "100%",
          width: "100%",
        }}
        src={
          config.isSolo
            ? "https://www.hiyllo.support/solo"
            : "https://www.hiyllo.support/work"
        }
      />
    );
  },
);
