import React from "react";
import { type TaskOrderType } from "../../../types/tasks/tasks-organization";
import { Tenant } from "../../../platform/tenancy";
import { Cache } from "../../../platform/cache";
import { seamlessClient } from "../../../seamless-client";
import * as SprintSpec from "../../../blueprints/tasks/pubsub/sprint-spec";
import { type ListTasksSlimTaskType } from "../../../types/tasks/task-item";
import { useGetSprint } from "./use-get-sprint";
import { useSetSprintTasksOrder } from "./use-set-sprint-tasks-order";

export function useSprintOrder(opts: { sprintUUID: string }): {
  order: TaskOrderType | null;
  onChangeOrder: (
    newOrder: TaskOrderType,
    updatedTask: ListTasksSlimTaskType | null,
  ) => void;
} {
  const liveSprint = useGetSprint({ sprintUUID: opts.sprintUUID });
  Cache.useCacheLive(`tasks.sprint.${opts.sprintUUID}`, liveSprint.data);
  const setSprintTasksOrderMutation = useSetSprintTasksOrder();

  const [order, setOrder] = React.useState<TaskOrderType | null>(null);

  seamlessClient.useSubscribeToTopic<SprintSpec.Typings>(
    SprintSpec,
    `[${Tenant}]sprint/${opts.sprintUUID}`,
    (sprint) => {
      setOrder(sprint.order ?? null);
    },
  );

  React.useEffect(() => {
    if (liveSprint.data != null) {
      setOrder(liveSprint.data.sprint.order ?? {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [liveSprint.data != null]);

  const onChangeOrder = React.useCallback(
    (
      newOrder: TaskOrderType,
      updatedTask: ListTasksSlimTaskType | null,
    ): void => {
      setOrder(newOrder);
      void setSprintTasksOrderMutation.call({
        sprintUUID: opts.sprintUUID,
        order: newOrder,
      });
    },
    [setSprintTasksOrderMutation, opts],
  );

  return {
    order,
    onChangeOrder,
  };
}
