/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { OAuthScopeEnum } from "../../types/oauth/scopes";

export type ParamsType = {
  /**
   * The id of the app the user is linking their account to
   */
  appId: string;
  /**
   * The authorized scopes
   */
  scopes: OAuthScopeEnum[];
};
export type ResponseType = {
  returnUrl: string;
};
export const Endpoint = "oauth/authorize";
export const Method = "POST";

export interface Plug {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
}

export const RateLimitingConfig = {
  calls: 2,
  per: 2500,
};
export const paramsSchema = {"type":"object","properties":{"appId":{"description":"The id of the app the user is linking their account to","type":"string"},"scopes":{"description":"The authorized scopes","type":"array","items":{"enum":["calendar:read","calendar:write"],"type":"string"}}},"required":["appId","scopes"],"$schema":"http://json-schema.org/draft-07/schema#"}