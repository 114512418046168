import React from "react";
import { LeftSidebar } from "../tokyo/components/left-sidebar";
import { LSHeader } from "../tokyo/components/ls-header";
import { MainContent } from "../tokyo/components/main-content";
import {
  FeatureRouter,
  type FeatureRoutesType,
} from "../tokyo/navigation/feature-router";
import { type StudioEDataParamsType } from "../../types/navigation/edata";
import { WorkflowsList } from "./views/workflows-list";
import { useNavigateTo } from "@hiyllo/omni-router";
import { Features } from "../../types/navigation/features";
import { CircleButton } from "@hiyllo/ux/circle-button";
import { faPlus } from "@fortawesome/pro-light-svg-icons";
import { CreateWorkflowView } from "./views/create-workflow";
import { WorkflowView } from "./views/workflow-view/workflow-view";

const FormsRoutes: FeatureRoutesType<StudioEDataParamsType> = {
  "workflows-list": WorkflowsList,
  "create-workflow": CreateWorkflowView,
  workflow: WorkflowView,
};

const DefaultRoute: StudioEDataParamsType = { view: "workflows-list" };

export const StudioFeature = React.memo(function StudioFeature(): JSX.Element {
  const navigateToCreateWorkflow = useNavigateTo({
    feature: Features.studio,
    params: {
      view: "create-workflow",
    },
  });

  return (
    <>
      <LeftSidebar>
        <LSHeader
          label="Studio"
          actionButton={
            <CircleButton
              onClick={navigateToCreateWorkflow}
              icon={faPlus}
              size={30}
            />
          }
        />
      </LeftSidebar>
      <MainContent noPadding>
        <FeatureRouter routes={FormsRoutes} default={DefaultRoute} />
      </MainContent>
    </>
  );
});
