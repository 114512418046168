/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { DayOfWeek } from "./base";

export interface DailyRepeatingSchedule {
  type: "daily";
  every: {
    /**
     * 1 would mean repeat every day, 2 every other day, and so on...
     */
    days: number;
  };
  until?: Date | null;
}

export interface WeeklyRepeatingSchedule {
  type: "weekly";
  every: {
    weeks: number;
  };
  on: {
    dow: DayOfWeek[];
  };
}

export interface MonthlyRepeatingSchedule {
  type: "monthly";
  on: /**
   * Repeat on the x of the month
   */
  | { type: "date"; dom: number }
  /**
   * Repeat on the nth day of the month
   */
  | { type: "day"; dow: DayOfWeek; nth: number };
}

export interface YearlyRepeatingSchedule {
  type: "yearly";
  on: {
    dom: number;
    month: number;
  };
}

export enum RepeatingConfigAsEnum {
  fromTo = "fromTo",
}

export type RepeatingSchedule =
  | DailyRepeatingSchedule
  | WeeklyRepeatingSchedule
  | MonthlyRepeatingSchedule
  | YearlyRepeatingSchedule;

export interface RepeatingConfig {
  schedule: RepeatingSchedule;
  endOn: {
    /**
     * Repeating events MUST end
     */
    date: Date;
  };
  startOn: {
    date: Date;
  };
  isRoot: boolean;
  /**
   * For example, when as="fromTo" we still store it as a daily repeating event with an endOnd.date, but
   * we can tweak the UI to make it a bit more cohesive
   */
  as?: RepeatingConfigAsEnum | null;
}

export enum ActionRepeatContextEnum {
  this = "this",
  thisAndFuture = "thisAndFuture",
  all = "all",
}
