/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { EDataType } from "../navigation/edata";
import { Features } from "../navigation/features";

export type AlertPriorityType = 0 | 1;

export type AlertIconType = "chat" | "task" | "phone" | null;

export enum AlertFeature {
  chat = "chat",
  tasks = "tasks",
  calendar = "calendar",
  mail = "mail",
  call = "call",
  other = "other",
}

export type AlertLinkType = { href: string } | { enav: EDataType };

export interface JoinMeetingActionType {
  format: "join-meeting";
  label?: string;
  as: "meeting" | "call";
  action: {
    type: "link";
    params: {
      link: EDataType;
    };
  };
}

export interface RSVPActionType {
  format: "rsvp";
  action: {
    eventUUID: string;
  };
}

export type AlertActionsType = JoinMeetingActionType | RSVPActionType;

export enum AlertSpecialType {
  call = "call",
}

export type ChatMessageEAD = {
  type: "chat/message";
  senderId: string;
  senderName: string;
  in: string | null;
  message: string;
  link: EDataType;
};

export type MeetingEAD = {
  type: "meeting";
  startsAt: Date;
  eventUUID: string;
  title: string;
  link: EDataType;
};

export type EnhancedAlertData = MeetingEAD | ChatMessageEAD;

export interface AlertType {
  priority: AlertPriorityType;
  title: string;
  body: string | null;
  imageSrc: string | null;
  icon: AlertIconType;
  feature: AlertFeature;
  link: AlertLinkType | null;
  actions?: AlertActionsType[];
  uuid: string;
  receiver: string;
  tag: string | null;
  date: Date;
  persistVisibleTill?: Date | null;
  collapseOnLabel?: string | null;
  specialType?: AlertSpecialType | null;
  ead?: EnhancedAlertData | null;
  from?: string | null;
}
