import { faEmptySet } from "@fortawesome/pro-light-svg-icons";
import { EmptySplash } from "@hiyllo/ux/empty-splash";
import { LoadingSpinner } from "@hiyllo/ux/loading-spinner";
import { styled } from "@hiyllo/ux/styled";
import React from "react";

type GalleryItem = {
  src: string;
};

const CenteredFull = styled("div", {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  width: "100%",
});

function determineExtension(urlIn: string): string {
  const url = new URL(urlIn);
  const fileName = url.pathname.split("/").pop();
  const extension = fileName?.split(".").pop();
  return (extension ?? "").toLowerCase();
}

function determineTypeFromExtension(extension: string): string | null {
  switch (extension) {
    case "jpg":
    case "jpeg":
    case "png":
    case "gif":
    case "webp":
    case "svg":
    case "bmp":
      return "image/";
    case "mp4":
    case "webm":
    case "ogg":
    case "avi":
    case "mov":
    case "wmv":
    case "flv":
    case "mkv":
      return "video/";
    case "mp3":
    case "wav":
    case "ogg":
    case "flac":
    case "aac":
    case "wma":
    case "m4a":
      return "audio/";
    case "pdf":
      return "application/pdf";
    default:
      return null;
  }
}

const GalleryPanel = React.memo(function GalleryPanel(props: {
  item: GalleryItem;
}): JSX.Element {
  const type = React.useMemo(() => {
    const extension = determineExtension(props.item.src);
    return determineTypeFromExtension(extension) ?? '';
  }, [props.item]);

  return (
    <CenteredFull>
      {type.startsWith("image/") ? (
        <img
          src={props.item.src}
          alt="Asset"
          style={{
            maxWidth: "100%",
            maxHeight: "100%",
            objectFit: "contain",
            objectPosition: "center",
          }}
        />
      ) : type.startsWith("video/") ? (
        <video
          src={props.item.src}
          controls
          style={{
            maxWidth: "100%",
            maxHeight: "100%",
            objectFit: "contain",
            objectPosition: "center",
          }}
        />
      ) : type.startsWith("audio/") ? (
        <audio
          src={props.item.src}
          controls
          style={{
            maxWidth: "100%",
            maxHeight: "100%",
            objectFit: "contain",
            objectPosition: "center",
          }}
        />
      ) : type === "application/pdf" ?
        <embed type="application/pdf" src={props.item.src} style={{ width: "100%", height: "100%", paddingTop: 60 }} />
        : (
          <EmptySplash icon={faEmptySet} label="No preview available" hint={`"${type}" is not supported`} />
        )}
    </CenteredFull>
  );
});

export const FileGallery = React.memo(function FileGallery(props: {
  files: [GalleryItem];
}): JSX.Element {
  return <GalleryPanel item={props.files[0]} />;
});