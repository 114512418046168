import { styled } from "@hiyllo/ux/styled";

export const WarningCard = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 10,
  // background: "#ff9800",
  color: "#ff9800",
  border: "2px #ff9800 solid",
  padding: 10,
  borderRadius: 10,
});
