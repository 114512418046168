import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as LoginBP from "../../blueprints/accounts/login/login-with-native-token";
import { onLoginTokenAvailable, seamlessClient } from "../../seamless-client";
import { EmptySplash } from "@hiyllo/ux/empty-splash";
import { faSpinner } from "@fortawesome/pro-light-svg-icons";

export const ElectronLogin = React.memo(function ElectronLogin(): JSX.Element {
  const loginMutation =
    seamlessClient.useMutation<LoginBP.Plug>(LoginBP);
  const { token } = useParams<{ token: string }>();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (token == null) {
      return;
    }

    void loginMutation.call({ token }).then((res) => {
      void onLoginTokenAvailable(res.tokenPackage).then(() => {
        navigate("/");
        window.location.reload();
      });
    });
  }, []);

  return <EmptySplash icon={faSpinner} label="Logging in..." />;
});
