/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { AccountAuthenticationMethod } from "../../../types/accounts/authentication";

export type ParamsType = {
  identifier: string;
};
export type ResponseType = {
  status:
    | AccountAuthenticationMethod.password
    | AccountAuthenticationMethod.hiylloAuth;
};
export const Endpoint = "auth/query-login";
export const Method = "POST";

export interface Plug {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
}
export const isPublic = true;

export const RateLimitingConfig = {
  calls: 2,
  per: 500,
};
export const paramsSchema = {"type":"object","properties":{"identifier":{"type":"string"}},"required":["identifier"],"$schema":"http://json-schema.org/draft-07/schema#"}