/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { PriorityType } from "../../types/tasks/task-item";

export type ParamsType = {
  priority: PriorityType;
  taskUUID: string;
};
export type ResponseType = void;
export const Endpoint = "tasks/update-task-priority";
export const Method = "POST";

export type Plug = {
  params: ParamsType,
  response: ResponseType,
  method: typeof Method,
  endpoint: typeof Endpoint,
};

export const RateLimitingConfig = {
  calls: 1,
  per: 500,
};export const paramsSchema = {"type":"object","properties":{"priority":{"description":"Underlying system uses P0-P4 but we can map these to other values (e.g. \"high\" | \"medium\" | \"low\")\non the frontend","enum":[0,1,2,3,4],"type":"number"},"taskUUID":{"type":"string"}},"required":["priority","taskUUID"],"$schema":"http://json-schema.org/draft-07/schema#"}