/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
export type ParamsType = { message: string; sessionUUID: string | null };
export type ResponseType = { response: string; sessionUUID: string };
export const Endpoint = "executive-assistant/send-request";
export const Method = "POST";

export interface Plug {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
}

export const RateLimitingConfig = {
  calls: 2,
  per: 1000,
};
export const isPublic = false;
export const paramsSchema = {"type":"object","properties":{"message":{"type":"string"},"sessionUUID":{"type":["null","string"]}},"required":["message","sessionUUID"],"$schema":"http://json-schema.org/draft-07/schema#"}