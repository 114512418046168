import { styled } from "@hiyllo/ux/styled";
import React from "react";

const Row = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 10,
  cursor: "pointer",
});

const OuterCircle = styled<"div", { selected: boolean }>(
  "div",
  ({ $theme, selected }) => ({
    height: 18,
    width: 18,
    borderWidth: 2,
    borderColor: $theme.foreground,
    opacity: selected ? 1 : 0.4,
    borderStyle: "solid",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }),
);

const InnerCircle = styled("div", ({ $theme }) => ({
  height: 12,
  width: 12,
  borderRadius: "50%",
  background: $theme.foreground,
}));

interface RadioPropsType {
  selected: boolean;
  onSelect: () => void;
  label: string;
}

export const Radio = React.memo(function Radio(
  props: RadioPropsType,
): JSX.Element {
  return (
    <Row onClick={props.onSelect}>
      <OuterCircle selected={props.selected}>
        {props.selected ? <InnerCircle /> : null}
      </OuterCircle>
      {props.label}
    </Row>
  );
});

export const RadiosContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: 8,
  paddingTop: 10,
  paddingBottom: 10,
});
