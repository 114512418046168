/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { TaskProject } from "../../types/tasks/tasks-organization";

export type ParamsType = null;
export type ResponseType = {
  projects: TaskProject[]
};
export const Endpoint = "tasks/get-my-projects";
export const Method = "GET";

export type Plug = {
  params: ParamsType,
  response: ResponseType,
  method: typeof Method,
  endpoint: typeof Endpoint,
};

export const RateLimitingConfig = {
  calls: 5,
  per: 750,
};export const paramsSchema = {"type":"null","$schema":"http://json-schema.org/draft-07/schema#"}