import {
  type MoopsyBlueprintConstsType,
  type MoopsyBlueprintPlugType,
} from "@moopsyjs/core";
import { seamlessClient } from "../../seamless-client";
import {
  type AnyMoopsyQuery,
  type UseMoopsyMutationRetVal,
} from "@moopsyjs/react";

export function createPostEndpointHook<Plug extends MoopsyBlueprintPlugType>(
  BP: MoopsyBlueprintConstsType,
) {
  return function useHook(options?: {
    querySideEffects?: Array<AnyMoopsyQuery | null>;
  }): Omit<UseMoopsyMutationRetVal<Plug>, "call"> & {
    call: (params: Plug["params"]) => Promise<Plug["response"]>;
  } {
    const mutation = seamlessClient.useMutation<Plug>(BP, options);

    const wrappedCall = async (
      params: Plug["params"],
    ): Promise<Plug["response"]> => {
      return await mutation.call(params);
    };

    return {
      ...mutation,
      call: wrappedCall,
    };
  };
}
