import React from "react";
import {
  useDeriveURL,
  useNavigate,
  usePath,
} from "@hiyllo/omni-router";
import { type StuffEDataParamsType } from "../../../types/navigation/edata";
import * as GetAssetBP from "../../../blueprints/stuff/get-asset";
import { seamlessClient } from "../../../seamless-client";
import { LoadingSpinnerFullView } from "../../../platform/loading/spinner-loading-full";
import { ErrorSplash } from "../../../ux/error-splash";
import { styled } from "@hiyllo/ux/styled";
import { Typography } from "@hiyllo/ux/typography";
import { Features } from "../../../types/navigation/features";
import { Modal } from "@hiyllo/ux/modal";
import { Button } from "@hiyllo/ux/button";
import { IS_BETA_ENV } from "../../../platform/xp";
import { Chain } from "./chain";
import { AccessControlModal } from "./access-control-modal";

const Container = styled("div", ({ $theme }) => ({
  color: $theme.foreground,
  padding: 20,
  display: "flex",
  flexDirection: "column",
  height: "calc(100% - 40px)",
}));



export const AssetDetailsSidebar = React.memo(
  function AssetDetailsSidebar(): JSX.Element {
    const [showSharing, setShowSharing] = React.useState(false);
    const [linkCopied, setLinkCopied] = React.useState(false);
    const params = usePath().params as StuffEDataParamsType;
    const assetQuery = seamlessClient.useQuery<GetAssetBP.Plug>(
      GetAssetBP,
      { uuid: params.uuid as string },
    );
    const navigate = useNavigate();
    const deriveURL = useDeriveURL();

    if (assetQuery.error != null) {
      return <ErrorSplash error={assetQuery.error} />;
    }

    if (assetQuery.isLoading) {
      return <LoadingSpinnerFullView />;
    }

    if (assetQuery.data.asset.type === "document") {
      const { asset } = assetQuery.data;
      return (
        <>
          {showSharing ? (
            <Modal onClose={() => setShowSharing(false)}>
              <AccessControlModal element={{ type: "asset", asset }} elementQuery={assetQuery} />
            </Modal>
          ) : null}
          <Container>
            <Typography.SubHeader>
              <b>{assetQuery.data.asset.name}</b>
            </Typography.SubHeader>
            <div style={{ height: 5 }} />
            <Chain elementUUID={assetQuery.data.asset.parent as string} removePadding />
            <div style={{ height: 10 }} />
            <Button
              isSecondary
              label="Copy Link to Document"
              success={linkCopied}
              successMessage="Link Copied!"
              onClick={() => {
                const url = deriveURL({
                  feature: Features.stuff,
                  params: {
                    view: "document",
                    uuid: asset.uuid,
                  },
                });
                void window.navigator.clipboard.writeText(url);
                setLinkCopied(true);
              }}
            />
            <div style={{ height: 10 }} />
            {IS_BETA_ENV ? (
              <Button
                isSecondary
                label="View History"
                onClick={() => {
                  navigate({
                    feature: Features.stuff,
                    params: {
                      view: "document-history",
                      uuid: asset.uuid,
                    },
                  });
                }}
              />
            ) : null}
            <div style={{ height: 0, flexGrow: 1 }} />
            <div
              onClick={() => setShowSharing(true)}
              style={{ cursor: "pointer" }}
            >
              Manage Access
            </div>
          </Container>
        </>
      );
    }

    return <div />;
  },
);
