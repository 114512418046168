/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { TaskProject } from "../../../types/tasks/tasks-organization";

export const TopicID = "project";
export type TopicNameType = `[${string}]project/${string}`;
export type SubscriptionParamsType = null;
export type MessageType = TaskProject;
export const TopicNameRegExp = new RegExp('/project\\/[A-z]*/');
export const SubscriptionParamsSchema = { type: "null" };

export type Typings = {
  TopicNameType: TopicNameType,
  SubscriptionParamsType: SubscriptionParamsType,
  MessageType: MessageType;
};