import {
  faEmptySet,
  faPhoneArrowDown,
  faSquareArrowDown,
} from "@fortawesome/pro-light-svg-icons";
import { EmptySplash } from "@hiyllo/ux/empty-splash";
import { styled } from "@hiyllo/ux/styled";
import React from "react";
import * as GetDownloadLinkBP from "../../blueprints/misc/get-download-link";
import { seamlessClient } from "../../seamless-client";
import { LoadingSpinnerFullView } from "../../platform/loading/spinner-loading-full";

const Container = styled("div", {
  height: "100%",
  width: "100%",
  background: "black",
  color: "white",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

export const DownloadApp = (): JSX.Element => {
  const downloadLinkQuery =
    seamlessClient.useQuery<GetDownloadLinkBP.Plug>(GetDownloadLinkBP, {
      userAgent: window.navigator.userAgent,
    });

  if (downloadLinkQuery.isLoading) {
    return <LoadingSpinnerFullView />;
  }

  if (downloadLinkQuery.isError || downloadLinkQuery.data.url === null) {
    return (
      <EmptySplash
        icon={faEmptySet}
        label="Unsupported Device"
        hint="We don't have an app for your device yet"
      />
    );
  }

  if (downloadLinkQuery.data.url) {
    window.location.href = downloadLinkQuery.data.url;
  }

  return (
    <EmptySplash
      icon={faSquareArrowDown}
      label="Download will begin shortly..."
    />
  );
};
