/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { TaskDueDateType } from "../../types/tasks/task-item";

export type ParamsType = {
  dueDate: TaskDueDateType | null,
  taskUUID: string
};
export type ResponseType = void;
export const Endpoint = "tasks/update-task-due-date";
export const Method = "POST";

export type Plug = {
  params: ParamsType,
  response: ResponseType,
  method: typeof Method,
  endpoint: typeof Endpoint,
};

export const RateLimitingConfig = {
  calls: 3,
  per: 500,
};export const paramsSchema = {"type":"object","properties":{"dueDate":{"anyOf":[{"$ref":"#/definitions/TaskDueDateType"},{"type":"null"}]},"taskUUID":{"type":"string"}},"required":["dueDate","taskUUID"],"definitions":{"TaskDueDateType":{"type":"object","properties":{"date":{"type":"object","format":"date-time"},"as":{"enum":["day","day-and-time"],"type":"string"}},"required":["as","date"]}},"$schema":"http://json-schema.org/draft-07/schema#"}