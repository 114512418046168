import {
  TrackLoop,
  type UseParticipantsOptions,
} from "@livekit/components-react";
import React from "react";
import type { TrackReferenceOrPlaceholder } from "@livekit/components-core";
import { styled } from "@hiyllo/ux/styled";

export interface GridLayoutProps
  extends React.HTMLAttributes<HTMLDivElement>,
    Pick<UseParticipantsOptions, "updateOnlyOn"> {
  children: React.ReactNode;
  tracks: TrackReferenceOrPlaceholder[];
}

const TrackCountContext = React.createContext<number>(0);

export function useTrackGridElementStyles(): React.CSSProperties {
  const trackCount = React.useContext(TrackCountContext);

  if (trackCount === 1) {
    return {
      width: "100%",
      height: "100%",
    };
  }
  if (trackCount === 2) {
    return {
      width: "50%",
      height: "100%",
    };
  }
  if (trackCount <= 4) {
    return {
      width: "50%",
      height: "50%",
    };
  }
  if (trackCount <= 6) {
    return {
      width: "33.333%",
      height: "50%",
    };
  }
  if (trackCount <= 9) {
    return {
      width: "33.333%",
      height: "33.333%",
    };
  }
  if (trackCount <= 12) {
    return {
      width: "25%",
      height: "33.333%",
    };
  }
  // if(trackCount <= 16) {
  return {
    width: "25%",
    height: "25%",
  };
  // }
}

const GridContainer = styled("div", {
  height: "100%",
  width: "100%",
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  justifyContent: "center",
});

export function GridLayout({ tracks, ...props }: GridLayoutProps): JSX.Element {
  return (
    <TrackCountContext.Provider value={tracks.length}>
      <GridContainer>
        <TrackLoop tracks={tracks}>{props.children}</TrackLoop>
      </GridContainer>
    </TrackCountContext.Provider>
  );
}

export const TrackGridElement = React.memo(function TrackGridElement(
  props: React.PropsWithChildren,
): JSX.Element {
  const styles = useTrackGridElementStyles();
  return <div style={styles}>{props.children}</div>;
});
