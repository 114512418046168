import React from "react";

import { createLazyComponent } from "../../ux/create-lazy-component";

import { TasksFeature } from "./features/tasks";
import { CalendarFeature } from "./features/calendar";
import { MeetFeature } from "./features/meet";
import { HomeView } from "../home/views/home-view";
import { StuffFeature } from "./features/stuff/stuff-feature";
import { SupportFeature } from "../support/support-feature";
import AdminDashboard from "../admin/dashboard";
import { FormsFeature } from "./features/forms";
import { FeedbackFeature } from "../feedback/feedback-feature";
import { StudioFeature } from "../studio/studio-feature";
import { Features } from "../../types/navigation/features";
import EDataRouter from "./navigation/e-data-router";
import { ContactsFeature } from "../contacts/contacts-feature";

const ProfileFeature = createLazyComponent(
  async () => await import("./features/profile"),
);
const MailFeature = createLazyComponent(
  async () => await import("./features/mail/mail-feature"),
);
const ChatFeature = createLazyComponent(
  async () => await import("./features/chat/chat-feature"),
);
const OrganizationFeature = createLazyComponent(
  async () => await import("./features/organization"),
);

ChatFeature.preload();

export const NilFeature = (): JSX.Element => <div>nil</div>;
export const ComponentMap = {
  [Features.posts]: null,
  [Features.chat]: ChatFeature,
  [Features.profile]: ProfileFeature,
  [Features.tasks]: TasksFeature,
  [Features.calendar]: CalendarFeature,
  [Features.tickets]: NilFeature,
  [Features.swipeMeet]: NilFeature,
  [Features.greenlisting]: NilFeature,
  [Features.mail]: MailFeature,
  [Features.meet]: MeetFeature,
  [Features.home]: HomeView,
  [Features.organization]: OrganizationFeature,
  [Features.stuff]: StuffFeature,
  [Features.support]: SupportFeature,
  [Features.workspaceConfig]: AdminDashboard,
  [Features.forms]: FormsFeature,
  [Features.feedback]: FeedbackFeature,
  [Features.studio]: StudioFeature,
  [Features.wardrobe]: NilFeature,
  [Features.contacts]: ContactsFeature,
} as const;

export const TokyoENavRouter = React.memo(
  function TokyoENavRouter(): JSX.Element {
    return <EDataRouter componentMap={ComponentMap} />;
  },
);
