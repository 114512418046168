/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
export const TopicID = `calendar.my-calendar`;
export type TopicNameType = `[${string}]calendar.my-calendar/for:${string}`;
export type SubscriptionParamsType = null;
export type MessageType = { to: "do" };
export const TopicNameRegExp = new RegExp('/DEPRECATED/');
export const SubscriptionParamsSchema = { type: "null" };

export type Typings = {
  TopicNameType: TopicNameType,
  SubscriptionParamsType: SubscriptionParamsType,
  MessageType: MessageType;
};