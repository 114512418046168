import React from "react";
import { onLoginTokenAvailable, seamlessClient } from "../../seamless-client";

import * as CompleteSamlSSOLoginBP from "../../blueprints/accounts/login/complete-hiyllo-auth-login";
import { LoadingPage } from "@hiyllo/ux/standard-loader";

export const SSO = React.memo(function SSO(): JSX.Element {
  const completeSamlSSOLoginMutation =
    seamlessClient.useMutation<CompleteSamlSSOLoginBP.Plug>(
      CompleteSamlSSOLoginBP,
    );
  const [error, setError] = React.useState<string | null>(null);

  React.useEffect(() => {
    void (async () => {
      console.log("[SSO] Performing SSO Login");

      const usp = new window.URLSearchParams(window.location.search);

      try {
        const { tokenPackage } = await completeSamlSSOLoginMutation.call({
          SAMLResponse: usp.get("SAMLResponse") as string,
          RelayState: usp.get("RelayState") as string,
        });

        await onLoginTokenAvailable(tokenPackage);

        const returnRedirect = usp.get("returnRedirect");

        if (returnRedirect && returnRedirect.length > 0) {
          window.location.href = returnRedirect;
        }
      } catch (e: any) {
        setError(e.description ?? e.message);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (error) {
    return <div>{error}</div>;
  }

  return <LoadingPage />;
});
