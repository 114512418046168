import { styled } from "@hiyllo/ux/styled";
import React, { useImperativeHandle } from "react";
import { faBold, faItalic, faUnderline } from "@fortawesome/pro-light-svg-icons";
import { LoadingSpinner } from "@hiyllo/ux/loading-spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { type IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { BaseElement, BaseOperation, BaseText, createEditor, Descendant, Selection, Editor as SlateEditor, Transforms, Element as SlateElement } from "slate";
import { Editable, ReactEditor, RenderElementProps, RenderLeafProps, Slate, withReact } from "slate-react";
import { withHistory } from "slate-history";

import { DescendantType, DocumentContentsV2 } from "../../../../types/stuff/document";
import { useOnPaste } from "./hooks/use-on-paste";
import { Select } from "@hiyllo/ux/select";
import { Formats } from "./formats";
import { withVoidNodeCursorFix } from "./behaviors/void-node-cursor-fix";
import { withDeleteFirstLineBehavior } from "./behaviors/delete-first-line";
import { withImageDragIn } from "./behaviors/image-drag-in";
import { useUploadFile } from "@hiyllo/omni-user-files/main";

const EditorCtx = React.createContext<ReactEditor | null>(null);

const HiylloImageElement = React.memo(function HiylloImageElement(props: {
  src: string;
  fsId: string;
  width?: number;
  element: BaseElement;
  children: React.ReactNode;
}): JSX.Element {
  const editor = React.useContext(EditorCtx);
  const [size, setSize] = React.useState({ width: props.width ?? 300, height: "auto" });
  const resizingRef = React.useRef(false);
  const startPos = React.useRef({ x: 0, y: 0 });
  const imageRef = React.useRef<HTMLImageElement>(null);
  const widthRef = React.useRef(size.width);

  const onMouseDown = (e: React.MouseEvent) => {
    // Start resizing
    resizingRef.current = true;
    startPos.current = { x: e.clientX, y: e.clientY };

    // Prevent text selection while resizing
    document.body.style.userSelect = "none";
  };

  const onMouseMove = (e: MouseEvent) => {
    if (!resizingRef.current || !imageRef.current) return;

    // Calculate new width based on mouse movement
    const dx = e.clientX - startPos.current.x;
    const newWidth = Math.max(50, imageRef.current.clientWidth + dx);

    // Set new image size
    setSize({
      width: newWidth,
      height: "auto", // Keep aspect ratio
    });
    widthRef.current = newWidth;

    // Update start position for next movement
    startPos.current = { x: e.clientX, y: e.clientY };
  };

  const onMouseUp = () => {
    // Stop resizing
    resizingRef.current = false;

    // Re-enable text selection
    document.body.style.userSelect = "auto";

    if (editor == null) throw new Error("editor is null");

    // @ts-expect-error ---
    Transforms.setNodes(editor, { width: widthRef.current }, { at: [], match: n => n === props.element });
  };

  // Add mousemove and mouseup listeners to the window so that resizing continues
  // even if the user moves the cursor outside of the image bounds.
  React.useEffect(() => {
    window.addEventListener("mousemove", onMouseMove);
    window.addEventListener("mouseup", onMouseUp);

    return () => {
      window.removeEventListener("mousemove", onMouseMove);
      window.removeEventListener("mouseup", onMouseUp);
    };
  }, []);

  return (
    <div>
      <div
        contentEditable={false}
        style={{ position: "relative", display: "inline-block" }}
      >
        <img
          ref={imageRef}
          src={props.src}
          style={{ width: size.width, height: size.height, cursor: "ew-resize" }}
          alt="resizable"
          onMouseDown={onMouseDown}
          draggable={false}
        />
        {props.children}
      </div>
    </div>
  );
});

const customElements: Record<string, React.NamedExoticComponent<any>> = {
  "hiylloImage": HiylloImageElement,
};

function isHiylloVoid(type: string): boolean {
  return type in customElements;
}

const withCustomEnterBehavior = (editor: ReactEditor): ReactEditor => {
  const { insertBreak } = editor;

  editor.insertBreak = () => {
    console.log('>>> inserting break');
    const { selection } = editor;

    if (selection) {
      const [node] = SlateEditor.node(editor, selection);

      console.log('>>> selection', node);

      // Check if the selected node is a void node
      if (SlateElement.isElement(node) && editor.isVoid(node)) {
        console.log('>>> selection void');

        // Insert a new paragraph after the void node
        Transforms.insertNodes(editor, {
          // @ts-expect-error ---
          type: 'paragraph',
          children: [{ text: '' }],
        });

        return;
      }
    }

    insertBreak();
  };

  return editor;
};



const withEmbeds = (editor: SlateEditor) => {
  const { isVoid } = editor;
  editor.isVoid = (element) => {
    // @ts-expect-error ---
    return isHiylloVoid(element.type) || isVoid(element);
  };
  return editor;
};

const Element = (props: RenderElementProps) => {
  const { attributes, children, element } = props;
  // @ts-expect-error ---
  switch (element.type) {
    case "hiylloImage":
      // @ts-expect-error ---
      return <HiylloImageElement {...attributes} {...element} element={element}>{children}</HiylloImageElement>;
    default:
      return <p {...attributes}>{children}</p>;
  }
};

interface HiylloText extends BaseText {
  bold?: boolean;
  italic?: boolean;
  underline?: boolean;
  fontSize?: string | number;
}

const Leaf = React.memo(function Leaf(props: RenderLeafProps): JSX.Element {
  const { attributes, children, leaf } = props;
  const { text, ...rest } = (leaf as HiylloText);

  const styles = React.useMemo(() => {
    const styles: React.CSSProperties = {};

    if (rest.bold) {
      styles.fontWeight = "bold";
    }

    if (rest.italic) {
      styles.fontStyle = "italic";
    }

    if (rest.underline) {
      styles.textDecoration = "underline";
    }

    if (rest.fontSize) {
      styles.fontSize = rest.fontSize;
    }

    return styles;
  }, [rest]);

  return (
    <span {...attributes} {...rest} style={styles} className={Object.keys(rest).join(" ")}>
      {children}
    </span>
  );
});

const initialValue: Descendant[] = [
  {
    // @ts-expect-error ---
    type: "paragraph",
    children: [{ text: "I'm a new document..." }],
  },
];

const EditorContainer = styled<"div", { noPadding?: boolean }>(
  "div",
  ({ $theme, noPadding }) => ({
    background: $theme.background1,
    height: noPadding ? "100%" : "calc(100% - 32px)",
    width: noPadding ? "100%" : "calc(100% - 32px)",
    display: "flex",
    flexDirection: "column",
    padding: noPadding ? 0 : 16,
  }),
);

const EditorDocumentName = styled("div", ({ $theme }) => ({
  fontSize: 24,
  fontWeight: "bold",
  fontFamily: "hiyllo",
}));

const EditorMain = styled("div", ({ $theme }) => ({
  display: "flex",
  flexDirection: "row",
  height: 0,
  flexGrow: 1,
}));

const EditorSidebar = styled("div", ({ $theme }) => ({
  width: 256,
  padding: 16,
  display: "flex",
  flexDirection: "column",
  gap: 16,
  //
}));

const EditorContentArea = styled("div", ({ $theme }) => ({
  background: $theme.background3,
  borderRadius: 16,
  height: "calc(100% - 32px)",
  width: "calc(100% - 32px)",
  padding: 16,
  display: "flex",
  flexDirection: "column",
  whiteSpace: "pre-wrap",
  overflowY: "auto"
}));

const EditorContentContainer = styled("div", ({ $theme }) => ({
  width: 0,
  flexGrow: 1,
}));

const EditorSidebarSimpleButtonContainer = styled<"div", { active: boolean }>(
  "div",
  ({ $theme, active }) => ({
    background: active ? $theme.buttonBackground : $theme.midground,
    height: 32,
    width: 32,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    fontSize: 16,
    cursor: "pointer",
  }),
);

const ButtonHorizontalContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const EditorSidebarButtonContainer = styled<"div", { active: boolean }>(
  "div",
  ({ $theme, active }) => ({
    background: active ? $theme.buttonBackground : $theme.midground,
    height: 32,
    paddingLeft: 16,
    paddingRight: 16,
    display: "flex",
    flexDirection: "row",
    gap: "8px",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 16,
    fontSize: 16,
    cursor: "pointer",
  }),
);

const SavingIndicatorRow = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 16,
});

const EditorSidebarSimpleButton = React.memo(
  function EditorSidebarSimpleButton(props: {
    active?: boolean;
    icon: IconDefinition;
    onClick?: (evt: React.MouseEvent) => void;
  }): JSX.Element {
    const onClick = React.useCallback(
      (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        props.onClick?.(e);
      },
      [props.onClick],
    );

    return (
      <EditorSidebarSimpleButtonContainer
        active={props.active ?? false}
        onMouseDown={onClick}
      >
        <FontAwesomeIcon icon={props.icon} color="white" />
      </EditorSidebarSimpleButtonContainer>
    );
  },
);

const EditorSidebarSimpleButtonsContainer = styled("div", ({ $theme }) => ({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  gap: 8,
}));

export interface EditorRef {
  editor: ReactEditor | null;
}

export interface EditorPropsType {
  initialContents?: DocumentContentsV2 | null;
  initialHTML?: string | null;
  onValueChanged: (contents: DocumentContentsV2, delta: BaseOperation[]) => void;
  isSaving?: boolean;
  name?: string | null;
  noPadding?: boolean;
  contentPrefix?: React.ReactNode | JSX.Element;
  contentSuffix?: React.ReactNode | JSX.Element;
  readOnly?: boolean;
  extraElementBelowName?: React.ReactNode | JSX.Element;
  onExportBlob?: null | ((blob: Blob) => void);
  onImageUploaded?: (image: Blob) => Promise<{ src: string, fsId: string }>;
  onSelectionChanged?: (selection: Selection) => void;
}

const EditorFR = React.forwardRef<EditorRef, EditorPropsType>(
  function Editor(props, forwardedRef): JSX.Element {
    const blurredSelectionRef = React.useRef<Selection | null>(null);
    const uploadFile = useUploadFile();
    const editorRef = React.useRef<ReactEditor | null>(null);
    const editor: ReactEditor = React.useMemo(() => withEmbeds(
      withImageDragIn(
        withDeleteFirstLineBehavior(
          withVoidNodeCursorFix(
            withCustomEnterBehavior(
              withHistory(
                withReact(
                  createEditor()
                )
              )
            )
          )
        ),
        uploadFile
      )
    ) as ReactEditor, []);
    editorRef.current = editor;

    const onChange = React.useCallback((newValue: Descendant[]) => {
      props.onValueChanged?.({ v2: true, descendants: newValue as DescendantType[] }, editorRef.current?.operations ?? []);
    }, [props]);

    useImperativeHandle(
      forwardedRef,
      () => ({
        editor,
      }),
      [editor],
    );

    // @ts-expect-error ---
    const isBold = SlateEditor.marks(editorRef.current)?.bold === true;

    // @ts-expect-error ---
    const isItalic = SlateEditor.marks(editorRef.current)?.italic === true;

    // @ts-expect-error ---
    const isUnderline = SlateEditor.marks(editorRef.current)?.underline === true;

    // @ts-expect-error ---
    const fontSize: number = SlateEditor.marks(editorRef.current)?.fontSize ?? 15;

    const bold = React.useCallback((evt: React.MouseEvent) => {
      evt.preventDefault();
      if (editorRef.current == null) return;
      if (isBold) {
        SlateEditor.removeMark(editorRef.current, "bold");
      }
      else {
        SlateEditor.addMark(editorRef.current, "bold", true);
      }
    }, [isBold]);

    const italic = React.useCallback((evt: React.MouseEvent) => {
      evt.preventDefault();
      if (editorRef.current == null) return;
      if (isItalic) {
        SlateEditor.removeMark(editorRef.current, "italic");
      }
      else {
        SlateEditor.addMark(editorRef.current, "italic", true);
      }
    }, [isItalic]);

    const underline = React.useCallback((evt: React.MouseEvent) => {
      evt.preventDefault();
      if (editorRef.current == null) return;
      if (isUnderline) {
        SlateEditor.removeMark(editorRef.current, "underline");
      }
      else {
        SlateEditor.addMark(editorRef.current, "underline", true);
      }
    }, [isUnderline]);

    const onPaste = useOnPaste(editor);

    const onBlur = React.useCallback(() => {
      if (editorRef.current == null) return;
      blurredSelectionRef.current = editorRef.current.selection ?? null;
    }, []);

    return (
      <EditorCtx.Provider value={editor}>
        <EditorContainer noPadding={props.noPadding}>
          <EditorMain>
            <EditorContentContainer>
              <EditorContentArea>
                {props.contentPrefix}
                <Slate editor={editor} initialValue={(props.initialContents?.descendants as Descendant[] | void) ?? initialValue} onChange={onChange} onSelectionChange={props.onSelectionChanged}>
                  <Editable
                    renderElement={props => <Element {...props} />}
                    renderLeaf={props => <Leaf {...props} />}
                    // placeholder="Enter some text..."
                    disableDefaultStyles
                    onPaste={onPaste}
                    onBlur={onBlur}
                    style={{ border: 'none', outline: 'none', height: '100%', width: '100%' }}
                  />
                </Slate>
                {props.contentSuffix}
              </EditorContentArea>
            </EditorContentContainer>
            <EditorSidebar>
              {props.name != null ? (
                <EditorDocumentName>{props.name}</EditorDocumentName>
              ) : null}
              {props.extraElementBelowName}
              {props.readOnly !== true ? <>
                <EditorSidebarSimpleButtonsContainer>
                  <EditorSidebarSimpleButton
                    icon={faBold}
                    onClick={bold}
                    active={isBold}
                  />
                  <EditorSidebarSimpleButton
                    icon={faItalic}
                    onClick={italic}
                    active={isItalic}
                  />
                  <EditorSidebarSimpleButton
                    icon={faUnderline}
                    onClick={underline}
                    active={isUnderline}
                  />
                  {/* 
                {props.onExportBlob != null ? <EditorSidebarSimpleButton
                  icon={faFileDownload}
                  onClick={print}
                  active={false}
                /> : null}
                <ColorPicker onSelectColor={color}>
                  <EditorSidebarSimpleButton icon={faPalette} active={false} />
                </ColorPicker> */}
                </EditorSidebarSimpleButtonsContainer>
                <div>
                  <Select
                    options={Formats}
                    value={fontSize}
                    onChangeValue={(value) => {
                      ReactEditor.focus(editor);
                      if (blurredSelectionRef.current != null) {
                        Transforms.select(editor, blurredSelectionRef.current);
                      }
                      SlateEditor.addMark(editor, "fontSize", value);
                    }}
                  />
                </div>
                {/* <ButtonHorizontalContainer>
                <EditorSidebarButtonContainer active={false} onClick={bullet}>
                  <FontAwesomeIcon icon={faList} color="white" />
                  Bullet List
                </EditorSidebarButtonContainer>
              </ButtonHorizontalContainer> */}
              </> : null}

              <div style={{ flexGrow: 1 }} />
              {props.isSaving ? (
                <SavingIndicatorRow>
                  <LoadingSpinner />
                  Saving...
                </SavingIndicatorRow>
              ) : null}
            </EditorSidebar>
          </EditorMain>
        </EditorContainer>
      </EditorCtx.Provider>
    );
  },
);

export const Editor = React.memo(EditorFR) as typeof EditorFR;