/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
export interface HiylloFormFieldStructureBase {
  label: string;
  description: string | null;
  required: boolean;
}

export interface HiylloFormTextFieldStructure
  extends HiylloFormFieldStructureBase {
  type: "text";
  multiline: boolean;
  minLength: number | null;
  maxLength: number | null;
}

export interface HiylloFormSelectFieldStructure
  extends HiylloFormFieldStructureBase {
  type: "select";
  options: string[];
  multiselect?: boolean;
}

export interface HiylloFormFileUploadFieldStructure
  extends HiylloFormFieldStructureBase {
  type: "file-upload";
}

export type HiylloFormFieldStructure =
  | HiylloFormTextFieldStructure
  | HiylloFormSelectFieldStructure
  | HiylloFormFileUploadFieldStructure;

export interface HiylloFormField {
  uuid: string;
  structure: HiylloFormFieldStructure;
}

export enum HiylloFormFormat {
  sequential = "sequential",
  unison = "unison",
}

export interface HiylloFormStructure {
  v: 1;
  fields: HiylloFormField[];
  format: HiylloFormFormat;
}
