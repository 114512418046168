import { Electron } from "./electron";

export function openWindow(path: string) {
  if (path.startsWith(`https://${Electron.electronHost}`)) {
    path = path.replace(`https://${Electron.electronHost}`, "");
  }

  if (Electron.isElectron && !path.startsWith("https://")) {
    const url = new URL(path, `https://${Electron.electronHost}`);
    Electron.callAPI("openWindow", `?${url.search.slice(1)}#${url.pathname}`);
  } else {
    window.open(path, "_blank");
  }
}
