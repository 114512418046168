import React from "react";
import { styled } from "@hiyllo/ux/styled";

export const CalendarOutlineInput = styled<"input", { disabled?: boolean }>(
  "input",
  ({ $theme, disabled }) => ({
    background: "none",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: disabled ? "transparent" : $theme.midground,
    padding: 5,
    borderRadius: 5,
    lineHeight: "1em",
    color: $theme.foreground,
    display: "inline-block",
    colorScheme: $theme.colorScheme,
    fontFamily: "sans-serif",
  }),
);

export const Centered = styled("div", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

export const FlexStartRow = styled("div", {
  display: "flex",
  flexDirection: "row",
  // alignItems: "flex-start",
  gap: 20,
  marginTop: 5,
  height: "100%",
});

export const Row = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 10,
});

export const EventDetailsViewContainer = styled("div", ({ $theme }) => ({
  // background: $theme.background1,
  color: $theme.foreground,
  // padding: 30,
  display: "flex",
  flexDirection: "column",
  gap: 10,
  userSelect: "auto",
  height: "calc(90vh - 60px)",
  // width: "calc(100% - 60px)",
  overflow: "hidden",
  position: "relative",
}));

export const GappedCol = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: 15,
  alignSelf: "stretch",
});

export const EventTitleText = styled("div", { fontSize: 26 });

export const JoinHiylloMeetButton = styled("div", ({ $theme }) => ({
  background: $theme.buttonBackground,
  padding: 10,
  paddingLeft: 15,
  paddingRight: 15,
  borderRadius: 5,
  display: "inline-flex",
  flexDirection: "row",
  gap: 5,
  fontFamily: "hiyllo",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  textAlign: "center",
}));

export const CenteredRow = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 10,
});
