import React from "react";
import { motion } from "framer-motion";
import { styled } from "@hiyllo/ux/styled";

const ErrorTextElem = styled("div", ({
  color: "rgb(255, 35, 69)",
}));

export const ErrorText = React.memo(function ErrorText(props: {
  message: string | null;
}): JSX.Element | null {
  if (props.message === null) return null;

  return (
    <motion.div
      animate={{
        x: [-2, 0, 2, 0, -2, 0, 2, 0, -2, 0, 2, 0],
      }}
      transition={{ duration: 0.4 }}
    >
      <ErrorTextElem>{props.message}</ErrorTextElem>
    </motion.div>
  );
});
