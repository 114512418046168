/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
export type ParamsType = {
  elementUUID: string;
  kind: "asset" | "container";
  newParent: string;
};
export type ResponseType = void;
export const Endpoint = 'stuff/move-element';
export const Method = 'POST';

export interface Plug {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
}export const paramsSchema = {"type":"object","properties":{"elementUUID":{"type":"string"},"kind":{"enum":["asset","container"],"type":"string"},"newParent":{"type":"string"}},"required":["elementUUID","kind","newParent"],"$schema":"http://json-schema.org/draft-07/schema#"}