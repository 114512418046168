/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { FileStorageUsageWithBreakdown } from "../../../types/fs/file";

export type ParamsType = null;
export type ResponseType = { breakdown: FileStorageUsageWithBreakdown };
export const Endpoint = "admin/storage/get-total-storage-used-with-breakdown";
export const Method = "POST";

export interface Plug {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
}
export const paramsSchema = {"type":"null","$schema":"http://json-schema.org/draft-07/schema#"}