import React from "react";

import * as RotatePublicAPIKeyBP from "../../../blueprints/admin/public-api/rotate-public-api-key";
import { FormButton } from "../../../ux/alpha";
import { seamlessClient } from "../../../seamless-client";

export const PublicAPIAdmin = React.memo(
  function PublicAPIAdmin(): JSX.Element {
    const rotatePublicApiKeyMutation =
      seamlessClient.useMutation<RotatePublicAPIKeyBP.Plug>(
        RotatePublicAPIKeyBP,
      );

    return (
      <div>
        <div
          style={{
            color: "red",
            marginBottom: 15,
          }}
        >
          <b>Heads up!</b> The Public API is a powerful tool but grants tons of
          control to whoever has an API key. Use caution, and consult Hiyllo for
          guidance on how to use this feature.
        </div>
        <FormButton
          onClick={() => {
            void rotatePublicApiKeyMutation.call(null).then(({ key }) => {
              alert(key);
            });
          }}
          isLoading={rotatePublicApiKeyMutation.isLoading}
          label="Rotate Public API Key"
        />
      </div>
    );
  },
);
