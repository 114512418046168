/**
 * Dedicated to our beloved Chief Operating Officer, Ashiya, whose work kept getting lost due to updates
 */

import { useDebounce } from "@hiyllo/ux/use-debounce";
import React from "react";

export function useSimpleAutosave(key: string): {
  defaultValue: string;
  update: (v: string) => void;
  clear: () => void;
} {
  const lsKey = `@has/${key}`;
  const db = useDebounce(500, "ignore");

  const update = React.useCallback(
    (v: string) => {
      db.debounce(() => {
        window.localStorage.setItem(lsKey, v);
      });
    },
    [db, lsKey],
  );

  const clear = React.useCallback(() => {
    window.localStorage.removeItem(lsKey);
  }, [lsKey]);

  return {
    defaultValue: window.localStorage.getItem(lsKey) ?? "",
    update,
    clear,
  };
}
