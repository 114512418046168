import React from "react";

interface HPropsType {
  children: React.ReactNode;
  noMargin?: boolean;
  inheritColor?: boolean;
  style?: React.CSSProperties;
}

export const Header = ({
  children,
  inheritColor,
  noMargin,
  style,
}: HPropsType): JSX.Element => {
  return (
    <div
      style={{
        fontFamily: "Work Sans",
        fontWeight: "500",
        fontSize: 16,
        color: "inherit",
        letterSpacing: -0.5,
        ...(style ?? {}),
      }}
    >
      {children}
    </div>
  );
};

export const H1 = ({
  children,
  inheritColor,
  noMargin,
}: HPropsType): JSX.Element => {
  return (
    <div
      style={{
        fontFamily: "Work Sans",
        fontWeight: "500",
        fontSize: 48,
        color: "inherit",
        letterSpacing: -0.5,
        marginBottom: noMargin === true ? 0 : 15,
      }}
    >
      {children}
    </div>
  );
};

export const H2 = ({ children, noMargin }: HPropsType): JSX.Element => {
  return (
    <div
      style={{
        fontFamily: "Work Sans",
        fontWeight: "500",
        fontSize: 32,
        // color: '#121212',
        letterSpacing: -0.5,
        marginBottom: noMargin === true ? 0 : 15,
      }}
    >
      {children}
    </div>
  );
};

export const H4 = ({ children, inheritColor }: HPropsType): JSX.Element => {
  return (
    <div
      style={{
        fontFamily: "Work Sans",
        // fontWeight: '500',
        fontSize: 26,
        color: inheritColor ? "inherit" : "#121212",
        letterSpacing: -0.5,
        marginBottom: 15,
      }}
    >
      {children}
    </div>
  );
};

export const H5 = ({
  children,
  inheritColor,
}: {
  children: React.ReactNode;
  inheritColor?: boolean;
}): JSX.Element => {
  return (
    <div
      style={{
        fontFamily: "Work Sans",
        // fontWeight: '500',
        fontSize: 22,
        color: inheritColor ? "inherit" : "#121212",
        letterSpacing: -0.5,
        marginBottom: 15,
      }}
    >
      {children}
    </div>
  );
};
