import type React from "react";
import { useContext } from "react";
import { DarkMode } from "./contexts";

export const SHADOW_A = "0px 0px 20px -4px rgb(0 0 0 / 12.5%)";
export const SHADOW_B = "0px 0px 10px 1px rgb(0 0 0 / 25%)";
export const SHADOW_C = "0px 0px 15px 1px rgb(0 0 0 / 12.5%)";

export const useShadows = (): {
  SHADOW_A: React.CSSProperties;
  SHADOW_B: React.CSSProperties;
  SHADOW_C: React.CSSProperties;
} => {
  const isDark = useContext(DarkMode);

  if (isDark) {
    return {
      SHADOW_A: { boxShadow: "0px 0px 20px -4px rgb(255 255 255 / 12.5%)" },
      SHADOW_B: { boxShadow: "0px 0px 10px 1px rgb(255 255 255 / 25%)" },
      SHADOW_C: { boxShadow: "0px 0px 15px 1px rgb(255 255 255 / 12.5%)" },
    };
  } else {
    return {
      SHADOW_A: { boxShadow: "0px 0px 20px -4px rgb(0 0 0 / 12.5%)" },
      SHADOW_B: { boxShadow: "0px 0px 10px 1px rgb(0 0 0 / 25%)" },
      SHADOW_C: { boxShadow: "0px 0px 15px 1px rgb(0 0 0 / 12.5%)" },
    };
  }
};
