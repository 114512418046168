/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { WardrobeEntryWithImage } from "../../types/wardrobe/wardrobe-entry";

export type ParamsType = null;
export type ResponseType = {
  entries: Array<WardrobeEntryWithImage>;
};
export const Endpoint = "wardrobe/retrieve-wardrobe-entries";
export const Method = "GET";

export interface Plug {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
}

export const RateLimitingConfig = {
  calls: 2,
  per: 750,
};
export const paramsSchema = {"type":"null","$schema":"http://json-schema.org/draft-07/schema#"}