import React from "react";

interface StandardLayoutComponentProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
}

/**
 * SpaceBetween - Row
 * [A] <=====> [B]
 */
export const SpaceBetweenRowLayout = (
  props: StandardLayoutComponentProps,
): JSX.Element => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        ...props.style,
      }}
    >
      {props.children}
    </div>
  );
};

/**
 * Vertical Layout - Simple
 * [A]
 * [B]
 * ...
 */
export const VerticalLayout = (
  props: { gap?: number } & StandardLayoutComponentProps,
): JSX.Element => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: props.gap ?? 0,
        ...props.style,
      }}
    >
      {props.children}
    </div>
  );
};

/**
 * Horizontal Layout - Simple
 * [A] <- $gap -> [B]
 * ...
 */
export const HorizontalLayout = (
  props: { gap?: number } & StandardLayoutComponentProps,
): JSX.Element => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: props.gap ?? 0,
        ...props.style,
      }}
    >
      {props.children}
    </div>
  );
};

/**
 * Width: 100%;
 */
export const AssertWidth = (
  props: StandardLayoutComponentProps,
): JSX.Element => {
  return (
    <div
      style={{
        width: "100%",
        ...props.style,
      }}
    >
      {props.children}
    </div>
  );
};

export const Spacer = (
  props: { width?: number; height?: number } & Omit<
    StandardLayoutComponentProps,
    "children"
  >,
): JSX.Element => {
  return (
    <div
      style={{
        width: props.width,
        height: props.height,
        ...props.style,
      }}
    />
  );
};
