// @ts-expect-error ---
const windowTenant: string | null = window.__tenant__;

export const Tenant =
  window.localStorage.electronTenant != null
    ? (JSON.parse(window.localStorage.electronTenant).tenantId as string)
    : windowTenant != null ? windowTenant
      : window.location.hostname.startsWith("www")
        ? window.location.hostname.slice(window.location.hostname.indexOf(".") + 1)
        : window.location.hostname.split(".")[0];
