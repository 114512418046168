import React from "react";
import { type EnhancedAlertData } from "@hiyllo/omni-common/src/types/alerts/alert";
import { ContinuityMeetingContext } from "../../../../main/meeting-provider";
import { styled } from "@hiyllo/ux/styled";
import { useDuration } from "../../../../platform/hooks/use-duration";
import { faPhoneAlt, faTimesCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigateTo, usePath } from "@hiyllo/omni-router";
import { useMaybeRoomContext } from "@livekit/components-react";
import { Features } from "@hiyllo/omni-common/src/types/navigation/features";
import { MeetingEAD } from "../../../../types/alerts/alert";
import { useTickCallback } from "@hiyllo/omni-dynamicui/main";
import { motion } from "framer-motion";

const BlurContainer = styled("div", ({ $theme }) => ({
  background: "rgba(50,50,50,0.25)",
  backdropFilter: "blur(25px)",
  color: "white",
}));

export const MeetingTimer = React.memo(function MeetingTimer(props: {
  startDate: Date;
}): JSX.Element {
  const durationStr = useDuration(props.startDate, true, true);

  return <span>{durationStr}</span>;
});

const useNow = (): Date => {
  const [now, setNow] = React.useState(new Date());
  React.useEffect(() => {
    const interval = setInterval(() => setNow(new Date()), 1000);
    return () => clearInterval(interval);
  }, []);
  return now;
};

export const AlertEventCard = React.memo(function AlertEventCard(props: {
  ead: MeetingEAD;
  onClose: (v: boolean) => void;
}): JSX.Element {
  const { current } = React.useContext(ContinuityMeetingContext);
  const navigateJoin = useNavigateTo(props.ead.link);
  const now = useNow();
  const room = useMaybeRoomContext();
  const path = usePath();

  React.useEffect(() => {
    if (path.feature === Features.home) {
      props.onClose(false);
    }
  }, [path.feature, props]);

  const onClick = useNavigateTo({
    feature: Features.calendar,
    params: {
      view: 'event',
      eventUUID: props.ead.eventUUID,
    }
  });

  const onClickJoin = React.useCallback((evt: React.MouseEvent) => {
    evt.preventDefault();
    evt.stopPropagation();
    void (async () => {
      const promise = room?.disconnect();
      if (promise != null) {
        await promise;
      }
      navigateJoin();
    })();
    props.onClose(true);
  }, [navigateJoin, props, room]);

  const [minsTill, setMinsTill] = React.useState<number>(
    (props.ead.startsAt.valueOf() - Date.now()) / 1000 / 60,
  );

  useTickCallback(() => {
    setMinsTill((props.ead.startsAt.valueOf() - Date.now()) / 1000 / 60);
  });

  return (
    <BlurContainer>
      <div style={{ padding: 20, width: 260, position: "relative", cursor: "pointer" }} onClick={onClick}>
        <div
          style={{
            position: "absolute",
            top: 10,
            right: 10,
            cursor: "pointer",
          }}
        >
          <FontAwesomeIcon
            icon={faTimesCircle}
            onClick={() => props.onClose(true)}
          />
        </div>
        <motion.div animate={{ fontFamily: "hiyllo", fontSize: minsTill < 5 ? "17.5px" : "12.5px" }}>
          {props.ead.startsAt < now ? (
            <div>
              Started <MeetingTimer startDate={props.ead.startsAt} /> ago
            </div>
          ) : (
            <div>
              Starting in <MeetingTimer startDate={props.ead.startsAt} />
            </div>
          )}
        </motion.div>
        <motion.div style={{ fontWeight: "bold", fontFamily: "hiyllo", overflowWrap: 'break-word' }} animate={{ fontSize: `${minsTill < 10 ? 30 : minsTill < 15 ? 20 : 15}px` }}>
          {props.ead.title}
        </motion.div>
        <motion.div initial={{ height: minsTill < 5 ? "" : 0 }} animate={{ height: minsTill < 5 ? "" : 0 }} style={{ overflow: "hidden" }}>
          <div
            onClick={onClickJoin}
            style={{
              cursor: "pointer",
              userSelect: "none",
              marginTop: 5,
              backgroundColor: "#4caf5033",
              padding: 10,
              borderRadius: 10,
              textAlign: "center",
              fontFamily: "hiyllo",
              fontWeight: "bold",
              display: "flex",
              flexDirection: "row",
              gap: 5,
              justifyContent: "center",
            }}
          >
            <FontAwesomeIcon icon={faPhoneAlt} fixedWidth />
            {current == null ? "Join Meeting" : "Switch to Meeting"}
          </div>
        </motion.div>
      </div>
    </BlurContainer>
  );
});
