import React from "react";
import {
  DragDropContext,
  Draggable,
  type DraggableProvided,
  Droppable,
  type DropResult,
} from "@hello-pangea/dnd";

const BaseComponent = function SimpleDND<T>(props: {
  renderItem: (
    item: T,
    index: number,
    dragHandleProps: DraggableProvided["dragHandleProps"],
  ) => JSX.Element;
  items: T[];
  keyExtractor: (i: T) => string;
  onChangeOrder: (result: { keys: string[]; items: T[] }) => void;
}): JSX.Element {
  const onDragEnd = React.useCallback(
    (evt: DropResult) => {
      if (evt.destination == null) return;

      const newOrder: Array<[string, T]> = props.items.map((item) => [
        props.keyExtractor(item),
        item,
      ]);

      const [removed] = newOrder.splice(
        newOrder.findIndex(([key]) => key === evt.draggableId),
        1,
      );
      newOrder.splice(evt.destination.index, 0, removed);

      props.onChangeOrder({
        keys: newOrder.map((n) => n[0]),
        items: newOrder.map((n) => n[1]),
      });
    },
    [props],
  );

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="default">
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {props.items.map((item, i) => {
              const key = props.keyExtractor(item);

              return (
                <Draggable key={key} draggableId={key} index={i}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      style={{
                        ...provided.draggableProps.style,
                        left: "0px",
                      }}
                    >
                      {props.renderItem(item, i, provided.dragHandleProps)}
                    </div>
                  )}
                </Draggable>
              );
            })}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export const SimpleDND = React.memo(BaseComponent) as typeof BaseComponent;
