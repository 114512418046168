import React from "react";
import { type TaskItem } from "../../../types/tasks/task-item";
import { styled } from "@hiyllo/ux/styled";
import { TaskCardQueryWrapper } from "../components/task-card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus } from "@fortawesome/pro-light-svg-icons";
import { useRemoveTaskDependency } from "../hooks/use-remove-task-dependency";
import { LoadingSpinner } from "@hiyllo/ux/loading-spinner";

const DependencyList = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: 10,
});

const TaskCardWrapper = styled("div", {
  position: "relative",
});

const DeleteIcon = styled("div", ({ $theme }) => ({
  background: $theme.midground,
  height: 25,
  width: 25,
  position: "absolute",
  top: -12.5,
  right: -12.5,
  borderRadius: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: $theme.foreground,
  pointerEvents: "auto",
  cursor: "pointer",
}));

const Title = styled("div", ({ $theme }) => ({
  color: $theme.foreground,
}));

export const DependenciesView = React.memo(function DependenciesView(props: {
  task: Omit<TaskItem, "activity">;
}): JSX.Element | null {
  const removeDependencyMutation = useRemoveTaskDependency();
  const removeDependency = React.useCallback(
    (dependency: string) => {
      void removeDependencyMutation.call({
        taskUUID: props.task.uuid,
        dependencyUUID: dependency,
      });
    },
    [props.task.uuid, removeDependencyMutation],
  );

  if (
    props.task.dependencyUUIDs == null ||
    props.task.dependencyUUIDs.length === 0
  ) {
    return null;
  }

  return (
    <DependencyList>
      <Title>Depends on</Title>
      {props.task.dependencyUUIDs?.map((dependency) => (
        <TaskCardWrapper key={dependency}>
          <TaskCardQueryWrapper taskUUID={dependency} />
          <DeleteIcon onClick={() => removeDependency(dependency)}>
            {removeDependencyMutation.isLoading ? (
              <LoadingSpinner />
            ) : (
              <FontAwesomeIcon icon={faMinus} />
            )}
          </DeleteIcon>
        </TaskCardWrapper>
      ))}
    </DependencyList>
  );
});
