import React from "react";
import { Form, FormButton } from "../../ux/alpha";
import { FileInput } from "../../ux/alpha/input";
import * as SetProfileBlueprint from "../../blueprints/accounts/user/set-profile";
import { useRefreshSelf } from "@hiyllo/omni-continuity";
import { useThemeStyling } from "../../ux/themings";
import { seamlessClient } from "../../seamless-client";
import { Input } from "@hiyllo/ux/input";
import { type MoopsyError } from "@moopsyjs/core";
import { useShowDialog } from "@hiyllo/ux/dialogs";
import { AnimateChangeInHeight } from "@hiyllo/ux/animation";
import { ErrorText } from "../../ux/error";
import { Typography } from "@hiyllo/ux/typography";
import { styled } from "@hiyllo/ux/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/pro-light-svg-icons";

const ProfilePictureContainer = styled("div", ({ $theme }) => ({
  height: 150,
  width: 150,
  backgroundColor: $theme.midground,
  borderRadius: 150 / 4,
  marginBottom: 20,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontSize: 80,
}));

const ProfilePictureImage = styled("img", {
  height: "100%",
  width: "100%",
  borderRadius: "inherit",
  userSelect: "none",
});

const SetupFormContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
});

export const SetupForm = (): JSX.Element => {
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const nameInputRef = React.useRef<HTMLInputElement>(null);
  const usernameInputRef = React.useRef<HTMLInputElement>(null);
  const [imageId, setImageId] = React.useState<string | null>(null);
  const [imagePreviewURL, setImagePreviewURL] = React.useState<string | null>(
    null,
  );
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const refreshSelf = useRefreshSelf();
  const setProfileMutation =
    seamlessClient.useMutation<SetProfileBlueprint.Plug>(
      SetProfileBlueprint,
      { querySideEffects: [] },
    );

  const showDialog = useShowDialog();

  const onSubmit = (): void => {
    setErrorMessage(null);

    const name = nameInputRef?.current?.value;
    const username = usernameInputRef?.current?.value;

    if (name == null || name.length < 2) {
      setErrorMessage("Please enter a longer name");
      return;
    }

    if (username == null || username.length < 1) {
      setErrorMessage("Please enter a longer username");
      return;
    }

    // if (imageId == null) {
    //   setErrorMessage("Please upload a profile picture");
    //   return;
    // }

    void setProfileMutation
      .call({
        name,
        imageId,
        username,
      })
      .then(() => {
        refreshSelf?.();
        window.location.reload();
      })
      .catch((err) => {
        showDialog({
          title: "Error",
          message: (err as MoopsyError).description,
          onConfirm: () => {
            //
          },
        });
      });
  };

  return (
    <SetupFormContainer>
      <ProfilePictureContainer>
        {imagePreviewURL != null ? (
          <ProfilePictureImage src={imagePreviewURL} />
        ) : (
          <FontAwesomeIcon icon={faUserCircle} />
        )}
      </ProfilePictureContainer>
      <Typography.Header>Care to introduce yourself?</Typography.Header>
      <div style={{ width: 300, marginTop: 16 }}>
        <Form onSubmit={onSubmit} isLoading={setProfileMutation.isLoading}>
          <Input
            type="text"
            ref={nameInputRef}
            label="What's your name?"
            fullWidth
            placeholder="Taylor Doe"
          />
          <div style={{ height: 16 }} />
          <Input
            type="text"
            ref={usernameInputRef}
            label="Pick a username"
            startEnhancer="@"
            fullWidth
            placeholder="taylor_doe123"
          />
          <div style={{ height: 16 }} />
          <FileInput
            inputRef={fileInputRef}
            autoUpload={true}
            onFileId={setImageId}
            onImagePreviewUrlAvailable={setImagePreviewURL}
            label="Select a profile picture"
            fullWidth
          />
          <div style={{ height: 16 - 12.5 }} />
          <FormButton label="Continue" />
          <AnimateChangeInHeight>
            {errorMessage != null ? (
              <>
                <div style={{ height: 0 }} />
                <ErrorText message={errorMessage} />
              </>
            ) : null}
          </AnimateChangeInHeight>
        </Form>
      </div>
    </SetupFormContainer>
  );
};

export const SetupPage = (): JSX.Element => {
  const pageStyling = useThemeStyling<"page.standard">("page.standard");

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        ...pageStyling,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <SetupForm />
    </div>
  );
};
