/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
/**
 * RSVP to an event. Requires used to be logged in or
 * pass externalActionToken
 */

import { RSVPEnum } from "../../types/calendar/calendar-event";
import { ActionRepeatContextEnum } from "../../types/calendar/repeating";

export type ParamsType = {
  eventUUID: string,
  rsvp: RSVPEnum,
  externalActionToken: string | null,
  repeatHandling: ActionRepeatContextEnum
};
export type ResponseType = void;
export const Endpoint = 'calendar/rsvp-to-event';
export const Method = 'POST';

export interface Plug {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
}

export const isPublic = true;export const paramsSchema = {"type":"object","properties":{"eventUUID":{"type":"string"},"rsvp":{"$ref":"#/definitions/RSVPEnum"},"externalActionToken":{"type":["null","string"]},"repeatHandling":{"$ref":"#/definitions/ActionRepeatContextEnum"}},"required":["eventUUID","externalActionToken","repeatHandling","rsvp"],"definitions":{"RSVPEnum":{"enum":["maybe","no","yes"],"type":"string"},"ActionRepeatContextEnum":{"enum":["all","this","thisAndFuture"],"type":"string"}},"$schema":"http://json-schema.org/draft-07/schema#"}