import { styled } from "@hiyllo/ux/styled";
import { Card } from "@hiyllo/ux/surface";
import React from "react";
import { Typography } from "@hiyllo/ux/typography";
import { Input } from "@hiyllo/ux/input";
import { CircleButton } from "@hiyllo/ux/circle-button";
import { faArrowRight } from "@fortawesome/pro-light-svg-icons";
import { useCreateNewWorkflow } from "../hooks/use-create-new-workflow";
import { useStatefulRef } from "@hiyllo/ux/use-stateful-ref";
import { useShowDialog } from "@hiyllo/ux/dialogs";
import { useNavigate } from "@hiyllo/omni-router";
import { Features } from "../../../types/navigation/features";

const CenteredContainer = styled("div", {
  height: "100%",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
});

const CardInner = styled("div", {
  minWidth: 300,
  display: "flex",
  flexDirection: "column",
  gap: 15,
});

export const CreateWorkflowView = React.memo(
  function CreateWorkflowView(): JSX.Element {
    const navigate = useNavigate();
    const [workflowName, setWorkflowName, workflowNameRef] =
      useStatefulRef<string>("");
    const createNewWorkflowMutation = useCreateNewWorkflow();
    const showDialog = useShowDialog();
    const submit = React.useCallback(() => {
      const name = workflowNameRef.current;

      if (!name || name.length < 1) {
        void showDialog({
          title: "Error",
          message: "Workflow name cannot be empty",
          noCancel: true,
          confirmLabel: "Okay",
          onConfirm: () => {
            //
          },
        });
      }

      void createNewWorkflowMutation
        .call({
          name,
        })
        .then((res) => {
          navigate({
            feature: Features.studio,
            params: {
              view: "workflow",
              uuid: res.uuid,
            },
          });
        });
    }, [createNewWorkflowMutation, navigate, showDialog, workflowNameRef]);

    return (
      <CenteredContainer>
        <Card color="background2">
          <CardInner>
            <Typography.SubHeader>Create Workflow</Typography.SubHeader>
            <div style={{ marginBottom: 5 }}>
              <Typography.Label>Workflow Name</Typography.Label>
              <Input
                fullWidth
                value={workflowName}
                onChangeValue={setWorkflowName}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <CircleButton
                icon={faArrowRight}
                onClick={submit}
                size={45}
                isLoading={createNewWorkflowMutation.isLoading}
              />
            </div>
          </CardInner>
        </Card>
      </CenteredContainer>
    );
  },
);
