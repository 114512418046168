import React from "react";
import { type PositioningDate, dayToRowPosition } from "../consts";
import { DayWidthContext } from "../contexts";
import { useHourHeight } from "./use-hour-height";

export function useDeterminePosition(opts: PositioningDate): {
  x: number;
  y: number;
} {
  const dayWidth = React.useContext(DayWidthContext);
  const hourHeight = useHourHeight();

  return {
    x: 60 + dayToRowPosition[opts.day] * dayWidth,
    y: opts.hours * hourHeight + opts.minutes * (hourHeight / 60) + 1.2,
  };
}
