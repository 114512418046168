import { styled } from "@hiyllo/ux/styled";
import React from "react";
import { LoadingSpinner } from "@hiyllo/ux/loading-spinner";

const Container = styled("div", ({ $theme }) => ({
  position: "absolute",
  bottom: 20,
  right: 20,
  height: 60,
  backgroundColor: "#1976d2",
  display: "flex",
  flexDirection: "row",
  gap: 10,
  alignItems: "center",
  paddingLeft: 15,
  paddingRight: 15,
  borderRadius: 5,
  color: $theme.foreground,
  zIndex: 2000,
  fontFamily: "hiyllo"
}));

export const ReconnectingBanner = React.memo(
  function ReconnectingBanner(): JSX.Element {
    return (
      <Container>
        <LoadingSpinner />
        <div>
          <div
            style={{
              color: "white",
              fontWeight: "bold",
              fontSize: 14,
            }}
          >
            Connection Lost
          </div>
          <div
            style={{
              color: "white",
              fontSize: 12,
            }}
          >
            Trying to reconnect...
          </div>
        </div>
      </Container>
    );
  },
);
