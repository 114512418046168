import { BaseElement, Editor as SlateEditor, Transforms } from "slate";
import { ReactEditor } from "slate-react";

export const withVoidNodeCursorFix = (editor: ReactEditor) => {
  const { normalizeNode } = editor;

  editor.normalizeNode = entry => {
    const [node, path] = entry;

    // Ensure that the cursor is allowed to be placed after a void node
    if (editor.isVoid(node as BaseElement)) {
      const nextNode = SlateEditor.next(editor, { at: path });

      if (!nextNode) {
        // Insert a new paragraph after the void node if there is none
        Transforms.insertNodes(editor, {
          // @ts-expect-error ---
          type: 'paragraph',
          children: [{ text: '' }],
        }, { at: SlateEditor.after(editor, path) });
      }
    }

    normalizeNode(entry);
  };

  return editor;
};