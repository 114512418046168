import { styled } from "@hiyllo/ux/styled";
import React from "react";
import { getRootURL } from "../../../platform/environment/get-root-url";

import * as RSVPToEventBP from "../../../blueprints/calendar/rsvp-to-event";
import { seamlessClient } from "../../../seamless-client";
import { LoadingSpinnerFullView } from "../../../platform/loading/spinner-loading-full";
import { type RSVPEnum } from "../../../types/calendar/calendar-event";
import { useParams } from "react-router-dom";
import { ActionRepeatContextEnum } from "../../../types/calendar/repeating";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/pro-light-svg-icons";

const PageContainer = styled("div", ({ $theme, $isMobile }) => ({
  padding: 30,
  height: "calc(100% - 60px)",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  background: $theme.background1,
  gap: 15,
  color: $theme.foreground,
}));

const CornerImage = styled("img", ({ $isMobile }) => ({
  position: "fixed",
  bottom: 20,
  left: $isMobile ? 25 : 20,
  maxWidth: 200,
  maxHeight: 50,
  objectFit: "contain",
}));

export const RSVPExternal = React.memo(function RSVPExternal(): JSX.Element {
  const { call, isLoading, error } =
    seamlessClient.useMutation<RSVPToEventBP.Plug>(RSVPToEventBP);

  const params = useParams() as {
    eventUUID: string;
    externalActionToken: string;
    rsvp: RSVPEnum;
  };

  React.useEffect(() => {
    void call({
      eventUUID: params.eventUUID,
      rsvp: params.rsvp,
      repeatHandling: ActionRepeatContextEnum.this,
      externalActionToken: params.externalActionToken,
    });
  }, [call, params]);

  return (
    <PageContainer>
      {isLoading ? (
        <LoadingSpinnerFullView />
      ) : error !== null ? (
        <div>{error.message}</div>
      ) : (
        <>
          <FontAwesomeIcon icon={faCircleCheck} style={{ fontSize: 64 }} />
          <div></div>
          <div
            style={{
              textAlign: "center",
              lineHeight: "1.25em",
            }}
          >
            Your RSVP has
            <br />
            been recorded
          </div>
        </>
      )}
      <CornerImage src={getRootURL() + "/ufplogo.png"} />
    </PageContainer>
  );
});
