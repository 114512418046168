import React from "react";
import { useIsSolo } from "../../platform/hooks/use-is-solo";

export const PrivacyPolicy = React.memo(function PrivacyPolicy(): JSX.Element {
  const isSolo = useIsSolo();

  const mainDomain = isSolo ? "solo.hiyllo.io" : "www.hiyllo.work";
  const productName = isSolo ? "Hiyllo Solo" : "Hiyllo Work";
  const termsOfUseLink = isSolo
    ? "https://cdn.hiyllo.net/legal/solo/terms-of-use.pdf"
    : "https://www.hiyllo.work/work/legal/platform-terms-of-use.pdf";

  return (
    <div
      style={{
        color: "white",
        fontFamily: "hiyllo",
        padding: 20,
        fontSize: 16,
        lineHeight: "1.25em",
      }}
    >
      <div>
        <img
          src="https://cdn.hiyllo.net/logo/hiyllo/icon-white.png"
          style={{ height: 50 }}
        />
      </div>
      <h1>Privacy Policy</h1>
      <h5>Last Modified: December 6th, 2024</h5>
      <h3>1. Introduction</h3>
      <p>
        Hiyllo Inc. (“Hiyllo”) respects your privacy and is committed to
        protecting it through compliance with this Privacy Policy (the “Policy”
        or “Privacy Policy”).
      </p>
      <p>
        This Privacy Policy describes the types of information Hiyllo may
        collect from you or that you may provide when you visit, access, or use{" "}
        {mainDomain}, and any other Hiyllo website or client portal
        (collectively, the “Websites”), any Hiyllo mobile, desktop or web apps
        (the “Apps”), any content, functionality, technology solutions and
        services offered on or through the Websites or Apps, including
        subscription services (collectively, the “Services”), and Hiyllo’s
        practices for collecting, using, maintaining, protecting, and disclosing
        that information. The Websites, Apps, and Services, are collectively
        referred to as “{productName}.” This Policy applies to information
        Hiyllo collects:
      </p>
      <ul>
        <li>On or through the {productName}.</li>
        <li>
          In email, text, and other electronic or other messages or
          communications between you, Hiyllo, and/or {productName}
        </li>
        <li>
          When you interact with Hiyllo’s advertising and applications on
          third-party websites and services, if those applications or
          advertising include links to this Policy.
        </li>
        <li>Through the provision of Hiyllo Services.</li>
      </ul>
      <p>It does not apply to information collected by:</p>
      <ul>
        <li>
          Any third party that gets access to the information, including through
          any application or content (including advertising) that may link to or
          be accessible from, on or through the {productName} or any information
          you provide to or is collected by any third party.
        </li>
      </ul>
      <p>
        Please read this Policy carefully to understand Hiyllo’s policies and
        practices regarding your information and how Hiyllo will treat it. If
        you do not agree with this Policy, your choice is not to use the{" "}
        {productName}. By accessing or using the {productName}, you agree to
        this Privacy Policy. This Policy may change from time to time (see the
        Changes to Hiyllo Privacy Policy section below). Your continued use of
        the {productName} after Hiyllo makes changes is deemed to be acceptance
        of those changes, so please check the Policy periodically for updates.
      </p>
      <p>
        The {productName} is hosted on servers in the United States and Hiyllo
        is located in the United States. Accordingly, if you are located outside
        of the United States, any information you provide to Hiyllo will be
        transferred to the United States. By providing Hiyllo with any
        information, you consent to its transfer and storage in the United
        States.
      </p>
      <h3>2. Children Under the Age of 18</h3>
      <p>
        The {productName} is not intended for individuals under 18 years of age.
        No one under age 18 may provide any information to or on the{" "}
        {productName}. Hiyllo does not knowingly collect personal information
        from children under 18. If you are under 18, do not use or provide any
        information on the {productName}, or through any of its features,
        register on the {productName}, or provide any information about yourself
        to Hiyllo, including your name, address, telephone number, email
        address, or any screen name or username you may use. If Hiyllo learns
        that it has collected or received personal information from a child
        under 18 without verification of parental consent, Hiyllo will delete
        that information.
      </p>
      <h3>
        3. Information Hiyllo Collects About You and How Hiyllo Collects It
      </h3>
      <p>
        Hiyllo collects several types of information from and about users of the
        {productName}:
      </p>
      <ul>
        <li>Directly from you when you provide it to Hiyllo.</li>
        <li>Automatically when you use the {productName}.</li>
      </ul>
      <p>Information You Provide to Hiyllo</p>
      <p>
        When you register with or use the {productName}, Hiyllo may ask that you
        provide information
      </p>
      <ul>
        <li>
          By which you may be personally identified, such as name, postal
          address, e-mail address, telephone number, online profile (such as
          LinkedIn, Facebook, etc.), facial image or photo, or other identifier
          by which you may be contacted online or offline (“personal
          information”).
        </li>
      </ul>
      <p>
        The information Hiyllo collects on or through the {productName} or
        otherwise may include:
      </p>
      <ul>
        <li>
          Information that you provide by filling in forms on the {productName}.
          This includes information provided at the time of registering to use
          the {productName}, subscribing to any service, posting material, and
          requesting further services. Hiyllo may also ask you for information
          when you enter a promotion sponsored by Hiyllo, and when you report a
          problem with the {productName}.
        </li>
        <li>
          Records and copies of your correspondence (including email addresses
          and phone numbers) if you contact us.
        </li>
        <li>
          Your responses to surveys that we might ask you to complete for
          research purposes
        </li>
        <li>
          Details of transactions you carry out through the {productName} or
          with Hiyllo, and of the fulfillment of your orders.
        </li>
        <li>Your search queries on the {productName}</li>
        <li>Any interactive features of the {productName}.</li>
        <li>When you request customer support.</li>
      </ul>
      <p>
        You also may provide information or content to be published or displayed
        (hereinafter, “posted”) on the {productName} or transmitted to other
        users of the {productName}, or third parties (collectively, “User
        Content”). Your User Content is posted on and transmitted to others at
        your own risk. Please be aware that no security measures are perfect or
        impenetrable. Additionally, Hiyllo cannot control the actions of other
        users of the {productName} with whom you may choose to share your User
        Content. Therefore, Hiyllo cannot and does not guarantee that your User
        Content will not be viewed by unauthorized persons.
      </p>
      <p>Other Information Hiyllo Collects</p>
      <p>
        In order to provide you with access to and use of the {productName}, or
        to provide you with better service in general, Hiyllo may combine
        information obtained from other sources (for example, a thirdparty
        service whose application you have authorized or used to sign in) and
        combine that with information collected by Hiyllo.
      </p>
      <p>
        Information Hiyllo Collects Through Automatic Data Collection
        Technologies
      </p>
      <p>
        When you access, and use the {productName}, or as you navigate through
        and interact with portions of the {productName}, Hiyllo may use
        technology to automatically collect:
      </p>
      <ul>
        <li>
          Usage Details. When you access and use the {productName}, Hiyllo may
          automatically collect certain details of your access to and use of the{" "}
          {productName}, including location data, logs, access times, pages
          viewed, and other communication data and the resources that you access
          and use on or through the {productName}.
        </li>
        <li>
          Device Information. Hiyllo may collect information about your mobile
          device, computer and internet connection, including the device’s
          unique device identifier, IP address, operating system, browser type,
          mobile network information, and the device’s telephone number.
        </li>
        <li>
          Stored Information and Files. The {productName} also may access
          metadata and other information associated with other files stored on
          your device or computer. This may include, for example, photographs,
          audio and video clips, personal contacts, and address book
          information.
        </li>
        <li>
          Location Information. When you are logged in to the {productName}, the{" "}
          {productName} collects real-time information about the location of
          your device.
        </li>
      </ul>
      <p>
        If you do not want us to collect this information do not visit or use
        the {productName}. For more information, see Choices About How Hiyllo
        Uses and Discloses Your Information section below. Note, however, that
        opting out of {productName}’s collection of location information will
        disable its location-based features.
      </p>
      <p>
        The technologies Hiyllo uses for this automatic data collection may
        include:
      </p>
      <ul>
        <li>
          Cookies (or mobile/browser cookies). A cookie is a small file placed
          on the hard drive of your computer. You may be able to refuse to
          accept mobile or browser cookies by activating the appropriate setting
          on your device or browser. However, if you select this setting you may
          be unable to access certain parts of the {productName}. Unless you
          have adjusted your browser setting so that it will refuse cookies,
          Hiyllo’s system will issue cookies when you direct your browser to the
          {productName}.
        </li>
        <li>
          Web Beacons. Pages of the {productName} and Hiyllo’s e-mails may
          contain small electronic files known as web beacons (also referred to
          as clear gifs, pixel tags, and single-pixel gifs) that permit Hiyllo,
          for example, to count users who have visited those pages or opened an
          email and for other related App or Website statistics (for example,
          recording the popularity of certain content and verifying system and
          server integrity).
        </li>
      </ul>
      <p>
        Hiyllo does not collect personal information automatically, but Hiyllo
        may tie this information to personal information about you that Hiyllo
        collects from other sources or that you provide to us.
      </p>
      <h3>4. Third-Party Use of Cookies and Other Tracking Technologies</h3>
      <p>
        When you use the {productName}, or its content, certain third parties
        may use automatic information collection technologies to collect
        information about you or your device. These third parties may include:
      </p>
      <ul>
        <li>Analytics companies</li>
        <li>Your mobile device manufacturer</li>
        <li>Your mobile service provider</li>
        <li>
          The applicable payment processor, including credit card companies,
          Stripe, PayPal, Apple Pay, etc.
        </li>
      </ul>
      <p>
        These third parties may use tracking technologies to collect information
        about you when you use the {productName}. The information they collect
        may be associated with your personal information or they may collect
        information, including personal information, about your online
        activities over time and across different websites, apps, and other
        online services websites. They may use this information to provide you
        with interest-based (behavioral) advertising or other targeted content.
      </p>
      <p>
        Hiyllo does not control these third parties’ tracking technologies or
        how they may be used. If you have any questions about an advertisement
        or other targeted content, you should contact the responsible provider
        directly. For information about how you can opt out of receiving
        targeted advertising from many providers, see below.
      </p>
      <p>
        Hiyllo currently uses Google Analytics on pages that appear before a
        purchase that has been made, and currently does use any third party
        analytics providers inside the Websites or Apps. See https://
        policies.google.com/privacy and
        https://policies.google.com/technologies/partner-sites for the Google
        Analytics privacy policies.
      </p>
      <h3>5. How Hiyllo Uses Your Information</h3>
      <p>
        Hiyllo uses information that it collects about you or that you provide
        to Hiyllo, including any personal information:
      </p>
      <ul>
        <li>
          To provide you with the {productName}, its contents, and any other
          information, products or services that you request from Hiyllo.
        </li>
        <li>
          To process and track your orders, process payments, and coordinate
          delivery of merchandise.
        </li>
        <li>
          To verify your identity so that you can access the {productName}.
        </li>
        <li>To manage any registered account that you hold with us.</li>
        <li>To fulfill any other purpose for which you provide it.</li>
        <li>To provide you with notices about your account.</li>
        <li>
          To carry out Hiyllo’s obligations and enforce Hiyllo’s rights arising
          from any contracts entered into between you (or the organization your
          account is associated with) and Hiyllo, including for billing and
          collection
        </li>
        <li>
          To notify you about changes to the {productName} or any products or
          services Hiyllo offers or provides though it.
        </li>
        <li>
          To allow you to participate in interactive features on the{" "}
          {productName}.
        </li>
        <li>To send you promotional materials and other communications.</li>
        <li>To understand and set your communication preferences.</li>
        <li>
          In any other way Hiyllo may describe when you provide the information.
        </li>
        <li>
          Send you technical notices, updates, security alerts and support and
          administrative messages.
        </li>
        <li>
          Respond to your comments, questions and requests and provide customer
          service.
        </li>
        <li>
          Communicate with you about news and information related to the{" "}
          {productName}
        </li>
        <li>
          Monitor and analyze trends, usage and activities in connection with
          our services; and
        </li>
        <li>Personalize and improve the {productName}</li>
        <li>For any other purpose with your consent.</li>
      </ul>
      <p>
        The information Hiyllo collects helps Hiyllo to improve the{" "}
        {productName} and to deliver a better and more personalized service,
        including by enabling Hiyllo to:
      </p>
      <ul>
        <li>Estimate Hiyllo’s audience size and usage patterns.</li>
        <li>
          Store information about your preferences, allowing us to customize the
          {productName} according to your individual interests.
        </li>
        <li>Speed up your searches.</li>
        <li>Recognize you when you return to the {productName}.</li>
      </ul>
      <p>
        Hiyllo may also use your information to contact you about Hiyllo’s own
        and third parties’ goods and services that may be of interest to you. If
        you do not want us to use your information in this way, please send an
        email to Hiyllo at support@hiyllo.io. For more information, see Choices
        About How Hiyllo Uses and Discloses Your Information below.
      </p>
      <p>
        Hiyllo may use the information it has collected from you to enable
        Hiyllo to display advertisements to Hiyllo’s advertisers’ target
        audiences. Even though Hiyllo does not disclose your personal
        information for these purposes without your consent, if you click on or
        otherwise interact with an advertisement, the advertiser may assume that
        you meet its target criteria.
      </p>
      <h3>6. Disclosure of Your Information</h3>
      <p>
        Hiyllo may disclose aggregated information about Hiyllo’s users, and
        information that does not identify any individual, without restriction.
      </p>
      <p>
        Hiyllo may disclose personal information that Hiyllo collects or you
        provide as described in this Privacy Policy:
      </p>
      <ul>
        <li>To Hiyllo’s subsidiaries and affiliates.</li>
        <li>
          To contractors, service providers, and other third parties Hiyllo uses
          to support Hiyllo’s business such as such as third parties involved in
          processing credit card payments, reducing credit risk, fulfilling
          orders, data storage, website hosting, cellular service, performing
          data analytics, and sending marketing messages.
        </li>
        <li>
          To operators of social media platforms if you accessed the{" "}
          {productName} through a social media site.
        </li>
        <li>
          To a buyer or other successor in the event of a merger, divestiture,
          restructuring, reorganization, dissolution, or other sale or transfer
          of some or all of Hiyllo’s assets, whether as a going concern or as
          part of bankruptcy, liquidation, or similar proceeding, in which
          personal information held by Hiyllo about {productName} users is among
          the assets transferred.
        </li>
        <li>
          To fulfill the purpose for which you provide it. For example, if you
          give us an email address to use the “email a friend” feature of the
          {productName}, Hiyllo will transmit the contents of that email and
          your email address to the recipients.
        </li>
        <li>
          For any other purpose disclosed by us when you provide the
          information.
        </li>
        <li>With your consent.</li>
        <li>
          To comply with any court order, law, or legal process, including to
          respond to any government or regulatory request.
        </li>
        <li>
          To enforce or apply {productName} Terms of Use at{" "}
          <a href={termsOfUseLink} target="blank">
            {termsOfUseLink}
          </a>
          {isSolo
            ? ""
            : `, the ${productName} Terms of Service at https://www.hiyllo.work/work/legal/terms-of-service.pdf`}
          , a Customer Order, and other agreements, including for billing and
          collection purposes.
        </li>
        <li>
          If Hiyllo believes disclosure is necessary or appropriate to protect
          the rights, property, or safety of Hiyllo, Hiyllo’s customers, or
          others. This includes exchanging information with other companies and
          organizations for the purposes of fraud protection and credit risk
          reduction.
        </li>
      </ul>
      <h3>7. Choices About How Hiyllo Uses and Discloses Your Information</h3>
      <p>
        Hiyllo strives to provide you with choices regarding the personal
        information you provide to us. This section describes mechanisms Hiyllo
        provides for you to control certain uses and disclosures of your
        information:
      </p>
      <ul>
        <li>
          Location Information. You can choose whether or not to allow the
          {productName} to collect and use real-time information about your
          device’s location by not logging into the {productName} via the
          device, or through the device’s privacy settings. If you block the use
          of location information, some parts of the {productName} may become
          inaccessible or not function properly
        </li>
        <li>
          Promotion by Hiyllo. If you do not want us to use your email address
          to promote Hiyllo’s own or third parties’ products or services, you
          can opt-out by sending an email to support@hiyllo.io.
        </li>
      </ul>
      <p>
        Hiyllo does not control third parties’ collection or use of your
        information to serve interest-based advertising. However, these third
        parties may provide you with ways to choose not to have your information
        collected or used in this way. You can opt out of receiving targeted ads
        from members of the Network Advertising Initiative (“NAI”) on the NAI’s
        website.
      </p>
      <p>
        California and other state or country residents may have additional
        personal information rights and choices. Please see below for more
        information.
      </p>
      <h3>8. Accessing and Correcting Your Information</h3>
      <p>
        You may also send us an email at support@hiyllo.io to request access to,
        correct or delete any personal information that you have provided to us.
        Hiyllo cannot delete your personal information except by also deleting
        your user account. Hiyllo may not accommodate a request to change
        information if Hiyllo believes the change would violate any law or legal
        requirement or cause the information to be incorrect.
      </p>
      <p>
        If you delete your User Content from the {productName}, copies of your
        User Content may remain viewable in cached and archived pages or might
        have been copied or stored by other {productName} users. Proper access
        and use of information provided on the {productName}, including User
        Content, is governed by Hiyllo’s {productName} Terms of Use located at
        {termsOfUseLink}
        {isSolo
          ? ""
          : ` and Termsof Service located at https://www.hiyllo.work/work/legal/terms-of-service.pdf`}
        .
      </p>
      <p>
        California and other state or country residents may have additional
        personal information rights and choices. Please see below for more
        information.
      </p>
      <h3>9. Your California Privacy Rights</h3>
      <p>
        California Civil Code Section 1798.83, also known as the “Shine The
        Light” law may permit Users who are California residents to request and
        obtain from Hiyllo, once a year and free of charge, information about
        categories of personal information (if any) we disclosed to third
        parties for direct marketing purposes and the names and addresses of all
        third parties with which we shared personal information in the
        immediately preceding calendar year. If you are a California resident
        and would like to make such a request, please submit your request in
        writing to us using the contact information provided below.
      </p>
      <p>
        If you are under 18 years of age, reside in California, and have a
        registered account with the Services, you have the right to request
        removal of unwanted data that you publicly post on the Services. To
        request removal of such data, please contact us using the contact
        information provided below and include the email address associated with
        your account and a statement that you reside in California. We will make
        sure the data is not publicly displayed on the Services, but please be
        aware that the data may not be completely or comprehensively removed
        from all our systems (e.g., backups, etc.).
      </p>
      <p>
        <u>CCPA Privacy Notice</u>
      </p>
      <p>
        This section applies only to California residents. Under the California
        Consumer Privacy Act (CCPA), you have the rights listed below. The
        California Code of Regulations defines a “residents” as:
      </p>
      <ol>
        <li>
          every individual who is in the State of California for other than a
          temporary or transitory purpose and
        </li>
        <li>
          every individual who is domiciled in the State of California who is
          outside the State of California for a temporary or transitory purpose
          All other individuals are defined as “non-residents.
        </li>
      </ol>
      <p>
        If this definition of “resident” applies to you, we must adhere to
        certain rights and obligations regarding your personal information.
      </p>
      <p>
        <u>Your rights with respect to your personal data</u>
      </p>
      <p>
        You can ask for the deletion of your personal information. If you ask us
        to delete your personal information, we will respect your request and
        delete your personal information, subject to certain exceptions provided
        by law, such as (but not limited to) the exercise by another consumer of
        his or her right to free speech, our compliance requirements resulting
        from a legal obligation, or any processing that may be required to
        protect against illegal activities.
      </p>
      <p>Depending on the circumstances, you have a right to know:</p>
      <ul>
        <li>whether we collect and use your personal information;</li>
        <li>the categories of personal information that we collect</li>
        <li>
          the purposes for which the collected personal information is used
        </li>
        <li>whether we sell or share personal information to third parties;</li>
        <li>
          the categories of personal information that we sold, shared, or
          disclosed for a business purpose;
        </li>
        <li>
          the categories of third parties to whom the personal information was
          sold, shared, or disclosed for a business purpose;
        </li>
        <li>
          the business or commercial purpose for collecting, selling, or sharing
          personal information; and
        </li>
        <li>
          the specific pieces of personal information we collected about you
        </li>
      </ul>
      <p>
        In accordance with applicable law, Hiyllo is not obligated to provide or
        delete consumer information that is de-identified in response to a
        consumer request or to re-identify individual data to verify a consumer
        request.
      </p>
      <p>
        We will not discriminate against you if you exercise your privacy
        rights.
      </p>
      <p>We do not process consumer&apos;s sensitive personal information</p>
      <p>
        Verification process. Upon receiving your request, we will need to
        verify your identity to determine you are the same person about whom we
        have the information in our system. These verification efforts require
        us to ask you to provide information so that we can match it with
        information you have previously provided us. For instance, depending on
        the type of request you submit, we may ask you to provide certain
        information so that we can match the information you provide with the
        information we already have on file, or we may contact you through a
        communication method (e.g., phone or email) that you have previously
        provided to us. We may also use other verification methods as the
        circumstances dictate.
      </p>
      <p>
        We will only use personal information provided in your request to verify
        your identity or authority to make the request. To the extent possible,
        we will avoid requesting additional information from you for the
        purposes of verification. However, if we cannot verify your identity
        from the information already maintained by us, we may request that you
        provide additional information for the purposes of verifying your
        identity and for security or fraud-prevention purposes. We will delete
        such additionally provided information as soon as we finish verifying
        you
      </p>
      <p>
        <u>Other privacy rights</u>
      </p>
      <p>You may object to the processing of your personal information.</p>
      <p>
        You may request correction of your personal data if it is incorrect or
        no longer relevant or ask to restrict the processing of the information
      </p>
      <p>
        You can designate an authorized agent to make a request under the CCPA
        on your behalf. We may deny a request from an authorized agent that does
        not submit proof that they have been validly authorized to act on your
        behalf in accordance with the CCPA.
      </p>
      <p>
        You can opt out from the selling or sharing of your personal information
        as otherwise set forth in the Privacy Policy or on the Platform.
      </p>
      <p>
        To exercise these rights, you can contact us by submitting a data
        subject access request, or by referring to the contact details at the
        bottom of this document. If you have a complaint about how we handle
        your data, we would like to hear from you.
      </p>
      <h3>10. Rights of Colorado Residents</h3>
      <p>
        This section applies only to Colorado residents. Under the Colorado
        Privacy Act (CPA), you may have the rights listed below. However, these
        rights are not absolute, and in certain cases, we may decline your
        request as permitted by law
      </p>
      <ul>
        <li>
          Right to be informed whether or not we are processing your personal
          data
        </li>
        <li>Right to access your personal data</li>
        <li>Right to correct inaccuracies in your personal data</li>
        <li>Right to request deletion of your personal data</li>
        <li>
          Right to obtain a copy of the personal data you previously shared with
          us
        </li>
        <li>
          Right to opt out of the processing of your personal data if it is used
          for targeted advertising, the sale of personal data, or profiling in
          furtherance of decisions that produce legal or similarly significant
          effects (“profiling”).
        </li>
      </ul>
      <p>
        To submit a request to exercise any of the other rights described above,
        please email support@hiyllo.io. If we decline to take action regarding
        your request and you wish to appeal our decision, please email us at
        support@hiyllo.io. Within forty-five (45) days of receipt of an appeal,
        we will inform you in writing of any action taken or not taken in
        response to the appeal, including a written explanation of the reasons
        for the decisions.
      </p>
      <h3>11. Rights of Connecticut Residents</h3>
      <p>
        This section applies only to Connecticut residents. Under the
        Connecticut Data Privacy Act (CTDPA), you may have the rights listed
        below. However, these rights are not absolute, and in certain cases, we
        may decline your request as permitted by law.
      </p>
      <ul>
        <li>
          Right to be informed whether or not we are processing your personal
          data
        </li>
        <li>Right to access your personal data</li>
        <li>Right to correct inaccuracies in your personal data</li>
        <li>Right to request deletion of your personal data</li>
        <li>
          Right to obtain a copy of the personal data you previously shared with
          us
        </li>
        <li>
          Right to opt out of the processing of your personal data if it is used
          for targeted advertising, the sale of personal data, or profiling in
          furtherance of decisions that produce legal or similarly significant
          effects (“profiling”)
        </li>
      </ul>
      <p>
        We may sell personal data to third parties or process personal data for
        targeted advertising. You can opt out from the selling of your personal
        data, targeted advertising, or profiling by disabling cookies in cookies
        as set forth in this Privacy Policy. To submit a request to exercise any
        of the other rights described above, please email support@hiyllo.io.
      </p>
      <p>
        If we decline to take action regarding your request and you wish to
        appeal our decision, please email us at support@hiyllo.io. Within sixty
        (60) days of receipt of an appeal, we will inform you in writing of any
        action taken or not taken in response to the appeal, including a written
        explanation of the reasons for the decisions.
      </p>
      <h3>12. Rights of Utah Residents</h3>
      <p>
        This section applies only to Utah residents. Under the Utah Consumer
        Privacy Act (UCPA), you may have the rights listed below. However, these
        rights are not absolute, and in certain cases, we may decline your
        request as permitted by law
      </p>
      <ul>
        <li>
          Right to be informed whether or not we are processing your personal
          data.
        </li>
        <li>Right to access your personal data</li>
        <li>Right to request deletion of your personal data.</li>
        <li>
          Right to obtain a copy of the personal data you previously shared with
          us.
        </li>
        <li>
          Right to opt out of the processing of your personal data if it is used
          for targeted advertising or the sale of personal data.
        </li>
      </ul>
      <p>
        We may sell personal data to third parties or process personal data for
        targeted advertising. You can opt out from the selling of your personal
        data or targeted advertising by disabling cookies as set forth in this
        Privacy Policy. To submit a request to exercise any of the other rights
        described above, please email support@hiyllo.io.
      </p>
      <h3>13. Rights of Virginia Residents</h3>
      <p>Under the Virginia Consumer Data Protection Act (VCDPA):</p>
      <p>
        “Consumer” means a natural person who is a resident of the Commonwealth
        acting only in an individual or household context. It does not include a
        natural person acting in a commercial or employment context. “Personal
        data” means any information that is linked or reasonably linkable to an
        identified or identifiable natural person. “Personal data” does not
        include de-identified data or publicly available information. “Sale of
        personal data” means the exchange of personal data for monetary
        consideration. If this definition of “consumer” applies to you, we must
        adhere to certain rights and obligations regarding your personal data.
        Your rights with respect to your personal data.
      </p>
      <ul>
        <li>
          Right to be informed whether or not we are processing your personal
          data
        </li>
        <li>Right to access your personal data</li>
        <li>Right to correct inaccuracies in your personal data</li>
        <li>Right to request deletion of your personal data</li>
        <li>
          Right to obtain a copy of the personal data you previously shared with
          us
        </li>
        <li>
          Right to opt out of the processing of your personal data if it is used
          for targeted advertising, the sale of personal data, or profiling in
          furtherance of decisions that produce legal or similarly significant
          effects (“profiling”)
        </li>
      </ul>
      <p>
        We sell personal data to third parties or process personal data for
        targeted advertising. Please see the following section to find out how
        you can opt out from further selling or sharing of your personal data
        for targeted advertising or profiling purposes.
      </p>
      <p>Exercise your rights provided under the Virginia VCDPA</p>
      <p>
        You can opt out from the selling of your personal data, targeted
        advertising, or profiling by disabling cookies as set forth in this
        Privacy Policy. To submit a request to exercise any of the other rights
        described above, please email support@hiyllo.io. If you are using an
        authorized agent to exercise your rights, we may deny a request if the
        authorized agent does not submit proof that they have been validly
        authorized to act on your behalf.
      </p>
      <p>
        <u>Verification process</u>
      </p>
      <p>
        We may request that you provide additional information reasonably
        necessary to verify you and your consumer&apos;s request. If you submit
        the request through an authorized agent, we may need to collect
        additional information to verify your identity before processing your
        request. Upon receiving your request, we will respond without undue
        delay, but in all cases, within forty-five (45) days of receipt. The
        response period may be extended once by forty-five (45) additional days
        when reasonably necessary. We will inform you of any such extension
        within the initial 45-day response period, together with the reason for
        the extension.
      </p>
      <p>
        <u>Right to appeal</u>
      </p>
      <p>
        If we decline to take action regarding your request, we will inform you
        of our decision and reasoning behind it. If you wish to appeal our
        decision, please email us at support@hiyllo.io. Within sixty (60) days
        of receipt of an appeal, we will inform you in writing of any action
        taken or not taken in response to the appeal, including a written
        explanation of the reasons for the decisions. If your appeal is denied,
        you may contact the Attorney General to submit a complaint.
      </p>
      <h3>14. Rights of Residents in the European Union (EU), UK</h3>
      <p>
        If you use the Platform and reside in the EU or UK, you are entitled by
        law to access, correct, amend, or delete personal information about you
        that we hold. A list of these rights is below. Please note that these
        rights are not absolute and certain exemptions apply. To exercise these
        rights please contact Hiyllo at support. For your protection, we may
        need to verify your identity before responding to your request, such as
        verifying that the email address from which you send the request matches
        your email address that we have on file. In the event that we refuse a
        request under rights of access, we will provide you a reason as to why.
        ● The right to access: You have the right to ask Hiyllo for copies of
        your personal information. This right has some exemptions, which means
        you may not always receive all the information we process. When making a
        request, please provide an accurate description of the data you want
        access to. ● The right to rectification: You have the right to ask
        Hiyllo to rectify information you think is inaccurate. You also have the
        right to ask Hiyllo to complete information you think is incomplete. ●
        The right to erasure: You have the right to ask Hiyllo to erase your
        personal information in certain circumstances, including (i) when your
        personal information is no longer necessary for the purposes for which
        it was collected or processed or (ii) your information must be erased to
        comply with a legal obligation in EU Union or Member State law. ● The
        right to restrict processing: You have the right to ask Hiyllo to
        restrict the processing of your information in certain circumstances,
        including (i) when the accuracy of the information is brought into
        question or (ii) when we no longer need the information for purposes of
        the processing but you require such information for the establishment,
        exercise, or defense of a legal claim. See “choices about your
        information” for some ways you can restrict processing. ● The right to
        object to processing: You have the right to object to processing if we
        are able to process your information because the process is in Hiyllo’s
        legitimate interests. See “choices about your information” for some ways
        you can object to processing. ● The right to data portability: This only
        applies to information you have given Hiyllo. You have the right to ask
        that we transfer the information you gave Hiyllo from one organization
        to another, or give it to you. ● The right to lodge a complaint with the
        supervisory authority. A list of Supervisory Authorities is available
        here: http://ec.europa.eu/justice/data-protection/bodies/authorities/
        index_en.htm.
      </p>
      <h3>15. Rights of Canadian Residents</h3>
      <p>
        If you are located in Canada, we may process your information if you
        have given us specific permission (i.e., express consent) to use your
        personal information for a specific purpose, or in situations where your
        permission can be inferred (i.e., implied consent). You can withdraw
        your consent at any time. In some exceptional cases, we may be legally
        permitted under applicable law to process your information without your
        consent, including, for example: • If collection is clearly in the
        interests of an individual and consent cannot be obtained in a timely
        way. • For investigations and fraud detection and prevention. • For
        business transactions provided certain conditions are met. • If it is
        contained in a witness statement and the collection is necessary to
        assess, process, or settle an insurance claim. • For identifying
        injured, ill, or deceased persons and communicating with next of kin •
        If we have reasonable grounds to believe an individual has been, is, or
        may be victim of financial abuse. • If it is reasonable to expect
        collection and use with consent would compromise the availability or the
        accuracy of the information and the collection is reasonable for
        purposes related to investigating a breach of an agreement or a
        contravention of the laws of Canada or a province. • If disclosure is
        required to comply with a subpoena, warrant, court order, or rules of
        the court relating to the production of records. • If it was produced by
        an individual in the course of their employment, business, or profession
        and the collection is consistent with the purposes for which the
        information was produced. • If the collection is solely for
        journalistic, artistic, or literary purposes. • If the information is
        publicly available and is specified by the regulations
      </p>
      <h3>16. How Long Do We Keep Your Information</h3>
      <p>
        Hiyllo will retain your personal information until the later of (i) the
        information is no longer necessary to accomplish the purpose for which
        it was provided; (ii) twelve (12) months after it is provided or
        collected; or (iii) your account with Hiyllo is terminated.
      </p>
      <p>
        We retain your information for longer periods for specific purposes to
        the extent that we are obliged to do so in accordance with applicable
        laws and regulations and/or as necessary to protect our legal rights or
        for certain business requirements. For example, when we process your
        payments, we will retain this data for longer periods of time as
        required for tax or accounting purposes. More specifically, we would
        retain some data for longer periods of time for the following reasons:
      </p>
      <ul>
        <li>
          Security, fraud, and abuse prevention: To protect you, other people,
          and us from fraud, abuse, and unauthorized access.
        </li>
        <li>
          Financial recordkeeping: When you make a payment to us we are often
          required to retain this information for long period of time for
          purposes of accounting, dispute resolution, and compliance with tax,
          anti-money laundering, and other financial regulations.
        </li>
        <li>
          Complying with legal or regulatory requirements: To meet any
          applicable law, regulation, legal process, or enforceable governmental
          request, as required to enforce our terms of service, including
          investigation of potential violations.
        </li>
        <li>
          Direct communications with us: If you have directly communicated with
          us, through a support channel, feedback form, or a bug report, we may
          retain reasonable records of those communications.
        </li>
      </ul>
      <p>
        Even if you delete your account, keep in mind that the deletion by our
        third-party providers may not be immediate and that the deleted
        information may persist in backup copies for a reasonable period of
        time. For any privacy or data-protection-related questions, please write
        to support@hiyllo.io.
      </p>
      <h3>17. Data Security</h3>
      <p>
        Hiyllo has implemented measures designed to secure your personal
        information from accidental loss and from unauthorized access, use,
        alteration, and disclosure.
      </p>
      <p>
        The safety and security of your information also depends on you. Where
        Hiyllo has given you (or where you have chosen) a password or other
        account code for access to certain parts of the {productName}, you are
        responsible for keeping this password and/or code confidential. Hiyllo
        asks that you not share your password or code with anyone. Hiyllo urges
        you to be careful about giving out information in public areas of the{" "}
        {productName} or the sharing of information with third parties.
      </p>
      <p>
        Unfortunately, the transmission of information via the internet and
        mobile platforms is not completely secure. Although Hiyllo takes
        reasonable steps to protect your personal information, Hiyllo cannot
        guarantee the security of your personal information transmitted to or
        through the {productName}. Any transmission of personal information is
        at your own risk. Hiyllo is not responsible for circumvention of any
        privacy settings or security measures contained on the {productName}.
      </p>
      <h3>18. Changes to Hiyllo’s Privacy Policy</h3>
      <p>
        Hiyllo may update the Privacy Policy from time to time. If Hiyllo makes
        material changes to how it treats its users’ personal information,
        Hiyllo will post the new Privacy Policy on this page with a notice that
        the Privacy Policy has been updated and notify you by email to the email
        address specified in your account.
      </p>
      <p>
        The date the Privacy Policy was last revised is identified at the top of
        the page. You are responsible for ensuring Hiyllo has an up-to-date
        active and deliverable email address for you and for periodically
        visiting this Privacy Policy to check for any changes.
      </p>
      <h3>19. Contact Information</h3>
      <p>
        To ask questions or comment about this Privacy Policy and Hiyllo’s
        privacy practices, contact us at: support@hiyllo.io. Visit the{" "}
        {productName} for the most up to date contact information.
      </p>
    </div>
  );
});
