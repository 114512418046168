import React from "react";
import { type ListTasksSlimTaskType } from "../../../types/tasks/task-item";
import { type TaskOrderType } from "../../../types/tasks/tasks-organization";
import { KanbanFramelessContext } from "./task-kanban-view";
import { faBarsSort } from "@fortawesome/pro-light-svg-icons";
import {
  BoardColumn,
  BoardColumnContainer,
  CardsContainer,
  ColumnHeader,
  ColumnHeaderTitleText,
  SubtleButton,
} from "./components";
import {
  ContextMenuContainer,
  ContextMenuItem,
  useContextMenu,
} from "@hiyllo/ux/context-menu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Droppable, Draggable } from "@hello-pangea/dnd";
import { TaskCard } from "../components/task-card";

export const KanbanColumn = React.memo(function KanbanColumn(props: {
  hideHeader?: boolean;
  currentOrder: TaskOrderType;
  onChangeOrder: (
    order: TaskOrderType,
    updatedTask: ListTasksSlimTaskType | null,
  ) => void;
  onSelectTask: (task: ListTasksSlimTaskType) => void;
  label: string;
  id: string | null;
  tasks: ListTasksSlimTaskType[];
  order: string[];
}): JSX.Element {
  const frameless = React.useContext(KanbanFramelessContext);
  const cxMenu = useContextMenu();

  const sortByPriority = React.useCallback(() => {
    if (props.id == null) {
      return;
    }

    const tasks: ListTasksSlimTaskType[] = props.order
      .map((taskUUID) => props.tasks.find((t) => t.uuid === taskUUID))
      .filter<ListTasksSlimTaskType>(
        (t): t is ListTasksSlimTaskType => t != null,
      );
    const newOrder = tasks
      .sort((a, b) => {
        return (
          a.priority - b.priority ||
          (a.dueDate?.date.valueOf() ?? Infinity) -
          (b.dueDate?.date.valueOf() ?? Infinity)
        );
      })
      .map((t) => t.uuid);

    props.onChangeOrder(
      {
        ...props.currentOrder,
        [props.id]: newOrder,
      },
      null,
    );

    cxMenu.close();
  }, [cxMenu, props]);

  const moveTaskToTop = React.useCallback(
    (task: ListTasksSlimTaskType) => {
      if (props.id == null) {
        return;
      }

      const newOrder = props.order.filter((t) => t !== task.uuid);
      newOrder.unshift(task.uuid);

      props.onChangeOrder(
        {
          ...props.currentOrder,
          [props.id]: newOrder,
        },
        task,
      );
    },
    [props],
  );

  const moveTaskToBottom = React.useCallback(
    (task: ListTasksSlimTaskType) => {
      if (props.id == null) {
        return;
      }

      const newOrder = props.order.filter((t) => t !== task.uuid);
      newOrder.push(task.uuid);

      props.onChangeOrder(
        {
          ...props.currentOrder,
          [props.id]: newOrder,
        },
        task,
      );
    },
    [props],
  );

  const renderTasks = (props.id === null
    ? props.tasks.map((t) => t.uuid)
    : props.order
  );

  return (
    <BoardColumnContainer>
      <cxMenu.CXMenuContainer>
        <ContextMenuContainer>
          <ContextMenuItem
            icon={faBarsSort}
            label="Sort by Priority"
            onClick={sortByPriority}
          />
        </ContextMenuContainer>
      </cxMenu.CXMenuContainer>
      <BoardColumn frameless={frameless}>
        {props.hideHeader !== true ? (
          <ColumnHeader>
            <ColumnHeaderTitleText>{props.label}</ColumnHeaderTitleText>
            <SubtleButton onClick={cxMenu.open}>
              <FontAwesomeIcon icon={faBarsSort} />
            </SubtleButton>
          </ColumnHeader>
        ) : null}
        <CardsContainer>
          <Droppable droppableId={props.id ?? "default"}>
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                style={{
                  height: "100%",
                  overflowY: "auto",
                }}
              >
                {renderTasks.map((taskUUID, i) => {
                  const task: ListTasksSlimTaskType | undefined =
                    props.tasks.find((task) => task.uuid === taskUUID);

                  if (task == null) {
                    return <div key={(props.id ?? "default") + taskUUID} />;
                  }

                  return (
                    <Draggable
                      key={task.uuid}
                      draggableId={task.uuid}
                      index={i}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            ...provided.draggableProps.style,
                            marginBottom: 7.5,
                          }}
                        >
                          <TaskCard
                            isDragging={snapshot.isDragging}
                            onClick={() => props.onSelectTask(task)}
                            task={task}
                            onMoveTaskToTop={i === 0 ? null : () => moveTaskToTop(task)}
                            onMoveTaskToBottom={i === renderTasks.length - 1 ? null : () => moveTaskToBottom(task)}
                          />
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </CardsContainer>
      </BoardColumn>
    </BoardColumnContainer>
  );
});
