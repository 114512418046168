import React from "react";

import * as RetrieveEventBP from "../../../blueprints/calendar/retrieve-event";
import { type CalendarEvent } from "../../../types/calendar/calendar-event";
import { seamlessClient } from "../../../seamless-client";

const MeetingEventContext = React.createContext<CalendarEvent | null>(null);

export function useMeetingEvent(): CalendarEvent | null {
  return React.useContext(MeetingEventContext);
}

export const MeetingEventProvider = React.memo(function MeetingEventProvider(
  props: React.PropsWithChildren<{ eventUUID: string }>,
): JSX.Element {
  const calendarEventQuery =
    seamlessClient.useQuery<RetrieveEventBP.Plug>(RetrieveEventBP, {
      eventUUID: props.eventUUID,
    });

  return (
    <MeetingEventContext.Provider
      value={calendarEventQuery.data?.event ?? null}
    >
      {props.children}
    </MeetingEventContext.Provider>
  );
});
