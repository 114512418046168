import React, { useContext, useImperativeHandle } from "react";
import { useUploadFile } from "@hiyllo/omni-user-files";
import { cropImageToSquareAndDownsize } from "../../web-utils/image-tools";
import { type Theme, useThemeStyling } from "../themings";
import { FormIsLoadingContext } from "./form";
import { FormSubmitContext } from "./contexts";
import { useTheme } from "@hiyllo/ux/theme";
import { Typography } from "@hiyllo/ux/typography";
import { styled } from "@hiyllo/ux/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCloudUpload } from "@fortawesome/pro-light-svg-icons";
import { LoadingSpinner } from "@hiyllo/ux/loading-spinner";

interface PropsType {
  type: "email" | "text" | "password" | "file" | "tel" | "date" | "number";
  inputRef?: React.Ref<HTMLInputElement>;
  label?: string;
  placeholder?: string;
  fontSize?: number;
  fullWidth?: boolean;
  defaultValue?: string;
  style?: any;
  containerStyle?: any;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  onInput?: React.KeyboardEventHandler<HTMLDivElement>;
  onBlur?: React.FocusEventHandler<HTMLTextAreaElement>;
  onClick?: React.MouseEventHandler<HTMLInputElement>;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  value?: string;
  id?: string;
  themeStylingKey?: keyof Theme;
  disabled?: boolean;
  autoComplete?: string;
}

export const Input = (props: PropsType): JSX.Element => {
  const inputStyling = useThemeStyling<"input.standard">("input.standard");
  const isDisabled = useContext(FormIsLoadingContext);

  return (
    <div
      style={{
        paddingBottom: 12.5,
        width:
          props.fullWidth === true ? "100%" : "min(300px, calc(100% - 20px))",
        fontSize: props.fontSize ?? 14,
        fontFamily: '"Work Sans", sans-serif',
        ...props.containerStyle,
      }}
    >
      {props.label ? (
        <div
          style={{
            paddingBottom: 2.5,
            fontSize: 18,
            fontWeight: "bold",
          }}
        >
          {props.label}
        </div>
      ) : null}
      <input
        type={props.type}
        ref={props.inputRef}
        disabled={isDisabled || props.disabled}
        placeholder={props.placeholder}
        defaultValue={props.defaultValue}
        value={props.value}
        id={props.id}
        onChange={props.onChange}
        onClick={props.onClick}
        onKeyDown={props.onKeyDown}
        autoComplete={props.autoComplete}
        style={{
          colorScheme: "dark",
          backgroundColor: "#eee",
          borderRadius: 10,
          padding: "0.8em",
          outline: "none",
          border: "none",
          width: "calc(100% - 1.6em)",
          display: "block",
          fontSize: "inherit",
          fontFamily: '"Work Sans", sans-serif',
          ...inputStyling,
          ...props.style,
        }}
      />
    </div>
  );
};

export const CEInput = (
  props: Omit<PropsType, "inputRef" | "onChange"> & {
    inputRef: React.RefObject<HTMLDivElement>;
    multiline?: boolean;
    onChange?: React.ChangeEventHandler<HTMLDivElement>;
  },
): JSX.Element => {
  const inputStyling = useThemeStyling<"input.standard">("input.standard");
  const isDisabled = useContext(FormIsLoadingContext) || props.disabled;
  const onSubmitForm = useContext(FormSubmitContext);
  const [changed, setChanged] = React.useState(false);

  React.useEffect(() => {
    if (!changed && props.inputRef.current != null) {
      props.inputRef.current.innerText = props.defaultValue ?? "";
    }
  }, [changed, props.defaultValue, props.inputRef]);

  const onSubmit = (): void => {
    if (onSubmitForm != null) {
      onSubmitForm();
    }
  };

  return (
    <div
      style={{
        width:
          props.fullWidth === true ? "100%" : "min(300px, calc(100% - 20px))",
        fontSize: props.fontSize ?? 14,
        fontFamily: '"Work Sans", sans-serif',
        userSelect: isDisabled && "none",
        ...props.containerStyle,
      }}
    >
      {props.label ? (
        <div
          style={{
            paddingBottom: 2.5,
            fontSize: 18,
            fontWeight: "bold",
          }}
        >
          {props.label}
        </div>
      ) : null}
      <div
        contentEditable={!isDisabled}
        ref={props.inputRef}
        id={props.id}
        onInput={props.onInput}
        onClick={
          isDisabled
            ? props.onClick
            : (evt) => {
              // @ts-expect-error - Div focus
              evt.target.focus();
              evt.stopPropagation();
            }
        }
        onKeyUp={(evt) => {
          setChanged(true);
          if (!evt.shiftKey && evt.key === "Enter") {
            evt.preventDefault();
            evt.stopPropagation();
            onSubmit();
          } else if (props.onChange != null) {
            props.onChange(evt as unknown as React.ChangeEvent<HTMLDivElement>);
          }
        }}
        style={{
          backgroundColor: "#eee",
          borderRadius: 10,
          padding: "0.8em",
          outline: "none",
          border: "none",
          width: "calc(100% - 1.6em)",
          display: "block",
          fontSize: "inherit",
          fontFamily: '"Work Sans", sans-serif',
          cursor: isDisabled ? "pointer" : "text",
          ...(!props.multiline
            ? {
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }
            : { whiteSpace: "pre-wrap" }),
          ...inputStyling,
          ...props.style,
        }}
      />
    </div>
  );
};

export const Textarea = (props: PropsType & { rows?: number }): JSX.Element => {
  const inputStyling = useThemeStyling<"input.standard">("input.standard");
  const isDisabled = useContext(FormIsLoadingContext);

  return (
    <div
      style={{
        paddingBottom: 12.5,
        width:
          props.fullWidth === true ? "100%" : "min(300px, calc(100% - 20px))",
        fontSize: props.fontSize ?? 14,
        fontFamily: '"Work Sans", sans-serif',
      }}
    >
      <div style={{ paddingBottom: 2.5 }}>{props.label}</div>
      <textarea
        ref={props.inputRef as React.Ref<HTMLTextAreaElement>}
        onChange={props.onChange}
        onBlur={props.onBlur}
        disabled={isDisabled}
        placeholder={props.placeholder}
        defaultValue={props.defaultValue}
        value={props.value}
        id={props.id}
        rows={props.rows}
        style={{
          backgroundColor: "#eee",
          borderRadius: 10,
          padding: "0.8em",
          outline: "none",
          border: "none",
          width: "calc(100% - 1.6em)",
          display: "block",
          fontSize: "inherit",
          fontFamily: '"Work Sans", sans-serif',
          minHeight: "5em",
          ...inputStyling,
          ...props.style,
        }}
      />
    </div>
  );
};

export interface FileInputRefType {
  reset: () => void;
}

type FileInputPropsType = Omit<PropsType, "type"> &
  (
    | {
      skipCropping?: boolean;
      autoUpload: true;
      onFileId: (fileId: string) => void;
      onImagePreviewUrlAvailable?: (imageUrl: string) => void;
    }
    | {
      autoUpload: false;
      onChange: React.ChangeEventHandler<HTMLInputElement>;
    }
  );

const FileInputContainer = styled<"div", { fullWidth?: boolean }>(
  "div",
  ({ $theme, fullWidth }) => ({
    width: fullWidth === true ? "100%" : "min(300px, calc(100% - 20px))",
    height: 40 - 15,
    background: $theme.midground,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 7.5,
    paddingBottom: 7.5,
    borderRadius: 10,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  }),
);

export const FileInput = React.forwardRef<FileInputRefType, FileInputPropsType>(
  function FileInput(props: FileInputPropsType, ref): JSX.Element {
    const [progress, setProgress] = React.useState<number | null>(null);
    useImperativeHandle(ref, () => ({ reset: () => setProgress(null) }));
    const inputStyling = useThemeStyling<"input.standard">("input.standard");
    const uploadFile = useUploadFile();
    const onChange = !props.autoUpload
      ? props.onChange
      : (evt: React.ChangeEvent<HTMLInputElement>): void => {
        const file = evt?.target?.files?.[0];
        if (file != null) {
          if (props.skipCropping === true) {
            uploadFile(file, { onProgress: setProgress })
              .then(({ fsId }) => {
                setProgress(1);
                props.onFileId(fsId);
                if (props.onImagePreviewUrlAvailable != null) {
                  props.onImagePreviewUrlAvailable(URL.createObjectURL(file));
                }
              })
              .catch(() => {
                setProgress(null);
              });
          } else {
            void cropImageToSquareAndDownsize(file).then(
              (croppedImageBlob) => {
                uploadFile(croppedImageBlob, { onProgress: setProgress })
                  .then(({ fsId }) => {
                    setProgress(1);
                    props.onFileId(fsId);
                    if (props.onImagePreviewUrlAvailable != null) {
                      props.onImagePreviewUrlAvailable(
                        URL.createObjectURL(croppedImageBlob),
                      );
                    }
                  })
                  .catch(() => {
                    setProgress(null);
                  });
              },
            );
          }
        }
      };

    return (
      <div
        style={{
          paddingBottom: 12.5,
          width:
            props.fullWidth === true ? "100%" : "min(300px, calc(100% - 20px))",
          fontSize: props.fontSize ?? 14,
          fontFamily: '"Work Sans", sans-serif',
        }}
      >
        <Typography.Label>{props.label}</Typography.Label>
        {progress != null ? (
          <div
            style={{
              backgroundColor: "#eee",
              borderRadius: 10,
              outline: "none",
              border: "none",
              width: "calc(100%)",
              display: "block",
              fontSize: "inherit",
              fontFamily: '"Work Sans", sans-serif',
              height: "2.85em",
              ...props.style,
              ...inputStyling,
            }}
          >
            <div
              style={{
                width: Math.floor(progress * 100).toString() + "%",
                background: progress === 1 ? "#4caf50" : "#2196f3",
                height: "100%",
                borderRadius: "inherit",
                color: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                setProgress(null);
              }}
            >
              {progress === 1 ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 10,
                  }}
                >
                  <FontAwesomeIcon icon={faCheck} /> File Uploaded! Click to
                  change
                </div>
              ) : null}
            </div>
          </div>
        ) : (
          <FileInputContainer>
            <input type="file" ref={props.inputRef} onChange={onChange} />
          </FileInputContainer>
        )}
      </div>
    );
  },
);

type FileInputV2PropsType = Omit<PropsType, "type"> & {
  skipCropping?: boolean;
  autoUpload: true;
  onFileId: (fileId: string | null) => void;
  onImagePreviewUrlAvailable?: (imageUrl: string | null) => void;
  square?: boolean;
};

export const FileInputV2 = React.forwardRef<
  FileInputRefType,
  FileInputV2PropsType
>(function FileInputV2(props: FileInputV2PropsType, ref): JSX.Element {
  const theme = useTheme();
  const [progress, setProgress] = React.useState<number | null>(null);
  useImperativeHandle(ref, () => ({ reset: () => setProgress(null) }));
  const inputStyling = useThemeStyling<"input.standard">("input.standard");
  const uploadFile = useUploadFile();
  const onChange = !props.autoUpload
    ? props.onChange
    : (evt: React.ChangeEvent<HTMLInputElement>): void => {
      const file = evt?.target?.files?.[0];
      if (file != null) {
        if (props.skipCropping === true) {
          uploadFile(file, { onProgress: setProgress })
            .then(({ fsId }) => {
              setProgress(1);
              props.onFileId(fsId);
              if (props.onImagePreviewUrlAvailable != null) {
                props.onImagePreviewUrlAvailable(URL.createObjectURL(file));
              }
            })
            .catch(() => {
              setProgress(null);
            });
        } else {
          void cropImageToSquareAndDownsize(file).then((croppedImageBlob) => {
            uploadFile(croppedImageBlob, { onProgress: setProgress })
              .then(({ fsId }) => {
                setProgress(1);
                props.onFileId(fsId);
                if (props.onImagePreviewUrlAvailable != null) {
                  props.onImagePreviewUrlAvailable(
                    URL.createObjectURL(croppedImageBlob),
                  );
                }
              })
              .catch(() => {
                setProgress(null);
              });
          });
        }
      }
    };

  const reset = React.useCallback(() => {
    setProgress(null);
    if (props.onImagePreviewUrlAvailable) {
      props.onImagePreviewUrlAvailable(null);
    }
    props.onFileId(null);
  }, [props]);

  return (
    <div
      style={{
        paddingBottom: 0,
        width:
          props.square === true ? 40 : (props.fullWidth === true ? "100%" : "min(300px, calc(100% - 20px))"),
        fontSize: props.fontSize ?? 14,
        fontFamily: '"Work Sans", sans-serif',
        userSelect: "none",
      }}
    >
      {progress != null ? (
        <div
          style={{
            backgroundColor: "#eee",
            borderRadius: 10,
            outline: "none",
            border: "none",
            width: "calc(100%)",
            display: "block",
            fontSize: "inherit",
            fontFamily: '"Work Sans", sans-serif',
            height: "2.85em",
            ...props.style,
            ...inputStyling,
          }}
        >
          <div
            style={{
              width: Math.floor(progress * 100).toString() + "%",
              background: progress === 1 ? "#4caf50" : "#2196f3",
              height: "100%",
              borderRadius: "inherit",
              color: "white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={reset}
          >
            {progress === 1 ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 10,
                  paddingLeft: 30,
                  paddingRight: 30,
                }}
              >
                {props.square === true ?
                  <FontAwesomeIcon icon={faCheck} />
                  : <>
                    <FontAwesomeIcon icon={faCheck} /> File Uploaded! Click to change
                  </>}
              </div>
            ) : <LoadingSpinner />}
          </div>
        </div>
      ) : (
        <label>
          <div
            style={{
              backgroundColor: "#eee",
              borderRadius: 10,
              outline: "none",
              border: "none",
              display: "block",
              fontSize: "inherit",
              fontFamily: '"Work Sans", sans-serif',
              height: 40,
              width: props.square === true ? 40 : "calc(100%)",
              ...(props.square === true ? { display: "flex", justifyContent: "center", alignItems: "center" } : {}),
              ...props.style,
              ...inputStyling,
            }}
          >
            <div
              style={{
                width: "100%",
                background: theme.midground,
                height: "100%",
                borderRadius: "inherit",
                color: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                setProgress(null);
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 10,
                }}
              >
                {props.square ? <FontAwesomeIcon icon={faCloudUpload} /> : props.label ?? "Click here to upload..."}
              </div>
            </div>
          </div>
          <input
            type="file"
            ref={props.inputRef}
            onChange={onChange}
            style={{ display: "none" }}
          />
        </label>
      )}
    </div>
  );
});
