import React from "react";
import * as GetAssetBP from "../../../blueprints/stuff/get-asset";
import { seamlessClient } from "../../../seamless-client";
import { LoadingSpinnerFullView } from "../../../platform/loading/spinner-loading-full";
import { EmptySplash } from "@hiyllo/ux/empty-splash";
import {
  faChainBroken,
  faExclamationTriangle,
} from "@fortawesome/pro-light-svg-icons";
import { FileAssetView } from "./file-asset-view";


export const StuffAssetView = React.memo(function StuffAssetView(props: {
  uuid: string;
}): JSX.Element {
  const assetQuery = seamlessClient.useQuery<GetAssetBP.Plug>(
    GetAssetBP,
    { uuid: props.uuid },
  );

  if (assetQuery.isLoading) {
    return <LoadingSpinnerFullView />;
  }

  if (assetQuery.error != null) {
    return (
      <EmptySplash
        icon={faExclamationTriangle}
        label={assetQuery.error.message}
      />
    );
  }

  const { asset } = assetQuery.data;

  if (asset.type !== "file") {
    return (
      <EmptySplash
        icon={faChainBroken}
        label="Unsupported asset type. This link may be broken"
      />
    );
  }

  return <FileAssetView asset={asset} />;
});
