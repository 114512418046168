/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { UserFacingStuffContainerType } from "../../types/stuff/container";

export type ParamsType = {
  uuid: string | null;
};
export type ResponseType = {
  /**
   * This will only be null if the container is undefined
   */
  container: UserFacingStuffContainerType | null;
};
export const Endpoint = 'stuff/get-container-details';
export const Method = 'GET';

export interface Plug {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
}export const paramsSchema = {"type":"object","properties":{"uuid":{"type":["null","string"]}},"required":["uuid"],"$schema":"http://json-schema.org/draft-07/schema#"}