import { styled } from "@hiyllo/ux/styled";
import React from "react";

const TaskCardPillInner = styled<
  "div",
  {
    withLabel?: boolean;
  }
>("div", ({ $theme, withLabel }) => ({
  backgroundColor: withLabel ? $theme.midground2 : $theme.midground1,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  height: 25,
  paddingLeft: 5,
  paddingRight: 5,
  borderRadius: 5,
  gap: 5,
  cursor: "pointer",
  fontSize: 12.5,
}));

const TaskCardPillOuter = styled<"div">("div", ({ $theme }) => ({
  backgroundColor: $theme.midground1,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  height: "100%",
  paddingLeft: 7.5,
  paddingRight: 0,
  borderRadius: 5,
  gap: 7.5,
  cursor: "pointer",
  fontSize: 12.5,
}));

export const TaskPill = React.memo(function TaskPill(
  props: React.PropsWithChildren<{
    label?: string | null;
    onClick?: (evt: React.MouseEvent) => void;
    _ref?: React.MutableRefObject<HTMLDivElement>;
  }>,
): JSX.Element {
  if (props.label != null) {
    return (
      <TaskCardPillOuter onClick={props.onClick} ref={props._ref}>
        {props.label}
        <TaskCardPillInner withLabel>{props.children}</TaskCardPillInner>
      </TaskCardPillOuter>
    );
  }

  return (
    <TaskCardPillInner ref={props._ref} onClick={props.onClick}>
      {props.children}
    </TaskCardPillInner>
  );
});
