import React from "react";
import { Form, FormButton, Input } from "../../../ux/alpha";
import { useConfigPropertyHandle } from "../hooks/config-property-handle";

export const BasicTextConfigPropertyEditor = (props: {
  path: string;
  label: string;
}): JSX.Element => {
  const handle = useConfigPropertyHandle<string | null>(props.path);
  const nameInputRef = React.useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = React.useState<string | null>(null);

  const onSubmit = (): void => {
    const value = nameInputRef.current?.value;

    if (value == null) {
      return;
    }

    void handle.onChange(value === "" ? null : value);
  };

  if (handle.isLoading) {
    return <div />;
  }

  return (
    <Form isLoading={handle.changeLoading} onSubmit={onSubmit}>
      <div style={{ minWidth: 250 }}>
        <div style={{ fontSize: 16, marginBottom: 10 }}>
          {props.label +
            (inputValue === handle.current || inputValue === null
              ? ""
              : " (Changed)")}
        </div>
        <Input
          type="text"
          inputRef={nameInputRef}
          defaultValue={handle.current ?? ""}
          onChange={(evt) => {
            setInputValue(evt.target.value);
          }}
        />
        <FormButton label="Save" />
      </div>
    </Form>
  );
};
