import React from "react";
import { DayWidthContext } from "../week-view/contexts";
import { TimeColumn } from "../week-view/components/time-column";
import { DayColumn } from "../week-view/components/day-column";
import { DatePositioned } from "../week-view/components/date-positioned";
import moment from "moment";
import { useHourHeight } from "../week-view/hooks/use-hour-height";
import { styled } from "@hiyllo/ux/styled";
import {
  type CalendarEvent,
  type EventGuestInviteeType,
} from "@hiyllo/omni-common/src/types/calendar/calendar-event";

import * as RetrieveEventsBP from "../../../../blueprints/calendar/retrieve-events";
import { seamlessClient } from "../../../../seamless-client";
import { useSelf } from "@hiyllo/omni-continuity";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/pro-regular-svg-icons";
import { PositionedEvents } from "../week-view/event-tile-renderer";

const DayPreviewContainer = styled("div", ({ $theme }) => ({
  display: "flex",
  flexDirection: "row",
  overflow: "auto",
  height: 0,
  flexGrow: 1,
  alignItems: "flex-start",
  position: "relative",
  background: $theme.background3,
  borderRadius: 10,
  padding: 0,
  paddingRight: 0,
}));

interface PropsType {
  startTime: string;
  endTime: string;
  date: string;
  invitees: EventGuestInviteeType[];
  onChangeDate: (date: string) => void;
  ignoreEvents?: string | string[];
}

export const VisualPreviewWithGuests = React.memo(
  function VisualPreviewWithGuests({
    onChangeDate,
    startTime,
    endTime,
    date,
    invitees,
    ignoreEvents = "",
  }: PropsType): JSX.Element {
    const eventPlaceholderTileRef = React.useRef<HTMLDivElement>(null);
    const startDate = moment(startTime + date, "HH:mmYYYY-MM-DD");
    const endDate = moment(endTime + date, "HH:mmYYYY-MM-DD");
    const { userId } = useSelf();

    React.useEffect(() => {
      eventPlaceholderTileRef.current?.scrollIntoView();
    }, [startTime]);

    const durationInMinutes = Math.floor(endDate.diff(startDate) / 1000 / 60);
    const hourHeight = useHourHeight();
    const minuteHeight = hourHeight / 60;
    const { call: callRetrieveEvents } =
      seamlessClient.useMutation<RetrieveEventsBP.Plug>(
        RetrieveEventsBP,
      );
    const inviteeUsers = React.useMemo(
      () => [
        userId,
        ...invitees
          .filter<{ type: "internal"; userId: string }>(
            (i): i is { type: "internal"; userId: string } =>
              i.type === "internal",
          )
          .map((i) => i.userId),
      ],
      [userId, invitees],
    );
    const [existingEvents, setExistingEvents] = React.useState<CalendarEvent[]>(
      [],
    );

    React.useEffect(() => {
      const startDate = moment(startTime + date, "HH:mmYYYY-MM-DD");
      const endDate = moment(endTime + date, "HH:mmYYYY-MM-DD");

      void callRetrieveEvents({
        after: startDate.startOf("day").toDate(),
        before: endDate.endOf("day").toDate(),
        calendarForUsers: inviteeUsers,
      }).then((res) =>
        setExistingEvents(
          res.events.filter((e) =>
            typeof ignoreEvents === "string"
              ? e.uuid !== ignoreEvents
              : !ignoreEvents.includes(e.uuid),
          ),
        ),
      );
    }, [
      callRetrieveEvents,
      startTime,
      endTime,
      userId,
      inviteeUsers,
      date,
      ignoreEvents,
    ]);

    return (
      <DayWidthContext.Provider value={200}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              paddingBottom: 15,
              alignItems: "center",
            }}
          >
            <div
              style={{ cursor: "pointer" }}
              onClick={() =>
                onChangeDate(
                  moment(startTime + date, "HH:mmYYYY-MM-DD")
                    .subtract(1, "days")
                    .format("YYYY-MM-DD"),
                )
              }
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </div>
            <div
              style={{
                width: 120,
                textAlign: "center",
                userSelect: "none",
              }}
            >
              {moment(startTime + date, "HH:mmYYYY-MM-DD").format(
                "ddd, MMM Do",
              )}
            </div>
            <div
              style={{ cursor: "pointer" }}
              onClick={() =>
                onChangeDate(
                  moment(startTime + date, "HH:mmYYYY-MM-DD")
                    .add(1, "days")
                    .format("YYYY-MM-DD"),
                )
              }
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </div>
          </div>
          <DayPreviewContainer>
            <TimeColumn />
            <DayColumn day={3} />
            <PositionedEvents
              events={existingEvents}
              invitees={invitees}
              overrideDay={1}
              range={{
                from: startDate.clone().startOf("day").toDate(),
                to: endDate.clone().endOf("day").toDate(),
              }}
            />
            <DatePositioned
              date={{
                day: 1,
                hours: startDate.hours(),
                minutes: startDate.minutes(),
              }}
            >
              <div
                ref={eventPlaceholderTileRef}
                style={{
                  borderColor: "#2196f3",
                  borderWidth: 5,
                  borderRadius: 2.5,
                  borderStyle: "dashed",
                  width: 200 - 1.25 * 2 - 10,
                  marginLeft: 1.25,
                  marginRight: 1.25,
                  zIndex: 2,
                }}
              >
                <div
                  style={{ height: durationInMinutes * minuteHeight - 12.5 }}
                ></div>
              </div>
            </DatePositioned>
          </DayPreviewContainer>
        </div>
      </DayWidthContext.Provider>
    );
  },
);
