import React from "react";
import { type PositioningDate } from "../consts";
import { useHourHeight } from "./use-hour-height";
import { DayWidthContext } from "../contexts";

export function useDetermineDateFromPosition(
  _dayWidth?: number,
): (opts: { x: number; y: number }) => PositioningDate {
  const dayWidthCtx = React.useContext(DayWidthContext);
  const dayWidth = _dayWidth ?? dayWidthCtx;
  const hourHeight = useHourHeight();
  return React.useCallback(
    ({ x, y }: { x: number; y: number }) => {
      const minuteHeight = hourHeight / 60;
      const minutes = Math.floor(y / minuteHeight);
      const base = Math.floor((x - 60) / dayWidth);

      return {
        day: base === 6 ? 0 : ((base + 1) as PositioningDate["day"]),
        hours: Math.floor(minutes / 60),
        minutes: Math.floor((minutes % 60) / 15) * 15,
      };
    },
    [dayWidth, hourHeight],
  );
}
