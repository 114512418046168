import { type OptionType } from "@hiyllo/ux/select";

export const SPRINT_DURATION_OPTIONS: OptionType[] = [
  {
    label: "1 Days",
    value: 1,
  },
  {
    label: "2 Days",
    value: 2,
  },
  {
    label: "3 Days",
    value: 3,
  },
  {
    label: "4 Days",
    value: 4,
  },
  {
    label: "5 Days",
    value: 5,
  },
  {
    label: "6 Days",
    value: 6,
  },
  {
    label: "1 Week",
    value: 7,
  },
  {
    label: "2 Weeks",
    value: 14,
  },
];
