/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { StuffAccessType } from "../../types/stuff/access";

export type ParamsType = {
  uuid: string;
  set: {
    access: StuffAccessType;
  };
};
export type ResponseType = void;
export const Endpoint = "stuff/set-container-access";
export const Method = "POST";

export interface Plug {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
}
export const paramsSchema = {"type":"object","properties":{"uuid":{"type":"string"},"set":{"type":"object","properties":{"access":{"description":"If inherit is specified, the access level is inherited from the parent container.\nImmediate access traverses inherit up the tree to the first parent whose access\ntype is not inherit.\n\nIf root is specified, the access is derived from the context of the root container","anyOf":[{"type":"object","properties":{"explicit":{"type":"object","properties":{}}},"required":["explicit"]},{"enum":["inherit","root"],"type":"string"}]}},"required":["access"]}},"required":["set","uuid"],"$schema":"http://json-schema.org/draft-07/schema#"}