import React from "react";
import { DefaultEDataContext } from "./contexts/default-edata-context";
import {
  type MeetEDataParamsType,
  type CalendarEDataParamsType,
  type ChatEDataParamsType,
  type EDataType,
  type GreenlistingEDataParamsType,
  type MailEDataParamsType,
  type PostsEDataParamsType,
  type ProfileEDataParamsType,
  type TasksEDataParamsType,
  type TicketsEDataParamsType,
  type HomeEDataParamsType,
  type OrganizationEDataParamsType,
  type StuffEDataParamsType,
  type FormsEDataParamsType,
  type StudioEDataParamsType,
  ContactsEDataParamsType,
} from "../../../types/navigation/edata";
import { type Features } from "../../../types/navigation/features";
import { usePath } from "@hiyllo/omni-router";

type EDataComponentType<ParamsType> = React.ComponentType<{
  params: ParamsType;
}> | null;

interface ComponentMap {
  [Features.posts]: EDataComponentType<PostsEDataParamsType>;
  [Features.chat]: EDataComponentType<ChatEDataParamsType>;
  [Features.profile]: EDataComponentType<ProfileEDataParamsType>;
  [Features.tasks]: EDataComponentType<TasksEDataParamsType>;
  [Features.calendar]: EDataComponentType<CalendarEDataParamsType>;
  [Features.tickets]: EDataComponentType<TicketsEDataParamsType>;
  [Features.greenlisting]: EDataComponentType<GreenlistingEDataParamsType>;
  [Features.mail]: EDataComponentType<MailEDataParamsType>;
  [Features.meet]: EDataComponentType<MeetEDataParamsType>;
  [Features.home]: EDataComponentType<HomeEDataParamsType>;
  [Features.organization]: EDataComponentType<OrganizationEDataParamsType>;
  [Features.stuff]: EDataComponentType<StuffEDataParamsType>;
  [Features.support]: EDataComponentType<StuffEDataParamsType>;
  [Features.workspaceConfig]: EDataComponentType<null>;
  [Features.forms]: EDataComponentType<FormsEDataParamsType>;
  [Features.feedback]: EDataComponentType<null>;
  [Features.studio]: EDataComponentType<StudioEDataParamsType>;
  [Features.wardrobe]: EDataComponentType<null>;
  [Features.contacts]: EDataComponentType<ContactsEDataParamsType>;
}

export const EDataRouter = React.memo(function EDataRouter(props: {
  componentMap: ComponentMap;
  defaultEData?: EDataType;
}): JSX.Element {
  const eData = usePath();
  const Component = props.componentMap[eData.feature];

  if (Component === null) {
    return <div>404: Component {eData.feature} not found</div>;
  }

  return (
    <DefaultEDataContext.Provider value={eData}>
      {/** @ts-expect-error zzz */}
      <Component params={eData.params} />
    </DefaultEDataContext.Provider>
  );
});

export default EDataRouter;
