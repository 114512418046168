import React from "react";
import {
  DEFAULT_STATUSES,
  type ListTasksSlimTaskType,
  type TaskStatusType,
} from "../../../types/tasks/task-item";
import { styled } from "@hiyllo/ux/styled";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { type TaskOrderType } from "../../../types/tasks/tasks-organization";
import { TaskCard } from "../components/task-card";
import {
  type OnChangeOrderFnType,
  StatusPill,
  useDNDOnDragEnd,
  useOrder,
} from "@hiyllo/omni-tasks";

const Container = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: 20,
});

interface ListViewPropsType {
  tasks: ListTasksSlimTaskType[];
  isReady?: boolean;
  order: TaskOrderType;
  onChangeOrder: OnChangeOrderFnType;
}

const TasksContext = React.createContext<ListTasksSlimTaskType[]>([]);
const TaskOrderContext = React.createContext<TaskOrderType>({});

const EmptyGroupPlaceholderContainer = styled("div", ({ $theme }) => ({
  borderColor: $theme.midground,
  borderStyle: "solid",
  borderWidth: 2,
  height: 50,
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: 10,
  position: "absolute",
}));
const EmptyGroupPlaceholder = React.memo(
  function EmptyGroupPlaceholder(): JSX.Element {
    return (
      <EmptyGroupPlaceholderContainer>
        Nothing Here
      </EmptyGroupPlaceholderContainer>
    );
  },
);

const TaskGroup = React.memo(function TaskGroup(props: {
  status: TaskStatusType;
}): JSX.Element {
  const tasks = React.useContext(TasksContext);
  const order = React.useContext(TaskOrderContext);
  const resolvedTasks: ListTasksSlimTaskType[] = React.useMemo(() => {
    return order[props.status.id]
      .map((taskUUID) => {
        return tasks.find((task) => task.uuid === taskUUID);
      })
      .filter((t): t is ListTasksSlimTaskType => t != null);
  }, [order, props.status.id, tasks]);

  return (
    <div>
      <div style={{ marginBottom: 10 }}>
        <StatusPill status={props.status} onChangeStatus={null} />
      </div>
      <Droppable droppableId={props.status.id}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={{ minHeight: 50, position: "relative" }}
          >
            {resolvedTasks.map((task, i) => {
              return (
                <Draggable key={task.uuid} draggableId={task.uuid} index={i}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={{
                        ...provided.draggableProps.style,
                        marginBottom: 7.5,
                      }}
                    >
                      <TaskCard isDragging={snapshot.isDragging} task={task} />
                    </div>
                  )}
                </Draggable>
              );
            })}
            {resolvedTasks.length === 0 ? <EmptyGroupPlaceholder /> : null}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  );
});

export const ListView = React.memo(function ListView(
  props: ListViewPropsType,
): JSX.Element {
  const order = useOrder(props.order, props.tasks);

  const onDragEnd = useDNDOnDragEnd({
    order,
    onChangeOrder: props.onChangeOrder,
    tasks: props.tasks,
  });

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <TasksContext.Provider value={props.tasks}>
        <TaskOrderContext.Provider value={order}>
          <Container>
            <TaskGroup status={DEFAULT_STATUSES[1]} />
            <TaskGroup status={DEFAULT_STATUSES[0]} />
            <TaskGroup status={DEFAULT_STATUSES[2]} />
            <TaskGroup status={DEFAULT_STATUSES[3]} />
            <div style={{ height: 20 }} />
          </Container>
        </TaskOrderContext.Provider>
      </TasksContext.Provider>
    </DragDropContext>
  );
});
