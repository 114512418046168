import React, { type FormEvent } from "react";
import { AccountsPageWrapper, CardHeader } from "../components/common";
import { FormButton, Form, Input, CardA } from "../../../ux/alpha";

import * as ChangePasswordWithResetTokenBP from "../../../blueprints/accounts/user/reset-password/change-password-with-reset-token";
import { seamlessClient } from "../../../seamless-client";
import { useNavigate, useParams } from "react-router-dom";
import { hashString } from "../../../utils/browser-safe-sha256";

export const CompleteResetPassword = (): JSX.Element => {
  const navigate = useNavigate();
  const { code } = useParams();
  const newPasswordInputRef = React.useRef<HTMLInputElement>(null);
  const changePasswordMutation =
    seamlessClient.useMutation<ChangePasswordWithResetTokenBP.Plug>(
      ChangePasswordWithResetTokenBP,
    );
  const resetPassword = React.useCallback(
    (evt?: FormEvent) => {
      evt?.preventDefault();

      const newPassword = newPasswordInputRef.current?.value;

      if (newPassword == null) {
        return;
      }

      void hashString(newPassword).then((newPassword) => {
        void changePasswordMutation
          .call({
            resetToken: code as string,
            newPassword,
          })
          .then(() => {
            navigate("/login?prs=1");
          });
      });
    },
    [changePasswordMutation, code, navigate],
  );

  return (
    <AccountsPageWrapper>
      <CardA
        style={{
          margin: 40,
          padding: 40,
          maxWidth: 600,
        }}
      >
        <CardHeader>Reset your password</CardHeader>
        <div>
          <Form
            onSubmit={resetPassword}
            isLoading={changePasswordMutation.isLoading}
          >
            <Input
              label="New Password"
              type="password"
              inputRef={newPasswordInputRef}
            />
            <FormButton label="Submit" />
          </Form>
        </div>
      </CardA>
    </AccountsPageWrapper>
  );
};
